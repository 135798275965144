import React, { useContext, useEffect, useState } from 'react'
import { useCallback } from 'react'
import PropTypes from 'prop-types'

import { useHttp } from '../../hooks/http.hook'
import { AuthContext } from '../../context/AuthContext'

import { Avatar, Card, CardActions, CircularProgress } from '@mui/material'
import Button from '@mui/material/Button'
import LockIcon from '@mui/icons-material/Lock'
import { Form } from 'react-bootstrap'
import TextField from '@mui/material/TextField'

import { getURLAPI } from '../../utils/getURL'
import { Client as WebSocket } from 'rpc-websockets'
import Box from '@mui/material/Box'
//import { REFUSED } from 'dns'
const MyLogin = () => {
  //console.log('Refresh !!!')
  //const [loading1, setLoading] = useState(false)

  const auth = useContext(AuthContext)

  const config = auth.config

  const {
    loading,
    request,
    error,
    clearError,
    getIpInfo,
    setLoading,
    setError,
  } = useHttp()
  const [form, setForm] = useState({
    login: '',
    password: '',
  })
  //const [ws_client, setWs_client] = useState(null)
  const [change, setChange] = useState(false)

  //if (!ws_client) {
  //ws_client = new WebSocket(URL_WS) //'ws://' + ws_host + ':' + ws_port)
  //  setWs_client(new WebSocket(URL_WS))
  // }

  const changeHandler = (event) => {
    let value = event.target.value
    if (event.target.name === 'login') value = value.toLowerCase()
    setForm({ ...form, [event.target.name]: value })
    setChange(true)
    clearError()
  }
  //async
  const wssrequest = useCallback(async (options = null, URL_WS = null) => {
    setLoading(true)
    try {
      //URL_WS = 'wss://shop.pegas-sk.com.ua:8043'

      let ws_client = new WebSocket(URL_WS)

      ws_client.on('close', function () {
        console.log('event : ws_client close !!!')
      })
      ws_client.on('error', (error) => {
        console.log('ws_client error', error)
      })

      ws_client.on('open', async function () {
        // login your client to be able to use protected methods
        ws_client
          .login(options)
          .then(function (result) {
            //console.log('read from table Users where login passw : ', result)
            /*
            if (result.status === 200) {
              auth.login(result.data.token, result.data.userId)
            } else if (result.status === 400) {
              //err
              console.log('ERR login : ', result)
              setError(result.message)
            }
*/

            //let options = { ...form }
            let tableName = 'Users'
            let action = { metod: 'login' }
            //read from table UnitTypes, where  id=1
            ws_client
              .call('Model_read', { tableName, where: options, action })
              .then(function ({ tableName, result }) {
                //console.log('read from table Users where login passw : ',result)
                if (result.status === 200) {
                  auth.login(result.data.token, result.data.userId)
                } else if (result.status === 400) {
                  //err
                  setError(result.message)
                }
              })
              .catch(function (error) {
                console.log('err', error)
                setError(error)
              })

            setLoading(false)
          })
          .catch(function (error) {
            console.log('auth failed - error : ', error)
            setLoading(false)
            setError(error)
          })
      })
    } catch (error) {
      console.log(error)
      setLoading(false)
      setError(error)
    }
  })

  //
  const loginHandler = async () => {
    try {
      const config = auth.config

      //      let URLAPI = getURLAPI(config)

      //
      let URL_WS = getURLAPI(config, 'URL_WS') //get address WebSocket BK from config

      let options = { ...form }

      const { login, password } = options

      let message
      if (!login || login === '') {
        message = 'Не задан login'
        setError(message)

        return
      }

      if (!password || password === '') {
        message = 'Не задан пароль'
        setError(message)

        return
      }

      const data1 = await wssrequest(options, URL_WS)
      /*
      const data = await request(
        '/api/auth/login',
        'POST',
        { ...form },
        {},
        URLAPI
      )
*/
      /*
      const data = await request('/api/auth/login', 'POST', { ...form })
      auth.login(data.token, data.userId)
*/
      /*    const ipinfo = await getIpInfo('185.30.200.25')
      console.log('1', ipinfo)
      const ipinfo2 = await getIpInfo()
      console.log('2', ipinfo2)
      */
    } catch (e) {
      console.log('errr e', e)
    }
  }

  const getChange = () => {
    if (change === true && form.login !== '' && form.password !== '')
      return 'contained'
    else return 'outlined'
  }

  const handleSubmit = (auth) => {
    setLoading(true)
    /*
    login(
      auth,
      location.state ? (location.state as any).nextPathname : '/'
    ).catch((error: Error) => {
      setLoading(false)
      notify(
        typeof error === 'string'
          ? error
          : typeof error === 'undefined' || !error.message
          ? 'ra.auth.sign_in_error'
          : error.message,
        {
          type: 'warning',
          messageArgs: {
            _:
              typeof error === 'string'
                ? error
                : error && error.message
                ? error.message
                : undefined,
          },
        }
      )
    })
    */
  }
  //  const image = require(`assets/wt2.png`)
  //const image = Image('anna/wt1.png')
  // assets/wt2.png`
  //      <img src={image}></img>

  const h = (
    <Form>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100vh',
          alignItems: 'center',
          justifyContent: 'flex-start',
          //          background: 'url(./wt2.png)', //</Form> { image }, //url(https://source.unsplash.com/random/1600x900)',
          backgroundRepeat: 'no-repeat',
          backgroundColor: 'primary.dark',
          backgroundSize: 'cover',
          '&:hover': {
            backgroundColor: 'primary.light',
            //     background: 'url(./hs4.png)', //            background: 'url(https://source.unsplash.com/random/1600x900)',
            //            backgroundColor: 'primary.dark',
            opacity: [0.9, 0.8, 0.7],
          },
        }}
      >
        <Card sx={{ minWidth: 200, marginTop: '11em' }}>
          <Box
            sx={{
              margin: '1em',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Avatar sx={{ bgcolor: 'primary.main' }}>
              <LockIcon />
            </Avatar>
          </Box>
          <Box
            sx={{
              marginTop: '1em',
              display: 'flex',
              justifyContent: 'center',
              color: (theme) => theme.palette.grey[500],
            }}
          >
            Регистрация
          </Box>
          <Box sx={{ padding: '0 1em 1em 1em' }}>
            <Box sx={{ marginTop: '1em' }}>
              <Form.Control
                type="name"
                placeholder="Enter login"
                name="login"
                defaultValue={form.login || ''}
                onChange={changeHandler}
                /*
              <TextField
                id="outlined-basic"
                label="Enter login" //"Ведите имя"
                //variant="standard"
                variant="outlined"
                style={{
                  marginBottom: 15,
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
                type="name"
                name="login"
                defaultValue={form.login}
                onChange={changeHandler}
              />

*/
              />
            </Box>
            <Box sx={{ marginTop: '1em' }}>
              <Form.Control
                type="password"
                name="password"
                placeholder="Password"
                defaultValue={form.password || ''}
                onChange={changeHandler}
              />
            </Box>
          </Box>
          <CardActions sx={{ padding: '0 1em 1em 1em' }}>
            <Button
              variant={getChange()} //"outline-success"
              type="submit"
              color="primary"
              disabled={loading}
              fullWidth
              onClick={loginHandler}
            >
              {loading && <CircularProgress size={25} thickness={2} />}
              {'Вход'}
            </Button>
          </CardActions>
          <CardActions sx={{ padding: '0 1em 1em 1em' }}>
            <Form.Text className="text-muted">{error}</Form.Text>
          </CardActions>
        </Card>
      </Box>
    </Form>
  )

  const h2 = <h1></h1>
  return h
}

MyLogin.propTypes = {
  authProvider: PropTypes.func,
  previousRoute: PropTypes.string,
}

export default MyLogin
/*
interface FormValues {
  username?: string
  password?: string
}
*/
