import React, { useContext, useState } from 'react'
import { useEffect } from 'react'
//import { Button, Form } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'

import { useTable } from 'react-table'
import styled from 'styled-components'
import { useCallback } from 'react'
import { useMediaQuery } from '@mui/material'
import Typography from '@material-ui/core/Typography'
import TextField from '@mui/material/TextField'

import SelectWorkplace from '../../../components/SelectWorkplace'

import Button from '@mui/material/Button'
import Switch, { SwitchProps } from '@mui/material/Switch'
import { getAccessCode } from '../../../access'
import FormControlLabel from '@mui/material/FormControlLabel'
import { AuthContext } from '../../../context/AuthContext'
import moment from 'moment'

import { Card, CardContent, Box } from '@mui/material'
import { useTranslate } from '../../../lib/translate/i18n/useTranslate'

import { getURLAPI } from '../../../utils/getURL'

import { default as Spinner } from '../../../components/Spinner'

const Styles = styled.div`
  table {
    border-spacing: 0;
    border: 1px solid #c3c3c3;
    border-radius: 10px;
    border-collapse: initial;
    tr {
      border-radius: 0px;

      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th,
    td {
      margin: 0;
      padding: 0.5rem;
      border-bottom: 1px solid #c3c3c3;
      border-right: 1px solid #c3c3c3;

      :last-child {
        border-right: 0;
      }
    }
  }
`

const defaultPropGetter = () => ({})

//History
function Table2({
  columns,
  data,
  getHeaderProps = defaultPropGetter,
  getColumnProps = defaultPropGetter,
  getRowProps = defaultPropGetter,
  getCellProps = defaultPropGetter,
}) {
  // Use the state and functions returned from useTable to build your UI
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns,
      data,
    })

  return (
    <table {...getTableProps()}>
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <th
                // Return an array of prop objects and react-table will merge them appropriately
                {...column.getHeaderProps([
                  {
                    className: column.className,
                    style: column.style,
                  },
                  getColumnProps(column),

                  getHeaderProps(column),
                ])}
              >
                {column.render('Header')}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row, i) => {
          prepareRow(row)
          return (
            // Merge user row props in
            <tr {...row.getRowProps(getRowProps(row))}>
              {row.cells.map((cell) => {
                let colorbutton = 'primary'
                let variant = 'outlined'
                if (cell.row.original.color2 === 'green') {
                  colorbutton = 'success'
                  variant = 'contained'
                }
                return (
                  <td
                    // Return an array of prop objects and react-table will merge them appropriately
                    {...cell.getCellProps([
                      {
                        className: cell.column.className,
                        style: cell.column.style,
                      },
                      getColumnProps(cell.column),
                      //  getCellProps(cell),
                    ])}
                    align="center"
                    //                    width="140px"
                  >
                    {(cell.column.id === 'times' &&
                      !cell.row.original.worktime && (
                        <Button
                          size="small"
                          //component={Link}
                          color={colorbutton} //"primary"
                          variant={variant} //"outlined"
                          sx={{ display: 'inline-flex', alignItems: 'center' }}
                          //                        as={Link}
                          //                          variant="outline-primary"
                          style={{ marginBottom: 5 }}
                          onClick={() => {
                            //                        console.log('CLICK!!!', cell.row.original)
                            cell.row.original.fn(
                              cell.row.original.time1,
                              cell.row.original.time2
                            )
                          }}
                          /*
                          onClick={(item) => {
                            const change = true
                            const dispatch = useDispatch()

                            dispatch({
                              type: 'app/set',
                              payload: {
                                id: cell.row.original.worktime_item,
                                field: 'time1',
                                value: cell.row.original.time1,
                              },
                              ob: 'worktime_item',
                            })
                            dispatch({
                              type: 'app/set',
                              payload: {
                                id: cell.row.original.worktime_item,
                                field: 'time2',
                                value: cell.row.original.time2,
                              },
                              ob: 'worktime_item',
                            })
                          }}

                          /*                          to={{
                            pathname: '/pages/worktime/item',
                          }}
                          /*                        state={{
                          id: cell.row.original.worktime.id, //cell.row.original['worktime_id'],
                          arg: { parenthtml: '/pages/worktime/list' },
                        }}
  */
                        >
                          {cell.render('Cell')}
                        </Button>
                      )) ||
                      cell.render('Cell')}
                  </td>
                )
              })}
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}

let columns2 = []
let data2 = []

const Worker = () => {
  const worker_item = useSelector((state) => state.app.body.worker_item)
  const body = useSelector((state) => state.app.body)
  const access = useSelector((state) => state.app.access.access)
  const isadmin = (access && access.isadmin) || false
  const isXSmall = useMediaQuery((theme) => theme.breakpoints.down('sm'))

  const nameTheme = access && access.user && access.user.theme
  const auth = useContext(AuthContext)
  const dispatch = useDispatch()
  const translate = useTranslate()

  const config = auth.config
  let URLAPI = getURLAPI(config)

  //START get all str from table Roles
  const [startRefresh, setStartRefresh] = useState(true)

  //замыкание
  const ws_client = auth.ws_client //body.ws_client

  const item_id = body.item_id

  const readonly = body.readonly || false

  //  const [startWss, setstartWss] = useState(true)

  const GetData_handle = function (_result) {
    const { tableName, result, readonly, action } = _result
    //console.log('result handle1 ', result)
    dispatch({
      type: 'fetch/get_add',
      payload: result,
      ob: 'worker_item',
    })
    dispatch({
      type: 'fetch/get_add',
      payload: readonly,
      ob: 'readonly',
    })
  }

  const Refresh_handle = async function (result, event, parent) {
    const { tableName, action, metod } = result
    /*console.log(
      'Refresh_handle ',
      event,
      new Date().toLocaleTimeString(),
      result
    )*/
    setStartRefresh(true)
  }

  const params = {
    URL_WS: getURLAPI(auth.config, 'URL_WS'), //get address WebSocket BK from config
    auth: { userId: auth.userId, tocken: auth.token },
    handleGetData: GetData_handle,
    //handleChangeData: Refresh_handle,
    handleSetRefresh: Refresh_handle, //setStartRefresh,
    handleErrorRead: null,
    handleErrorChange: null,
    models: [
      {
        model_call_nm: 'Model_read',
        tableName: 'Users',
        where: { id: item_id },
        action: {
          metod: 'getUserName', //'getOne',
          isBlocked: true,
        },
      },
    ],
  }

  //deblock item whet close page
  useEffect(
    () => () => {
      try {
        if (!readonly)
          ws_client && ws_client.deblock({ tableName: 'Users', id: item_id })
      } catch (err) {
        console.log('deblock error ', err)
      }
    },
    []
  )

  if (item_id && ws_client && ws_client.ws_login && startRefresh) {
    ws_client.open_process(params)
    setStartRefresh(false)
  }

  //END get all str from table Roles

  let greybkcolor = 'rgba(255,255,255,.3)'

  if (nameTheme) {
    greybkcolor =
      nameTheme !== 'dark' ? 'rgba(255,255,255,1.0)' : 'rgba(255,255,255,.3)'
  }

  const [HistoryOn, setHistoryOn] = useState(false)

  const handleChangeHistory = useCallback((event) => {
    setHistoryOn(!HistoryOn)
  })

  let maxwidth = '33em'

  if (HistoryOn) maxwidth = '57em'

  //Table2
  columns2 = [
    {
      Header: '№',
      accessor: 'num',
    },
    {
      Header: translate('resources.worker.fields.name'), //'Имя',
      accessor: 'name',
    },
    {
      Header: translate('resources.worker.fields.tel'), //'Телефон 1',
      accessor: 'phone',
    },
  ]

  if (!isXSmall) {
    columns2.push({
      Header: translate('resources.worker.fields.jobtitle'), //'Должность',
      accessor: 'jobTitle',
    })
    columns2.push({
      Header: translate('resources.worker.fields.workplace'), //'Рабочее место',
      accessor: 'workplace',
    })

    columns2.push({
      Header: translate('resources.worker.fields.datechange'), //'Изменено',
      accessor: 'datechange',
    })

    columns2.push({
      Header: translate('resources.worker.fields.user'), //'User',
      accessor: 'userinfo',
    })
  }

  data2 = []

  let num2 = 1
  if (worker_item && worker_item.historis) {
    const worker2 = worker_item.historis

    for (let iw2 in worker2) {
      data2.push({
        num: num2++,
        name: worker2[iw2].fullName,
        jobTitle: worker2[iw2].jobTitle,
        phone: worker2[iw2].phone,
        worker: worker2[iw2],
        workplace: worker2[iw2].workplace && worker2[iw2].workplace.name,
        userinfo:
          worker2[iw2].changeuser &&
          worker2[iw2].changeuser.login +
            '/' +
            worker2[iw2].changeuser.firstName,
        datechange:
          worker2[iw2].createdAt &&
          moment(worker2[iw2].createdAt).format('DD-MM-YY HH:mm:ss'),

        userinfo:
          worker2[iw2].user &&
          worker2[iw2].user.login + '/' + worker2[iw2].user.firstName,
      })
    }
  }

  const getChange = () => {
    if (worker_item && worker_item.change === true) return 'contained'
    else return 'outlined'
    //    if (worker_item && worker_item.change === true) return 'success'
    //    else return 'outline-success'
  }

  const getAccessToChange = () => {
    return readonly || false
  }

  const ready = () => {
    if (worker_item) return true
    else return false
  }

  return (
    <>
      {ready() && (
        <>
          <Box maxWidth={maxwidth}>
            <Card>
              <CardContent>
                <>
                  <div
                    //            className="hstack gap-2"
                    style={{
                      marginTop: -20,
                      display: 'flex',
                      justifyContent: 'space-between',
                    }}
                  >
                    <Typography
                      id="outlined-basic"
                      label="Клиент"
                      //variant="standard"
                      className="title h6"
                      style={{
                        marginTop: 15,
                        marginBottom: 15,
                        marginRight: 150,
                        //display: 'flex',
                        //justifyContent: 'space-between',
                      }}
                    >
                      {translate('resources.worker.title')}
                    </Typography>

                    {(isadmin ||
                      getAccessCode('SHOW_LOGS', access && access.access)) && (
                      <FormControlLabel
                        control={
                          <Switch
                            id={worker_item && worker_item.id}
                            name={'History'}
                            color="primary"
                            onChange={handleChangeHistory}
                            //                          onFocus={onFocus}
                            checked={HistoryOn}
                            //                      {...sanitizeInputRestProps(rest)}
                            //                      {...options}
                            //                      disabled={disabled}
                          />
                        }
                        label={translate('resources.switch.changed')}
                      />
                    )}
                    {readonly && (
                      <Typography
                        id="outlined-basic"
                        label="Title"
                        //variant="standard"
                        //className="title h6"
                        style={{
                          marginTop: 15,
                          marginBottom: 15,
                          //  marginRight: 275,
                          color: 'red',
                        }}
                        //                      {translate('resources.workers.readonly')}
                      >
                        Readonly
                      </Typography>
                    )}
                  </div>
                  {worker_item && (
                    <>
                      <TextField
                        id="outlined-basic"
                        label={translate('resources.worker.fields.name')} //"Имя" //"Ведите имя"
                        //variant="standard"
                        variant="outlined"
                        style={{
                          marginBottom: 15,
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}
                        value={(worker_item && worker_item.fullName) || ''}
                        onChange={(item) => {
                          if (!getAccessToChange()) {
                            worker_item.change = true
                            dispatch({
                              type: 'app/set',
                              payload: {
                                id: worker_item,
                                field: 'fullName',
                                value: item.target.value,
                              },
                              ob: 'worker_item',
                            })
                          }
                        }}
                      />

                      <TextField
                        id="outlined-basic"
                        label={translate('resources.worker.fields.jobtitle')} //"Должность"
                        //variant="standard"
                        variant="outlined"
                        style={{
                          marginBottom: 15,
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}
                        value={(worker_item && worker_item.jobTitle) || ''}
                        onChange={(item) => {
                          if (!getAccessToChange()) {
                            worker_item.change = true
                            dispatch({
                              type: 'app/set',
                              payload: {
                                id: worker_item,
                                field: 'jobTitle',
                                value: item.target.value,
                              },
                              ob: 'worker_item',
                            })
                          }
                        }}
                      />
                      <SelectWorkplace
                        name="workplace"
                        field={worker_item}
                        title="Рабочее место"
                        placeholder={translate(
                          'resources.worker.changeworkplace'
                        )} //"Выберите Рабочее место"
                        value="worker_item"
                        disabled={getAccessToChange()}
                        onChanged={() => {
                          worker_item.change = true
                          dispatch({
                            type: 'app/set',
                            payload: {
                              id: worker_item,
                              field: 'change',
                              value: true,
                            },
                            ob: 'worker_item',
                          })
                        }}
                      />

                      <TextField
                        id="outlined-basic"
                        label={translate('resources.worker.fields.tel1')} //"Телефон 1"
                        //variant="standard"
                        variant="outlined"
                        style={{
                          marginBottom: 15,
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}
                        value={(worker_item && worker_item.phone) || ''}
                        onChange={(item) => {
                          if (!getAccessToChange()) {
                            worker_item.change = true
                            dispatch({
                              type: 'app/set',
                              payload: {
                                id: worker_item,
                                field: 'phone',
                                value: item.target.value,
                              },
                              ob: 'worker_item',
                            })
                          }
                        }}
                      />

                      <TextField
                        id="outlined-basic"
                        label={translate(
                          'resources.worker.fields.telforclient'
                        )} //"Телефон для клиентов"
                        //variant="standard"
                        variant="outlined"
                        style={{
                          marginBottom: 15,
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}
                        value={(worker_item && worker_item.message_phone) || ''}
                        onChange={(item) => {
                          if (!getAccessToChange()) {
                            worker_item.change = true
                            dispatch({
                              type: 'app/set',
                              payload: {
                                id: worker_item,
                                field: 'message_phone',
                                value: item.target.value,
                              },
                              ob: 'worker_item',
                            })
                          }
                        }}
                      />
                      <TextField
                        id="outlined-basic"
                        label={translate(
                          'resources.worker.fields.firstmessage'
                        )} //"Первая строка сообщения клиенту"
                        //variant="standard"
                        variant="outlined"
                        style={{
                          marginBottom: 15,
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}
                        value={
                          (worker_item && worker_item.worktime_message) || ''
                        }
                        onChange={(item) => {
                          if (!getAccessToChange()) {
                            worker_item.change = true
                            dispatch({
                              type: 'app/set',
                              payload: {
                                id: worker_item,
                                field: 'worktime_message',
                                value: item.target.value,
                              },
                              ob: 'worker_item',
                            })
                          }
                        }}
                      />
                    </>
                  )}
                  <Button
                    size="small"
                    component={Link}
                    color="primary"
                    //variant="outlined"
                    sx={{ display: 'inline-flex', alignItems: 'center' }}
                    variant={getChange()} //"outline-success"
                    to={{
                      pathname: '/pages/worker/list',
                    }}
                    //state={worker_item}
                    disabled={getAccessToChange()}
                    onClick={async () => {
                      try {
                        if (!getAccessToChange())
                          await ws_client.wss_setItem({
                            tableName: 'Users',
                            item: worker_item,
                            call_nm: 'Model_update',
                            where: { id: worker_item.id },
                            action: { metod: 'setWorker' },
                          })
                      } catch (e) {
                        console.log('errr e', e)
                      }
                    }}
                  >
                    {translate('resources.button.save')}
                  </Button>
                  <Button
                    size="small"
                    component={Link}
                    color="primary"
                    //variant="outlined"
                    sx={{ display: 'inline-flex', alignItems: 'center' }}
                    style={{ marginLeft: 5 }}
                    variant="outlined" //"outline-danger"
                    to="/pages/worker/list"
                  >
                    {translate('resources.button.cancel')}
                  </Button>
                </>
                <br />
                <br />
                {(isadmin ||
                  getAccessCode('SHOW_LOGS', access && access.access)) &&
                  HistoryOn && (
                    <>
                      {translate('resources.switch.changed')}
                      <Styles>
                        <Table2
                          columns={columns2}
                          data={data2}
                          /*          
          getHeaderProps={(column) => ({
            onClick: () => alert('Header!'),
          })}
          getColumnProps={(column) => ({
            onClick: () => alert('Column!'),
          })}
          */
                          getRowProps={(row) => ({
                            style: {
                              background: row.original.color, //index % 2 === 0 ? 'rgba(0,0,0,.1)' : 'white',
                            },
                          })}
                          getCellProps={(cellInfo) => {
                            console.log('sssss')

                            return {
                              //            onClick: (req, res) => onClickCell(cellInfo, req, res),

                              style: {
                                backgroundColor: `hsl(${
                                  120 * ((120 - cellInfo.value) / 120) * -1 +
                                  120
                                }, 100%, 67%)`,
                              },
                            }
                          }}
                        />
                      </Styles>
                    </>
                  )}
              </CardContent>
            </Card>
          </Box>
        </>
      )}
      {!ready() && (
        <>
          <Box maxWidth={maxwidth}>
            <Card>
              <CardContent>
                <Spinner />
              </CardContent>
            </Card>
          </Box>
        </>
      )}
    </>
  )
}

export default Worker
