import React, { useContext, useState, useEffect } from "react";
import { Button, Form, Col, Row, ListGroup } from "react-bootstrap";
import { Link, Navigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useCallback } from "react";
import SelectConfigCloud from "../../../components/SelectConfigCloud";
import { AuthContext } from "../../../context/AuthContext";
import FormGroup, { FormGroupProps } from "@mui/material/FormGroup";
import Switch, { SwitchProps } from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormHelperText from "@mui/material/FormHelperText";
import { Card, CardContent, Box, Grid, Typography } from "@mui/material";
import { useMediaQuery, Divider, Tabs, Tab, Theme } from "@mui/material";
import { darkTheme, lightTheme } from "../../../layouts/Them/themes";
import { defaultTheme } from "../../../layouts/Them/defaultTheme";
import { useTheme } from "../../../layouts/Them/useTheme";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";

import { useTranslate } from "../../../lib/translate/i18n/useTranslate";
import { useLocaleState } from "../../../lib/translate/i18n/useLocaleState";
import { getURLAPI } from "../../../utils/getURL";

import { default as Spinner } from "../../../components/Spinner";

//  setTheme(darkTheme)

const ConfigPage = () => {
  const config_item = useSelector((state) => state.app.body.config_item);
  const access = useSelector((state) => state.app.access.access);
  const auth = useContext(AuthContext);
  const body = useSelector((state) => state.app.body);
  const dispatch = useDispatch();
  const [theme, setTheme] = useTheme();

  const [locale, setLocale] = useLocaleState();

  const translate = useTranslate();

  //START get all str from table Roles
  const [startRefresh, setStartRefresh] = useState(true);

  //замыкание
  const ws_client = auth.ws_client; //body.ws_client

  const item_id = auth.userId; //body.item_id

  const readonly = body.readonly || false;

  //  const [startWss, setstartWss] = useState(true)

  const GetData_handle = function (_result) {
    const { tableName, result, readonly, action } = _result;
    //console.log('result handle1 ', result)
    dispatch({
      type: "fetch/get_add",
      payload: result && result.config,
      ob: "config_item",
    });
    dispatch({
      type: "fetch/get_add",
      payload: readonly,
      ob: "readonly",
    });
  };

  const Refresh_handle = async function (result, event, parent) {
    const { tableName, action, metod } = result;
    /*console.log(
      'Refresh_handle ',
      event,
      new Date().toLocaleTimeString(),
      result
    )*/
    setStartRefresh(true);
  };

  const params = {
    URL_WS: getURLAPI(auth.config, "URL_WS"), //get address WebSocket BK from config
    auth: { userId: auth.userId, tocken: auth.token },
    handleGetData: GetData_handle,
    //handleChangeData: Refresh_handle,
    handleSetRefresh: Refresh_handle, //setStartRefresh,
    handleErrorRead: null,
    handleErrorChange: null,
    models: [
      {
        model_call_nm: "Model_read",
        tableName: "Users",
        where: { id: item_id },
        action: {
          metod: "config",
          //            isBlocked:true,
        },
      },

      /*      {
        model_call_nm: 'Model_read',
        tableName: 'UserConfigs',
        where: { id: item_id },
        action: {
          metod: 'getOne',
          isBlocked: true,
        },
      },
      */
    ],
  };

  //deblock item whet close page
  useEffect(
    () => () => {
      try {
        if (!readonly)
          ws_client && ws_client.deblock({ tableName: "Users", id: item_id });
      } catch (err) {
        console.log("deblock error ", err);
      }
    },
    []
  );

  if (item_id && ws_client && ws_client.ws_login && startRefresh) {
    ws_client.open_process(params);
    setStartRefresh(false);
  }

  //END get all str from table Roles

  const [value, setValue] = React.useState(
    (config_item && config_item.theme) || "default"
  );

  const RhandleChangelanguage = (event) => {
    config_item.change = true;
    dispatch({
      type: "app/set",
      payload: {
        id: config_item,
        field: "language",
        value: event.target.value,
      },
      ob: "config_item",
    });

    //    dispatch({ type: 'FETCH_ACCESS', id: access.user.id })

    /*    setValue(event.target.value)*/

    if (event.target.value === "ua") setLocale("ua");
    if (event.target.value === "ru") setLocale("ru");
    if (event.target.value === "en") setLocale("en");
  };

  const RhandleChange = (event) => {
    config_item.change = true;
    dispatch({
      type: "app/set",
      payload: {
        id: config_item,
        field: "theme",
        value: event.target.value,
      },
      ob: "config_item",
    });

    /*    setValue(event.target.value)*/

    if (event.target.value === "default") setTheme(defaultTheme);
    if (event.target.value === "dark") setTheme(darkTheme);
    if (event.target.value === "light") setTheme(lightTheme);
  };

  const getChange = () => {
    if (config_item && config_item.change === true) return "success";
    else return "outline-success";
  };

  const ready = () => {
    if (config_item) return true;
    else return false;
  };

  return (
    <>
      {ready() && (
        <>
          <Box maxWidth="40em">
            <Card>
              <CardContent>
                <>
                  {config_item && (
                    <>
                      <Form.Group className="mb-3" controlId="formName">
                        <Form.Label>
                          {translate("pos.configuration")}
                        </Form.Label>

                        <Divider />
                        <RadioGroup
                          row
                          //              aria-labelledby="demo-row-radio-buttons-group-label"
                          //            name="row-radio-buttons-group"
                          value={config_item && config_item.theme} //value}
                          onChange={RhandleChange}
                        >
                          <FormControlLabel
                            value="default"
                            control={<Radio />}
                            label="Default"
                          />
                          <FormControlLabel
                            value="light"
                            control={<Radio />}
                            label="Light"
                          />
                          <FormControlLabel
                            value="dark"
                            control={<Radio />}
                            label="Dark"
                          />
                        </RadioGroup>
                        <Divider />
                      </Form.Group>

                      <Form>
                        <Form.Group className="mb-3" controlId="formName">
                          <Form.Label>{translate("pos.language")}</Form.Label>

                          <Divider />
                          <RadioGroup
                            row
                            //              aria-labelledby="demo-row-radio-buttons-group-label"
                            //            name="row-radio-buttons-group"
                            value={config_item && config_item.language} //value}
                            onChange={RhandleChangelanguage}
                          >
                            <FormControlLabel
                              value="ua"
                              control={<Radio />}
                              label="ua"
                            />
                            <FormControlLabel
                              value="ru"
                              control={<Radio />}
                              label="ru"
                            />
                            <FormControlLabel
                              value="en"
                              control={<Radio />}
                              label="en"
                            />
                          </RadioGroup>
                          <Divider />
                        </Form.Group>
                        <>
                          <div className="hstack gap-2">
                            <Button
                              variant={getChange()} //"outline-primary"
                              as={Link}
                              to={{
                                pathname: "/", //auth/config/save',
                              }}
                              //state={{
                              // config_item,
                              //}}
                              onClick={async () => {
                                try {
                                  auth.setdispatchAccess(false);
                                  await ws_client.wss_setItem({
                                    tableName: "UserConfigs",
                                    item: config_item,
                                    call_nm:
                                      config_item && config_item.id
                                        ? "Model_update"
                                        : "Model_create",
                                    where:
                                      config_item && config_item.id
                                        ? {
                                            id: config_item && config_item.id,
                                          }
                                        : { userid: auth.userId },
                                    action: null,
                                  });
                                  setLocale(config_item.config.language);
                                } catch (e) {
                                  console.log("errr e", e);
                                }
                              }}
                            >
                              {translate("resources.button.save")}
                            </Button>{" "}
                            <Button as={Link} variant="outline-primary" to="/">
                              {translate("resources.button.return")}
                            </Button>
                          </div>
                          <br />
                        </>
                      </Form>
                      <br />
                    </>
                  )}
                </>
              </CardContent>
            </Card>
          </Box>
        </>
      )}
      {!ready() && (
        <>
          <Box maxWidth="40em">
            <Card>
              <CardContent>
                <Spinner />
              </CardContent>
            </Card>
          </Box>
        </>
      )}
    </>
  );
};

export default ConfigPage;
