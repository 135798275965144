import React, { forwardRef, useCallback, ReactElement, ReactNode } from 'react'
import { styled } from '@mui/material/styles'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { Link, LinkProps, useMatch } from 'react-router-dom'
import {
  MenuItem,
  MenuItemProps,
  ListItemIcon,
  Tooltip,
  TooltipProps,
  useMediaQuery,
  Theme,
} from '@mui/material'

import { useSidebarState } from './useSidebarState'

export const MenuItemLink = forwardRef((props: MenuItemLinkProps, ref) => {
  const {
    className,
    primaryText,
    leftIcon,
    onClick,
    sidebarIsOpen,
    tooltipProps,
    ...rest
  } = props

  const isSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down('md'))

  const [open, setOpen] = useSidebarState()
  const handleMenuTap = useCallback(
    (e: any) => {
      if (isSmall) {
        setOpen(false)
      }
      onClick && onClick(e)
    },
    [setOpen, isSmall, onClick]
  )

  const to = (typeof props.to === 'string' ? props.to : props.to.pathname) || ''
  const match = useMatch({ path: to, end: to === `/` })

  const renderMenuItem = () => {
    return (
      <StyledMenuItem
        className={clsx(className, {
          [MenuItemLinkClasses.active]: !!match,
        })}
        component={LinkRef}
        // @ts-ignore
        ref={ref}
        tabIndex={0}
        {...rest}
        onClick={handleMenuTap}
      >
        {leftIcon && (
          <ListItemIcon className={MenuItemLinkClasses.icon}>
            {leftIcon}
          </ListItemIcon>
        )}
        {typeof primaryText === 'string' ? primaryText : primaryText}
      </StyledMenuItem>
    )
  }

  return open ? (
    renderMenuItem()
  ) : (
    <Tooltip
      title={typeof primaryText === 'string' ? primaryText : 'primaryText'}
      placement="right"
      {...tooltipProps}
    >
      {renderMenuItem()}
    </Tooltip>
  )
})

export type MenuItemLinkProps = LinkProps &
  MenuItemProps<'li'> & {
    leftIcon?: ReactElement
    primaryText?: ReactNode
    /**
     * @deprecated
     */
    sidebarIsOpen?: boolean
    tooltipProps?: TooltipProps
  }

MenuItemLink.propTypes = {
  className: PropTypes.string,
  leftIcon: PropTypes.element,
  onClick: PropTypes.func,
  primaryText: PropTypes.node,
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  sidebarIsOpen: PropTypes.bool,
}

const PREFIX = 'MenuItemLink'

export const MenuItemLinkClasses = {
  active: `${PREFIX}-active`,
  icon: `${PREFIX}-icon`,
}

const StyledMenuItem = styled(MenuItem, {
  name: PREFIX,
  overridesResolver: (props, styles) => styles.root,
})(({ theme }) => ({
  color: theme.palette.text.secondary,

  [`&.${MenuItemLinkClasses.active}`]: {
    color: theme.palette.text.primary,
  },

  [`& .${MenuItemLinkClasses.icon}`]: { minWidth: theme.spacing(5) },
}))

const LinkRef = forwardRef<HTMLAnchorElement, LinkProps>((props, ref) => (
  <Link ref={ref} {...props} />
))
