const initialState = {
  body: {},
  current: {},
  filter: {},
  access: {},
}
function saveimg(body, action) {
  let rez = body
  if (action.ob === 'worktime_item') {
    if (action.payload.field === 'load_img') {
      rez.worktime_item.load_img = true
    }
    if (action.payload.field === 'image') {
      const filename = action.payload.value.filename
      const image = action.payload.value.image
      let manyimages

      for (let i in rez.worktime_item.items) {
        manyimages =
          rez.worktime_item.items[i].images &&
          rez.worktime_item.items[i].images.manyimages
        for (let j in manyimages) {
          if (manyimages[j].image === filename) {
            rez.worktime_item.items[i].images.manyimages[j].megaimage = image
          }
        }
      }

      //old variant
      /*
      for (let i in rez.worktime_item.items)
        for (let j in rez.worktime_item.items[i].itemimages) {
          if (rez.worktime_item.items[i].itemimages[j].image === filename) {
            rez.worktime_item.items[i].itemimages[j].megaimage = image
          }
        }
        */
    }
  }
  return rez
}
const appReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'fetch/access': {
      return {
        access: { [action.ob]: action.payload },
        current: state.current,
        filter: state.filter,
        body: state.body,
      }
    }
    case 'fetch/access_add': {
      return {
        access: {
          ...state.access,
          [action.ob]: action.payload,
        },
        current: state.current,
        filter: state.filter,
        body: state.body,
      }
    }

    case 'fetch/mega': {
      return {
        access: { ...state.access, [action.ob]: action.payload },
        current: state.current,
        filter: state.filter,
        body: state.body,
      }
    }
    case 'fetch/getimg': {
      return {
        access: state.access,
        current: state.current,
        filter: state.filter,
        body: saveimg(state.body, action),
      }
    }
    case 'fetch/get': {
      return {
        access: state.access,
        current: state.current,
        filter: state.filter,
        body: { [action.ob]: action.payload },
      }
    }
    case 'fetch/get_add': {
      return {
        access: state.access,
        current: state.current,
        filter: state.filter,
        body: {
          ...state.body,
          [action.ob]: action.payload,
        },
      }
    }
    case 'fetch_current/get': {
      return {
        access: state.access,
        current: { [action.ob]: action.payload },
        filter: state.filter,
        body: state.body,
      }
    }
    case 'fetch/item': {
      return {
        access: state.access,
        current: state.current,
        filter: state.filter,
        body: {
          [action.ob + '_item']: action.payload,
        },
      }
    }
    case 'fetch/item_add': {
      return {
        access: state.access,
        current: state.current,
        filter: state.filter,
        body: {
          ...state.body,
          [action.ob + '_item']: action.payload,
        },
      }
    }
    case 'clear/filter': {
      return {
        access: state.access,
        current: state.current,
        filter: {},
        body: state.body,
      }
    }
    case 'fetch/filter': {
      return {
        access: state.access,
        current: state.current,
        filter: {
          ...state.filter,

          [action.ob + '_filter']: action.payload,
        },
        body: state.body,
      }
    }
    case 'app/clearbody': {
      return {
        access: state.access,
        current: state.current,
        filter: state.filter,
        body: {}, //state.body,
      }
    }
    case 'app/set': {
      if (!action.payload.ob) {
        //        if (state.body[action.ob].item && state.body[action.ob].item)
        //        console.log(state.body[action.ob].item)
        if (
          state.body[action.ob] &&
          state.body[action.ob].item &&
          state.body[action.ob].item
        ) {
          return {
            access: state.access,
            current: state.current,
            filter: state.filter,
            body: {
              ...state.body,
              [action.ob]: {
                ...state.body[action.ob],
                item: {
                  ...state.body[action.ob].item,
                  [action.payload.field]: action.payload.value,
                },
              },
            },
          }
        } else {
          return {
            access: state.access,
            current: state.current,
            filter: state.filter,
            body: {
              ...state.body,
              [action.ob]: {
                ...state.body[action.ob],
                [action.payload.field]: action.payload.value,
              },
            },
          }
        }
      } else {
        if (state.body[action.ob].item && state.body[action.ob].item) {
          return {
            access: state.access,
            current: state.current,
            filter: state.filter,
            body: {
              ...state.body,
              [action.ob]: {
                ...state.body[action.ob],
                item: {
                  ...state.body[action.ob].item,
                  [action.payload.ob]: action.payload.value,
                  [action.payload.field]: action.payload.value.id,
                },
              },
            },
          }
        } else {
          return {
            access: state.access,
            current: state.current,
            filter: state.filter,
            body: {
              ...state.body,
              [action.ob]: {
                ...state.body[action.ob],
                [action.payload.ob]: action.payload.value,
                [action.payload.field]: action.payload.value.id,
              },
            },
          }
        }
      }
    }
    case 'app/setconfig': {
      //user,xonfig
      if (!action.payload.ob) {
        //        if (state.body[action.ob].config && state.body[action.ob].config)
        //        console.log(state.body[action.ob].config)
        if (state.body[action.ob].config && state.body[action.ob].config) {
          return {
            //cloudOn
            access: state.access,
            current: state.current,
            filter: state.filter,
            body: {
              ...state.body,
              [action.ob]: {
                ...state.body[action.ob],
                config: {
                  ...state.body[action.ob].config,
                  [action.payload.field]: action.payload.value,
                },
              },
            },
          }
        } else {
          return {
            access: state.access,
            current: state.current,
            filter: state.filter,
            body: {
              ...state.body,
              [action.ob]: {
                ...state.body[action.ob],
                [action.payload.field]: action.payload.value,
              },
            },
          }
        }
      } else {
        if (state.body[action.ob].config && state.body[action.ob].config) {
          return {
            access: state.access,
            current: state.current,
            filter: state.filter,
            body: {
              ...state.body,
              [action.ob]: {
                ...state.body[action.ob],
                config: {
                  ...state.body[action.ob].config,
                  [action.payload.ob]: action.payload.value,
                  [action.payload.field]: action.payload.value.id,
                },
              },
            },
          }
        } else {
          return {
            access: state.access,
            current: state.current,
            filter: state.filter,
            body: {
              ...state.body,
              [action.ob]: {
                ...state.body[action.ob],
                [action.payload.ob]: action.payload.value,
                [action.payload.field]: action.payload.value.id,
              },
            },
          }
        }
      }
    }
    case 'auth/set2': {
      if (!action.payload.ob) {
        return {
          access: state.access,
          current: state.current,
          filter: state.filter,
          body: {
            ...state.body,
            [action.ob]: {
              ...state.body[action.ob],
              [action.payload.field]: action.payload.value,
            },
          },
        }
      }
    }
    case 'auth/set_psw': {
      if (!action.payload.ob) {
        return {
          access: state.access,
          current: state.current,
          filter: state.filter,
          body: {
            ...state.body,
            [action.ob]: {
              ...state.body[action.ob],
              [action.payload.field]: action.payload.value,
            },
          },
        }
      }
    }
    case 'auth/change_psw': {
      if (!action.payload.ob) {
        return {
          access: state.access,
          current: state.current,
          filter: state.filter,
          body: {
            ...state.body,
            [action.ob]: {
              ...state.body[action.ob],
              [action.payload.field]: action.payload.message,
            },
          },
        }
      }
    }
    case 'auth/set_item': {
      if (action.payload.status) {
        switch (action.payload.status) {
          case 200: {
            return state
          }
          case 400: {
            return {
              access: state.access,
              current: state.current,
              filter: state.filter,
              body: { [action.ob + '_item']: action.payload },
            }
          }
          default:
            return state
        }
      }
      return state
    }
    default:
      return state
  }
}

export default appReducer
