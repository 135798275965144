import React, { useContext, ReactElement } from 'react'
import PropTypes from 'prop-types'
import CalendarTodayIcon from '@mui/icons-material/CalendarToday'

import DashboardIcon from '@mui/icons-material/Dashboard'
import FactoryIcon from '@mui/icons-material/Factory'

import { MenuItemLink } from './MenuItemLink'

import { useTranslate } from '../lib/translate/i18n/useTranslate'
import {
  //  AppBar as MuiAppBar,
  //  AppBarProps as MuiAppBarProps,
  Card,
  Divider,
  Typography,
} from '@mui/material'

import { Form, Modal, ListGroup, Row, Col } from 'react-bootstrap'
import TextField from '@mui/material/TextField'
import { AuthContext } from '../context/AuthContext'

import { Box } from '@mui/material'

export const DashboardMenuItem = (props: DashboardMenuItemProps) => {
  const { locale, leftIcon = <CalendarTodayIcon />, ...rest } = props
  const image = require(`../logo1.png`)

  const auth = useContext(AuthContext)

  const translate = useTranslate()
  return (
    <>
      <Card
        style={{
          display: 'flex',
          //        justifyContent: 'space-between',
          //          backgroundColor:linear-gradient(45deg, rgb(254 243 107) 30%, rgb(19 152 238) 90%)
          background:
            'linear-gradient(45deg, rgb(255 246 128) 30%, rgb(128 205 255) 90%)', //'red', // ${theme.palette.grey[500]}, //'red',
          marginTop: -15,
          // marginLeft: 15,
          height: 60,
          //paddingRight: 120,
          borderRadius: 0,
          //marginBottom: 20,
        }}
      >
        <div style={{ display: 'flex', marginLeft: 10, marginTop: 14 }}>
          <img
            className="image-gallery-thumbnail-image"
            src={image}
            height={'32px'}
            width={'32px'} //                          style={(width = 100)}
            //            onClick={() => setModalShow(true)}
            onClick={() => {}}
            onMouseOver={(item) => {
              //              setModalShow(true)
            }}
          ></img>

          <Typography
            //        variant="body2"
            style={{ marginLeft: 10, marginTop: 4 }}
            color="inherit"
            //    className={AppBarClasses.title}
            id="title"
          >
            {translate('pos.menu.title')}
          </Typography>
        </div>
      </Card>

      <Divider />
      <MenuItemLink
        to={`/`}
        primaryText="Dashboard" //{translate('pos.menu.diary')}
        leftIcon={leftIcon}
        {...rest}
      />
    </>
  )
}

export interface DashboardMenuItemProps {
  leftIcon?: ReactElement
  locale?: string
  onClick?: () => void
  dense?: boolean
  /**
   * @deprecated
   */
  sidebarIsOpen?: boolean
}

DashboardMenuItem.propTypes = {
  leftIcon: PropTypes.element,
  locale: PropTypes.string,
  onClick: PropTypes.func,
  dense: PropTypes.bool,
  sidebarIsOpen: PropTypes.bool,
}
