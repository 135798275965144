import React, { useContext, useState, useEffect } from 'react'
//import { Button, Form } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { useMediaQuery } from '@mui/material'
import Typography from '@material-ui/core/Typography'
import TextField from '@mui/material/TextField'
import { AuthContext } from '../../../context/AuthContext'

import { useTable } from 'react-table'
import { getAccessCode } from '../../../access'
import Button from '@mui/material/Button'

import { Card, CardContent, Box } from '@mui/material'
import { useTranslate } from '../../../lib/translate/i18n/useTranslate'

import { getURLAPI } from '../../../utils/getURL'

import { default as Spinner } from '../../../components/Spinner'

import styled from 'styled-components'

const Styles = styled.div`
  table {
    border-spacing: 0;
    border: 1px solid #c3c3c3;
    border-radius: 10px;
    border-collapse: initial;
    tr {
      border-radius: 0px;

      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th,
    td {
      margin: 0;
      padding: 0.5rem;
      border-bottom: 1px solid #c3c3c3;
      border-right: 1px solid #c3c3c3;

      :last-child {
        border-right: 0;
      }
    }
  }
`

const defaultPropGetter = () => ({})

var columns = []
var data = []
var columns1 = []
var data1 = []

const SupervisorItem = () => {
  const supervisor_item = useSelector((state) => state.app.body.supervisor_item)
  const dispatch = useDispatch()
  const auth = useContext(AuthContext)
  const body = useSelector((state) => state.app.body)
  const access = useSelector((state) => state.app.access.access)
  const isadmin = (access && access.isadmin) || false
  const isXSmall = useMediaQuery((theme) => theme.breakpoints.down('sm'))

  const nameTheme = access && access.user && access.user.theme
  const translate = useTranslate()

  const config = auth.config
  let URLAPI = getURLAPI(config)

  const [subPages, setSubPages] = useState({ page: 'main' })

  //START get all str from table Roles
  const [startRefresh, setStartRefresh] = useState(true)

  //замыкание
  const ws_client = auth.ws_client //body.ws_client

  const item_id = body.item_id

  const readonly = body.readonly || false

  //  const [startWss, setstartWss] = useState(true)

  const getSupervisorItem = function (_result, _parent) {
    //console.log(_result)
    if (_result && _result.result && _result.result.status === 'err') {
      console.log('Err', _result.result.message)
      return
    }
    let { parent, item, getid = null } = _result && _result.result
    dispatch({
      type: 'fetch/item_add',
      payload: { item, getid }, //parent,
      ob: 'manager',
    })
    setSubPages({ page: 'managers' })
  }

  const setSupervisorItem = function (_result, _parent) {
    //console.log(_result)
    if (_result && _result.result && _result.result.status === 'err') {
      console.log('Err', _result.result.message)
      setSubPages({ page: 'main' })
      return
    }

    if (_result) _result.result.change = true
    dispatch({
      type: 'fetch/get_add',
      payload: _result && _result.result,
      ob: 'supervisor_item',
    })
    setSubPages({ page: 'main' })
  }
  //work_item

  const GetData_handle = function (_result) {
    const { tableName, result, readonly, action } = _result
    //console.log('result handle1 ', result)
    dispatch({
      type: 'fetch/get_add',
      payload: result,
      ob: 'supervisor_item',
    })
    dispatch({
      type: 'fetch/get_add',
      payload: readonly,
      ob: 'readonly',
    })
  }

  const Refresh_handle = async function (result, event, parent) {
    const { tableName, action, metod } = result
    /*console.log(
      'Refresh_handle ',
      event,
      new Date().toLocaleTimeString(),
      result
    )*/
    setStartRefresh(true)
  }

  const params = {
    URL_WS: getURLAPI(auth.config, 'URL_WS'), //get address WebSocket BK from config
    auth: { userId: auth.userId, tocken: auth.token },
    handleGetData: GetData_handle,
    //handleChangeData: Refresh_handle,
    handleSetRefresh: Refresh_handle, //setStartRefresh,
    handleErrorRead: null,
    handleErrorChange: null,
    models: [
      {
        model_call_nm: 'Model_read',
        tableName: 'Supervisors',
        where: { id: item_id },
        action: {
          metod: 'getOne',
          isBlocked: true,
        },
      },
    ],
  }

  //deblock item whet close page
  useEffect(
    () => () => {
      try {
        if (!readonly)
          ws_client &&
            ws_client.deblock({ tableName: 'Supervisors', id: item_id })
      } catch (err) {
        console.log('deblock error ', err)
      }
    },
    []
  )

  if (item_id && ws_client && ws_client.ws_login && startRefresh) {
    ws_client.open_process(params)
    setStartRefresh(false)
  }

  //END get all str from table Roles

  let greybkcolor = 'rgba(255,255,255,.3)'

  if (nameTheme) {
    greybkcolor =
      nameTheme !== 'dark' ? 'rgba(255,255,255,1.0)' : 'rgba(255,255,255,.3)'
  }

  columns = [
    {
      Header: '№',
      accessor: 'num',
    },
    {
      Header: translate('resources.manager.fields.name'), //'Работа',
      accessor: 'name',
    },
  ]

  data = []

  const items =
    supervisor_item && supervisor_item.items && supervisor_item.items

  let num = 1
  for (var iw in items) {
    data.push({
      num: num++,
      name: items[iw].managers && items[iw].managers.name,
      items: items[iw],
    })
  }

  columns1 = [
    {
      Header: '№',
      accessor: 'num',
    },
    {
      Header: translate('resources.store.fields.name'), //'Работа',
      accessor: 'name',
    },
    {
      Header: translate('resources.store.fields.customer'), //'Работа',
      accessor: 'customer',
    },
  ]

  data1 = []

  const stores =
    supervisor_item && supervisor_item.stores && supervisor_item.stores

  let num1 = 1
  for (var iw1 in stores) {
    data1.push({
      num: num1++,
      name: stores[iw1].stores.name,
      customer:
        stores[iw1].stores.itemcustomers &&
        stores[iw1].stores.itemcustomers.items &&
        stores[iw1].stores.itemcustomers.items.name,
      items: stores[iw1],
    })
  }

  function Table({
    columns,
    data,
    getHeaderProps = defaultPropGetter,
    getColumnProps = defaultPropGetter,
    getRowProps = defaultPropGetter,
    getCellProps = defaultPropGetter,
  }) {
    // Use the state and functions returned from useTable to build your UI
    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
      useTable({
        columns,
        data,
      })

    return (
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  // Return an array of prop objects and react-table will merge them appropriately
                  {...column.getHeaderProps([
                    {
                      className: column.className,
                      style: column.style,
                    },
                    getColumnProps(column),

                    getHeaderProps(column),
                  ])}
                >
                  {column.render('Header')}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row)
            return (
              // Merge user row props in
              <tr {...row.getRowProps(getRowProps(row))}>
                {row.cells.map((cell) => {
                  const vv = cell
                  let parent = Object.assign(
                    {},
                    cell.row.original.supervisor_item
                  )
                  function clearptw(parent) {
                    if (parent && parent.getwt) {
                      parent.getwt = false //обнуляем getwt для загрузки WT при возврате назад
                    }
                    return parent
                  }
                  let supervisorid = cell.row.original.items.supervisorid
                  return (
                    <td
                      // Return an array of prop objects and react-table will merge them appropriately
                      {...cell.getCellProps([
                        {
                          className: cell.column.className,
                          style: supervisorid
                            ? cell.column.style
                            : { color: 'blue', backgroundColor: 'yellow' },
                        },
                        getColumnProps(cell.column),
                        //  getCellProps(cell),
                      ])}
                      align="center"
                      //                    width="140px"
                    >
                      {(cell.column.id !== 'timeline' && (
                        <Button
                          //as={Link}
                          variant="outline-primary"
                          //style={{ marginBottom: 5 }}
                          style={{
                            pointerEvents: readonly ? 'none' : '',
                          }}
                          onClick={async () => {
                            try {
                              await ws_client.wss_getItem({
                                tableName: 'Heads',
                                //item: cell.row.original.supervisor_item,
                                where: cell.row.original.supervisor_item.id,
                                action: {
                                  metod: 'getSupervisor',
                                  mode: 'get',
                                  parent: clearptw(parent), //cell.row.original.supervisor_item,
                                  id: cell.row.original.items.id, //role_id,
                                },
                                //callback: getWork,
                              })
                            } catch (e) {
                              console.log('errr e', e)
                            }
                            //setSubPages({ page: 'role' })
                          }}
                        >
                          {cell.render('Cell')}
                        </Button>
                      )) ||
                        cell.render('Cell')}
                    </td>
                  )
                })}
              </tr>
            )
          })}
        </tbody>
      </table>
    )
  }

  function Table1({
    columns,
    data,
    getHeaderProps = defaultPropGetter,
    getColumnProps = defaultPropGetter,
    getRowProps = defaultPropGetter,
    getCellProps = defaultPropGetter,
  }) {
    // Use the state and functions returned from useTable to build your UI
    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
      useTable({
        columns,
        data,
      })

    return (
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  // Return an array of prop objects and react-table will merge them appropriately
                  {...column.getHeaderProps([
                    {
                      className: column.className,
                      style: column.style,
                    },
                    getColumnProps(column),

                    getHeaderProps(column),
                  ])}
                >
                  {column.render('Header')}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row)
            return (
              // Merge user row props in
              <tr {...row.getRowProps(getRowProps(row))}>
                {row.cells.map((cell) => {
                  const vv = cell
                  let parent = Object.assign(
                    {},
                    cell.row.original.supervisor_item
                  )
                  function clearptw(parent) {
                    if (parent && parent.getwt) {
                      parent.getwt = false //обнуляем getwt для загрузки WT при возврате назад
                    }
                    return parent
                  }
                  let supervisorid = cell.row.original.items.supervisorid
                  return (
                    <td
                      // Return an array of prop objects and react-table will merge them appropriately
                      {...cell.getCellProps([
                        {
                          className: cell.column.className,
                          style: supervisorid
                            ? cell.column.style
                            : { color: 'blue', backgroundColor: 'yellow' },
                        },
                        getColumnProps(cell.column),
                        //  getCellProps(cell),
                      ])}
                      align="center"
                      //                    width="140px"
                    >
                      {(cell.column.id !== 'timeline' && (
                        <Button
                          //as={Link}
                          variant="outline-primary"
                          //style={{ marginBottom: 5 }}
                          style={{
                            pointerEvents: readonly ? 'none' : '',
                          }}
                          onClick={async () => {
                            try {
                              await ws_client.wss_getItem({
                                tableName: 'Heads',
                                //item: cell.row.original.supervisor_item,
                                where: cell.row.original.supervisor_item.id,
                                action: {
                                  metod: 'getSupervisor',
                                  mode: 'get',
                                  parent: clearptw(parent), //cell.row.original.supervisor_item,
                                  id: cell.row.original.items.id, //role_id,
                                },
                                //callback: getWork,
                              })
                            } catch (e) {
                              console.log('errr e', e)
                            }
                            //setSubPages({ page: 'role' })
                          }}
                        >
                          {cell.render('Cell')}
                        </Button>
                      )) ||
                        cell.render('Cell')}
                    </td>
                  )
                })}
              </tr>
            )
          })}
        </tbody>
      </table>
    )
  }

  const getChange = () => {
    if (supervisor_item && supervisor_item.change === true) return 'contained'
    else return 'outlined'
    //   if (supervisor_item && supervisor_item.change === true) return 'success'
    //    else return 'outline-success'
  }
  const getAccessToChange = () => {
    return readonly || false
  }

  const ready = () => {
    if (supervisor_item) return true
    else return false
  }

  return (
    <>
      {ready() && (
        <>
          <Box maxWidth="80em">
            <div
              //            className="hstack gap-2"
              style={{
                marginTop: -20,
                display: 'flex',
                //justifyContent: 'space-between',
              }}
            >
              <Card>
                <CardContent>
                  <>
                    <div
                      //            className="hstack gap-2"
                      style={{
                        marginTop: -20,
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                    >
                      <Typography
                        id="outlined-basic"
                        label="Тип"
                        //variant="standard"
                        className="title h6"
                        style={{
                          marginTop: 15,
                          marginBottom: 15,
                          marginRight: 150,
                          //display: 'flex',
                          //justifyContent: 'space-between',
                        }}
                      >
                        {translate('resources.supervisor.title')}
                      </Typography>

                      {readonly && (
                        <Typography
                          id="outlined-basic"
                          label="Title"
                          //variant="standard"
                          //className="title h6"
                          style={{
                            marginTop: 15,
                            marginBottom: 15,
                            //  marginRight: 275,
                            color: 'red',
                          }}
                          //                      {translate('resources.workers.readonly')}
                        >
                          Readonly
                        </Typography>
                      )}
                    </div>

                    <div
                      //            className="hstack gap-2"
                      style={
                        {
                          //            marginTop: -20,
                          //            display: 'flex',
                          //          justifyContent: 'space-between',
                        }
                      }
                    >
                      {supervisor_item && (
                        <>
                          <TextField
                            id="outlined-basic"
                            label={translate(
                              'resources.supervisor.fields.name'
                            )} //"Тип работ"
                            //variant="standard"
                            variant="outlined"
                            style={{
                              marginBottom: 15,
                              display: 'flex',
                              justifyContent: 'space-between',
                            }}
                            value={
                              (supervisor_item && supervisor_item.name) || ''
                            }
                            onChange={(item) => {
                              if (!getAccessToChange()) {
                                supervisor_item.change = true
                                dispatch({
                                  type: 'app/set',
                                  payload: {
                                    id: supervisor_item,
                                    field: 'name',
                                    value: item.target.value,
                                  },
                                  ob: 'supervisor_item',
                                })
                              }
                            }}
                          ></TextField>
                          <TextField
                            id="outlined-basic"
                            label={translate(
                              'resources.supervisor.fields.headname'
                            )} //"Тип работ"
                            //variant="standard"
                            variant="outlined"
                            style={{
                              marginBottom: 15,
                              width: 320,
                              //display: 'flex',
                              //justifyContent: 'space-between',
                            }}
                            value={
                              (supervisor_item &&
                                supervisor_item.itemheads &&
                                supervisor_item.itemheads.items &&
                                supervisor_item.itemheads.items.name) ||
                              ''
                            }
                            /*                          onChange={(item) => {
                            if (!getAccessToChange()) {
                              supervisor_item.change = true
                              dispatch({
                                type: 'app/set',
                                payload: {
                                  id: supervisor_item,
                                  field: 'name',
                                  value: item.target.value,
                                },
                                ob: 'supervisor_item',
                              })
                            }
                          }}*/
                          ></TextField>
                        </>
                      )}
                    </div>
                    <Button
                      size="small"
                      component={Link}
                      color="primary"
                      //variant="outlined"
                      sx={{ display: 'inline-flex', alignItems: 'center' }}
                      variant={getChange()} //"outline-success"
                      to={{
                        pathname: '/pages/supervisor/list',
                      }}
                      //state={supervisor_item}
                      disabled={getAccessToChange()}
                      onClick={async () => {
                        try {
                          if (!getAccessToChange())
                            await ws_client.wss_setItem({
                              tableName: 'Supervisors',
                              item: supervisor_item,
                              call_nm: 'Model_update',
                              where: { id: supervisor_item.id },
                              //action: { metod: 'set' },
                            })
                        } catch (e) {
                          console.log('errr e', e)
                        }
                      }}
                    >
                      {translate('resources.button.save')}
                    </Button>

                    <Button
                      size="small"
                      component={Link}
                      color="primary"
                      //variant="outlined"
                      sx={{ display: 'inline-flex', alignItems: 'center' }}
                      style={{ marginLeft: 5 }}
                      variant="outlined" //"outline-danger"
                      to="/pages/supervisor/list"
                    >
                      {translate('resources.button.cancel')}
                    </Button>
                    {(isadmin ||
                      getAccessCode(
                        'SUPEVISORS_VIEW',
                        access && access.access
                      )) && (
                      <Styles>
                        <br />
                        <Table
                          columns={columns}
                          data={data}
                          getHeaderProps={
                            (nameTheme &&
                              nameTheme !== 'dark' &&
                              ((row) => {
                                return {
                                  style: {
                                    //                   background:
                                    //                   row.index % 2 === 0 ? 'rgba(0,0,0,.1)' : 'white',
                                  },
                                }
                              })) ||
                            ((row) => {
                              return {
                                style: {
                                  color: '#ffffffb3',
                                  //                    background:
                                  //                    row.index % 2 === 0 ? 'rgba(0,0,0,.1)' : '#ffffffb3', //'#616161e6', //'#ffffffb3',
                                },
                              }
                            })
                          }
                          /*          
          getHeaderProps={(column) => ({
            onClick: () => alert('Header!'),
          })}
          getColumnProps={(column) => ({
            onClick: () => alert('Column!'),
          })}
          */
                          getRowProps={
                            (nameTheme &&
                              nameTheme !== 'dark' &&
                              ((row) => {
                                return {
                                  style: {
                                    background:
                                      row.index % 2 === 0
                                        ? 'rgba(0,0,0,.1)'
                                        : 'white',
                                  },
                                }
                              })) ||
                            ((row) => {
                              return {
                                style: {
                                  background:
                                    row.index % 2 === 0
                                      ? 'rgba(0,0,0,.1)'
                                      : '#616161e6', //'#ffffffb3',
                                },
                              }
                            })
                          }
                          getCellProps={(cellInfo) => ({
                            //            onClick: (req, res) => onClickCell(cellInfo, req, res),
                            //                style: {
                            //                  backgroundColor: `hsl(${
                            //                    120 * ((120 - cellInfo.value) / 120) * -1 + 120
                            //                  }, 100%, 67%)`,
                            //                },
                          })}
                        />
                      </Styles>
                    )}
                  </>
                </CardContent>
              </Card>
              <Card
                style={{
                  marginLeft: 10,
                }}
              >
                <CardContent>
                  <>
                    {(isadmin ||
                      getAccessCode(
                        'SUPEVISORS_VIEW',
                        access && access.access
                      )) && (
                      <Styles>
                        <br />
                        <Table1
                          columns={columns1}
                          data={data1}
                          getHeaderProps={
                            (nameTheme &&
                              nameTheme !== 'dark' &&
                              ((row) => {
                                return {
                                  style: {
                                    //                   background:
                                    //                   row.index % 2 === 0 ? 'rgba(0,0,0,.1)' : 'white',
                                  },
                                }
                              })) ||
                            ((row) => {
                              return {
                                style: {
                                  color: '#ffffffb3',
                                  //                    background:
                                  //                    row.index % 2 === 0 ? 'rgba(0,0,0,.1)' : '#ffffffb3', //'#616161e6', //'#ffffffb3',
                                },
                              }
                            })
                          }
                          /*          
          getHeaderProps={(column) => ({
            onClick: () => alert('Header!'),
          })}
          getColumnProps={(column) => ({
            onClick: () => alert('Column!'),
          })}
          */
                          getRowProps={
                            (nameTheme &&
                              nameTheme !== 'dark' &&
                              ((row) => {
                                return {
                                  style: {
                                    background:
                                      row.index % 2 === 0
                                        ? 'rgba(0,0,0,.1)'
                                        : 'white',
                                  },
                                }
                              })) ||
                            ((row) => {
                              return {
                                style: {
                                  background:
                                    row.index % 2 === 0
                                      ? 'rgba(0,0,0,.1)'
                                      : '#616161e6', //'#ffffffb3',
                                },
                              }
                            })
                          }
                          getCellProps={(cellInfo) => ({
                            //            onClick: (req, res) => onClickCell(cellInfo, req, res),
                            //                style: {
                            //                  backgroundColor: `hsl(${
                            //                    120 * ((120 - cellInfo.value) / 120) * -1 + 120
                            //                  }, 100%, 67%)`,
                            //                },
                          })}
                        />
                      </Styles>
                    )}
                  </>
                </CardContent>
              </Card>
            </div>
          </Box>
        </>
      )}
      {!ready() && (
        <>
          <Box maxWidth="30em">
            <Card>
              <CardContent>
                <Spinner />
              </CardContent>
            </Card>
          </Box>
        </>
      )}
    </>
  )
}

export default SupervisorItem
