//import { countBy } from 'lodash'
import React, { useContext, useState } from "react";

import { Client as WebSocket } from "rpc-websockets";
import { v4 as uuid } from "uuid";

export class SocketManager {
  wss;
  URL_WS;
  auth;
  //onCallback
  ws_login;
  connectStatus;
  reconnectTimeout;
  _saveStatus;
  countReconnect;
  maxReconnect;

  handle_Error_change;
  handle_Error_read;
  handle_ChangeData;
  handle_SetRefresh;

  get saveStatus() {
    return this._saveStatus;
  }
  set saveStatus(status) {
    this._saveStatus = status;
  }

  constructor(params) {
    this.URL_WS = params.URL_WS;
    this.auth = params.auth;
    this.connectStatus = "not connect";
    this._saveStatus = null;
    this.countReconnect = 0;
    this.maxReconnect = 5;
    this.handle_ChangeData = null;
    this.handle_SetRefresh = null;
    this.handle_Error_change = null;
    this.handle_Error_read = null;
    this.connect();
  }

  //  setCallback(onCallback) {
  //    this.onCallback = onCallback
  //  }

  close() {
    console.log("wss CLOSING!!!!!!!!: ");
    try {
      if (this.wss && this.wss.close && this.wss.ready) {
        this.wss.close();
        this.setConnectStatus("closed");
        this.connect();
      }
    } catch (e) {
      console.log("wss close error: ", e);
    }
  }

  setConnectStatus(status) {
    this.connectStatus = status;
    if (this._saveStatus) {
      this._saveStatus(status);
    }
  }

  async connect(noReconnect = false) {
    //{ onCallback = () => {} }) {
    //***************************** */
    let ws_clients;
    //const [ws_clients1, set_ws_clients] = useState(null)

    try {
      //      if (this.wss) this.wss.close()
      ws_clients = new WebSocket(this.URL_WS);
      /*, {
        autoconnect: true,
        reconnect: true,
        reconnect_interval: 1000,
        max_reconnects: 3,
      })*/
      ws_clients.parent = this;
      this.wss = ws_clients;

      ws_clients.on("close", function () {
        console.log("event : ws_client close !!!");
        ws_clients.parent.setConnectStatus("close");
      });

      //ws_clients.on('connect', (param) => {
      //  ws_clients.parent.setConnectStatus('connect')
      //})

      ws_clients.on("error", (error) => {
        ws_clients.parent.setConnectStatus("error");

        //        if (
        //          !noReconnect &&
        //          ws_clients.parent.countReconnect < ws_clients.parent.maxReconnect
        //        ) {
        console.log("ws_client ERROR  need CLOSE and reconnect!!!");
        ws_clients.close();
        ws_clients.parent.setConnectStatus("error");
        console.log("ws_client error... try Reconnect!!!", error);
        ws_clients.parent.countReconnect = ws_clients.parent.countReconnect + 1;

        ws_clients.parent.reconnectTimeout = setTimeout(() => {
          ws_clients.parent.setConnectStatus("reconnect");
          ws_clients.parent.connect(false);
        }, 1000);
        //      }
        //      if (
        //        ws_clients.parent.countReconnect === ws_clients.parent.maxReconnect
        //      ) {
        //        ws_clients.parent.countReconnect =
        //          ws_clients.parent.countReconnect + 1
        //       console.log('STOP Reconnect')
        //     }
      });

      ws_clients.on("open", async function () {
        // login your client to be able to use protected methods
        //if (ws_clients.parent.reconnectTimeout) {
        //  clearTimeout(ws_clients.parent.reconnectTimeout)
        // }

        ws_clients.parent.setConnectStatus("open");

        let test_login = ws_clients.login(ws_clients.parent.auth);
        try {
          //test login
          test_login
            .then(async function (result) {
              ws_clients.parent.ws_login = test_login;
              ws_clients.parent.setConnectStatus("login");

              //SUBSCRIBES

              ws_clients.subscribe("Error_change");
              ws_clients.on("Error_change", async function (result) {
                if (ws_clients.parent.handle_Error_change) {
                  ws_clients.parent.handle_Error_change(
                    result,
                    ws_clients.parent
                  );
                }
              });

              ws_clients.subscribe("Error_read");
              ws_clients.on("Error_read", async function (result) {
                if (ws_clients.parent.handle_Error_read) {
                  ws_clients.parent.handle_Error_read(
                    result,
                    ws_clients.parent
                  );
                }
              });

              ws_clients.subscribe("Table_change");

              ws_clients.on("Table_change", async function (result) {
                const { tableName, action, metod } = result;

                /*console.log(
            'event : ' + tableName + '_change params',
            result,
            new Date().toLocaleTimeString()
          )*/
                if (ws_clients.parent.handle_ChangeData) {
                  ws_clients.parent.handle_ChangeData(
                    result,
                    "change",
                    ws_clients.parent
                  );
                }

                if (ws_clients.parent.handle_SetRefresh) {
                  //                let refreshTimeout = setTimeout(() => {
                  ws_clients.parent.handle_SetRefresh(true);
                  //               }, 500)
                  //  clearTimeout(refreshTimeout);
                }
              });

              ws_clients.subscribe("Table_deblock");

              ws_clients.on("Table_deblock", async function (result) {
                const { tableName, session, socket_id } = result;
                /*console.log(
              'event : ' + tableName + '_deblock params',
              result,
              new Date().toLocaleTimeString()
            )*/
                if (ws_clients.parent.handle_ChangeData) {
                  ws_clients.parent.handle_ChangeData(
                    result,
                    "deblock",
                    ws_clients.parent
                  );
                }
                if (ws_clients.parent.handle_SetRefresh) {
                  ws_clients.parent.handle_SetRefresh(true);
                }
              });
            })
            .catch(function (error) {
              console.log("err", error, new Date().toLocaleTimeString());
              ws_clients.parent.setConnectStatus("error");

              console.log("ws_client CLOSE!!!");

              ws_clients.close();
              console.log(
                "ws_client login error... try Reconnect!!!  error =",
                error
              );
              ws_clients.parent.reconnectTimeout = setTimeout(() => {
                ws_clients.parent.setConnectStatus("reconnect");
                ws_clients.parent.connect(false);
              }, 5000);
            });

          //ws_clients.parent.onCallback &&
          //ws_clients.parent.onCallback(ws_clients.parent)
        } catch (error) {
          console.log("error test login  ", error);
        }
      });
    } catch (err) {
      ws_clients.parent.setConnectStatus("error");
      console.log(err);

      if (!noReconnect) {
        console.log("ws_client login error... try Reconnect!!!  error  =", err);
        console.log("ws_client CLOSE!!!");
        ws_clients.close();
        ws_clients.parent.reconnectTimeout = setTimeout(() => {
          ws_clients.parent.setConnectStatus("reconnect");
          ws_clients.parent.connect(false);
        }, 5000);
      }
    }
  }

  async open_process(params) {
    //    console.log('Ready for process : ', this.wss.ready)
    //if (!this.wss.ready) this.connect()

    //console.log("open_process - params", params);
    let ws_clients = this.wss;
    if (!ws_clients.ready) {
      console.log("ws_client CLOSE!!!");
      ws_clients.close();
      ws_clients.parent.setConnectStatus("reconnect");
      ws_clients.parent.connect(false);
    } else {
      let models = params.models;
      let handleGetData = params.handleGetData;
      let handleChangeData = params.handleChangeData;
      let handleSetRefresh = params.handleSetRefresh;
      let handleErrorRead = params.handleErrorRead;
      let handleErrorChange = params.handleErrorChange;

      try {
        ws_clients.parent.ws_login
          .then(async function (result) {
            //          console.log(result)
            if (result && ws_clients) {
              try {
                ws_clients.parent.setConnectStatus("process");
                let model_call_nm;
                let tableName;
                let where;
                let action;

                for (let i in models) {
                  model_call_nm = models[i].model_call_nm;
                  tableName = models[i].tableName;
                  where = models[i].where || null;
                  action = models[i].action || {};

                  if (handleChangeData) {
                    ws_clients.parent.handle_ChangeData = handleChangeData;
                  }

                  if (handleSetRefresh) {
                    ws_clients.parent.handle_SetRefresh = handleSetRefresh;
                  }

                  if (handleErrorChange) {
                    ws_clients.parent.handle_Error_change = handleErrorChange;
                  }

                  if (handleErrorRead) {
                    ws_clients.parent.handle_Error_read = handleErrorRead;
                  }

                  //read from table UnitTypes, where  id=1
                  ws_clients
                    .call(model_call_nm, {
                      tableName,
                      where,
                      action,
                    })
                    .then(async function (_result) {
                      const { readonly, tableName, action, id, socket_id } =
                        _result;
                      const { isBlocked = null } = action || {};
                      ws_clients.parent.socketId = socket_id;
                      /* console.log(
              'result from table ' + tableName + ' : ',
              socket_id,
              new Date().toLocaleTimeString()
            )*/
                      //if (isBlocked && !readonly) {
                      //  ws_clients.parent.blockedRecords.push({ tableName, id })
                      //}

                      if (handleGetData)
                        handleGetData(_result, ws_clients.parent);
                      //ws_clients.connect()
                    })
                    .catch(function (error) {
                      console.log(
                        "err",
                        error,
                        new Date().toLocaleTimeString()
                      );
                    });
                } //for
              } catch (err) {
                console.log(err);
              }
            }
          })
          .catch(function (error) {
            console.log("err", error, new Date().toLocaleTimeString());
            ws_clients.parent.setConnectStatus("error");
            console.log(
              "ws_client login error... try Reconnect!!!  error =",
              error
            );
            console.log("ws_client CLOSE!!!");
            ws_clients.close();
            ws_clients.parent.reconnectTimeout = setTimeout(() => {
              ws_clients.parent.setConnectStatus("reconnect");
              ws_clients.parent.connect(false);
            }, 5000);
          });
      } catch (error) {
        console.log(error);
        ws_clients.parent.setConnectStatus("error");
        console.log(
          "ws_client login error... try Reconnect!!!  error =",
          error
        );
        console.log("ws_client CLOSE!!!");
        ws_clients.close();
        ws_clients.parent.reconnectTimeout = setTimeout(() => {
          ws_clients.parent.setConnectStatus("reconnect");
          ws_clients.parent.connect(false);
        }, 5000);
      }
    }
  }

  async deblock(param) {
    const { tableName, id } = param;
    let ws_clients = this.wss;
    try {
      ws_clients
        .call("Session_deblock", {
          tableName,
          blockedId: id,
        })
        .then(async function (_result) {
          /* console.log(
      'result from table ' + tableName + ' : ',
      socket_id,
      new Date().toLocaleTimeString()
    )*/
        })
        .catch(function (error) {
          ws_clients.parent.setConnectStatus("error");
          console.log("err", error, new Date().toLocaleTimeString());
          console.log(
            "ws_client deblock error... try Reconnect!!!  error =",
            error
          );
          console.log("ws_client CLOSE!!!");
          ws_clients.close();
          ws_clients.parent.reconnectTimeout = setTimeout(() => {
            ws_clients.parent.setConnectStatus("reconnect");
            ws_clients.parent.connect(false);
          }, 5000);
        });
    } catch (err) {
      console.log(err);
    }
  }

  async wss_setItem(param) {
    const { tableName, item, call_nm, where, action, callback } = param;

    //console.log('Ready for setItem : ', this.wss.ready)
    //if (!this.wss.ready) this.connect()
    let ws_client2 = this.wss;
    if (ws_client2.ready) {
      //      let ws_client2

      try {
        ws_client2.parent.ws_login
          .then(async function (result) {
            //console.log(result)
            ws_client2.parent.setConnectStatus("setItem");
            let values = item;
            //            let Where = item.id ? { id: item.id } : null
            let model_call_nm = call_nm
              ? call_nm
              : item.id
              ? "Model_update"
              : "Model_create";
            ws_client2
              .call(model_call_nm, { values, tableName, where, action })
              .then(function (result) {
                if (callback) callback(result);
                /*  console.log(
                  'update values in table Workers  : ',
                  values,
                  result
                )*/
                //                  ws_client2.close()
              })
              .catch(function (error) {
                console.log("err", error);
              });
          })
          .catch(function (error) {
            console.log("auth failed - error : ", error);
          });
      } catch (error) {
        ws_client2.parent.setConnectStatus("error");
        console.log(error);
        console.log(
          "ws_client setItem error... try Reconnect!!!  error =",
          error
        );
        ws_client2.parent.reconnectTimeout = setTimeout(() => {
          ws_client2.parent.connect(false);
        }, 5000);
      }
    }
  }

  async wss_getItem(param) {
    const { tableName, where, action, callback } = param;

    //console.log('Ready for getItem : ', this.wss.ready)
    //if (!this.wss.ready) this.connect()
    let ws_client3 = this.wss;
    if (ws_client3.ready) {
      //      let ws_client2

      try {
        ws_client3.parent.ws_login
          .then(async function (result) {
            //console.log(result)
            if (result && ws_client3) {
              ws_client3.parent.setConnectStatus("getItem");
              //            let Where = item.id ? { id: item.id } : null
              ws_client3
                .call("Model_read", { tableName, where, action })
                .then(function (result) {
                  /*  console.log(
                  'update values in table Workers  : ',
                  values,
                  result
                )*/
                  //ws_client3.close()
                  if (callback) callback(result, ws_client3.parent);

                  if (ws_client3.parent.handleReadData) {
                    ws_client3.parent.handleReadData(result, ws_client3.parent);
                  }
                })
                .catch(function (error) {
                  console.log("err", error);
                });
            }
          })
          .catch(function (error) {
            console.log("read failed - error : ", error);
          });
      } catch (error) {
        ws_client3.parent.setConnectStatus("error");
        console.log(error);
        console.log(
          "ws_client getItem error... try Reconnect!!!  error =",
          error
        );
        ws_client3.parent.reconnectTimeout = setTimeout(() => {
          ws_client3.parent.connect(false);
        }, 5000);
      }
    }
  }

  //Get User Access
  ParseAccess_crm(data) {
    let isadmin = (data && data.isadmin) || false;
    let issuper = (data && data.issuper) || false;
    let allsupervisors = (data && data.allsupervisors) || false;
    let access = [];
    let user;
    let UserName = null;

    let role;
    let unit;
    let type_access;
    let code;

    //data.active - !!!

    try {
      if (data.login)
        user = {
          id: data.id,
          login: data.login,
          fullname: data.fullname,
          avatar: data.avatar && data.avatar.avatar,
          language: (data.config && data.config.language) || "ru",
          theme: (data.config && data.config.theme) || "default",
          Host: data.phones && data.phones[0].phone.pbx.HOST,
          WebSocketPort: data.phones && data.phones[0].phone.pbx.WEBSOCKETPORT,
          WebSocketPath: data.phones && data.phones[0].phone.pbx.WEBSOCKETPATH,
          SipUsername: data.extent && data.extent.resource,
          SipPassword: data.extent && data.extent.password,
          internal: data.internal,
          pbxId: data.pbxId,
          OnLogs: data.OnLogs,

          /*        workers: data.workers && data.workers,
          typeworks: data.typeworks && data.typeworks,
          cloud: {
            email: data.config && data.config.cloud && data.config.cloud.email,
            password:
              data.config && data.config.cloud && data.config.cloud.password,
            cloudtype:
              data.config &&
              data.config.cloud &&
              data.config.cloud.cloudtype.code,
            rootfolder:
              data.config &&
              data.config.cloud &&
              data.config.cloud.rootfolder &&
              data.config.cloud.rootfolder,
            userfolder: userfolder,
            cloudid: cloudid,
          },
     */
        };

      if (data) {
        for (let j in data.roles) {
          type_access = null;
          code = null;
          role = data.roles[j].role;
          for (let k in role.units) {
            type_access = role.units[k].type_access;
            code = role.units[k].unit.code;
            access.push({ code, type_access });
          }
        }
        type_access = null;
        code = null;

        for (let i in data.units) {
          unit = data.units[i];
          type_access = unit.type_access;
          code = unit.unit.code;
          access.push({ code, type_access });
        }
      }

      if (user) {
        if (user.firstName) UserName = user.firstName;
        if (user.lastName) UserName = UserName + " " + user.lastName;
        if (user.fullname) UserName = user.fullname;
        if (UserName === null) UserName = user.login;
      }
    } catch (error) {
      console.log(error);
    }
    return { isadmin, issuper, allsupervisors, access, user, UserName };
  }

  //wtm
  ParseAccess(data) {
    let isadmin = (data && data.isadmin) || false;
    let issuper = (data && data.issuper) || false;
    let allsupervisors = (data && data.allsupervisors) || false;
    let access = [];
    let user;
    let UserName = null;

    let role;
    let unit;
    let type_access;
    let code;

    //data.active - !!!

    try {
      if (data.login)
        user = {
          id: data.id,
          login: data.login,
          fullName: data.fullName,
          avatar: data.avatar && data.avatar.avatar,
          language: data.config && data.config.language,
          theme: (data.config && data.config.theme) || "default",
          supervisors: data.supervisors && data.supervisors,
        };

      if (data) {
        for (let j in data.roles) {
          type_access = null;
          code = null;
          role = data.roles[j].role;
          for (let k in role.units) {
            type_access = role.units[k].type_access;
            code = role.units[k].unit.code;
            access.push({ code, type_access });
          }
        }
        type_access = null;
        code = null;

        for (let i in data.units) {
          unit = data.units[i];
          type_access = unit.type_access;
          code = unit.unit.code;
          access.push({ code, type_access });
        }
      }

      if (user) {
        if (user.fullName) UserName = user.fullName;
        if (UserName === null) UserName = user.login;
      }
    } catch (error) {
      console.log(error);
    }
    return { isadmin, issuper, allsupervisors, access, user, UserName };
  }

  async wss_getaccess(userId, callback) {
    //************** */
    //console.log('Ready for wss_get_access : ', this.wss.ready)
    let ws_client = this.wss;
    if (ws_client.ready) {
      try {
        ws_client.parent.ws_login
          .then(async function (result) {
            //console.log(' login passw : ', result)

            let tableName = "Users";
            //let values = params
            let where = { id: userId };
            let action = { metod: "access" };

            ws_client
              .call("Model_read", { tableName, where, action })
              .then(function (res) {
                const { result } = res;
                let access = ws_client.parent.ParseAccess(result);
                if (callback) callback(access);
              })
              .catch(function (error) {
                console.log("err", error);
              });
          })
          .catch(function (error) {
            console.log("auth failed - error : ", error);
          });
      } catch (error) {
        console.log(error);
      }
    }
  }

  async wss_get_access(userId) {
    //: Promise<any> {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        //************** */
        console.log("Ready for wss_get_access : ", this.wss.ready);
        //if (!this.wss.ready) this.connect()
        let ws_client = this.wss;
        if (ws_client.ready) {
          try {
            //URL_WS = 'wss://shop.pegas-sk.com.ua:8043'

            //let ws_client = new WebSocket(URL_WS)

            ws_client.parent.ws_login
              .then(async function (result) {
                //console.log(' login passw : ', result)

                let tableName = "Users";
                //let values = params
                let where = { id: userId };
                let action = { metod: "access" };

                ws_client
                  .call("Model_read", { tableName, where, action })
                  .then(function (res) {
                    //  console.log('update values in table Workers  : ', res)
                    const { result } = res;
                    //ws_client.close()
                    let access = ws_client.parent.ParseAccess(result);
                    resolve(access);
                  })
                  .catch(function (error) {
                    console.log("err", error);
                  });
              })
              .catch(function (error) {
                console.log("auth failed - error : ", error);
              });
          } catch (error) {
            console.log(error);
          }
        }
      }, 300);
    });
  }
}
