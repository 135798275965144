import { TranslationMessages } from '../lib/translate/i18n/TranslationMessages'
import ukraineMessages from './ukraineMessages'

const customUkraineMessages: TranslationMessages = {
  ...ukraineMessages,
  pos: {
    search: 'Search',
    configuration: 'Конфігурація',
	configuser:'Конфігурація користувача : ',
    language: 'Мова',
	configfolder:'Місце для зберігання фотографій до робіт',
	selectfolder:'Виберіть місце зберігання',
	usecloud:'Використовувати хмару',
	recording:'Запис',
	place:'Місце',
	minutes:'хвилин',
    theme: {
      name: 'Theme',
      light: 'Light',
      dark: 'Dark',
    },
    dashboard: {
      monthly_revenue: 'Monthly Revenue',
      month_history: '30 Day Revenue History',
      new_orders: 'New Orders',
      pending_reviews: 'Pending Reviews',
      all_reviews: 'See all reviews',
      new_customers: 'New Customers',
      all_customers: 'See all customers',
      pending_orders: 'Pending Orders',
      order: {
        items:
          'by %{customer_name}, one item |||| by %{customer_name}, %{nb_items} items',
      },
      welcome: {
        title: 'Welcome to the react-admin e-commerce demo',
        subtitle:
          "This is the admin of an imaginary poster shop. Feel free to explore and modify the data - it's local to your computer, and will reset each time you reload.",
        ra_button: 'react-admin site',
        demo_button: 'Source for this demo',
      },
    },
    menu: {
	  title:'Збір замовлень',
      diary: 'Щоденник',
      catalogs: 'Довідники',
	  searchrecord:'Пошук за записами',
      clients: 'Партнери',
      head: 'Керівник',
	  supervisor: 'Супервайзер',
	  manager: 'Менеджер',
	  stores: 'Торгова точка',
 	  route:'Маршрут',
      orders: 'Замовлення',

	  amqpsettings:'Сервер RabbitMQ',
      crontasks: 'Cron Tasks',

	  
	  
      workers: 'Співробітники',
      tovunits: 'Одиниці виміру',
 	  tovs: 'Товар',
      typeprices: 'Типи цін',
 	  prices: 'Ціни',
      formpays: 'Форма оплати',
      vidpays: 'Вид оплати',

      workplace: 'Робочі місця',
      worktypes: 'Типи робіт',
	  costwork:'Робота - ціна',
      groupclient:'Група клієнтів',
	  recordstatus:'Статуси записів',

      workday: 'Графік роботи',
	  cloudstorage: 'Хмарні сховища',
	  messengers:'Месенджери',
	  deletedrecords:'Віддалені записи',
      sessions: 'Сессии',
	  
      worker: 'Співробітники',
      filestorage: 'Сховище файлів',
      warehouses: 'Склади',
      units: 'Одиниці виміру',
      dimensions: 'Типові розміри',
      woodspecies: 'Порода дерева',
      quality: 'Якість продукції',
      variant: 'Виконання',
      operations: 'Тех.операції',
      productvid: 'Види номенклатури',
      productcat: 'Товарні категорії',
      product: 'Номенклатура',
      downtimetypes: 'Типи Простоїв',
      subdivision: 'Підрозділи',
      brigades: 'Бригади',
      jobtitle: 'Посада',
      vidrepairs: 'Види ремонтів',
      repairs: 'Ремонтні заходи',
      sample: 'Шаблони виконання',
      norms: 'Норми виробітку',
      variable: 'Характеристики',
      antbl: 'Значення Характеристик',
      admin: 'Адмін',
      users: 'Користувачі',
      role: 'Ролі',
      access: 'Доступи',
      accesstype: 'Типи доступу',

      config: 'Конфіг',
      profile: 'Профіль',
      logout: 'Вихід',

      sales: 'Sales',
      catalog: 'Catalog',
      customers: 'Customers',
    },
  },
  resources: {
    button: {
      add: 'Додати',
      return: 'Назад',
      save: 'Зберегти',
      cancel: 'Скасувати',
      del: 'Видалити',
      download: 'Завантажити файл',
      search: 'Пошук',
	  addwork: 'Додати роботу',
    },
    switch: {
      changed: 'Зміни',
      contracts: 'Договори',
      documents: 'Документи',
      tasks: 'Завдання',
      files: 'Файли',
      time: 'Час',
      dop: 'Доп',
      top: 'Топ',
      isworker: 'Работник',
	  allsupervisors:"Доступ до всіх супервайзерів",
    },

	tooltip:{
	serveraccess:"Доступ до сервера",
	},

    home: {
      changedate: 'Виберіть дату',
	  date:'Дата',
	  time:'Час',
	  maps:"Карта",
	  foto:"Фото",
	  orders:"Замовлення",
	  payments:"Платежі",
	  debts:"Борги",
	  return:"Повернення",
	  returns:"Повернення",
	  invoice:"Відвантаження",
	  invoices:"Доставка",
	  lastinvoices:"Відвантаження",
	  salesplans:'Планування',
      managertasks: 'Завдання',
	  managers:"Менеджери",
	  notmanager:"Менеджер не вибраний",
	  routes:"Маршрути",
	  store:"Торгова точка",
	  selectacsupervisor:"Виберіть супервайзера",
	  notstore:"Торговельну точку не вибрано",
	  notcustomer:"Контрагент не заданий",
	  },

    debts: {
      changedate: 'Виберіть дату',
      changeworker: 'Виберіть майстра',
	  changeworkplace:'Виберіть робоче місце',
	  time:'Час',
	  maps:"Карта",
	  foto:"Фото",
	  orders:"Замовлення",
	  payments:"Платежі",
	  debts:"Борги",
	  managers:"Менеджери",
	  routes:"Маршрути",
	  store:"Торгова точка",
      fields: {
        numnakl: '№ накл.',
        datenakl: 'Дата накл.',
        storename: 'Торгова точка',
        dolgsumma: 'Сума боргу',
		delaydolgsumma:"Прострочка боргу",
        delaydays: 'Прострочка - днів',
      },
	  },

    returns: {
      name: 'Повернення',
      title: 'Повернення',
      fields: {
        date: 'Дата',
        store: 'ТТ',		
		manager:"Менеджер",
		tov:'Товар',
		qty:'Кількість',
		sellqty:'Отгружено ранее',
        tovunit: 'Одиниці виміру',
      },
    },

	  payments: {
      changedate: 'Виберіть дату',
      changeworker: 'Виберіть майстра',
	  changeworkplace:'Виберіть робоче місце',
	  time:'Час',
	  maps:"Карта",
	  foto:"Фото",
	  orders:"Замовлення",
	  payments:"Платежі",
	  debts:"Борги",
	  managers:"Менеджери",
	  routes:"Маршрути",
	  store:"Торгова точка",
      fields: {
        numnakl: '№ накл.',
        datenakl: 'Дата накл.',
        datepay: 'Дата платежу',
        storename: 'Торгова точка',
        paysumma: 'Сума платежу',
      },
	  },
	  
	  
	  head: {
      name: 'Керівник',
      title: 'Керівник',
      fields: {
        name: 'Керівник',
      },
    },

    amqp: {
      name: 'RabbitMQ',
      title: 'RabbitMQ',
	  addamqp:'Add',
      fields: {
        name: 'Назва',
		url:'url',
        comments: 'Коментар',
		sendsmsqueue:'send_sms',
		receivesmsqueue:'receive_sms',
		rpc_send:'rpc_send',
		rpc_respond:'rpc_respond',
		
      },
    },

	crontasks: {
      name: 'Cron Tasks',
      title: 'Cron Tasks',
	  addcrontask:'Add',
      fields: {
        name: 'Назва',
		taskcode:'TaskCode',
		schedule:'Schedule',
		active:'On/Off',
        comments: 'Коментар',
		params:'Параметри до завдання',
		needhistory:'history',
      },
    },

    supervisor: {
      name: 'Супервайзер',
      title: 'Супервайзер',
      noname: 'Супервайзер не вибраний',
	  addsupervisor:'Додати доступ до супервайзера',
      fields: {
        name: 'Супервайзер',
        headname: 'Керівник',
      },
    },

    manager: {
      name: 'Менеджер',
      title: 'Менеджер',
      noname: 'Менеджер не вибраний',
      fields: {
        name: 'Менеджер',
		summorder_order:'Суми: Замовлень/Платежів',
		supervisor: 'Супервайзер',
      },
    },

	salesplan: {
      name: 'Виконання Планів Менеджером',
      title: 'Виконання Планів Менеджером',
      noname: 'Супервайзер не вибраний',
      fields: {
        date: 'Дата',
        manager: 'Менеджер',
		category:'Категория',
        plan: 'План',		
        sale: 'Факт',
		procent: '% виконання',
      },
    },

    managertask: {
      name: 'Задачи на Менеджера',
      title: 'Задачи на Менеджера',
      noname: 'Менеджер не вибраний',
      fields: {
        date: 'Дата',
        manager: 'Менеджер',
        store: 'ТТ',		
        name: 'Название',
		description:'Описание',
		finished: 'Выполнено',
      },
    },
	
	
    store: {
      name: 'Торгова точка',
      title: 'Торгова точка',
      fields: {
        name: 'Торгова точка',        
        typeprice: 'Тип ціни',        
        customer: 'Партнер',
 		supervisor: 'Супервайзер',
 		info: 'Замовлення / Платежі / Борги / Прострочка',
      },
    },
	
    route: {
      name: 'Маршрут',
      title: 'Маршрут',
      fields: {
        name: 'Маршрут',
        date1: 'Дата с',
        date2: 'Дата по',
 		supervisor: 'Супервайзер',
		manager: 'Менеджер',
		day1: 'Пн',
		day2: 'Вт',
		day3: 'Ср',
		day4: 'Чт',
		day5: 'Пт',
		day6: 'Сб',
		day7: 'Нд',
      },
    },

    tovunit: {
      name: 'Одиниці виміру',
      title: 'Одиниці виміру',
      fields: {
        name: 'Найменування',
        kf: 'Коефіцієнт',
      },
    },

    tov: {
      name: 'Товар',
      title: 'Товар',
      fields: {
        name: 'Найменування',
        top: 'ТОП',
        stock: 'Залишок',
        tovunit: 'Одиниці',
      },
    },
	
    typeprice: {
      name: 'Типи цін',
      title: 'Типи цін',
      fields: {
        name: 'Найменування',
      },
    },
	
	price: {
      name: 'Ціна',
      title: 'Ціна',
      fields: {
        name: 'Найменування',
		typeprice: 'Типи цін',
		price: 'Ціна',
      },
    },

    formpay: {
      name: 'Форма оплати',
      title: 'Форма оплати',
      fields: {
        name: 'Найменування',
       },
    },

    vidpay: {
      name: 'Вид оплати',
      title: 'Вид оплати',
      fields: {
        name: 'Найменування',
       },
    },

    order: {
      name: 'Замовлення',
      title: 'Замовлення',
	  
      fields: {
        doctype: 'Тип документа',
        date: 'Дата',
        store: 'ТТ',		
        driver: 'Водій',		
        summa: 'Сума',
        formpay: 'Форма оплати',
        vidpay: 'Вид оплати',
		typeprice: 'Тип цін',
		manager:"Менеджер",
		tov:'Товар',
		price:'Ціна',
		qty:'Кількість',
        tovunit: 'Одиниці виміру',
      },
    },
	
	worktime: {
      title: 'Запис',
      title2: 'Опис роботи',
	  title3: 'Фото [Запис: ',
      datefrom: 'Дата з:',
      dateto: 'Дата по:',
      changeworker: 'Виберіть майстра',
      changeclient: 'Виберіть клієнта',
	  changeworktype:'Виберіть тип робіт',
	  changework:'Виберіть роботу', 
	  changeworkplace:'Виберіть робоче місце', 
	  changestatus:'Виберіть статус', 
	  recordfrom:'Запис від ',
	  entercost:'Введіть ціну',
	  foto:'Фото',
	  viewmessages:'Перегляд Повідомлень',
	  message:'Повідомлення',
	  messages:'Повідомлення',
	  addfoto:'Додати фото в хмару',
	  deletedrecords:'Віддалені записи !!!',  
	  deletedrecord:'Віддалений Запис !!!',  
	  delete:'Видалити (без відновлення)',
	  restore:'Відновити Віддалений Запис!!!',
	  
      fields: {
		date:'Дата',
		time:'Час',
		worker:'Майстер',
		client:'Клієнт',
        comments: 'Коментар',
		status:'Статус',
		work:'Робота',
		cost:'Ціна',
        datechange: 'Змінено',
		user: 'Користувач',
		workplace:'Робоче місце',
		deleted:'Вилучено',
		no:'Ні',
		foto:'Фото',
		filelocation:'Розміщення Файлу',
      },
    },
	
    customers: {
      name: 'Customer |||| Customers',
      title: 'Партнер',
	  addaccesscustomers:'Додати Доступ до Партнерів (Працівника)',
	  search:'Пошук партнера',
      segments:'Групи клієнтів',			  	  
	  congratulate:'Привітати', 
	  congratulate2:'Привітати за тел.',
	  datebirthday:'День народження',
	  viber:'Viber',
	  vibermessage:'Повідомлення на Viber',
	  viberprofile:'Виберіть Viber Profile',
	  telegram:'Telegram',
	  telegrammessage:'Повідомлення на Telegram',
	  firstmessage:'Повідомлення-1',
	  firstmessage2:'Перше Повідомлення',
	  secondmessage:'Повідомлення-2',
	  secondmessage2:'Друге Повідомлення',
	  records:'Записи',
	  messages:'Повідомлення',
      message: {
       new: 'Нове (не надіслано)',
       send:'Надіслано',
       delivered:'Доставлено',
       seen: 'Переглянуто',
	  },
      fields: {
        name: "Найменування",
		lastname:'Прізвище',
		tel1:'Телефон ',
		tel2:'Телефон 2 ',
        comments: 'Коментар',
	    segments:'Група',		
 		date:'Дата',
		worker:'Майстер',
		client:'Клієнт',
		status:'Статус',
      changed:'Изменено ',
      user:'User ',
      birthday:'birthday',
      birthdaymessage:'birthdaymessage',
      viberOn:'viberOn',
      viberid:'viberid',
		message:'Повідомлення',
		record:'Запис',
     },
    },

    worker: {
      name: 'Співробітник',
      title1: 'Співробітникі',
      title: 'Співробітник',
	  changeworkplace:'Виберіть робоче місце', 
	  selectworker:'Вибір Співробітника',	  
	  priority:'Черговість',
      addaccessworker:'Додати Доступ до Працівника',
	fields: {
        name: "Ім'я",
        lastname: 'Прізвище',
        firstname: "Ім'я",
        fathername: 'По-батькові',
        birthday: 'Дата народження',
        datechange: 'Змінено',
      jobtitle:'Посада',
      workplace:'Робоче місце',
      tel:'Телефони',
      tel1:'Телефон 1',
      tel2:'Телефон 2',
	  telforclient:'Телефон для клієнтів',
	  firstmessage:'Перший рядок повідомлення клієнту',
      avatar:'Аватар',		
      user:'User ',
      },
    },

    workplace: {
      name: 'Робоче місце',
      title: 'Робоче місце',
      addaccessworkplace:'Додати Доступ до Робочего місця',
      fields: {
        name: 'Найменування',
        comments: 'Коментар',
      },
    },
	
    typework: {
      name: 'Типи робот',
      title: 'Типи робот',
      addaccesstypework:'Додати Доступ до Типа робот',
      fields: {
        name: 'Тип роботи',
        comments: 'Коментар',
      },
    },
	
    works: {
      name: 'Робота - Ціна',
      title: 'Робота - Ціна',
	  selecttypework:'Виберіть тип робіт',
      fields: {
        name: 'Найменування Роботи',
        typework: 'Тип роботи',
        cost: 'Ціна',
        comments: 'Коментар',
        datechange: 'Змінено',
      user:'User ',
      },
    },

	
    segments: {
      name: 'Групи клієнтів',
      title: 'Група клієнтів',
      search: 'Пошук',
	  selectsegment:'Виберіть Групу',
	  addaccesssegments:'Додати Доступ до Груп Клієнтів',
      fields: {
        name: 'Найменування групи',
        comments: 'Коментар',
      },
    },

    worktimestatus: {
      name: 'Статуси записів',
      title: 'Статуси записів',
      search: 'Пошук',
      fields: {
        name: 'Найменування',
      comments: 'Коментар',
      },
    },

    workingday: {
      name: 'Графік роботи',
      title: 'Робочий день',
      fields: {
        name: 'День тижня',
        comments: 'Коментар',
      },
    },


	auth: {
      name: 'Name',
      title: 'Користувач',
	  config:'Конфігурація',
	  setworker:"Прив'язка до Майстра",
	  selectworker:'Виберіть Майстра',
	  changepassword:'Змінити пароль',
	  loadavatar:'Завантажити Аватар',
	  worker_typework:'Працівники / Типи робіт',
	  clients_groups:'Клієнти / Групи',
      fields: {
		name: "Ім'я",
        comments: 'Коментар',
		lastname:'Прізвище',
		worker:'Майстер',
		tel:'Телефон',
		avatar:'Аватар',
		rolename:'Роль',
		active:'Активно',
		unitname:"Об'єкт",
		access:'Доступ',
		cloudname:'Хмара',
		userfolder:'Папка для фото',
		comments1:'Коментарі до сховища',
		comments2:'Коментарі до Користувачa',
		jobtitle:'Посада',
		worktype:'Тип робіт',
		workplace:'Робоче місце',
		group:'Група',      		
		isworker: 'Работник',
    },
	},
	
    passwordpage: {
      password: 'Зміна пароля',
      oldpassword: 'Введіть старий password',
      newpassword: 'Введіть новий password',
      changepassword: 'Змінити пароль',
    },
    profile: {
      title: 'Профіль',
      login: 'login',
      email: 'email',
      name: "Ім'я",
      lastname: 'Прізвище',
      load_avatar: 'Завантажити аватар',
      changepassword: 'Змінити пароль',
    },
    
	role: {
      name: 'Name',
      title: 'Title',
	  addrole:'Додати Роль',
	  selectrole:'Виберіть Роль',
      accessrole:'Доступ по Ролі',
	  fields: {
        name: 'Найменування',
        role: 'Роль',
        comments: 'Коментар',
        unit:"Об'єкт",
        access:'Доступ',
	    code:'Код',
	    type:'Тип',
        active:'Активно',
	    namerole:'Назва ролі',
      },
    },
	
    unit: {
      name: 'Доступ',
      title: 'Доступ',
	  addaccess:'Додати Доступ',
	  selectaccesstype:'Виберіть Тип доступу',
      fields: {
        unit: "Об'єкт",
		unittype:'Тип доступу',
        code: 'Код Доступу',
        comments: 'Коментар',
		unitname:'Назва Доступу',
	    accesstype:'Тип доступу',
      },
    },
	
    unittype: {
      name: 'Тип Доступу',
      title: 'Тип Доступу',
	  addunittype:'Додати Тип Доступу',
      fields: {
        name: 'Назва Типу Доступу',
		unittype:'Тип доступу',
        comments: 'Коментар',
      },
    },

	cloud: {
      name: 'Хмарні сховища',
      title: 'Доступу до хмари',
	  title1:'Налаштування хмари',
	  namecloud:'Назва доступу до хмари',
	  selectcloudtype:'Виберіть Тип хмари',
	  usecloud:'У кого використовується цей доступ:',
	  selectcloud:'Виберіть Хмару',
	  folder:'Папка для фото',
	  addaccesscloud:'Додати Хмарне сховище',
      fields: {
        name: "Ім'я",
		rootfolder:'Коренева папка',
        comments: 'Коментар',
		cloudtype:'Тип хмари',
		worker:'Майстер',
      },
    },

	
	select:{
	search:'Введіть пошуковий запит',
	setname:'Введіть найменування',
	selectunittype:'Виберіть Тип доступу',
	selecttypework:'Виберіть тип робіт',
	selectworker:'Виберіть Майстра',
      fields: {
        name: "Ім'я",
		names:'Найменування',
		rootfolder:'Коренева папка',
		cloudtype:'Тип хмари',
        comments: 'Коментар',
		lastname:'Прізвище',
		tel1:'Телефон 1',
		tel2:'Телефон 2',
		newclient:'Новий клієнт',
		worktype:'Тип робіт',
		unitname:"Об'єкт",
		code:'Код',
		unittype:'Тип доступу',
		avatar:'Аватар',
		tel:'Телефон',
		subscribed:'Підписано',
		client:'Клієнт',
		linked:"+ Пов'язаний",
		notsubscribe:'+ Непідписано',
		typework:'Тип роботи',
		cost:'Вартість роботи',
		workplace:'Робоче місце',
		message:'Повідомлення',
		date:'Дата',
		status:'Статус',
      },
	},

	    allselect: {
      search: 'Введіть пошуковий запит',
      fields: {
        name: 'Найменування',
        comments: 'Коментар',
      },
    },

	
    files: {
      name: 'Сховище файлів',
      add_folder: 'Додати папку',
      edit_folder: 'Редагувати папку',
      add_rootfolder: 'Додати кореневу папку',
      name_folder: 'Назва папки для файлів',
      namefolder: 'Найменування',
      comments: 'Коментар',
      title: 'Файл',
      tooltip: {
        add_rootfolder: 'Додавання кореневої папки',
      },
      fields: {
        name: 'Найменування файлу',
        folders: 'Папка',
        description: 'Опис',
        autor: 'Автор',
        data: 'Дата створення',
        size: 'Розмір',
        tblnm: 'На підставі',
        datechange: 'Змінено',
      },
    },


	
    productvid: {
      name: 'Види номенклатури',
      title: 'Види номенклатури',
      title2: 'Група видів номенклатури',
      add_group: 'Додати групу',
      edit_group: 'Редагувати групу',
      add_productvid: 'Додати вид номенклатури',
      edit_productvid: 'Редагувати вид номенклатури',
      select_folders: 'Виберіть Групу видів номенклатури',
      tooltip: {
        add_rootfolder: 'Додавання кореневої групи видів номенклатури',
      },

      fields: {
        name: 'Найменування',
        folders: 'Група видів номенклатури',
        comments: 'Коментар',
      },
    },
    productcat: {
      name: 'Товарні категорії',
      title: 'Товарні категорії',
      select_vid: 'Виберіть Вид номенклатури',
      select_workplace: 'Виберіть Робочий центр',
      select_ed: 'Виберіть Од.вим.',
      select_variable: 'Виберіть параметр виміру',
      add_variable: 'Додати параметр виміру',
      variables: 'Параметри виміру',
      add: 'Додати',
      fields: {
        name: 'Найменування',
        date: 'Дата',
        vid: 'Вид номенклатури',
        workplace: 'Робочий центр',
        ed: 'Од.вим.',
        count: 'Кількість працівників',
        formula: 'Формула',
        comments: 'Коментар',
        datechange: 'Змінено',
        variable: 'Параметр виміру',
        add_variable_to_formula: 'Додати параметр до формули',
      },
    },
    product: {
      name: 'Номенклатура',
      title: 'Номенклатура',
      select_vid: 'Виберіть  Вид',
      select_dimension: 'Виберіть Розміри',
      fields: {
        name: 'Найменування',
        dimensions: 'Розміри',
        vid: 'Вид',
      },
    },
    workday: {
      name: 'Графік роботи',
      title: 'Графік роботи',
      title2: 'Робоча зміна',
      breaktimes: 'Перерви',
      breaktime: 'Перерва',
      add_breaktime: 'Додати Перерва',
      err_start: 'Помилковий час початку зміни',
      err_end: 'Помилковий час закінчення зміни',
      err_time1: 'Помилковий час початку перерви',
      err_time2: 'Помилковий час закінчення перерви',
      fields: {
        date: 'Дата',
        name: 'Зміна',
        start: 'Початок',
        end: 'Закінчення',

        time1: 'Початок',
        time2: 'Закінчення',
        breaktime: 'Перерва (хв.)',
        breaktimeh: 'Перерва (год.)',

        alltime: 'Тривалість (хв.)',
        allbreaktime: 'Час перерв (хв.)',
        allworktime: 'Час роботи (хв.)',
        alltimeh: 'Тривалість (год.)',
        allbreaktimeh: 'Час перерв (год.)',
        allworktimeh: 'Час роботи (год.)',

        AllTime: 'Сумарна тривалість (хв)',
        AllBreakTime: 'Сумарний час перерв (хв)',
        AllWorkTime: 'Сумарний час роботи (хв)',
        AllTimeh: 'Сумарна тривалість (год.)',
        AllBreakTimeh: 'Сумарний час перерв (год.)',
        AllWorkTimeh: 'Сумарний час роботи (год.)',

        datechange: 'Змінено',
        comments: 'Коментар',
        detail: 'Докладно',
      },
    },

    downtimetypes: {
      name: 'Типи Простоїв',
      title: 'Типи Простоїв',
      fields: {
        name: 'Найменування',
        comments: 'Коментар',
      },
    },

    subdivision: {
      name: 'Підрозділ',
      title: 'Підрозділ',
      add_folder: 'Додати папку',
      edit_folder: 'Редагувати папку',
      add_rootfolder: 'Додати кореневу папку',
      namefolder: 'Назва папки для підрозділів',
      tooltip: {
        add_rootfolder: 'Додавання кореневої папки',
      },
      fields: {
        name: 'Найменування',
        folder: 'Вищий підрозділ',
        code: 'Код',
        comments: 'Коментар',
        datechange: 'Змінено',
      },
    },
    brigades: {
      name: 'Name',
      title: 'Бригада',
      select_worker: 'Виберіть Начальника бригади',
      fields: {
        name: 'Номер бригади',
        date: 'Дата призначення бригадира',
        worker: 'Начальник бригади',
        comments: 'Коментар',
        datechange: 'Змінено',
      },
    },
    jobtitle: {
      name: 'Name',
      title: 'Title',
      fields: {
        name: 'Найменування',
        codekp: 'Код КП',
        codezkpptr: 'Код ЗКППТР',
        namekp: 'Найменування по КП',
        comments: 'Коментар',
        datechange: 'Змінено',
      },
    },
    vidrepairs: {
      name: 'Види Ремонтїв',
      title: 'Види Ремонтїв',
      fields: {
        name: 'Найменування',
        comments: 'Коментар',
      },
    },

    repairs: {
      name: 'Ремонтні заходи',
      title: 'Ремонтні заходи',
      title2: 'Дефектні вузли',
      titledeffect: 'Дефектний вузол',

      select_status: 'Виберіть Статус',
      select_subdivision: 'Виберіть Підрозділ',
      add_deffect: 'Додати дефектний вузол',
      fields: {
        name: 'Найменування',
        item: 'Вузол',
        itemname: 'Назва вузла',
        itemdescription: 'Опис дефекту',
        nomer: 'Номер',
        startdate: 'Дата',
        repairstatus: 'Статус',
        enddate: 'Виправити до',
        shortdescription: 'Короткий опис',
        subdivision: 'Підрозділ',
        description: 'Опис',
        datechange: 'Змінено',
        comments: 'Коментар',
      },
    },
    sample: {
      name: 'Шаблони виконання',
      title: 'Шаблони виконання',
      title1: 'Шаблон',
      title2: 'Характеристики',
      add_variable: 'Додати характеристику',
      select_variable: 'Виберіть Характеристику',
      tooltip: {
        variantname: 'Оновлення назви для Виконання',
      },
      fields: {
        name: 'Найменування Шаблона',
        variable: 'Характеристика',
        enttp: 'Тип',
        shortname: 'Коротка назва',
        comments2: 'Коментар до характеристики',
        sort: 'Позиція у Виконанні',
        sortname: 'Позиція у найменуванні',
        separator: 'Розділювач у найменуванні',
        variantname: 'Формування назви у Виконанні',
        comments: 'Коментар',
      },
    },
    variable: {
      name: 'Характеристики',
      title: 'Характеристики',
      title2: 'Характеристика',
      select_enttp: 'Виберіть Тип характеристики',
      select_antbl: 'Виберіть Перераховані значення',
      fields: {
        name: 'Найменування',
        enttp: 'Тип характеристики',
        shortname: 'Коротка назва',
        antbl: 'Перераховані значення',
        comments: 'Коментар',
      },
    },
    antbl: {
      name: 'Значення Характеристик',
      title: 'Значення Характеристик',
      title1: 'Значення Характеристики',
      title2: 'Додавання значення для Характеристики',
      add_value: 'Додати значення',
      value: 'Значення',
      fields: {
        name: 'Найменування',
        kod: 'Код',
        comments: 'Коментар',
      },
    },



    warehouses: {
      name: 'Склад',
      title: 'Склад',
      add_group: 'Додати групу',
      edit_group: 'Редагувати групу',
      add_warehouse: 'Додати cклад',
      edit_warehouse: 'Редагувати склад',
      group_warehouse: 'Група складів',
      add_group_warehouse: 'Додавання кореневої Групи складів',
      setworker: 'Виберіть Відповідального співробітника',
      setgroupwarehouse: 'Виберіть Групу складів',
      fields: {
        groupname: 'Найменування групы',
        name: 'Найменування складу',
        comments: 'Коментар',
        worker: 'Відповідальний',
        date: 'Дата призначення Відповідального',
        datechange: 'Змінено',
      },
    },
    unitdimension: {
      name: 'Одиниці виміру',
      title: 'Одиниці виміру',
      fields: {
        name: 'Найменування',
        fullname: 'Повне найменування',
        code: 'Код',
        comments: 'Коментар',
      },
    },
    dimensions: {
      name: 'Dimensions',
      title: 'Dimension',
      fields: {
        name: 'Найменування',
        differentlength: 'Різна довжина',
        height: 'Висота',
        width: 'Ширина',
        length: 'Довжина',
        volume: 'Об `єм',
        square: 'Площа',
      },
    },
    species: {
      name: 'Порода деревини',
      title: 'Порода деревини',
      fields: {
        name: 'Найменування',
        density: 'Питома вага',
      },
    },
    quality: {
      name: 'Якість продукції',
      title: 'Якість продукції',
      fields: {
        name: 'Якість',
      },
    },
    variant: {
      name: 'Виконання',
      title: 'Виконання',
      select_vid: 'Виберіть вигляд',
      select_species: 'Виберіть породу деревини',
      select_quality: 'Виберіть якість продукції',

      tooltip: {
        refresh_name: 'Оновлення Найменування',
      },
      fields: {
        name: 'Найменування',
        vid: 'Вид номенклатури',
        species: 'Порода деревини',
        quality: 'Якість',
        sample: 'Шаблон',
      },
    },
    operation: {
      name: 'Тех.операції',
      title: 'Тех.операції',
      titleinfo: 'Тех.операція',
      select_time: 'Виберіть од. вимірювання часу',
      select_load: 'Виберіть од. вимірювання завантаження',
      select_workplace: 'Виберіть Робочий центр',
      add_dop_workplace: 'Додати Дод. Робочий центр',
      dop_workplace: 'Дод. Робочі центри',

      fields: {
        name: 'Изготовление',
        nomer: 'Порядковий номер',
        workplace: 'Робочий центр',
        time1: 'Час',
        loading: 'Завантаження',
      },
    },

    commands: {
      name: 'Order |||| Orders',
      amount: '1 order |||| %{smart_count} orders',
      title: 'Order %{reference}',
      fields: {
        basket: {
          delivery: 'Delivery',
          reference: 'Reference',
          quantity: 'Quantity',
          sum: 'Sum',
          tax_rate: 'Tax Rate',
          taxes: 'Tax',
          total: 'Tot:{al',
          unit_price: 'Unit Price',
        },
        address: 'Address',
        customer_id: 'Customer',
        date_gte: 'Passed Since',
        date_lte: 'Passed Before',
        nb_items: 'Nb Items',
        total_gte: 'Min amount',
        status: 'Status',
        returned: 'Returned',
      },
      section: {
        order: 'Order',
        customer: 'Customer',
        shipping_address: 'Shipping Address',
        items: 'Items',
        total: 'Totals',
      },
    },
    invoices: {
      name: 'Invoice |||| Invoices',
      fields: {
        date: 'Invoice date',
        customer_id: 'Customer',
        command_id: 'Order',
        date_gte: 'Passed Since',
        date_lte: 'Passed Before',
        total_gte: 'Min amount',
        address: 'Address',
      },
    },
    products: {
      name: 'Poster |||| Posters',
      fields: {
        category_id: 'Category',
        height_gte: 'Min height',
        height_lte: 'Max height',
        height: 'Height',
        image: 'Image',
        price: 'Price',
        reference: 'Reference',
        sales: 'Sales',
        stock_lte: 'Low Stock',
        stock: 'Stock',
        thumbnail: 'Thumbnail',
        width_gte: 'Min width',
        width_lte: 'Max width',
        width: 'Width',
      },
      tabs: {
        image: 'Image',
        details: 'Details',
        description: 'Description',
        reviews: 'Reviews',
      },
      filters: {
        categories: 'Categories',
        stock: 'Stock',
        no_stock: 'Out of stock',
        low_stock: '1 - 9 items',
        average_stock: '10 - 49 items',
        enough_stock: '50 items & more',
        sales: 'Sales',
        best_sellers: 'Best sellers',
        average_sellers: 'Average',
        low_sellers: 'Low',
        never_sold: 'Never sold',
      },
    },
    categories: {
      name: 'Category |||| Categories',
      fields: {
        products: 'Products',
      },
    },
    reviews: {
      name: 'Review |||| Reviews',
      amount: '1 review |||| %{smart_count} reviews',
      relative_to_poster: 'Review on poster',
      detail: 'Review detail',
      fields: {
        customer_id: 'Customer',
        command_id: 'Order',
        product_id: 'Product',
        date_gte: 'Posted since',
        date_lte: 'Posted before',
        date: 'Date',
        comment: 'Comment',
        rating: 'Rating',
      },
      action: {
        accept: 'Accept',
        reject: 'Reject',
      },
      notification: {
        approved_success: 'Review approved',
        approved_error: 'Error: Review not approved',
        rejected_success: 'Review rejected',
        rejected_error: 'Error: Review not rejected',
      },
    },
  },
}

export default customUkraineMessages
