import React, { useContext, useCallback, useState, useEffect } from "react";
//import { Button, Form } from 'react-bootstrap'
import { Form, Col, Row, ListGroup } from "react-bootstrap";
import { Link } from "react-router-dom";

import { useSelector, useDispatch } from "react-redux";
import SelectControl from "../../components/SelectControl";
//import { format_date_time } from '../../utils/formatDate'
import { useTable } from "react-table";
import styled from "styled-components";

import Typography from "@material-ui/core/Typography";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import { DropzoneArea, DropzoneDialogBase } from "material-ui-dropzone";
import { getAccessCode } from "../../access";

import Switch, { SwitchProps } from "@mui/material/Switch";
import SelectRole from "../../components/SelectRole";

import { darkTheme, lightTheme } from "../../layouts/Them/themes";
import { defaultTheme } from "../../layouts/Them/defaultTheme";

import SelectUsers from "../../components/SelectUsers";
import SelectSupervisors from "../../components/SelectSupervisors";
import SelectSegment from "../../components/SelectSegment";
import SelectUserCloud from "../../components/SelectUserCloud";

import FormGroup, { FormGroupProps } from "@mui/material/FormGroup";

import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";

import { default as Spinner } from "../../components/Spinner";

import {
  useMediaQuery,
  Avatar,
  Box,
  Divider,
  Tabs,
  Tab,
  Theme,
} from "@mui/material";

import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import IconButton from "@material-ui/core/IconButton";
import AccountCircle from "@mui/icons-material/AccountCircle";
import { Button } from "@mui/material";

import { useTheme } from "../../layouts/Them/useTheme";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";

import CloseIcon from "@material-ui/icons/Close";
import PersonIcon from "@mui/icons-material/Person";
import PeopleIcon from "@mui/icons-material/People";
import SettingsIcon from "@mui/icons-material/Settings";
import CloudQueueIcon from "@mui/icons-material/CloudQueue";

import { useTranslate } from "../../lib/translate/i18n/useTranslate";
import { AuthContext } from "../../context/AuthContext";
import { getURLAPI } from "../../utils/getURL";

import FormControlLabel from "@mui/material/FormControlLabel";

import Checkbox from "@mui/material/Checkbox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

import Autocomplete from "@mui/material/Autocomplete";
import { Card, CardContent } from "@mui/material";

const blankicon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

/*
import PendingActionsIcon from '@mui/icons-material/PendingActions'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'
import ContentPasteSearchIcon from '@mui/icons-material/ContentPasteSearch'
import ThreePIcon from '@mui/icons-material/ThreeP'
import AppBlockingIcon from '@mui/icons-material/AppBlocking'
import BlockIcon from '@mui/icons-material/Block'
import ContentCutIcon from '@mui/icons-material/ContentCut'
import ConstructionIcon from '@mui/icons-material/Construction'
import ApiIcon from '@mui/icons-material/Api'
import AttachMoneyIcon from '@mui/icons-material/AttachMoney'
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks'
import TitleIcon from '@mui/icons-material/Title'
*/

const Styles = styled.div`
  table {
    border-spacing: 0;
    border: 1px solid #c3c3c3;
    border-radius: 10px;
    border-collapse: initial;
    tr {
      border-radius: 0px;

      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th,
    td {
      margin: 0;
      padding: 0.5rem;
      border-bottom: 1px solid #c3c3c3;
      border-right: 1px solid #c3c3c3;

      :last-child {
        border-right: 0;
      }
    }
  }
`;

const defaultPropGetter = () => ({});

var columns = [];
var data = [];

var columns2 = [];
var data2 = [];

var columns4 = [];
var data4 = [];

const AuthItem = () => {
  const auth_item = useSelector((state) => state.app.body.auth_item);
  const role_item = useSelector((state) => state.app.body.role_item);
  const worker_item = useSelector((state) => state.app.body.worker_item);
  const supervisor_item = useSelector(
    (state) => state.app.body.supervisor_item
  );
  const config_item = useSelector((state) => state.app.body.config_item);

  const body = useSelector((state) => state.app.body);
  const access = useSelector((state) => state.app.access.access);
  const app = useSelector((state) => state.app);
  const access2 = useSelector((state) => state.app.access);
  const isadmin = (access && access.isadmin) || false;
  const issuper = (access && access.issuper) || false;
  const isXSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const nameTheme = access && access.user && access.user.theme;
  const translate = useTranslate();
  const auth = useContext(AuthContext);
  const dispatch = useDispatch();

  const [theme, setTheme] = useTheme();

  if (isadmin || getAccessCode("CHANGE_PSW", access && access.access)) {
    //    console.log('PasswordPage auth_item', auth_item)

    //разрешение на изменение пароля без знания старого пароля пользователя
    if (auth_item && !auth_item.CHANGE_PSW)
      dispatch({
        type: "app/set",
        payload: {
          id: auth_item,
          field: "CHANGE_PSW",
          value: true,
        },
        ob: "auth_item",
      });
  }

  let UserInfo = auth_item && auth_item.login;
  if (auth_item && auth_item.fullname)
    UserInfo = auth_item.login + " / " + auth_item.fullname;

  const [subPages, setSubPages] = useState({ page: "main" });

  //START get all str from table Roles
  const [startRefreshFilter, setStartRefreshFilter] = useState(true);
  const [startRefresh, setStartRefresh] = useState(true);

  //замыкание

  const ws_client = auth.ws_client;

  const item_id = body.item_id || (auth_item && auth_item.id);

  const readonly = body.readonly || false;

  //setpsw

  const [pswopen, setPswlOpen] = React.useState(false);
  const handlePswOpen = () => setPswlOpen(true);
  const handlePswClose = () => setPswlOpen(false);

  const [showAlert, setShowAlert] = React.useState(false);
  const [AlertMessage, setAlertMessage] = React.useState(null);
  const [AlertType, setAlertType] = React.useState("error");

  const handlePswSave = (result) => {
    //    console.log(result)

    const { status, message } = result && result.result;
    if (status && status === "err") {
      setAlertType("error");
      setAlertMessage(message);
      setShowAlert(true);
    } else {
      setAlertType("success");
      setAlertMessage("Пароль успешно изменен !!!");
      setShowAlert(true);
    }

    //    setSubPages({ page: 'main' })
  };

  //setpsw

  //config_item
  let acces_cloud = false;
  if (
    access &&
    access.user &&
    access.user.clouds &&
    access.user.clouds.length > 0
  )
    acces_cloud = true;

  const getConfigItem = function (_result, _parent) {
    //console.log(_result)
    if (_result && _result.result && _result.result.status === "err") {
      console.log("Err", _result.result.message);
      return;
    }
    let { config = null } = _result && _result.result;
    dispatch({
      type: "fetch/item_add",
      payload: config, //parent,
      ob: "config",
    });
    setSubPages({ page: "config" });
  };

  const setConfigItem = function (_result, _parent) {
    //console.log(_result)
    dispatch({
      type: "fetch/get_add",
      payload: _result && _result.result,
      ob: "auth_item",
    });
    setSubPages({ page: "main" });
  };

  const RhandleChangelanguage = (event) => {
    config_item.change = true;
    dispatch({
      type: "app/set", //'app/setconfig',
      payload: {
        id: config_item,
        field: "language",
        value: event.target.value,
      },
      ob: "config_item",
    });
  };
  const RhandleChange = (event) => {
    config_item.change = true;
    dispatch({
      type: "app/set",
      payload: {
        id: config_item,
        field: "theme",
        value: event.target.value,
      },
      ob: "config_item",
    });

    //    if (event.target.value === 'default') setTheme(defaultTheme)
    //    if (event.target.value === 'dark') setTheme(darkTheme)
    //    if (event.target.value === 'light') setTheme(lightTheme)
  };

  //supervisor_item
  const getSupervisorItem = function (_result, _parent) {
    //console.log(_result)
    if (_result && _result.result && _result.result.status === "err") {
      console.log("Err", _result.result.message);
      return;
    }
    let { parent, item, getid = null } = _result && _result.result;
    dispatch({
      type: "fetch/item_add",
      payload: { item, getid }, //parent,
      ob: "supervisor",
    });
    setSubPages({ page: "supervisors" });
  };

  const setSupervisorItem = function (_result, _parent) {
    //console.log(_result)
    dispatch({
      type: "fetch/get_add",
      payload: _result && _result.result,
      ob: "auth_item",
    });
    setSubPages({ page: "main" });
  };

  const setWorkerItem = function (_result, _parent) {
    //console.log(_result)
    dispatch({
      type: "fetch/get_add",
      payload: _result && _result.result,
      ob: "auth_item",
    });
    setSubPages({ page: "main" });
  };
  //worker_item

  //typework_item
  const getTypeWorkItem = function (_result, _parent) {
    //console.log(_result)
    if (_result && _result.result && _result.result.status === "err") {
      console.log("Err", _result.result.message);
      return;
    }
    let { parent, item, getid = null } = _result && _result.result;
    dispatch({
      type: "fetch/item_add",
      payload: { item, getid }, //parent,
      ob: "typework",
    });
    setSubPages({ page: "typeworks" });
  };

  const setTypeWorkItem = function (_result, _parent) {
    //console.log(_result)
    dispatch({
      type: "fetch/get_add",
      payload: _result && _result.result,
      ob: "auth_item",
    });
    setSubPages({ page: "main" });
  };
  //typework_item

  //workplace_item
  const getWorkPlaceItem = function (_result, _parent) {
    //console.log(_result)
    if (_result && _result.result && _result.result.status === "err") {
      console.log("Err", _result.result.message);
      return;
    }
    let { parent, item, getid = null } = _result && _result.result;
    dispatch({
      type: "fetch/item_add",
      payload: { item, getid }, //parent,
      ob: "workplace",
    });
    setSubPages({ page: "workplaces" });
  };

  const setWorkPlaceItem = function (_result, _parent) {
    //console.log(_result)
    dispatch({
      type: "fetch/get_add",
      payload: _result && _result.result,
      ob: "auth_item",
    });
    setSubPages({ page: "main" });
  };
  //workplace_item

  //customer_item
  const getCustomerItem = function (_result, _parent) {
    //console.log(_result)
    if (_result && _result.result && _result.result.status === "err") {
      console.log("Err", _result.result.message);
      return;
    }
    let { parent, item, getid = null } = _result && _result.result;
    dispatch({
      type: "fetch/item_add",
      payload: { item, getid }, //parent,
      ob: "customer",
    });
    setSubPages({ page: "customers" });
  };

  const setCustomerItem = function (_result, _parent) {
    //console.log(_result)
    dispatch({
      type: "fetch/get_add",
      payload: _result && _result.result,
      ob: "auth_item",
    });
    setSubPages({ page: "main" });
  };
  //customer_item

  //segment_item
  const getSegmentItem = function (_result, _parent) {
    //console.log(_result)
    if (_result && _result.result && _result.result.status === "err") {
      console.log("Err", _result.result.message);
      return;
    }
    let { parent, item, getid = null } = _result && _result.result;
    dispatch({
      type: "fetch/item_add",
      payload: { item, getid }, //parent,
      ob: "segment",
    });
    setSubPages({ page: "segments" });
  };

  const setSegmentItem = function (_result, _parent) {
    //console.log(_result)
    dispatch({
      type: "fetch/get_add",
      payload: _result && _result.result,
      ob: "auth_item",
    });
    setSubPages({ page: "main" });
  };
  //segment_item

  //cloud_item
  const getCloudItem = function (_result, _parent) {
    //console.log(_result)
    if (_result && _result.result && _result.result.status === "err") {
      console.log("Err", _result.result.message);
      return;
    }
    let { parent, item, getid = null } = _result && _result.result;
    dispatch({
      type: "fetch/item_add",
      payload: { item, getid }, //parent,
      ob: "cloud",
    });
    setSubPages({ page: "clouds" });
  };

  const setCloudItem = function (_result, _parent) {
    //console.log(_result)
    dispatch({
      type: "fetch/get_add",
      payload: _result && _result.result,
      ob: "auth_item",
    });
    setSubPages({ page: "main" });
  };
  //cloud_item

  //role_item
  const getRoleItem = function (_result, _parent) {
    //console.log(_result)
    if (_result && _result.result && _result.result.status === "err") {
      console.log("Err", _result.result.message);
      return;
    }
    let { parent, item, getid = null } = _result && _result.result;
    dispatch({
      type: "fetch/item_add",
      payload: { item, getid }, //parent,
      ob: "role",
    });
    setSubPages({ page: "roles" });
  };

  const setRoleItem = function (_result, _parent) {
    //console.log(_result)
    dispatch({
      type: "fetch/get_add",
      payload: _result && _result.result,
      ob: "auth_item",
    });
    setSubPages({ page: "main" });
  };
  //role_item

  const GetData_handle = function (_result) {
    const { tableName, result, readonly, action } = _result;
    //console.log('result handle1 ', result)
    if (tableName === "Users" && action.metod === "getOne") {
      dispatch({
        type: "fetch/get_add",
        payload: result,
        ob: "auth_item",
      });
      dispatch({
        type: "fetch/get_add",
        payload: readonly,
        ob: "readonly",
      });
      return;
    }

    if (tableName === "Units") {
      dispatch({
        type: "fetch/filter", //'fetch/get_add',
        payload: result,
        ob: "unit",
      });
      return;
    }

    if (tableName === "UnitTypes") {
      dispatch({
        type: "fetch/filter", //'fetch/get_add',
        payload: result,
        ob: "unittype",
      });
      return;
    }

    if (tableName === "Users" && action.metod === "getUserSupervisors") {
      dispatch({
        type: "fetch/get_add",
        payload: result,
        ob: "supervisor",
      });
      return;
    }
  };

  const Refresh_handle = async function (result, event, parent) {
    const { tableName, action, metod } = result;
    /*    console.log(
      'Refresh_handle ',
      event,
      new Date().toLocaleTimeString(),
      result
    )
    */
    setStartRefresh(true);
  };

  const paramsFilter = {
    URL_WS: getURLAPI(auth.config, "URL_WS"), //get address WebSocket BK from config
    auth: { userId: auth.userId, tocken: auth.token },
    handleGetData: GetData_handle,
    //handleChangeData: Refresh_handle,
    handleSetRefresh: Refresh_handle, //setStartRefresh,
    //handleReadData: ReadData_handle,
    handleErrorRead: null,
    handleErrorChange: null,
    models: [
      {
        model_call_nm: "Model_read",
        tableName: "Units",
        action: {
          metod: "get",
        },
      },
      {
        model_call_nm: "Model_read",
        tableName: "UnitTypes",
        action: {
          metod: "get",
        },
      },
      {
        model_call_nm: "Model_read",
        tableName: "Users",
        action: {
          metod: "getUserSupervisors",
          id: auth.userId,
        },
      },
    ],
  };

  const params = {
    URL_WS: getURLAPI(auth.config, "URL_WS"), //get address WebSocket BK from config
    auth: { userId: auth.userId, tocken: auth.token },
    handleGetData: GetData_handle,
    //handleChangeData: Refresh_handle,
    handleSetRefresh: Refresh_handle, //setStartRefresh,
    handleErrorRead: null,
    handleErrorChange: null,
    models: [
      {
        model_call_nm: "Model_read",
        tableName: "Users",
        where: { id: item_id },
        action: {
          metod: "getOne",
          isBlocked: true,
        },
      },
    ],
  };

  //deblock item whet close page
  useEffect(
    () => () => {
      try {
        if (!readonly)
          ws_client && ws_client.deblock({ tableName: "Users", id: item_id });
      } catch (err) {
        console.log("deblock error ", err);
      }
    },
    []
  );

  if (ws_client && ws_client.ws_login && startRefreshFilter) {
    ws_client.open_process(paramsFilter);
    setStartRefreshFilter(false);
  }

  if (item_id && ws_client && ws_client.ws_login && startRefresh) {
    ws_client.open_process(params);
    setStartRefresh(false);
  }

  //END get all str from table Roles

  let maxwidth = "45em";

  let language = "ru";
  if (access && access.user && access.user.language) {
    if (access.user.language === "ru") language = "ru";
    if (access.user.language === "ua") language = "uk";
    if (access.user.language === "en") language = "en";
  }

  let greybkcolor = "rgba(255,255,255,.3)";

  if (nameTheme) {
    greybkcolor =
      nameTheme !== "dark" ? "rgba(255,255,255,1.0)" : "rgba(255,255,255,.3)";
  }

  //tabs

  const [filterValues, setFilter] = useState({ status: "role" });

  const tabs = [
    { id: "role", name: " Роли", icon: <ManageAccountsIcon /> },
    { id: "access", name: "Доступы ", icon: <SettingsIcon /> },
    { id: "works", name: "Супервайзеры", icon: <PersonIcon /> },
  ];

  const handleChange = useCallback(
    (event, value) => {
      setFilter({ status: value });
    },
    [filterValues] //displayedFilters, filterValues, setFilters]
  );

  if (filterValues.status === "role") maxwidth = "60em";
  if (filterValues.status === "access") maxwidth = "60em";
  if (filterValues.status === "works") maxwidth = "55em";

  //tabs
  // const userAvatar = access && access.user && access.user.avatar
  /*let Avatar1 = ''
  if (userAvatar && userAvatar.data) {
    for (let i in userAvatar.data) {
      Avatar1 = Avatar1 + String.fromCharCode(userAvatar.data[i])
    }
  }
*/
  // &&     access.user.avatar.data
  //  console.log('auth_item', auth_item)

  const Avatars = body.avatars;

  let UserAvatar = "";

  let uAvatar = auth_item && auth_item.avatar && auth_item.avatar.avatar;

  if (false && auth_item && !uAvatar)
    for (let a in Avatars) {
      if (Avatars[a].id === auth_item.id) {
        uAvatar = Avatars[a].avatar; //&& Avatars[a].avatar.avatar
      }
    }

  if (uAvatar && !auth_item.user_avatar) {
    if (uAvatar.data && typeof uAvatar.data === "object") {
      //Blob перевоим в String один раз
      for (let ii in uAvatar.data) {
        UserAvatar = UserAvatar + String.fromCharCode(uAvatar.data[ii]);
      }

      dispatch({
        type: "app/set",
        payload: {
          id: auth_item,
          field: "user_avatar",
          value: UserAvatar,
        },
        ob: "auth_item",
      });
    } //    UserAvatar = uAvatar
  } else if (auth_item && auth_item.user_avatar)
    UserAvatar = auth_item.user_avatar;

  const defaultIcon = <AccountCircle />;

  const icon = defaultIcon;

  //Доступ по ролям

  columns = [
    {
      Header: "№",
      accessor: "num",
    },
    {
      Header: translate("resources.auth.fields.rolename"), //'Роль',
      accessor: "rolename",
    },
    {
      Header: translate("resources.auth.fields.comments"), //'Коментарии',
      accessor: "comments",
    },
  ];

  if (!isXSmall) {
    columns.push({
      Header: translate("resources.auth.fields.active"), //'Активно',
      accessor: "active",
    });
  }
  data = [];
  //          body.workplace.map((Item) => <ItemList Item={Item} />)}

  const roles = auth_item && auth_item.roles && auth_item.roles;
  let num = 1;
  for (var iw in roles) {
    data.push({
      num: num++,
      rolename: roles[iw].role.name,
      comments: roles[iw].role.comments,
      active: roles[iw].role.active,
      role_id: roles[iw].roleid,
      roles: roles[iw],
      auth_item: auth_item,
      //itemsworks: items[iw].works,
    });
  }

  //Table2  доп Доступы

  const [filter, setfilter] = useState(null);

  const [unittype2, setunittype] = useState();

  const changeUnittype = (event, value) => {
    setunittype(value);
  };

  let unittypes = [];
  let Unittypes = app.filter.unittype_filter;
  for (var s in Unittypes) {
    unittypes.push({
      label: Unittypes[s].name,
      value: Unittypes[s].id,
    });
  }

  const [Access2, setAccess] = useState();

  const changeAccess = (event, value) => {
    setAccess(value);
  };

  let Access = [];
  Access.push({
    label: "On",
    value: true,
  });
  Access.push({
    label: "Off",
    value: false,
  });

  columns2 = [
    {
      Header: "№",
      accessor: "num",
    },
    {
      Header: translate("resources.unit.fields.unit"), //'Объект',
      accessor: "unitname",
    },
    {
      Header: translate("resources.role.fields.access"), //'Доступ',
      accessor: "access",
    },
    {
      Header: translate("resources.role.accessrole"), //'Доступ по Роли',
      accessor: "roleaccess",
    },
    {
      Header: translate("resources.unit.fields.code"), //'Код',
      accessor: "code",
    },
    {
      Header: translate("resources.unit.fields.unittype"), //'Тип',
      accessor: "type",
    },
    {
      Header: translate("resources.unit.fields.comments"), //'Коментарии',
      accessor: "comments",
    },
  ];

  data2 = [];
  //          body.workplace.map((Item) => <ItemList Item={Item} />)}

  var cloneDeep = require("lodash.clonedeep");

  let units;
  if (auth_item && auth_item.units) units = cloneDeep(auth_item.units);

  let allunits;

  if (app && app.filter) allunits = cloneDeep(app.filter.unit_filter);
  if (allunits && units)
    for (let i in allunits) {
      for (let j in units) {
        if (units[j].unit.id === allunits[i].id) {
          allunits[i].access = "ok";
          allunits[i].userunit_id = units[j].id;
        }
      }
    }

  //roleaccess

  let roleunits;
  for (let r in roles) {
    roleunits = roles[r].role.units;
  }

  if (allunits && roleunits)
    for (let i in allunits) {
      for (let j in roleunits) {
        if (roleunits[j].unit.id === allunits[i].id) {
          allunits[i].roleaccess = "ok";
          //          allunits[i].roleaccessunit_id = roleunits[j].id
        }
      }
    }

  if (filter)
    allunits =
      allunits &&
      allunits.filter((item) => {
        //        console.log('item', item)
        //        console.log('item.name', item.name)
        return (
          (item.name &&
            item.name.toLowerCase().includes(filter.toLowerCase())) ||
          (item.code &&
            item.code.toLowerCase().includes(filter.toLowerCase())) ||
          (item.comments &&
            item.comments.toLowerCase().includes(filter.toLowerCase())) ||
          false
        );
        //[item.name].contains('filter')
      });

  if (Access2) {
    allunits =
      allunits &&
      allunits.filter((item) => {
        return (
          (item.access && item.access === "ok" && Access2.value) ||
          (!item.access && !Access2.value) ||
          false
        );
        //[item.name].contains('filter')
      });
  }

  if (unittype2) {
    allunits =
      allunits &&
      allunits.filter((item) => {
        return (item.unittype && item.unittype.id === unittype2.value) || false;
        //[item.name].contains('filter')
      });
  }

  let num2 = 1;
  for (var iw2 in allunits) {
    if (!issuper && allunits[iw2].code === "MENU_ACCESS") {
      console.log("");
    } else
      data2.push({
        num: num2++,
        unitname: allunits[iw2].name,
        comments: allunits[iw2].comments,
        type: allunits[iw2].unittype && allunits[iw2].unittype.name,
        code: allunits[iw2].code,
        access: allunits[iw2].access,
        roleaccess: allunits[iw2].roleaccess,
        unit_id: allunits[iw2].id,
        userunit_id: allunits[iw2].userunit_id,
        units: allunits[iw2],
        auth_item: auth_item,
        //itemsworks: items[iw].works,
      });
  }

  //доступ к работникам
  //Table4
  columns4 = [
    //    {
    //      Header: '№',
    //      accessor: 'num',
    //    },
    {
      Header: translate("resources.auth.fields.name"), //'Имя',
      accessor: "name",
    },
  ];

  data4 = [];
  //          body.workplace.map((Item) => <ItemList Item={Item} />)}

  const supervisors = auth_item && auth_item.supervisors;
  let num4 = 1;
  for (var iw4 in supervisors) {
    data4.push({
      num: num4++,
      name: supervisors[iw4] && supervisors[iw4].supervisor.name,
      supervisor: supervisors[iw4],
      auth_item: auth_item,
    });
  }

  //****************** */

  function Table({
    columns,
    data,
    getHeaderProps = defaultPropGetter,
    getColumnProps = defaultPropGetter,
    getRowProps = defaultPropGetter,
    getCellProps = defaultPropGetter,
  }) {
    // Use the state and functions returned from useTable to build your UI
    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
      useTable({
        columns,
        data,
      });

    return (
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  // Return an array of prop objects and react-table will merge them appropriately
                  {...column.getHeaderProps([
                    {
                      className: column.className,
                      style: column.style,
                    },
                    getColumnProps(column),

                    getHeaderProps(column),
                  ])}
                >
                  {column.render("Header")}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              // Merge user row props in
              <tr {...row.getRowProps(getRowProps(row))}>
                {row.cells.map((cell) => {
                  let v = cell.row.original;

                  return (
                    <td
                      // Return an array of prop objects and react-table will merge them appropriately
                      {...cell.getCellProps([
                        {
                          className: cell.column.className,
                          style: cell.column.style,
                        },
                        getColumnProps(cell.column),
                        //  getCellProps(cell),
                      ])}
                      align="center"
                      //                    width="140px"
                    >
                      {(cell.column.id !== "timeline" && (
                        <Button
                          //as={Link}
                          variant="outlined" //"outline-primary"
                          //style={{ marginBottom: 5 }}
                          style={{ pointerEvents: v.readonly ? "none" : "" }}
                          disabled={readonly}
                          onClick={async () => {
                            try {
                              await ws_client.wss_getItem({
                                tableName: "Users",
                                item: cell.row.original.auth_item,
                                where: cell.row.original.auth_item.id,
                                action: {
                                  metod: "getuserrole",
                                  mode: "get",
                                  parent: cell.row.original.auth_item,
                                  id: cell.row.original.roles.id, //role_id,
                                },
                                callback: getRoleItem,
                              });
                            } catch (e) {
                              console.log("errr e", e);
                            }
                            //setSubPages({ page: 'role' })
                          }}
                          //to={{
                          //  pathname: '/auth/auth/item-role',
                          /*                                      
                              state: {
                              action: {
                                mode: 'edit',
                                parent: cell.row.original.worktime_item,
                                id: cell.row.original.items.id,
                              },
                            },
  */
                          //items: cell.row.original.items,
                          //                            id: cell.row.original.items.id, //cell.row.original['worktime_id'],
                          //                            itemsworks: cell.row.original.items.works,
                          //                            mode: 'edit',
                          //parent: cell.row.original.worktime_item,
                          //}}
                          /*state={{
                            mode: 'get',
                            parent: cell.row.original.auth_item,
                            id: cell.row.original.role_id,
                            //                          id: cell.row.original.role_id,
                            //                          id: cell.row.original.auth_item.id,
                            //                        role_id: cell.row.original.role_id,
                            //                            items: cell.row.original.items,
                          }}
                          */
                        >
                          {cell.render("Cell")}
                        </Button>
                      )) ||
                        cell.render("Cell")}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  }

  //доступ к работникам
  function Table4({
    columns,
    data,
    getHeaderProps = defaultPropGetter,
    getColumnProps = defaultPropGetter,
    getRowProps = defaultPropGetter,
    getCellProps = defaultPropGetter,
  }) {
    // Use the state and functions returned from useTable to build your UI
    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
      useTable({
        columns,
        data,
      });

    return (
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  // Return an array of prop objects and react-table will merge them appropriately
                  {...column.getHeaderProps([
                    {
                      className: column.className,
                      style: column.style,
                    },
                    getColumnProps(column),

                    getHeaderProps(column),
                  ])}
                >
                  {column.render("Header")}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              // Merge user row props in
              <tr {...row.getRowProps(getRowProps(row))}>
                {row.cells.map((cell) => {
                  const avatar = cell.row.original.avatar;
                  let UserAvatar = "";
                  if (avatar && avatar.data) {
                    UserAvatar = "";
                    for (let i in avatar.data) {
                      UserAvatar =
                        UserAvatar + String.fromCharCode(avatar.data[i]);
                    }
                  }

                  return (
                    <td
                      // Return an array of prop objects and react-table will merge them appropriately
                      {...cell.getCellProps([
                        {
                          className: cell.column.className,
                          style: cell.column.style,
                        },
                        getColumnProps(cell.column),
                        //  getCellProps(cell),
                      ])}
                      align="center"
                      //                    width="140px"
                    >
                      {cell.column.id === "avatar" && UserAvatar !== "" && (
                        <div>
                          <img
                            className="image-gallery-thumbnail-image"
                            src={UserAvatar}
                            height={"50px"}
                            width={"50px"} //                          style={(width = 100)}
                          ></img>
                        </div>
                      )}
                      {cell.column.id === "avatar" && UserAvatar === "" && (
                        <Avatar />
                      )}
                      {cell.column.id !== "avatar" && (
                        <Button
                          //as={Link}
                          variant="outlined" //"outline-primary"
                          //style={{ marginBottom: 5 }}
                          style={{ pointerEvents: readonly ? "none" : "" }}
                          disabled={readonly}
                          onClick={async () => {
                            try {
                              await ws_client.wss_getItem({
                                tableName: "Users",
                                //item: cell.row.original.auth_item,
                                where: auth_item.id,
                                action: {
                                  metod: "getusersupervisor",
                                  mode: "get",
                                  parent: auth_item,
                                  id:
                                    cell.row.original.supervisor &&
                                    cell.row.original.supervisor.id,
                                },
                                callback: getSupervisorItem,
                              });
                            } catch (e) {
                              console.log("errr e", e);
                            }
                            //setSubPages({ page: 'role' })
                          }}

                          //to={{pathname: '/auth/auth/item-worker',}}
                          /*state={{
                            mode: 'get',
                            parent: cell.row.original.auth_item,
                            id: cell.row.original.worker.id,
                            //                          id: cell.row.original.worker.id,
                          }}*/
                        >
                          {cell.render("Cell")}
                        </Button>
                      )}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  }

  //доступ к рабочим местам

  function Table2({
    columns,
    data,
    getHeaderProps = defaultPropGetter,
    getColumnProps = defaultPropGetter,
    getRowProps = defaultPropGetter,
    getCellProps = defaultPropGetter,
  }) {
    // Use the state and functions returned from useTable to build your UI
    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
      useTable({
        columns,
        data,
      });

    return (
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  // Return an array of prop objects and react-table will merge them appropriately
                  {...column.getHeaderProps([
                    {
                      className: column.className,
                      style: column.style,
                    },
                    getColumnProps(column),

                    getHeaderProps(column),
                  ])}
                >
                  {column.render("Header")}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              // Merge user row props in
              <tr {...row.getRowProps(getRowProps(row))}>
                {row.cells.map((cell) => {
                  let v = cell.row.original;
                  let Checked = false;
                  if (v && v.access === "ok") {
                    Checked = true;
                  }

                  let Checkedrole = false;
                  if (v && v.roleaccess === "ok") {
                    Checkedrole = true;
                  }

                  return (
                    <td
                      // Return an array of prop objects and react-table will merge them appropriately
                      {...cell.getCellProps([
                        {
                          className: cell.column.className,
                          style: cell.column.style,
                        },
                        getColumnProps(cell.column),
                        //  getCellProps(cell),
                      ])}
                      align="center"
                      //                    width="140px"
                    >
                      {cell.column.id === "access" && (
                        <>
                          <FormControlLabel
                            style={{
                              marginRight: 15,
                              marginLeft: 10,
                            }}
                            control={
                              <Checkbox
                                icon={blankicon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={Checked}
                                disabled={readonly}
                                color="primary"
                                onChange={async (item) => {
                                  if (!readonly) {
                                    try {
                                      await ws_client.wss_setItem({
                                        tableName: "Roles",
                                        item: auth_item,
                                        call_nm: "Model_update",
                                        where: {
                                          checked: Checked,
                                          unit_id: v.unit_id,
                                          //userunit_id: v.userunit_id,
                                        },
                                        action: { metod: "setuserunits" },
                                      });
                                    } catch (e) {
                                      console.log("errr e", e);
                                    }
                                  }
                                }}
                              />
                            }
                            label={""}
                          />
                        </>
                      )}

                      {cell.column.id === "roleaccess" && (
                        <>
                          <FormControlLabel
                            style={{
                              marginRight: 15,
                              marginLeft: 10,
                              pointerEvents: "none",
                            }}
                            control={
                              <Checkbox
                                icon={blankicon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={Checkedrole}
                                color="primary"
                              />
                            }
                            label={""}
                          />
                        </>
                      )}

                      {cell.column.id !== "access" &&
                        cell.column.id !== "roleaccess" &&
                        cell.render("Cell")}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  }
  const [open, setOpen] = React.useState(false);
  const [fileObjects, setFileObjects] = React.useState([]);

  const dialogTitle = () => (
    <>
      <span>Upload file</span>
      <IconButton
        style={{ right: "12px", top: "8px", position: "absolute" }}
        onClick={() => setOpen(false)}
      >
        <CloseIcon />
      </IconButton>
    </>
  );
  const getAvatar = () => {
    const avatar =
      UserAvatar || (fileObjects.length > 0 && fileObjects[0].data) || "";
    return avatar;
  };
  const getChange = () => {
    if (auth_item && auth_item.change === true) return "contained";
    else return "outlined";
  };

  const getDisabled = () => {
    if ((auth_item && auth_item.change === true) || data.length > 0)
      return true;
    else return false;
  };

  const getAccessToChange = () => {
    return readonly || false;
  };

  const ready = () => {
    if (auth_item) return true;
    else return false;
  };

  return (
    <>
      {ready() && (
        <>
          {subPages.page === "main" && (
            <>
              <Box maxWidth={maxwidth}>
                <Card>
                  <CardContent>
                    <>
                      <div
                        //            className="hstack gap-2"
                        style={{
                          marginTop: -20,
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography
                          id="outlined-basic"
                          label="title"
                          //variant="standard"
                          className="title h6"
                          style={{
                            marginTop: 15,
                            marginBottom: 15,
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          {translate("resources.auth.title")}
                        </Typography>
                        {readonly && (
                          <Typography
                            id="outlined-basic"
                            label="Title"
                            //variant="standard"
                            //className="title h6"
                            style={{
                              marginTop: 15,
                              marginBottom: 15,
                              //  marginRight: 275,
                              color: "red",
                            }}
                            //                      {translate('resources.workers.readonly')}
                          >
                            Readonly
                          </Typography>
                        )}
                      </div>
                      <div
                        //            className="hstack gap-2"
                        style={{
                          marginTop: 0,
                          //            marginBottomop: 30,
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        {(isadmin ||
                          true ||
                          getAccessCode(
                            "USER_CONFIG",
                            access && access.access
                          )) && (
                          <Button
                            size="small"
                            //component={Link}
                            color="primary"
                            variant="outlined"
                            disabled={getAccessToChange()}
                            //disabled={getDisabled()}
                            sx={{
                              display: "inline-flex",
                              alignItems: "center",
                            }}
                            onClick={async () => {
                              try {
                                await ws_client.wss_getItem({
                                  tableName: "Users",
                                  where: { id: auth_item.id },
                                  action: {
                                    metod: "config",
                                  },
                                  callback: getConfigItem,
                                });
                              } catch (e) {
                                console.log("errr e", e);
                              }
                              //setSubPages({ page: 'phones' })
                            }}
                            //to={{pathname: '/auth/auth/item-config',}}
                            //state={auth_item}
                            //              disabled={getDisabled()}
                          >
                            {translate("resources.auth.config")}
                          </Button>
                        )}
                        {(isadmin ||
                          true ||
                          getAccessCode(
                            "DEL_LOGIN",
                            access && access.access
                          )) && (
                          <Button
                            size="small"
                            component={Link}
                            color="primary"
                            variant="outlined"
                            sx={{
                              display: "inline-flex",
                              alignItems: "center",
                            }}
                            to={{
                              pathname: "/auth/auth/list",
                            }}
                            //state={auth_item}
                            disabled={getDisabled() || getAccessToChange()}
                            onClick={async () => {
                              try {
                                await ws_client.wss_setItem({
                                  tableName: "Users",
                                  item: auth_item,
                                  call_nm: "Model_del",
                                  where: { id: auth_item.id },
                                  action: null,
                                });
                              } catch (e) {
                                console.log("errr e", e);
                              }
                            }}
                          >
                            {translate("resources.button.del")}
                          </Button>
                        )}
                      </div>
                      {auth_item && (
                        <>
                          <div
                            className="hstack gap-2"
                            style={{
                              // marginTop: 0,
                              //            marginBottomop: 30,
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <TextField
                              id="outlined-basic"
                              label={translate("resources.auth.fields.name")} //"Имя"
                              //variant="standard"
                              variant="outlined"
                              style={{
                                marginTop: 15,
                                //display: 'flex',
                                //justifyContent: 'space-between',
                              }}
                              value={(auth_item && auth_item.fullName) || ""}
                              onChange={(item) => {
                                if (!getAccessToChange()) {
                                  auth_item.change = true;
                                  dispatch({
                                    type: "app/set",
                                    payload: {
                                      id: auth_item,
                                      field: "fullName",
                                      value: item.target.value,
                                    },
                                    ob: "auth_item",
                                  });
                                }
                              }}
                            />

                            <TextField
                              id="outlined-basic"
                              label="login"
                              //variant="standard"
                              variant="outlined"
                              style={{
                                marginTop: 15,
                                //marginTop: 15,
                                //display: 'flex',
                                //justifyContent: 'space-between',
                              }}
                              value={(auth_item && auth_item.login) || ""}
                              onChange={(item) => {
                                if (!getAccessToChange()) {
                                  auth_item.change = true;
                                  dispatch({
                                    type: "app/set",
                                    payload: {
                                      id: auth_item,
                                      field: "login",
                                      value: item.target.value.toLowerCase(),
                                    },
                                    ob: "auth_item",
                                  });
                                }
                              }}
                            />
                          </div>
                          <div
                            className="hstack gap-2"
                            style={{
                              marginTop: 0,
                              //            marginBottomop: 30,
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <TextField
                              id="outlined-basic"
                              label="email"
                              //variant="standard"
                              variant="outlined"
                              style={{
                                marginBottom: 15,
                                //display: 'flex',
                                //justifyContent: 'space-between',
                              }}
                              value={(auth_item && auth_item.email) || ""}
                              onChange={(item) => {
                                if (!getAccessToChange()) {
                                  auth_item.change = true;
                                  dispatch({
                                    type: "app/set",
                                    payload: {
                                      id: auth_item,
                                      field: "email",
                                      value: item.target.value,
                                    },
                                    ob: "auth_item",
                                  });
                                }
                              }}
                            />

                            <TextField
                              id="outlined-basic"
                              label="phone"
                              //variant="standard"
                              variant="outlined"
                              style={{
                                marginBottom: 15,
                                //display: 'flex',
                                //justifyContent: 'space-between',
                              }}
                              value={(auth_item && auth_item.phone) || ""}
                              onChange={(item) => {
                                if (!getAccessToChange()) {
                                  auth_item.change = true;
                                  dispatch({
                                    type: "app/set",
                                    payload: {
                                      id: auth_item,
                                      field: "phone",
                                      value: item.target.value,
                                    },
                                    ob: "auth_item",
                                  });
                                }
                              }}
                            />
                          </div>
                        </>
                      )}

                      <>
                        <div className="hstack gap-2">
                          <div className="hstack gap-2">
                            <Button
                              size="small"
                              component={Link}
                              color="primary"
                              //variant="outlined"
                              sx={{
                                display: "inline-flex",
                                alignItems: "center",
                              }}
                              variant={getChange()} //"outline-primary"
                              //              as={Link}
                              to={{
                                pathname: "/auth/auth/list",
                              }}
                              //state={auth_item} //, arg: { parenthtml: '/auth/auth/list' } }
                              disabled={getAccessToChange()}
                              onClick={async () => {
                                try {
                                  await ws_client.wss_setItem({
                                    tableName: "Users",
                                    item: auth_item,
                                    where: auth_item.id
                                      ? { id: auth_item.id }
                                      : null,
                                    action: { metod: "set" },
                                  });
                                } catch (e) {
                                  console.log("errr e", e);
                                }
                              }}
                            >
                              {translate("resources.button.save")}
                            </Button>
                            <Button
                              size="small"
                              //component={Link}
                              color="primary"
                              variant="outlined"
                              sx={{
                                display: "inline-flex",
                                alignItems: "center",
                              }}
                              //variant={'outline-success'}
                              //              as={Link}
                              //to={{pathname: '/auth/auth/setpsw',}}
                              //state={auth_item}
                              disabled={getAccessToChange()}
                              onClick={async () => {
                                setShowAlert(false);
                                setAlertMessage("");
                                setSubPages({ page: "setpsw" });
                              }}
                            >
                              {translate("resources.auth.changepassword")}
                            </Button>
                            <Button
                              size="small"
                              component={Link}
                              color="primary"
                              variant="outlined"
                              sx={{
                                display: "inline-flex",
                                alignItems: "center",
                              }}
                              //variant={'outline-success'}
                              //as={Link} variant="outline-primary"
                              to="/auth/auth/list"
                            >
                              {translate("resources.button.return")}
                            </Button>
                          </div>

                          <FormControlLabel
                            style={{
                              marginRight: isXSmall ? 10 : 10,
                              marginLeft: isXSmall ? 160 : 160,
                            }}
                            control={
                              <Switch
                                id={"isworker"}
                                name={"isworker"}
                                color="primary"
                                //                          onFocus={onFocus}
                                checked={
                                  (auth_item && auth_item.isworker) || false
                                }
                                onChange={(item, value) => {
                                  if (!getAccessToChange()) {
                                    auth_item.change = true;
                                    dispatch({
                                      type: "app/set",
                                      payload: {
                                        id: auth_item,
                                        field: "isworker",
                                        value: value,
                                      },
                                      ob: "auth_item",
                                    });
                                  }
                                }}
                                disabled={getAccessToChange()}
                              />
                            }
                            label={translate("resources.switch.isworker")}
                          />
                        </div>
                        <br />
                        <Grid
                          item={true}
                          key={
                            fileObjects.length > 0 &&
                            `${fileObjects[0].file?.name ?? "file"}-${0}`
                          }
                          //            className={classes.imageContainer}
                        ></Grid>
                        <div>
                          <Button
                            size="small"
                            color="primary"
                            variant="outlined"
                            sx={{
                              display: "inline-flex",
                              alignItems: "center",
                            }}
                            startIcon={
                              getAvatar() ? <Avatar src={getAvatar()} /> : icon
                            }
                            disabled={getAccessToChange()}
                            onClick={() => {
                              setOpen(true);
                            }}
                          >
                            {translate("resources.auth.loadavatar")}
                          </Button>

                          <DropzoneDialogBase
                            dialogTitle={dialogTitle()}
                            acceptedFiles={["image/*"]}
                            fileObjects={fileObjects}
                            cancelButtonText={"cancel"}
                            submitButtonText={"submit"}
                            maxFileSize={50000000}
                            filesLimit={0}
                            open={open}
                            onAdd={(newFileObjs) => {
                              console.log("onAdd", newFileObjs);
                              if (fileObjects.length === 0)
                                setFileObjects(
                                  [].concat(fileObjects, newFileObjs)
                                );
                              console.log("onAdd fileObjects", fileObjects);
                            }}
                            onDelete={(deleteFileObj, fileIndex) => {
                              console.log("onDelete", deleteFileObj, fileIndex);
                              fileObjects.splice(fileIndex, 1);
                            }}
                            onClose={() => setOpen(false)}
                            onSave={() => {
                              console.log("onSave", fileObjects);
                              setOpen(false);

                              auth_item.change = true;
                              dispatch({
                                type: "app/set",
                                payload: {
                                  id: auth_item,
                                  field: "user_avatar",
                                  value: fileObjects[0].data,
                                },
                                ob: "auth_item",
                              });
                              setFileObjects([]);
                            }}
                            showPreviews={true}
                            showFileNamesInPreview={false}
                            showPreviewsInDropzone={false}
                          />
                        </div>
                      </>
                      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                        <Tabs
                          variant="fullWidth"
                          centered
                          value={filterValues && filterValues.status}
                          indicatorColor="primary"
                          onChange={handleChange}
                        >
                          {tabs.map((choice) => (
                            <Tab
                              key={choice.id}
                              //              label={choice.name}
                              value={choice.id}
                              icon={choice.icon} //<PersonIcon />}
                            />
                          ))}
                        </Tabs>
                      </Box>
                      <Divider />
                      {filterValues && filterValues.status === "role" && (
                        <>
                          <div
                            //            className="hstack gap-2"
                            style={{
                              marginTop: -20,
                              display: "flex",
                              //            justifyContent: 'space-between',
                            }}
                          >
                            <Typography
                              id="outlined-basic"
                              label="l"
                              //variant="standard"
                              className="title h6"
                              style={{
                                marginTop: 25,
                                marginBottom: 15,
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              {translate("resources.auth.fields.rolename")}
                            </Typography>
                          </div>
                          <Button
                            size="small"
                            //component={Link}
                            color="primary"
                            variant="outlined"
                            sx={{
                              display: "inline-flex",
                              alignItems: "center",
                            }}
                            //variant={'outline-success'}
                            //              variant="outline-primary"
                            //as={Link}
                            //to={{pathname: '/auth/auth/item-role',}}
                            //state={{mode: 'add',parent: auth_item,}}
                            disabled={getAccessToChange()}
                            onClick={async () => {
                              try {
                                await ws_client.wss_getItem({
                                  tableName: "Users",
                                  where: auth_item && auth_item.id,
                                  action: {
                                    metod: "getuserrole",
                                    mode: "add",
                                    parent: auth_item,
                                  },
                                  callback: getRoleItem,
                                });
                              } catch (e) {
                                console.log("errr e", e);
                              }
                            }}
                          >
                            {translate("resources.role.addrole")}
                          </Button>
                          <br />
                          <Styles>
                            <Table
                              columns={columns}
                              data={data}
                              getHeaderProps={
                                (nameTheme &&
                                  nameTheme !== "dark" &&
                                  ((row) => {
                                    return {
                                      style: {
                                        //                   background:
                                        //                   row.index % 2 === 0 ? 'rgba(0,0,0,.1)' : 'white',
                                      },
                                    };
                                  })) ||
                                ((row) => {
                                  return {
                                    style: {
                                      color: "#ffffffb3",
                                      //                    background:
                                      //                    row.index % 2 === 0 ? 'rgba(0,0,0,.1)' : '#ffffffb3', //'#616161e6', //'#ffffffb3',
                                    },
                                  };
                                })
                              }
                              /*          
          getHeaderProps={(column) => ({
            onClick: () => alert('Header!'),
          })}
          getColumnProps={(column) => ({
            onClick: () => alert('Column!'),
          })}
          */
                              getRowProps={
                                (nameTheme &&
                                  nameTheme !== "dark" &&
                                  ((row) => {
                                    return {
                                      style: {
                                        background:
                                          row.index % 2 === 0
                                            ? "rgba(0,0,0,.1)"
                                            : "white",
                                      },
                                    };
                                  })) ||
                                ((row) => {
                                  return {
                                    style: {
                                      background:
                                        row.index % 2 === 0
                                          ? "rgba(0,0,0,.1)"
                                          : "#616161e6", //'#ffffffb3',
                                    },
                                  };
                                })
                              }
                              getCellProps={(cellInfo) => ({
                                //            onClick: (req, res) => onClickCell(cellInfo, req, res),
                                //                style: {
                                //                  backgroundColor: `hsl(${
                                //                    120 * ((120 - cellInfo.value) / 120) * -1 + 120
                                //                  }, 100%, 67%)`,
                                //                },
                              })}
                            />
                          </Styles>
                          <br />
                        </>
                      )}

                      {filterValues && filterValues.status === "access" && (
                        <>
                          <div
                            //            className="hstack gap-2"
                            style={{
                              marginTop: -20,
                              display: "flex",
                              //            justifyContent: 'space-between',
                            }}
                          >
                            <Typography
                              id="outlined-basic"
                              label="l"
                              //variant="standard"
                              className="title h6"
                              style={{
                                marginTop: 25,
                                marginBottom: 15,
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              {translate("resources.auth.fields.access")}
                            </Typography>
                          </div>

                          <div
                            //          className="hstack gap-3"
                            style={{
                              marginTop: 10,
                              //            width: 1240,
                              display: "flex",
                              //          justifyContent: 'space-between',
                            }}
                          >
                            <TextField
                              id="outlined-basic"
                              label={translate("resources.button.search")} //                "Поиск"
                              variant="outlined"
                              defaultValue={filter}
                              style={{
                                marginBottom: 15,
                                width: 380,
                                //                  display: 'flex',
                                //                justifyContent: 'space-between',
                              }}
                              onChange={(item) => {
                                setfilter(item.target.value);
                              }}
                            />
                            <Autocomplete
                              //   multiple
                              id="size-small-outlined" //      id="checkboxes-tags"
                              options={Access}
                              onChange={changeAccess}
                              size="small"
                              value={Access2} //customerproducttypes]}
                              limitTags={4}
                              //              filterSelectedOptions
                              isOptionEqualToValue={(props, option) => {
                                let rez = false;
                                if (props.value === option.value) rez = true;
                                return rez;
                              }}
                              selectOnFocus
                              clearOnBlur
                              onFocus={(e) => {
                                //            console.log(e.target.readOnly)
                                return (e.target.readOnly = true);
                              }} //выключение клавиатуры при выборе      handleHomeEndKeys
                              //      disableCloseOnSelect
                              getOptionLabel={(option) => option.label}
                              renderOption={(props, option, { selected }) => {
                                return (
                                  <li {...props}>
                                    <Checkbox
                                      icon={blankicon}
                                      checkedIcon={checkedIcon}
                                      style={{ marginRight: 1 }}
                                      checked={selected}
                                    />
                                    {option.label}
                                  </li>
                                );
                              }}
                              //            style={{ width: isXSmall ? 350 : 500 }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label={"Доступ"} //translate('resources.product.fields.vid')} //"Вид"
                                  variant="outlined"
                                  style={{
                                    marginBottom: 15,
                                    marginLeft: 15,
                                    marginRight: 15,
                                    width: 280,
                                    //    display: 'flex',
                                    //    justifyContent: 'space-between',
                                  }}
                                  //          variant="standard"
                                  //          placeholder="Выберите Мастера"
                                />
                              )}
                            />

                            <Autocomplete
                              //   multiple
                              id="size-small-outlined" //      id="checkboxes-tags"
                              options={unittypes}
                              onChange={changeUnittype}
                              size="small"
                              value={unittype2} //customerproducttypes]}
                              limitTags={4}
                              //              filterSelectedOptions
                              isOptionEqualToValue={(props, option) => {
                                let rez = false;
                                if (props.value === option.value) rez = true;
                                return rez;
                              }}
                              selectOnFocus
                              clearOnBlur
                              onFocus={(e) => {
                                //            console.log(e.target.readOnly)
                                return (e.target.readOnly = true);
                              }} //выключение клавиатуры при выборе      handleHomeEndKeys
                              //      disableCloseOnSelect
                              getOptionLabel={(option) => option.label}
                              renderOption={(props, option, { selected }) => {
                                return (
                                  <li {...props}>
                                    <Checkbox
                                      icon={blankicon}
                                      checkedIcon={checkedIcon}
                                      //    style={{ marginRight: 8 }}
                                      checked={selected}
                                    />
                                    {option.label}
                                  </li>
                                );
                              }}
                              //            style={{ width: isXSmall ? 350 : 500 }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label={"Тип"} //translate('resources.product.fields.vid')} //"Вид"
                                  variant="outlined"
                                  style={{
                                    marginBottom: 15,
                                    width: 280,
                                    //  display: 'flex',
                                    //  justifyContent: 'space-between',
                                  }}
                                  //          variant="standard"
                                  //          placeholder="Выберите Мастера"
                                />
                              )}
                            />
                          </div>

                          <Styles>
                            <Table2
                              columns={columns2}
                              data={data2}
                              getHeaderProps={
                                (nameTheme &&
                                  nameTheme !== "dark" &&
                                  ((row) => {
                                    return {
                                      style: {
                                        //                   background:
                                        //                   row.index % 2 === 0 ? 'rgba(0,0,0,.1)' : 'white',
                                      },
                                    };
                                  })) ||
                                ((row) => {
                                  return {
                                    style: {
                                      color: "#ffffffb3",
                                      //                    background:
                                      //                    row.index % 2 === 0 ? 'rgba(0,0,0,.1)' : '#ffffffb3', //'#616161e6', //'#ffffffb3',
                                    },
                                  };
                                })
                              }
                              /*          
getHeaderProps={(column) => ({
  onClick: () => alert('Header!'),
})}
getColumnProps={(column) => ({
  onClick: () => alert('Column!'),
})}
*/
                              getRowProps={
                                (nameTheme &&
                                  nameTheme !== "dark" &&
                                  ((row) => {
                                    return {
                                      style: {
                                        background:
                                          row.index % 2 === 0
                                            ? "rgba(0,0,0,.1)"
                                            : "white",
                                      },
                                    };
                                  })) ||
                                ((row) => {
                                  return {
                                    style: {
                                      background:
                                        row.index % 2 === 0
                                          ? "rgba(0,0,0,.1)"
                                          : "#616161e6", //'#ffffffb3',
                                    },
                                  };
                                })
                              }
                              getCellProps={(cellInfo) => ({
                                //            onClick: (req, res) => onClickCell(cellInfo, req, res),
                                //                style: {
                                //                  backgroundColor: `hsl(${
                                //                    120 * ((120 - cellInfo.value) / 120) * -1 + 120
                                //                  }, 100%, 67%)`,
                                //                },
                              })}
                            />
                          </Styles>
                        </>
                      )}

                      {filterValues && filterValues.status === "works" && (
                        <>
                          <Typography
                            id="outlined-basic"
                            label="l"
                            //variant="standard"
                            className="title h6"
                            style={{
                              marginTop: 25,
                              marginBottom: 15,
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            {translate("resources.supervisor.name")}
                          </Typography>
                          <div
                            //            className="hstack gap-2"
                            style={{
                              marginTop: 0,
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <Button
                              size="small"
                              //component={Link}
                              color="primary"
                              variant="outlined"
                              sx={{
                                marginBottom: 1,
                                display: "inline-flex",
                                alignItems: "center",
                              }}
                              //variant={'outline-success'}
                              //              variant="outline-primary"
                              //as={Link}
                              //to={{pathname: '/auth/auth/item-worker',}}
                              /*state={{
                          mode: 'addworker',
                          parent: auth_item,
                        }}*/
                              disabled={getAccessToChange()}
                              onClick={async () => {
                                try {
                                  await ws_client.wss_getItem({
                                    tableName: "Users",
                                    where: auth_item && auth_item.id,
                                    action: {
                                      metod: "getusersupervisor",
                                      mode: "add",
                                      parent: auth_item,
                                    },
                                    callback: getSupervisorItem,
                                  });
                                } catch (e) {
                                  console.log("errr e", e);
                                }
                              }}
                            >
                              {translate("resources.supervisor.addsupervisor")}
                            </Button>
                            <FormControlLabel
                              style={{
                                marginRight: isXSmall ? 10 : 10,
                                marginLeft: isXSmall ? 160 : 160,
                              }}
                              control={
                                <Switch
                                  id={"allsupervisors"}
                                  name={"allsupervisors"}
                                  color="primary"
                                  //                          onFocus={onFocus}
                                  checked={
                                    (auth_item && auth_item.allsupervisors) ||
                                    false
                                  }
                                  onChange={(item, value) => {
                                    if (!getAccessToChange()) {
                                      auth_item.change = true;
                                      dispatch({
                                        type: "app/set",
                                        payload: {
                                          id: auth_item,
                                          field: "allsupervisors",
                                          value: value,
                                        },
                                        ob: "auth_item",
                                      });
                                    }
                                  }}
                                  disabled={getAccessToChange()}
                                />
                              }
                              label={translate(
                                "resources.switch.allsupervisors"
                              )}
                            />
                          </div>
                          <br />
                          <Styles>
                            <Table4
                              columns={columns4}
                              data={data4}
                              getHeaderProps={
                                (nameTheme &&
                                  nameTheme !== "dark" &&
                                  ((row) => {
                                    return {
                                      style: {
                                        //                   background:
                                        //                   row.index % 2 === 0 ? 'rgba(0,0,0,.1)' : 'white',
                                      },
                                    };
                                  })) ||
                                ((row) => {
                                  return {
                                    style: {
                                      color: "#ffffffb3",
                                      //                    background:
                                      //                    row.index % 2 === 0 ? 'rgba(0,0,0,.1)' : '#ffffffb3', //'#616161e6', //'#ffffffb3',
                                    },
                                  };
                                })
                              }
                              /*          
        getHeaderProps={(column) => ({
          onClick: () => alert('Header!'),
        })}
        getColumnProps={(column) => ({
          onClick: () => alert('Column!'),
        })}
        */
                              getRowProps={
                                (nameTheme &&
                                  nameTheme !== "dark" &&
                                  ((row) => {
                                    return {
                                      style: {
                                        background:
                                          row.index % 2 === 0
                                            ? "rgba(0,0,0,.1)"
                                            : "white",
                                      },
                                    };
                                  })) ||
                                ((row) => {
                                  return {
                                    style: {
                                      background:
                                        row.index % 2 === 0
                                          ? "rgba(0,0,0,.1)"
                                          : "#616161e6", //'#ffffffb3',
                                    },
                                  };
                                })
                              }
                              getCellProps={(cellInfo) => ({
                                //            onClick: (req, res) => onClickCell(cellInfo, req, res),
                                //                style: {
                                //                  backgroundColor: `hsl(${
                                //                    120 * ((120 - cellInfo.value) / 120) * -1 + 120
                                //                  }, 100%, 67%)`,
                                //                },
                              })}
                            />
                          </Styles>
                          <br />
                        </>
                      )}

                      <br />
                      <br />
                    </>
                  </CardContent>
                </Card>
              </Box>
            </>
          )}

          {subPages.page === "roles" && (
            <>
              <Box maxWidth="45em">
                <Card>
                  <CardContent>
                    <>
                      <Form.Group className="mb-3" controlId="formName">
                        <Form.Label>Роль пользователя : {UserInfo}</Form.Label>
                      </Form.Group>
                      <Divider style={{ marginTop: -15 }} />
                      <Form.Group
                        className="mb-3"
                        controlId="formName"
                        style={{ marginTop: 15 }}
                      >
                        <SelectRole
                          name="role"
                          field={role_item && role_item.item}
                          title="Роль"
                          placeholder="Виберите Роль"
                          value="role_item"
                          onChanged={() => {
                            role_item.change = true;
                            dispatch({
                              type: "app/set", //'app/setconfig',
                              payload: {
                                id: role_item,
                                field: "change",
                                value: true,
                              },
                              ob: "role_item",
                            });
                          }}
                        />
                      </Form.Group>
                      <Form>
                        <div className="hstack gap-3">
                          <Button
                            variant={getChange("role")}
                            onClick={async () => {
                              auth_item.change = true;
                              try {
                                await ws_client.wss_getItem({
                                  tableName: "Users",
                                  where: { id: auth_item.id },
                                  action: {
                                    metod: "getitem",
                                    mode: "addrole",
                                    data: role_item,
                                    parent: auth_item,
                                  },
                                  callback: setRoleItem,
                                });
                              } catch (e) {
                                console.log("errr e", e);
                              }
                              //setSubPages({ page: 'main' })
                            }}
                          >
                            Сохранить
                          </Button>
                          <Button
                            variant="outlined" //"outline-primary"
                            style={{ marginLeft: 0 }}
                            onClick={async () => {
                              setSubPages({ page: "main" });
                            }}
                          >
                            Закрыть
                          </Button>
                          <Button
                            variant="outlined" //"outline-danger"
                            style={{ marginLeft: 0 }}
                            onClick={async () => {
                              try {
                                await ws_client.wss_getItem({
                                  tableName: "Users",
                                  where: { id: auth_item.id },
                                  action: {
                                    metod: "getitem",
                                    mode: "delrole",
                                    data: role_item,
                                    parent: auth_item,
                                  },
                                  callback: setRoleItem,
                                });
                              } catch (e) {
                                console.log("errr e", e);
                              }
                            }}
                          >
                            Удалить
                          </Button>
                        </div>
                      </Form>
                    </>
                  </CardContent>
                </Card>
              </Box>
            </>
          )}

          {subPages.page === "supervisors" && (
            <>
              <Box maxWidth="40em">
                <Card>
                  <CardContent>
                    <>
                      <div
                        //            className="hstack gap-2"
                        style={{
                          marginTop: -20,
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography
                          id="outlined-basic"
                          label="S"
                          //variant="standard"
                          className="title h6"
                          style={{
                            marginTop: 15,
                            marginBottom: 15,
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          {translate("resources.worker.selectworker")}
                        </Typography>
                      </div>

                      {supervisor_item && supervisor_item.item && (
                        <>
                          <SelectSupervisors
                            name="supervisor"
                            field={supervisor_item.item}
                            title={translate("resources.supervisor.name")} //"Работник"
                            placeholder={translate("resources.supervisor.name")} //"Работник"
                            value="supervisor_item"
                            onChanged={(supervisor) => {
                              supervisor_item.change = true;
                              dispatch({
                                type: "app/set",
                                payload: {
                                  id: supervisor_item,
                                  field: "supervisorid",
                                  value: supervisor && supervisor.id,
                                },
                                ob: "supervisor_item",
                              });
                            }}
                          />
                          <TextField
                            id="outlined-basic"
                            label={translate("resources.worker.priority")} //"Очередность"
                            //variant="standard"
                            variant="outlined"
                            style={{
                              marginBottom: 15,
                              marginTop: 15,
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                            value={supervisor_item.item.sort || ""}
                            onChange={(item) => {
                              dispatch({
                                type: "app/set",
                                payload: {
                                  id: supervisor_item.item,
                                  field: "sort",
                                  value: item.target.value,
                                },
                                ob: "supervisor_item",
                              });
                            }}
                          />
                          <Form>
                            <Button
                              variant={getChange()}
                              //as={Link}
                              //to={{pathname: '/auth/auth/item',}}
                              /*state={{
                            id:
                              supervisor_item &&
                              supervisor_item.parent &&
                              supervisor_item.parent.id,
                            action: {
                              mode: 'addworker',
                              data: supervisor_item,
                            },
                          }}*/
                              onClick={async () => {
                                supervisor_item.change = true;
                                try {
                                  await ws_client.wss_getItem({
                                    tableName: "Users",
                                    where: { id: auth_item.id },
                                    action: {
                                      metod: "getOne",
                                      mode: "addsupervisor",
                                      data: supervisor_item,
                                      parent: auth_item,
                                    },
                                    callback: setSupervisorItem,
                                  });
                                } catch (e) {
                                  console.log("errr e", e);
                                }
                                //setSubPages({ page: 'main' })
                              }}
                            >
                              {translate("resources.button.save")}
                            </Button>
                            <Button
                              variant="outlined" //"outline-primary"
                              //as={Link}
                              //to={{pathname: '/auth/auth/item',}}
                              //state={{id:auth_item.id,}}
                              onClick={async () => {
                                setSubPages({ page: "main" });
                              }}
                            >
                              {translate("resources.button.cancel")}
                            </Button>
                            <Button
                              variant="outlined" //"outline-danger"
                              /*as={Link}
                          to={{pathname: '/auth/auth/item',}}
                          state={{
                            id:auth_item.id,
                            action: { mode: 'delworker', data: supervisor_item },
                          }}*/
                              onClick={async () => {
                                supervisor_item.change = true;
                                try {
                                  await ws_client.wss_getItem({
                                    tableName: "Users",
                                    where: { id: auth_item.id },
                                    action: {
                                      metod: "getOne",
                                      mode: "delsupervisor",
                                      data: supervisor_item,
                                      parent: auth_item,
                                    },
                                    callback: setSupervisorItem,
                                  });
                                } catch (e) {
                                  console.log("errr e", e);
                                }
                              }}
                            >
                              {translate("resources.button.del")}
                            </Button>
                          </Form>
                        </>
                      )}
                    </>
                  </CardContent>
                </Card>
              </Box>
            </>
          )}

          {subPages.page === "workers" && (
            <>
              <Box maxWidth="40em">
                <Card>
                  <CardContent>
                    <>
                      <div
                        //            className="hstack gap-2"
                        style={{
                          marginTop: -20,
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography
                          id="outlined-basic"
                          label="S"
                          //variant="standard"
                          className="title h6"
                          style={{
                            marginTop: 15,
                            marginBottom: 15,
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          {translate("resources.worker.selectworker")}
                        </Typography>
                      </div>

                      {worker_item && worker_item.item && (
                        <>
                          <SelectUsers
                            name="user"
                            field={worker_item.item}
                            title={translate("resources.worker.name")} //"Работник"
                            placeholder={translate("resources.worker.name")} //"Работник"
                            value="worker_item"
                            onChanged={(user) => {
                              worker_item.change = true;
                              dispatch({
                                type: "app/set",
                                payload: {
                                  id: worker_item,
                                  field: "workerid",
                                  value: user && user.id,
                                },
                                ob: "worker_item",
                              });
                            }}
                          />
                          <TextField
                            id="outlined-basic"
                            label={translate("resources.worker.priority")} //"Очередность"
                            //variant="standard"
                            variant="outlined"
                            style={{
                              marginBottom: 15,
                              marginTop: 15,
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                            value={worker_item.item.sort || ""}
                            onChange={(item) => {
                              dispatch({
                                type: "app/set",
                                payload: {
                                  id: worker_item.item,
                                  field: "sort",
                                  value: item.target.value,
                                },
                                ob: "worker_item",
                              });
                            }}
                          />
                          <Form>
                            <Button
                              variant={getChange()}
                              //as={Link}
                              //to={{pathname: '/auth/auth/item',}}
                              /*state={{
                            id:
                              worker_item &&
                              worker_item.parent &&
                              worker_item.parent.id,
                            action: {
                              mode: 'addworker',
                              data: worker_item,
                            },
                          }}*/
                              onClick={async () => {
                                worker_item.change = true;
                                try {
                                  await ws_client.wss_getItem({
                                    tableName: "Users",
                                    where: { id: auth_item.id },
                                    action: {
                                      metod: "getOne",
                                      mode: "addworker",
                                      data: worker_item,
                                      parent: auth_item,
                                    },
                                    callback: setWorkerItem,
                                  });
                                } catch (e) {
                                  console.log("errr e", e);
                                }
                                //setSubPages({ page: 'main' })
                              }}
                            >
                              {translate("resources.button.save")}
                            </Button>
                            <Button
                              variant="outlined" //"outline-primary"
                              //as={Link}
                              //to={{pathname: '/auth/auth/item',}}
                              //state={{id:auth_item.id,}}
                              onClick={async () => {
                                setSubPages({ page: "main" });
                              }}
                            >
                              {translate("resources.button.cancel")}
                            </Button>
                            <Button
                              variant="outlined" //"outline-danger"
                              /*as={Link}
                          to={{pathname: '/auth/auth/item',}}
                          state={{
                            id:auth_item.id,
                            action: { mode: 'delworker', data: worker_item },
                          }}*/
                              onClick={async () => {
                                worker_item.change = true;
                                try {
                                  await ws_client.wss_getItem({
                                    tableName: "Users",
                                    where: { id: auth_item.id },
                                    action: {
                                      metod: "getOne",
                                      mode: "delworker",
                                      data: worker_item,
                                      parent: auth_item,
                                    },
                                    callback: setCloudItem,
                                  });
                                } catch (e) {
                                  console.log("errr e", e);
                                }
                              }}
                            >
                              {translate("resources.button.del")}
                            </Button>
                          </Form>
                        </>
                      )}
                    </>
                  </CardContent>
                </Card>
              </Box>
            </>
          )}

          {subPages.page === "config" && (
            <>
              <Box maxWidth={maxwidth}>
                <Card>
                  <CardContent>
                    <>
                      {config_item && (
                        <>
                          <Form.Group className="mb-3" controlId="formName">
                            <Form.Label>
                              {translate("pos.configuser")} {UserInfo}
                            </Form.Label>
                          </Form.Group>

                          <Divider />
                          <RadioGroup
                            row
                            //              aria-labelledby="demo-row-radio-buttons-group-label"
                            //            name="row-radio-buttons-group"
                            value={config_item && config_item.theme} //value}
                            onChange={RhandleChange}
                          >
                            <FormControlLabel
                              value="default"
                              control={<Radio />}
                              label="Default"
                            />
                            <FormControlLabel
                              value="light"
                              control={<Radio />}
                              label="Light"
                            />
                            <FormControlLabel
                              value="dark"
                              control={<Radio />}
                              label="Dark"
                            />
                          </RadioGroup>
                          <Divider />
                          <Form.Group className="mb-3" controlId="formName">
                            <Form.Label>{translate("pos.language")}</Form.Label>

                            <Divider />
                            <RadioGroup
                              row
                              //              aria-labelledby="demo-row-radio-buttons-group-label"
                              //            name="row-radio-buttons-group"
                              value={config_item && config_item.language} //value}
                              onChange={RhandleChangelanguage}
                            >
                              <FormControlLabel
                                value="ua"
                                control={<Radio />}
                                label="ua"
                              />
                              <FormControlLabel
                                value="ru"
                                control={<Radio />}
                                label="ru"
                              />
                              <FormControlLabel
                                value="en"
                                control={<Radio />}
                                label="en"
                              />
                            </RadioGroup>
                            <Divider />
                          </Form.Group>

                          <Form>
                            <>
                              <div className="hstack gap-2">
                                <Button
                                  variant={getChange()} //"outline-primary"
                                  //as={Link}
                                  to={{ pathname: "/auth/config/list" }}
                                  //state={{config_item: config_item,}}
                                  //disabled={getAccessToChange()}
                                  onClick={async () => {
                                    try {
                                      auth.setdispatchAccess(false);
                                      await ws_client.wss_setItem({
                                        tableName: "UserConfigs",
                                        item: config_item,
                                        call_nm: config_item.id
                                          ? "Model_update"
                                          : "Model_create",
                                        where: config_item.id
                                          ? {
                                              id: config_item && config_item.id,
                                            }
                                          : { userid: auth_item.id },
                                        action: null,
                                      });
                                      //setLocale(config_item.config.language)
                                    } catch (e) {
                                      console.log("errr e", e);
                                    }
                                    setSubPages({ page: "main" });
                                  }}
                                >
                                  {translate("resources.button.save")}
                                </Button>{" "}
                                <Button
                                  //as={Link}
                                  variant="outlined" //"outline-primary"
                                  //to="/auth/auth/item"
                                  //state={{id: config_item.id,}}
                                  onClick={async () => {
                                    setSubPages({ page: "main" });
                                  }}
                                >
                                  {translate("resources.button.return")}
                                </Button>
                              </div>
                              <br />
                            </>
                          </Form>
                          <br />
                        </>
                      )}
                    </>
                  </CardContent>
                </Card>
              </Box>
            </>
          )}

          {subPages.page === "setpsw" && (
            <>
              <Box maxWidth="30em">
                <Card>
                  <CardContent>
                    <>
                      <div
                        //            className="hstack gap-2"
                        style={{
                          marginTop: -20,
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography
                          id="outlined-basic"
                          label="psw"
                          //variant="standard"
                          className="title h6"
                          style={{
                            marginTop: 15,
                            marginBottom: 15,
                            //display: 'flex',
                            //justifyContent: 'space-between',
                          }}
                        >
                          {translate("resources.passwordpage.password")}
                        </Typography>
                      </div>
                      <TextField
                        readOnly
                        id="outlined-basic"
                        label="login"
                        //variant="standard"
                        variant="outlined"
                        style={{
                          marginBottom: 15,
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                        //defaultValue={(auth_item && auth_item.login) || ''}
                        value={(auth_item && auth_item.login) || ""}
                        onChange={(item) => {
                          setShowAlert(false);
                          dispatch({
                            type: "app/set",
                            payload: {
                              id: auth_item,
                              field: "login",
                              value: item.target.value,
                            },
                            ob: "auth_item",
                          });
                        }}
                      />
                      {!isadmin &&
                        !getAccessCode(
                          "CHANGE_PSW",
                          access && access.access
                        ) && (
                          <TextField
                            id="outlined-basic"
                            label={translate(
                              "resources.passwordpage.oldpassword"
                            )} //"Введите старый password"
                            //variant="standard"
                            variant="outlined"
                            style={{
                              marginBottom: 15,
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                            onChange={(item) => {
                              setShowAlert(false);
                              dispatch({
                                type: "app/set",
                                payload: {
                                  id: auth_item,
                                  field: "oldpassword",
                                  value: item.target.value,
                                },
                                ob: "auth_item",
                              });
                            }}
                          />
                        )}
                      <TextField
                        id="outlined-basic"
                        label={translate("resources.passwordpage.newpassword")} //"Введите новый password"
                        //variant="standard"
                        variant="outlined"
                        style={{
                          marginBottom: 15,
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                        placeholder={translate(
                          "resources.passwordpage.newpassword"
                        )} //"Введите новый password"
                        onChange={(item) => {
                          setShowAlert(false);
                          dispatch({
                            type: "app/set",
                            payload: {
                              id: auth_item,
                              field: "newpassword",
                              value: item.target.value,
                            },
                            ob: "auth_item",
                          });
                        }}
                      />
                      <Form>
                        <Button
                          //            changeHandler
                          variant={getChange()} //"primary"
                          //as={Link}
                          //to={{pathname: '/auth/auth/changepsw',}}
                          //state={auth_item}
                          onClick={async () => {
                            try {
                              await ws_client.wss_setItem({
                                tableName: "Users",
                                item: auth_item,
                                call_nm: "Model_update",
                                where: { id: auth_item.id },
                                action: {
                                  metod: "changepassword",
                                  deblock: false,
                                },
                                callback: handlePswSave,
                              });
                            } catch (e) {
                              console.log("errr e", e);
                            }
                          }}
                        >
                          {translate("resources.passwordpage.changepassword")}
                        </Button>{" "}
                        <Button
                          variant="outlined" //"outline-primary"
                          //as={Link}
                          //to={{pathname: '/auth/auth/profile',}}
                          //state={auth_item}
                          onClick={() => {
                            setSubPages({ page: "main" });
                          }}
                        >
                          {translate("resources.button.cancel")}
                        </Button>{" "}
                        <Button
                          //size="small"
                          //component={Link}
                          color="primary"
                          variant="outlined"
                          //sx={{ display: 'inline-flex', alignItems: 'center' }}
                          //variant={'outline-success'}
                          //as={Link} variant="outline-primary"
                          //to="/auth/auth/list"
                          onClick={() => {
                            setSubPages({ page: "main" });
                          }}
                        >
                          {translate("resources.button.return")}
                        </Button>
                      </Form>
                    </>
                    <br />
                    {showAlert && (
                      <Stack sx={{ width: "100%" }} spacing={2}>
                        <Alert
                          severity={AlertType}
                          onClose={() => {
                            setShowAlert(false);
                            setAlertMessage("");
                          }}
                        >
                          <strong>{AlertMessage}</strong>
                        </Alert>
                      </Stack>
                    )}
                  </CardContent>
                </Card>
              </Box>
            </>
          )}
        </>
      )}
      {!ready() && (
        <>
          <Box maxWidth={maxwidth}>
            <Card>
              <CardContent>
                <Spinner />
              </CardContent>
            </Card>
          </Box>
        </>
      )}
    </>
  );
};

export default AuthItem;
