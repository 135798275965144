//import * as React from 'react'
import React, { useContext } from 'react'
import { useEffect } from 'react'
import { ReactNode, useCallback, useMemo, useState } from 'react'
import { styled } from '@mui/material/styles'
import PropTypes from 'prop-types'
import {
  Tooltip,
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Button,
  Avatar,
  PopoverOrigin,
  useMediaQuery,
  Theme,
} from '@mui/material'
import AccountCircle from '@mui/icons-material/AccountCircle'

import { useSelector, useDispatch } from 'react-redux'
import { useLocaleState } from '../lib/translate/i18n/useLocaleState'
import { useLocales } from '../lib/translate/i18n/useLocales'
export const LocaleMenu = (props: LocaleMenuProps) => {
  const [locale, setLocale] = useLocaleState()

  const locales = [] //useLocales()
  locales.push({ locale: 'ua', name: 'ua' })
  locales.push({ locale: 'ru', name: 'ru' })
  locales.push({ locale: 'en', name: 'en' })
  locales.push({ locale: 'de', name: 'de' })
  const access = useSelector((state: any) => state.app.access.access)
  const dispatch = useDispatch()

  const [anchorEl, setAnchorEl] = useState(null)
  //const translate = useTranslate()
  const {
    children = null, //!!authProvider ? <Logout /> : null,<LogoutMenu />
    className,
    label = 'user_menu',
    icon = defaultIcon,
  } = props

  const handleMenu = (event: any) => setAnchorEl(event.currentTarget)
  const handleClose = useCallback(() => setAnchorEl(null), [])
  const handleClick = useCallback(() => setAnchorEl(null), [])

  const context = useMemo(() => ({ onClose: handleClose }), [handleClose])
  if (!children) return null
  const open = Boolean(anchorEl)

  //original
  return (
    <Root className={className}>
      <Tooltip title={'Language change'}>
        <Button
          aria-label={label}
          className={LocaleMenuClasses.userButton}
          color="inherit"
          onClick={handleMenu}
        >
          {locale}
        </Button>
      </Tooltip>
      <Menu
        id="menu-appbar"
        disableScrollLock
        anchorEl={anchorEl}
        anchorOrigin={AnchorOrigin}
        transformOrigin={TransformOrigin}
        open={open}
        onClose={handleClose}
        onClick={handleClick}
      >
        {locales.map((locale) => (
          <MenuItem
            //    component={Link}
            // @ts-ignore
            //    ref={ref}
            //    {...props}
            //  to="/auth/auth/profile"
            // state={{ id: auth.userId }}
            key={locale.locale}
            onClick={(e: any) => {
              console.log(e.target.textContent)
              dispatch({
                type: 'app/language',
                payload: {
                  value: e.target.textContent,
                },
              })
              setLocale(e.target.textContent)
            }}
            //        <ListItemIcon></ListItemIcon>

            //        <option key={locale.locale} value={locale.locale}>
          >
            <ListItemText>{locale.name}</ListItemText>
          </MenuItem>
        ))}
      </Menu>
    </Root>
  )
}

LocaleMenu.propTypes = {
  children: PropTypes.node,
  classes: PropTypes.object,
  label: PropTypes.string,
  icon: PropTypes.node,
}

export interface LocaleMenuProps {
  children?: ReactNode
  className?: string
  label?: string
  icon?: ReactNode
}

const PREFIX = 'LocaleMenu'

export const LocaleMenuClasses = {
  userButton: `${PREFIX}-userButton`,
  avatar: `${PREFIX}-avatar`,
}

const Root = styled('div', {
  name: PREFIX,
  overridesResolver: (props, styles) => styles.root,
})(({ theme }) => ({
  [`& .${LocaleMenuClasses.userButton}`]: {
    textTransform: 'none',
  },

  [`& .${LocaleMenuClasses.avatar}`]: {
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
}))

const defaultIcon = <AccountCircle />

const AnchorOrigin: PopoverOrigin = {
  vertical: 'bottom',
  horizontal: 'right',
}

const TransformOrigin: PopoverOrigin = {
  vertical: 'top',
  horizontal: 'right',
}
