import { any } from 'prop-types'
import { createContext } from 'react'

function noop() {}
function f1(arg1) {}
function f2(arg1, arg2) {}

export const AuthContext = createContext({
  token: null,
  userId: null,
  login: f2,
  logout: noop,
  isAuthenticated: false,
  WorkDate: null, //new Date(),
  setdt: noop,
  StartDate: null, //new Date(),
  setStartDate: noop,
  EndDate: null, //new Date(),
  setEndDate: noop,
  Worker: null,
  setWorker: noop,
  Worker2: null,
  setWorker2: noop,
  Customer: null,
  setCustomer: noop,
  homeType: null,
  sethomeType: noop,
  dispatchAccess: false, //null,
  setdispatchAccess: f1,
  config: null,
  setConfig: f1,
  ws_client: null,
  isRead: false,
  setIsRead: f1,
})
