import React, { useContext, useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
//import { Form } from 'react-bootstrap'
import Button from '@mui/material/Button'
import { useSelector, useDispatch } from 'react-redux'
import { useTable } from 'react-table'
import styled from 'styled-components'
import { useMediaQuery } from '@mui/material'
import { getAccessCode } from '../../../access'
import Checkbox from '@mui/material/Checkbox'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBoxIcon from '@mui/icons-material/CheckBox'

import { Card, CardContent, Box } from '@mui/material'
import { useTranslate } from '../../../lib/translate/i18n/useTranslate'
import { AuthContext } from '../../../context/AuthContext'
import { getURLAPI } from '../../../utils/getURL'

import { default as Spinner } from '../../../components/Spinner'

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />
const checkedIcon = <CheckBoxIcon fontSize="small" />

const Styles = styled.div`
  table {
    border-spacing: 0;
    border: 1px solid #c3c3c3;
    border-radius: 10px;
    border-collapse: initial;
    tr {
      border-radius: 0px;

      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th,
    td {
      margin: 0;
      padding: 0.5rem;
      border-bottom: 1px solid #c3c3c3;
      border-right: 1px solid #c3c3c3;

      :last-child {
        border-right: 0;
      }
    }
  }
`

const defaultPropGetter = () => ({})

var columns = []
var data = []

const Customers = () => {
  const body = useSelector((state) => state.app.body)
  const app = useSelector((state) => state.app)
  const access = useSelector((state) => state.app.access.access)
  const isadmin = (access && access.isadmin) || false
  const isXSmall = useMediaQuery((theme) => theme.breakpoints.down('sm'))

  const nameTheme = access && access.user && access.user.theme
  const translate = useTranslate()
  const auth = useContext(AuthContext)
  const dispatch = useDispatch()
  const config = auth.config
  let URLAPI = getURLAPI(config)

  //START get all str from table Roles
  //console.log('Refresh!!! customers...')
  const [startRefresh, setStartRefresh] = useState(true)

  //замыкание
  const ws_client = auth.ws_client //body.ws_client

  //  const item_id = body.item_id

  //  const readonly = body.readonly

  const GetData_handle = function (_result) {
    const { tableName, result, readonly, action } = _result
    //console.log('result handle1 ', result

    if (tableName === 'Customers') {
      dispatch({
        type: 'fetch/get_add', //'fetch/get_add',
        payload: result,
        ob: 'customers',
      })
    }
  }

  const Refresh_handle = async function (result, event, parent) {
    const { tableName, action, metod } = result
    /*console.log(
      'Refresh_handle ',
      event,
      new Date().toLocaleTimeString(),
      result
    )*/
    setStartRefresh(true)
  }

  const params = {
    URL_WS: getURLAPI(auth.config, 'URL_WS'), //get address WebSocket BK from config
    auth: { userId: auth.userId, tocken: auth.token },
    handleGetData: GetData_handle,
    //handleChangeData: Refresh_handle,
    handleSetRefresh: setStartRefresh,
    handleErrorRead: null,
    handleErrorChange: null,
    models: [
      {
        model_call_nm: 'Model_read',
        tableName: 'Customers',
        action: {
          metod: 'get',
        },
      },
    ],
  }

  // close page
  useEffect(
    () => () => {
      try {
        dispatch({
          type: 'fetch/get',
          payload: true,
          ob: 'close',
        })
      } catch (err) {
        console.log('deblock error ', err)
      }
    },
    []
  )

  if (ws_client && ws_client.ws_login && startRefresh) {
    setStartRefresh(false)
    ws_client.open_process(params)
  }

  //END get all str from table Roles

  let greybkcolor = 'rgba(255,255,255,.3)'

  if (nameTheme) {
    greybkcolor =
      nameTheme !== 'dark' ? 'rgba(255,255,255,1.0)' : 'rgba(255,255,255,.3)'
  }

  const [filter, setfilter] = useState(null)

  let customers = body.customers

  //let customerid = null

  //  if (customers_item && customers_item.id) customerid = customers_item.id

  if (customers) {
    /*
    console.log('worker2', worker2)
    console.log('customer2', customer2)
    console.log('date1', date1)
    console.log('date2', date2)
    console.log('workerid', workerid)
    console.log('customerid', customerid)
    //console.log(customer2['value']['0'].value)
    //workerid = null
    // customerid = null
    */
    if (filter)
      customers = customers.filter((item) => {
        //        console.log('item', item)
        //        console.log('item.name', item.name)
        return (
          item.name.includes(filter) ||
          (item.lastName && item.lastName.includes(filter)) ||
          (item.tel1 && item.tel1.includes(filter)) ||
          (item.tel2 && item.tel2.includes(filter)) ||
          (item.comments && item.comments.includes(filter)) ||
          false
        )
        //[item.name].contains('filter')
      })
  }

  columns = [
    {
      Header: '№',
      accessor: 'num',
    },
    {
      Header: translate('resources.customers.fields.name'), //'Имя',
      accessor: 'name',
    },
    /*    {
      Header: translate('resources.customers.fields.lastname'), //'Фамилия',
      accessor: 'lastName',
    },*/
    {
      Header: translate('resources.customers.fields.tel1'), //'Телефон ',
      accessor: 'phone',
    },
  ]

  if (!isXSmall) {
    /*  columns.push({
      Header: translate('resources.customers.fields.tel2'), //'Телефон 2',
      accessor: 'tel2',
    })
*/
    columns.push({
      Header: translate('resources.customers.fields.comments'), //'Коментарий',
      accessor: 'comments',
    })
  }

  data = []

  let num = 1
  for (var iw in customers) {
    data.push({
      num: num++,
      name: customers[iw].name,
      phone: customers[iw].phone,
      comments: customers[iw].comments,
      customer: customers[iw],
    })
  }

  function Table({
    columns,
    data,
    getHeaderProps = defaultPropGetter,
    getColumnProps = defaultPropGetter,
    getRowProps = defaultPropGetter,
    getCellProps = defaultPropGetter,
  }) {
    // Use the state and functions returned from useTable to build your UI
    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
      useTable({
        columns,
        data,
      })

    return (
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  // Return an array of prop objects and react-table will merge them appropriately
                  {...column.getHeaderProps([
                    {
                      className: column.className,
                      style: column.style,
                    },
                    getColumnProps(column),

                    getHeaderProps(column),
                  ])}
                >
                  {column.render('Header')}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row)
            return (
              // Merge user row props in
              <tr {...row.getRowProps(getRowProps(row))}>
                {row.cells.map((cell) => {
                  return (
                    <td
                      // Return an array of prop objects and react-table will merge them appropriately
                      {...cell.getCellProps([
                        {
                          className: cell.column.className,
                          style: cell.column.style,
                        },
                        getColumnProps(cell.column),
                        //  getCellProps(cell),
                      ])}
                      align="center"
                      //                    width="140px"
                    >
                      {(cell.column.id !== 'timeline' &&
                        (isadmin ||
                          getAccessCode(
                            'CHANGE_CUSTOMER',
                            access && access.access
                          )) && (
                          <Link
                            size="small"
                            component={Link}
                            color="primary"
                            variant="outlined"
                            sx={{
                              display: 'inline-flex',
                              alignItems: 'center',
                            }}
                            to={{
                              pathname: '/pages/customers/item',
                            }}
                            state={{
                              id: cell.row.original.customer.id, //cell.row.original['worktime_id'],
                            }}
                            onClick={() => {
                              dispatch({
                                type: 'fetch/get', //_add',
                                payload: cell.row.original.customer.id,
                                ob: 'item_id',
                              })
                            }}
                          >
                            {cell.render('Cell')}
                          </Link>
                        )) ||
                        cell.render('Cell')}
                    </td>
                  )
                })}
              </tr>
            )
          })}
        </tbody>
      </table>
    )
  }

  const ready = () => {
    if (body && body.customers) return true
    else return false
  }

  return (
    <>
      {ready() && (
        <>
          <Box maxWidth="40em">
            <Card>
              <CardContent>
                <>
                  {(isadmin ||
                    getAccessCode(
                      'CREATE_CUSTOMER',
                      access && access.access
                    )) && (
                    <Button
                      size="small"
                      component={Link}
                      color="primary"
                      variant="outlined"
                      sx={{ display: 'inline-flex', alignItems: 'center' }}
                      to="/pages/customers/item"
                    >
                      {translate('resources.button.add')}
                    </Button>
                  )}{' '}
                  <Button
                    size="small"
                    component={Link}
                    color="primary"
                    variant="outlined"
                    sx={{ display: 'inline-flex', alignItems: 'center' }}
                    to="/"
                  >
                    {translate('resources.button.return')}
                  </Button>
                  <TextField
                    id="outlined-basic"
                    label={translate('resources.customers.search')} //"Поиск клиента"
                    variant="outlined"
                    defaultValue={filter}
                    style={{
                      marginBottom: 10,
                      display: 'flex',
                      justifyContent: 'space-between',
                    }}
                    onChange={(item) => {
                      setfilter(item.target.value)
                    }}
                  />
                  <Styles>
                    <Table
                      columns={columns}
                      data={data}
                      getHeaderProps={
                        (nameTheme &&
                          nameTheme !== 'dark' &&
                          ((row) => {
                            return {
                              style: {
                                //                   background:
                                //                   row.index % 2 === 0 ? 'rgba(0,0,0,.1)' : 'white',
                              },
                            }
                          })) ||
                        ((row) => {
                          return {
                            style: {
                              color: '#ffffffb3',
                              //                    background:
                              //                    row.index % 2 === 0 ? 'rgba(0,0,0,.1)' : '#ffffffb3', //'#616161e6', //'#ffffffb3',
                            },
                          }
                        })
                      }
                      /*          
          getHeaderProps={(column) => ({
            onClick: () => alert('Header!'),
          })}
          getColumnProps={(column) => ({
            onClick: () => alert('Column!'),
          })}
          */
                      getRowProps={
                        (nameTheme &&
                          nameTheme !== 'dark' &&
                          ((row) => {
                            return {
                              style: {
                                background:
                                  row.index % 2 === 0
                                    ? 'rgba(0,0,0,.1)'
                                    : 'white',
                              },
                            }
                          })) ||
                        ((row) => {
                          return {
                            style: {
                              background:
                                row.index % 2 === 0
                                  ? 'rgba(0,0,0,.1)'
                                  : '#616161e6', //'#ffffffb3',
                            },
                          }
                        })
                      }
                      getCellProps={(cellInfo) => ({
                        //            onClick: (req, res) => onClickCell(cellInfo, req, res),
                        //            style: {
                        //            backgroundColor: `hsl(${
                        //            120 * ((120 - cellInfo.value) / 120) * -1 + 120
                        //        }, 100%, 67%)`,
                        //    },
                      })}
                    />
                  </Styles>
                </>
              </CardContent>
            </Card>
          </Box>
        </>
      )}
      {!ready() && (
        <>
          <Box maxWidth="60em">
            <Card>
              <CardContent>
                <Spinner />
              </CardContent>
            </Card>
          </Box>
        </>
      )}
    </>
  )
}
export default Customers
