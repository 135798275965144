const URL_API =
  process.env.NODE_ENV === 'development'
    ? //? 'http://185.233.39.133:3012'
      //'http://home2020.keenetic.pro:3012'
      'http://localhost:3012'
    : //'http://185.30.200.25:3013'
      //'http://srv.home2020.keenetic.pro'
      //'http://192.168.1.34:3012'
      //'http://bosch-shop.kiev.ua:3012'
      //'http://46.173.140.20:3012'
      // 'http://192.168.1.34:3012'
      //'http://185.30.200.25:3012'
      //'http://localhost:3012'
      //    'http://185.233.39.133:3012'
      //    : 'https://wt.home2020.keenetic,pro'
      //    ? 'http://localhost:3012'
      'http://home2020.keenetic.pro:3012'

const IMG_API =
  process.env.NODE_ENV === 'development'
    ? 'http://185.233.39.133:3016'
    : // 'http://185.30.200.25:5000'
      //'http://192.168.1.34:5000'
      //'http://185.30.200.25:5000'
      // 'http://46.173.140.20:5000'
      //'http://185.30.200.25:5000'
      //'http://localhost:5000'
      'http://185.233.39.133:3016'
//    : 'https://wt.home2020.keenetic,pro'
//    ? 'http://localhost:5000'
//? 'http://185.30.200.25:5000'

const URL_WS =
  process.env.NODE_ENV === 'development'
    ? 'wss://localhost:8043'
    : 'wss://shop.pegas-sk.com.ua:8043'

const FILE_API =
  process.env.NODE_ENV === 'development'
    ? // 'http://10.10.10.14:5000'
      'http://185.233.39.133:3015'
    : 'http://185.233.39.133:3015'
// 'http://10.10.10.14:5000'

export { URL_API, IMG_API, FILE_API, URL_WS }
