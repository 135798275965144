import * as React from "react";
import { Link } from "react-router-dom";
import { useContext, useEffect, useState, FC } from "react";
import { useSelector, useDispatch } from "react-redux";
import { styled } from "@mui/material/styles";
import { Children, memo } from "react";
import PropTypes from "prop-types";
import {
  AppBar as MuiAppBar,
  AppBarProps as MuiAppBarProps,
  Box,
  Link as MuiLink,
  Toolbar,
  Typography,
  useMediaQuery,
  Theme,
} from "@mui/material";
import { Button, Menu, MenuItem } from "@mui/material";
import WifiIcon from "@mui/icons-material/Wifi";
import Brightness1Icon from "@mui/icons-material/Brightness1";
import ComponentPropType from "./ComponentPropType";
import { AuthContext } from "../context/AuthContext";

import Tooltip from "@mui/material/Tooltip";

import { SidebarToggleButton } from "./SidebarToggleButton";
import { LoadingIndicator } from "./LoadingIndicator";
import { LocaleIndicator } from "./LocaleIndicator";
import { UserMenu } from "./UserMenu";
import { LocaleMenu } from "./LocaleMenu";
import { HideOnScroll } from "./HideOnScroll";
import ConfigMenu from "./ConfigMenu";
import ProfileMenu from "./ProfileMenu";
import LogoutMenu from "./LogoutMenu";
import { getAccessCode } from "../access";
import Logo, { Logo2, Logo3, Logo4, Logo5, Logo6, Logo7 } from "./Logo";
import { useTranslate } from "../lib/translate/i18n/useTranslate";

export const AppBar: FC<AppBarProps> = memo((props) => {
  let {
    children,
    className,
    color = "primary",
    open,
    title,
    userMenu = DefaultUserMenu,
    container: Container = HideOnScroll,
    ...rest
  } = props;

  const dispatch = useDispatch();
  const translate = useTranslate();

  const auth = useContext(AuthContext);
  let ws_client: any = auth.ws_client || null;

  const handleStatus = (status: string) => {
    //    let newstatus
    //    if (ws_client.saveStatus !== status) {
    //console.log('CHANGE STATUS ', status)
    //      newstatus = status

    dispatch({
      type: "fetch/get_add",
      payload: status,
      ob: "connectStatus",
    });

    //    }
  };

  if (ws_client && !ws_client.saveStatus) {
    ws_client.saveStatus = handleStatus;
  }
  const isXSmall = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down("sm")
  );
  const access = useSelector((state: any) => state.app.access.access);
  const isadmin = (access && access.isadmin) || false;

  const [countmsg, setcountmsg] = useState<null | number>(null);

  const [Title, setTitle] = useState("");
  // setTitle('')
  let UserName = access && access.UserName;
  const isLargeEnough = true;
  /*= useMediaQuery<Theme>(
    (theme) => theme.breakpoints.up('sm')
  )
  */
  const getColorStatus = () => {
    let colorstatus = "#cccccc"; //'red'
    let currentstatus = ws_client && ws_client.connectStatus;
    if (ws_client) {
      if (currentstatus === "open") colorstatus = "#0dcaf0";
      if (currentstatus === "login") colorstatus = "lime";
      if (currentstatus === "process") colorstatus = "lime";
      if (currentstatus === "setItem") colorstatus = "lime";
      if (currentstatus === "getItem") colorstatus = "lime";
      if (currentstatus === "error") colorstatus = "red";
      if (currentstatus === "reconnect") colorstatus = "yellow";
    }
    return colorstatus;
  };

  return (
    <Container className={className}>
      <StyledAppBar className={AppBarClasses.appBar} color={color} {...rest}>
        <Toolbar
          disableGutters
          variant={isXSmall ? "regular" : "dense"}
          className={AppBarClasses.toolbar}
        >
          <SidebarToggleButton className={AppBarClasses.menuButton} />
          {Children.count(children) === 0 ? (
            <Typography
              variant="h6"
              color="inherit"
              className={AppBarClasses.title}
              id="title"

              /*          <Typography
            variant="h6"
            color="inherit"
            sx={{
              flex: 1,
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
            }}
            id="react-admin-title"
          />
          {isLargeEnough && <Logo />}
          {isLargeEnough && <Box component="span" sx={{ flex: 1 }} />}

          {isXSmall ? <div style={{ marginLeft: 10 }}>{UserName}</div> : <></>}

          */
            >
              {title}
            </Typography>
          ) : (
            children
          )}
          <LoadingIndicator />
          <LocaleMenu>ru</LocaleMenu>

          <UserMenu>
            {(isadmin ||
              getAccessCode("MENU_CONFIG", access && access.access)) && (
              <ConfigMenu
                to=""
                onClick={() => setTitle(" / Конфиг")}
              ></ConfigMenu>
            )}{" "}
            {(isadmin ||
              getAccessCode("MENU_PROFILE", access && access.access)) && (
              <ProfileMenu
                to=""
                onClick={() => setTitle(" / Профайл")}
              ></ProfileMenu>
            )}{" "}
            <LogoutMenu />
          </UserMenu>
          {typeof userMenu === "boolean" ? (
            userMenu === true ? (
              <UserMenu />
            ) : null
          ) : (
            userMenu
          )}

          <Tooltip
            title={translate("resources.tooltip.serveraccess")} //"Доступ к серверу"
            placement="bottom" //"left-end" //top-start"
          >
            <Brightness1Icon
              fontSize="small"
              style={{ marginRight: -15, color: getColorStatus() }}
              onClick={(e) => {
                let c = (countmsg || 0) + 1;
                setcountmsg(c);
              }}
            />
          </Tooltip>
          {!countmsg && (
            <span
              id="imsg_id"
              style={{
                position: "relative",
                top: "-10px",
                left: "10px",
                marginLeft: "6px",
                //minWidth: '12px',
                padding: "0 4px",
              }}
            ></span>
          )}
          {countmsg && (
            <MuiLink
              component={Link}
              className="link-chat"
              to="/auth/chat/list"
              //href="/auth/chat/list"
              underline="none"
              //underline="hover"
              style={{
                backgroundColor: "#ff142b",
                position: "relative",
                top: "-10px",
                left: "10px",
                marginLeft: "2px",
                fontWeight: "bold",
                fontSize: "10px",
                //padding: '0 2px',
                color: "#fcfcfe", //'#3f4c56',
                textAlign: "center",
                minWidth: "12px",
                borderRadius: "4px",
              }}
            >
              <Tooltip
                title="Сообщения" //{translate('resources.tooltip.serveraccess')} //
                placement="bottom" //"left-end" //top-start"
              >
                <div
                  id="imsg_id"
                  onClick={(e) => {
                    let c: any = (countmsg || 0) - 1;
                    if (c <= 0) c = null;
                    setcountmsg(c);
                  }}
                  /*               onMouseOver={(item) => {
                    console.log('mouse over')
                  }}
  */
                >
                  {countmsg}
                </div>
              </Tooltip>
            </MuiLink>
          )}
        </Toolbar>
      </StyledAppBar>
    </Container>
  );
});

AppBar.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  color: PropTypes.oneOf([
    "default",
    "inherit",
    "primary",
    "secondary",
    "transparent",
  ]),
  //  container: ComponentPropType,
  // @deprecated
  open: PropTypes.bool,
  userMenu: PropTypes.oneOfType([PropTypes.element, PropTypes.bool]),
};

const DefaultUserMenu = <UserMenu />;

export interface AppBarProps extends Omit<MuiAppBarProps, "title"> {
  container?: React.ElementType<any>;
  // @deprecated
  open?: boolean;
  title?: string | JSX.Element;
  userMenu?: JSX.Element | boolean;
}

const PREFIX = "AppBar";

export const AppBarClasses = {
  appBar: `${PREFIX}-appBar`,
  toolbar: `${PREFIX}-toolbar`,
  menuButton: `${PREFIX}-menuButton`,
  menuButtonIconClosed: `${PREFIX}-menuButtonIconClosed`,
  menuButtonIconOpen: `${PREFIX}-menuButtonIconOpen`,
  title: `${PREFIX}-title`,
};

const StyledAppBar = styled(MuiAppBar, {
  name: PREFIX,
  overridesResolver: (props, styles) => styles.root,
})(({ theme }) => ({
  [`& .${AppBarClasses.toolbar}`]: {
    paddingRight: 24,
  },

  [`& .${AppBarClasses.menuButton}`]: {
    marginLeft: "0.2em",
    marginRight: "0.2em",
  },

  [`& .${AppBarClasses.title}`]: {
    flex: 1,
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
}));
