import React, { useContext, useState, useEffect } from 'react'
//import { Button, Form } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { useMediaQuery } from '@mui/material'
import Typography from '@material-ui/core/Typography'
import TextField from '@mui/material/TextField'
import { AuthContext } from '../../../context/AuthContext'

import { useTable } from 'react-table'
import { getAccessCode } from '../../../access'
import Button from '@mui/material/Button'

import Stack from 'react-bootstrap/Stack'
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
//import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import 'dayjs/locale/ru'
import 'dayjs/locale/uk'

import { Card, CardContent, Divider, Box } from '@mui/material'
import { useTranslate } from '../../../lib/translate/i18n/useTranslate'

import Checkbox from '@mui/material/Checkbox'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import FormControlLabel from '@mui/material/FormControlLabel'

import { getURLAPI } from '../../../utils/getURL'

import { default as Spinner } from '../../../components/Spinner'
import styled from 'styled-components'

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />
const checkedIcon = <CheckBoxIcon fontSize="small" />

const Styles = styled.div`
  table {
    border-spacing: 0;
    border: 1px solid #c3c3c3;
    border-radius: 10px;
    border-collapse: initial;
    tr {
      border-radius: 0px;

      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th,
    td {
      margin: 0;
      padding: 0.5rem;
      border-bottom: 1px solid #c3c3c3;
      border-right: 1px solid #c3c3c3;

      :last-child {
        border-right: 0;
      }
    }
  }
`

const defaultPropGetter = () => ({})

var columns = []
var data = []

const RouteItem = () => {
  const route_item = useSelector((state) => state.app.body.route_item)
  const dispatch = useDispatch()
  const auth = useContext(AuthContext)
  const body = useSelector((state) => state.app.body)
  const access = useSelector((state) => state.app.access.access)
  const isadmin = (access && access.isadmin) || false
  const isXSmall = useMediaQuery((theme) => theme.breakpoints.down('sm'))

  const nameTheme = access && access.user && access.user.theme
  const translate = useTranslate()

  const config = auth.config
  let URLAPI = getURLAPI(config)

  let language = 'ru'
  if (access && access.user && access.user.language) {
    if (access.user.language === 'ru') language = 'ru'
    if (access.user.language === 'ua') language = 'uk'
    if (access.user.language === 'en') language = 'en'
  }

  //START get all str from table Roles
  const [startRefresh, setStartRefresh] = useState(true)

  //замыкание
  const ws_client = auth.ws_client //body.ws_client

  const item_id = body.item_id

  const readonly = body.readonly || false

  //  const [startWss, setstartWss] = useState(true)

  const GetData_handle = function (_result) {
    const { tableName, result, readonly, action } = _result
    //console.log('result handle1 ', result)
    dispatch({
      type: 'fetch/get_add',
      payload: result,
      ob: 'route_item',
    })
    dispatch({
      type: 'fetch/get_add',
      payload: readonly,
      ob: 'readonly',
    })
  }

  const Refresh_handle = async function (result, event, parent) {
    const { tableName, action, metod } = result
    /*console.log(
      'Refresh_handle ',
      event,
      new Date().toLocaleTimeString(),
      result
    )*/
    setStartRefresh(true)
  }

  const params = {
    URL_WS: getURLAPI(auth.config, 'URL_WS'), //get address WebSocket BK from config
    auth: { userId: auth.userId, tocken: auth.token },
    handleGetData: GetData_handle,
    //handleChangeData: Refresh_handle,
    handleSetRefresh: Refresh_handle, //setStartRefresh,
    handleErrorRead: null,
    handleErrorChange: null,
    models: [
      {
        model_call_nm: 'Model_read',
        tableName: 'Routes',
        where: { id: item_id },
        action: {
          metod: 'getOne',
          isBlocked: true,
        },
      },
    ],
  }

  //deblock item whet close page
  useEffect(
    () => () => {
      try {
        if (!readonly)
          ws_client && ws_client.deblock({ tableName: 'Routes', id: item_id })
      } catch (err) {
        console.log('deblock error ', err)
      }
    },
    []
  )

  if (item_id && ws_client && ws_client.ws_login && startRefresh) {
    ws_client.open_process(params)
    setStartRefresh(false)
  }

  //END get all str from table Roles

  let greybkcolor = 'rgba(255,255,255,.3)'

  if (nameTheme) {
    greybkcolor =
      nameTheme !== 'dark' ? 'rgba(255,255,255,1.0)' : 'rgba(255,255,255,.3)'
  }

  columns = [
    {
      Header: '№',
      accessor: 'num',
    },
    {
      Header: translate('resources.store.fields.name'),
      accessor: 'name',
    },
    {
      Header: translate('resources.store.fields.customer'),
      accessor: 'customer',
    },
    {
      Header: translate('resources.route.fields.day1'),
      accessor: 'day1',
    },
    {
      Header: translate('resources.route.fields.day2'),
      accessor: 'day2',
    },
    {
      Header: translate('resources.route.fields.day3'),
      accessor: 'day3',
    },
    {
      Header: translate('resources.route.fields.day4'),
      accessor: 'day4',
    },
    {
      Header: translate('resources.route.fields.day5'),
      accessor: 'day5',
    },
    {
      Header: translate('resources.route.fields.day6'),
      accessor: 'day6',
    },
    {
      Header: translate('resources.route.fields.day7'),
      accessor: 'day7',
    },
  ]

  data = []

  const stores = route_item && route_item.items

  let num = 1
  let day1 = 0,
    day2 = 0,
    day3 = 0,
    day4 = 0,
    day5 = 0,
    day6 = 0,
    day7 = 0
  if (stores) {
    for (var iw in stores) {
      stores[iw].day1 && day1++
      stores[iw].day2 && day2++
      stores[iw].day3 && day3++
      stores[iw].day4 && day4++
      stores[iw].day5 && day5++
      stores[iw].day6 && day6++
      stores[iw].day7 && day7++
      data.push({
        num: num++,
        name: stores[iw].stores.name,
        customer:
          stores[iw].stores.itemcustomers &&
          stores[iw].stores.itemcustomers.items &&
          stores[iw].stores.itemcustomers.items.name,
        day1: stores[iw].day1,
        day2: stores[iw].day2,
        day3: stores[iw].day3,
        day4: stores[iw].day4,
        day5: stores[iw].day5,
        day6: stores[iw].day6,
        day7: stores[iw].day7,
        items: stores[iw],
      })
    }

    data.push({
      num: '',
      name: 'Итого посещений: ',
      customer: '',
      day1: day1,
      day2: day2,
      day3: day3,
      day4: day4,
      day5: day5,
      day6: day6,
      day7: day7,
      items: [],
    })
  }

  function Table({
    columns,
    data,
    getHeaderProps = defaultPropGetter,
    getColumnProps = defaultPropGetter,
    getRowProps = defaultPropGetter,
    getCellProps = defaultPropGetter,
  }) {
    // Use the state and functions returned from useTable to build your UI
    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
      useTable({
        columns,
        data,
      })

    return (
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  // Return an array of prop objects and react-table will merge them appropriately
                  {...column.getHeaderProps([
                    {
                      className: column.className,
                      style: column.style,
                    },
                    getColumnProps(column),

                    getHeaderProps(column),
                  ])}
                >
                  {column.render('Header')}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row)
            return (
              // Merge user row props in
              <tr {...row.getRowProps(getRowProps(row))}>
                {row.cells.map((cell) => {
                  const GetChecked = function (colname, v) {
                    if (
                      (colname === 'day1' && v.day1 === true) ||
                      (colname === 'day2' && v.day2 === true) ||
                      (colname === 'day3' && v.day3 === true) ||
                      (colname === 'day4' && v.day4 === true) ||
                      (colname === 'day5' && v.day5 === true) ||
                      (colname === 'day6' && v.day6 === true) ||
                      (colname === 'day7' && v.day7 === true)
                    )
                      return true
                    else return false
                  }
                  let v = cell.row.original
                  let Checked = false
                  if (v && v.access === 'ok') {
                    Checked = true
                  }
                  const vv = cell
                  let parent = Object.assign({}, cell.row.original.route_item)
                  function clearptw(parent) {
                    if (parent && parent.getwt) {
                      parent.getwt = false //обнуляем getwt для загрузки WT при возврате назад
                    }
                    return parent
                  }
                  let storeid =
                    cell.row.original.items.storeid || (v && v.num === '')
                  return (
                    <td
                      // Return an array of prop objects and react-table will merge them appropriately
                      {...cell.getCellProps([
                        {
                          className: cell.column.className,
                          style: storeid
                            ? cell.column.style
                            : { color: 'blue', backgroundColor: 'yellow' },
                        },
                        getColumnProps(cell.column),
                        //  getCellProps(cell),
                      ])}
                      align="center"
                      //                    width="140px"
                    >
                      {v.num !== '' &&
                        (cell.column.id === 'day1' ||
                          cell.column.id === 'day2' ||
                          cell.column.id === 'day3' ||
                          cell.column.id === 'day4' ||
                          cell.column.id === 'day5' ||
                          cell.column.id === 'day6' ||
                          cell.column.id === 'day7') && (
                          <>
                            <FormControlLabel
                              style={{
                                marginRight: -10,
                                marginLeft: 0,
                              }}
                              control={
                                <Checkbox
                                  icon={icon}
                                  checkedIcon={checkedIcon}
                                  style={{ marginRight: 8 }}
                                  checked={GetChecked(
                                    cell.column.id,
                                    cell.row.original
                                  )}
                                  disabled={readonly}
                                  /*                    <Switch
                                id={1}
                                name={'DopInfo'}*/
                                  color="primary"
                                  //                              onChange={handleChangeSwitch}
                                  onChange={async (item) => {
                                    if (!readonly) {
                                      try {
                                        /*
                                      await ws_client.wss_setItem({
                                        tableName: 'Roles',
                                        item: route_item,
                                        call_nm: 'Model_update',
                                        where: {
                                          checked: Checked,
                                          unit_id: v.unit_id,
                                          //roleunit_id: v.roleunit_id,
                                        },
                                        action: {
                                          metod: 'setroleunits',
                                        },
                                        callback: setRoles,
                                      })*/
                                      } catch (e) {
                                        console.log('errr e', e)
                                      }
                                    }
                                  }}
                                />

                                //                      {cell.column.id !== 'access' && cell.render('Cell')}
                              }
                              label={''}
                            />
                          </>
                        )}

                      {(false && cell.column.id !== 'timeline' && (
                        <Button
                          //as={Link}
                          variant="outline-primary"
                          //style={{ marginBottom: 5 }}
                          style={{
                            pointerEvents: readonly ? 'none' : '',
                          }}
                          onClick={async () => {
                            try {
                              await ws_client.wss_getItem({
                                tableName: 'Heads',
                                //item: cell.row.original.route_item,
                                where: cell.row.original.route_item.id,
                                action: {
                                  metod: 'getSupervisor',
                                  mode: 'get',
                                  parent: clearptw(parent), //cell.row.original.route_item,
                                  id: cell.row.original.items.id, //role_id,
                                },
                                //callback: getWork,
                              })
                            } catch (e) {
                              console.log('errr e', e)
                            }
                            //setSubPages({ page: 'role' })
                          }}
                        >
                          {cell.render('Cell')}
                        </Button>
                      )) ||
                        cell.render('Cell')}
                    </td>
                  )
                })}
              </tr>
            )
          })}
        </tbody>
      </table>
    )
  }

  const getChange = () => {
    if (route_item && route_item.change === true) return 'contained'
    else return 'outlined'
    //   if (route_item && route_item.change === true) return 'success'
    //    else return 'outline-success'
  }
  const getAccessToChange = () => {
    return readonly || false
  }

  const ready = () => {
    if (route_item) return true
    else return false
  }

  return (
    <>
      {ready() && (
        <>
          <Box maxWidth="100em">
            <div
              //            className="hstack gap-2"
              style={{
                marginTop: -20,
                display: 'flex',
                //justifyContent: 'space-between',
              }}
            >
              <Card>
                <CardContent>
                  <>
                    <div
                      //            className="hstack gap-2"
                      style={{
                        marginTop: -20,
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                    >
                      <Typography
                        id="outlined-basic"
                        label="Тип"
                        //variant="standard"
                        className="title h6"
                        style={{
                          marginTop: 15,
                          marginBottom: 15,
                          marginRight: 150,
                          //display: 'flex',
                          //justifyContent: 'space-between',
                        }}
                      >
                        {translate('resources.route.title')}
                      </Typography>

                      {readonly && (
                        <Typography
                          id="outlined-basic"
                          label="Title"
                          //variant="standard"
                          //className="title h6"
                          style={{
                            marginTop: 15,
                            marginBottom: 15,
                            //  marginRight: 275,
                            color: 'red',
                          }}
                          //                      {translate('resources.workers.readonly')}
                        >
                          Readonly
                        </Typography>
                      )}
                    </div>

                    <div
                      //            className="hstack gap-2"
                      style={{
                        //            marginTop: -20,
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                    >
                      {route_item && (
                        <>
                          <TextField
                            id="outlined-basic"
                            label={translate('resources.route.fields.name')} //"Тип работ"
                            //variant="standard"
                            variant="outlined"
                            style={{
                              marginBottom: 15,
                              //display: 'flex',
                              //justifyContent: 'space-between',
                            }}
                            value={(route_item && route_item.name) || ''}
                            onChange={(item) => {
                              if (!getAccessToChange()) {
                                route_item.change = true
                                dispatch({
                                  type: 'app/set',
                                  payload: {
                                    id: route_item,
                                    field: 'name',
                                    value: item.target.value,
                                  },
                                  ob: 'route_item',
                                })
                              }
                            }}
                          ></TextField>

                          <TextField
                            id="outlined-basic"
                            label={translate(
                              'resources.route.fields.supervisor'
                            )} //"Тип работ"
                            //variant="standard"
                            variant="outlined"
                            style={{
                              marginBottom: 15,
                              marginLeft: 15,
                              //display: 'flex',
                              //justifyContent: 'space-between',
                            }}
                            value={
                              (route_item &&
                                route_item.supervisors &&
                                route_item.supervisors.name) ||
                              ''
                            }
                            /*                          onChange={(item) => {
                            if (!getAccessToChange()) {
                              route_item.change = true
                              dispatch({
                                type: 'app/set',
                                payload: {
                                  id: route_item,
                                  field: 'name',
                                  value: item.target.value,
                                },
                                ob: 'route_item',
                              })
                            }
                          }}
*/
                          ></TextField>
                          <TextField
                            id="outlined-basic"
                            label={translate('resources.route.fields.manager')} //"Тип работ"
                            //variant="standard"
                            variant="outlined"
                            style={{
                              marginBottom: 15,
                              marginLeft: 20,
                              //display: 'flex',
                              //justifyContent: 'space-between',
                            }}
                            value={
                              (route_item &&
                                route_item.managers &&
                                route_item.managers.name) ||
                              ''
                            }
                            /*                          onChange={(item) => {
                            if (!getAccessToChange()) {
                              route_item.change = true
                              dispatch({
                                type: 'app/set',
                                payload: {
                                  id: route_item,
                                  field: 'name',
                                  value: item.target.value,
                                },
                                ob: 'route_item',
                              })
                            }
                          }}
*/
                          ></TextField>
                        </>
                      )}
                    </div>
                    <div className="gstack gap-4" style={{ marginBottom: 5 }}>
                      <Stack
                        direction="horizontal"
                        gap={2}
                        style={{ marginBottom: 5 }}
                      >
                        <div>
                          <Button
                            size="small"
                            component={Link}
                            color="primary"
                            //variant="outlined"
                            sx={{
                              display: 'inline-flex',
                              alignItems: 'center',
                            }}
                            variant={getChange()} //"outline-success"
                            to={{
                              pathname: '/pages/route/list',
                            }}
                            //state={route_item}
                            disabled={getAccessToChange()}
                            onClick={async () => {
                              try {
                                if (!getAccessToChange())
                                  await ws_client.wss_setItem({
                                    tableName: 'Routes',
                                    item: route_item,
                                    call_nm: 'Model_update',
                                    where: { id: route_item.id },
                                    //action: { metod: 'set' },
                                  })
                              } catch (e) {
                                console.log('errr e', e)
                              }
                            }}
                          >
                            {translate('resources.button.save')}
                          </Button>

                          <Button
                            size="small"
                            component={Link}
                            color="primary"
                            //variant="outlined"
                            sx={{
                              display: 'inline-flex',
                              alignItems: 'center',
                            }}
                            style={{ marginLeft: 10 }}
                            variant="outlined" //"outline-danger"
                            to="/pages/route/list"
                          >
                            {translate('resources.button.cancel')}
                          </Button>
                        </div>
                        <div style={{ marginLeft: 22 }}>
                          <LocalizationProvider
                            dateAdapter={AdapterDayjs}
                            adapterLocale={language}
                          >
                            <DesktopDatePicker
                              label={translate('resources.worktime.datefrom')} //"Дата с:"
                              onFocus={(e) => (e.target.readOnly = true)} //выключение клавиатуры при выборе значения
                              //                defaultValue={startDate}
                              value={route_item && route_item.date1} //startDate}
                              //inputFormat="dd.MM.yyyy"
                              //onChange={onChangeDate1}
                              renderInput={(params) => (
                                <TextField
                                  sx={{ width: 145 }}
                                  variant="outlined"
                                  {...params}
                                />
                              )}
                            />
                            <DesktopDatePicker
                              label={translate('resources.worktime.dateto')} //"Дата по:"
                              onFocus={(e) => (e.target.readOnly = true)} //выключение клавиатуры при выборе значения
                              //              defaultValue={dateState2}
                              value={route_item && route_item.date2} //endDate}
                              //inputFormat="dd.MM.yyyy"
                              //onChange={onChangeDate2}
                              renderInput={(params) => (
                                <TextField
                                  sx={{ width: 145, marginLeft: 11 }}
                                  variant="outlined"
                                  {...params}
                                />
                              )}
                            />
                          </LocalizationProvider>
                        </div>
                      </Stack>
                    </div>

                    <Divider
                      style={{
                        marginTop: 15,
                      }}
                    />
                    {(isadmin ||
                      getAccessCode(
                        'SUPEVISORS_VIEW',
                        access && access.access
                      )) && (
                      <Styles>
                        <br />
                        <Table
                          columns={columns}
                          data={data}
                          getHeaderProps={
                            (nameTheme &&
                              nameTheme !== 'dark' &&
                              ((row) => {
                                return {
                                  style: {
                                    //                   background:
                                    //                   row.index % 2 === 0 ? 'rgba(0,0,0,.1)' : 'white',
                                  },
                                }
                              })) ||
                            ((row) => {
                              return {
                                style: {
                                  color: '#ffffffb3',
                                  //                    background:
                                  //                    row.index % 2 === 0 ? 'rgba(0,0,0,.1)' : '#ffffffb3', //'#616161e6', //'#ffffffb3',
                                },
                              }
                            })
                          }
                          /*          
          getHeaderProps={(column) => ({
            onClick: () => alert('Header!'),
          })}
          getColumnProps={(column) => ({
            onClick: () => alert('Column!'),
          })}
          */
                          getRowProps={
                            (nameTheme &&
                              nameTheme !== 'dark' &&
                              ((row) => {
                                return {
                                  style: {
                                    background:
                                      row.index % 2 === 0
                                        ? 'rgba(0,0,0,.1)'
                                        : 'white',
                                  },
                                }
                              })) ||
                            ((row) => {
                              return {
                                style: {
                                  background:
                                    row.index % 2 === 0
                                      ? 'rgba(0,0,0,.1)'
                                      : '#616161e6', //'#ffffffb3',
                                },
                              }
                            })
                          }
                          getCellProps={(cellInfo) => ({
                            //            onClick: (req, res) => onClickCell(cellInfo, req, res),
                            //                style: {
                            //                  backgroundColor: `hsl(${
                            //                    120 * ((120 - cellInfo.value) / 120) * -1 + 120
                            //                  }, 100%, 67%)`,
                            //                },
                          })}
                        />
                      </Styles>
                    )}
                  </>
                </CardContent>
              </Card>
            </div>
          </Box>
        </>
      )}
      {!ready() && (
        <>
          <Box maxWidth="30em">
            <Card>
              <CardContent>
                <Spinner />
              </CardContent>
            </Card>
          </Box>
        </>
      )}
    </>
  )
}

export default RouteItem
