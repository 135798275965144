import { ReactNode, useCallback, useMemo, useState } from 'react'
import { styled } from '@mui/material/styles'
import PropTypes from 'prop-types'
import {
  Tooltip,
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Button,
  Avatar,
  PopoverOrigin,
  useMediaQuery,
  Theme,
} from '@mui/material'
import AccountCircle from '@mui/icons-material/AccountCircle'
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera'
import { UserMenuContextProvider } from './UserMenuContextProvider'

import { useSelector } from 'react-redux'

export const UserMenu = (props: UserMenuProps) => {
  const access = useSelector((state: any) => state.app.access.access)
  const access2 = useSelector((state: any) => state.app.access)
  let userAvatar =
    (access2 &&
      access2.avatar &&
      access2.avatar.avatar &&
      access2.avatar.avatar.avatar) ||
    null
  const mega = (access2 && access2.mega) || null
  let ismega = false

  if (!userAvatar) userAvatar = access && access.user && access.user.avatar

  if (mega) {
    ismega = true
  }
  let Avatar1 = ''
  if (userAvatar && userAvatar.data) {
    for (let i in userAvatar.data) {
      Avatar1 = Avatar1 + String.fromCharCode(userAvatar.data[i])
    }
  }

  let UserName = (access && access.UserName) || ''
  /*
  if (access && access.user) {
    if (access.user.firstName) UserName = access.user.firstName
    if (access.user.lastName) UserName = UserName + ' ' + access.user.lastName
    if (UserName === null) UserName = access.user.login && access.user.login
  }
  */
  const [anchorEl, setAnchorEl] = useState(null)
  //const translate = useTranslate()
  //const { isLoading, identity } = useGetIdentity()
  let isLoading = false
  let identity = { avatar: Avatar1, fullName: UserName }
  //const authProvider = useAuthProvider()
  const isLargeEnough = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.up('sm')
  )
  const {
    children = null, //!!authProvider ? <Logout /> : null,<LogoutMenu />
    className,
    label = 'user_menu',
    icon = defaultIcon,
  } = props

  const handleMenu = (event: any) => setAnchorEl(event.currentTarget)
  const handleClose = useCallback(() => setAnchorEl(null), [])
  const handleClick = useCallback(() => setAnchorEl(null), [])

  const context = useMemo(() => ({ onClose: handleClose }), [handleClose])
  if (!children) return null
  const open = Boolean(anchorEl)

  //original
  return (
    <Root className={className}>
      {isLargeEnough && !isLoading && identity?.fullName ? (
        <>
          <Button
            aria-label={label}
            className={UserMenuClasses.userButton}
            color="inherit"
            startIcon={
              identity.avatar ? (
                <Avatar
                  className={UserMenuClasses.avatar}
                  src={identity.avatar}
                  alt={identity.fullName}
                />
              ) : (
                icon
              )
            }
            onClick={handleMenu}
          >
            {identity.fullName}
          </Button>
        </>
      ) : (
        <Tooltip
          title="User Menu" //{UserName}
          placement="bottom" //"left-end" //top-start"
        >
          <IconButton
            aria-label={UserName}
            //            aria-owns={'menu-appbar'}
            aria-owns={open ? 'menu-appbar' : ''}
            aria-haspopup={true}
            color="inherit"
            onClick={handleMenu}
            size="large"
          >
            {!isLoading && identity?.avatar ? (
              <Avatar
                className={UserMenuClasses.avatar}
                src={identity.avatar}
                alt={identity.fullName}
              />
            ) : (
              icon
            )}
          </IconButton>
        </Tooltip>
      )}
      <UserMenuContextProvider value={context}>
        <Menu
          id="menu-appbar"
          disableScrollLock
          anchorEl={anchorEl}
          anchorOrigin={AnchorOrigin}
          transformOrigin={TransformOrigin}
          open={open}
          onClose={handleClose}
          onClick={handleClick}
        >
          {ismega && (
            <MenuItem>
              <ListItemIcon>
                <PhotoCameraIcon color={'success'} />
              </ListItemIcon>
              <ListItemText>{'Доступно'}</ListItemText>
            </MenuItem>
          )}
          {children}
        </Menu>
      </UserMenuContextProvider>
    </Root>
  )
  /*

  return (
    <Root className={'className'}>
      <Tooltip title={'Прифиль'}>
        <IconButton
          aria-label={'Avatar'}
          aria-owns={'menu-appbar'}
          aria-haspopup={true}
          color="inherit"
          onClick={handleMenu}
          size="large"
          //                      className={'UserMenuClasses.userButton'}
          //                    startIcon={<AccountCircle />}
          //onClick={handleMenu}
        >
          <Avatar
            className={'avatar'}
            //src={'avatar'}
            alt={'Name'}
          />
        </IconButton>
      </Tooltip>
      <Menu
        id="menu-appbar"
        disableScrollLock
        anchorEl={anchorEl}
        anchorOrigin={AnchorOrigin}
        transformOrigin={TransformOrigin}
        open={open}
        onClose={handleClose}
      >
        {children}
      </Menu>
    </Root>
  )
*/
}

UserMenu.propTypes = {
  children: PropTypes.node,
  classes: PropTypes.object,
  label: PropTypes.string,
  icon: PropTypes.node,
}

export interface UserMenuProps {
  children?: ReactNode
  className?: string
  label?: string
  icon?: ReactNode
}

const PREFIX = 'UserMenu'

export const UserMenuClasses = {
  userButton: `${PREFIX}-userButton`,
  avatar: `${PREFIX}-avatar`,
}

const Root = styled('div', {
  name: PREFIX,
  overridesResolver: (props, styles) => styles.root,
})(({ theme }) => ({
  [`& .${UserMenuClasses.userButton}`]: {
    textTransform: 'none',
  },

  [`& .${UserMenuClasses.avatar}`]: {
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
}))

const defaultIcon = <AccountCircle />

const AnchorOrigin: PopoverOrigin = {
  vertical: 'bottom',
  horizontal: 'right',
}

const TransformOrigin: PopoverOrigin = {
  vertical: 'top',
  horizontal: 'right',
}
