import React, {
  useContext,
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo,
} from "react";
import { Form } from "react-bootstrap";
//import Stack from 'react-bootstrap/Stack'
import { useSelector, useDispatch } from "react-redux";
import { useMediaQuery } from "@mui/material";
import { AuthContext } from "../context/AuthContext";

import { useTable } from "react-table";
import styled from "styled-components";
import { Link } from "react-router-dom";

import Brightness1Icon from "@mui/icons-material/Brightness1";

//import TimePicker from "rc-time-picker";

//import parser from "cron-parser";

//import SelectWorkers from "../components/SelectWorkers";
import Tooltip from "@mui/material/Tooltip";
import Alert from "@mui/material/Alert";
import Autocomplete from "@mui/material/Autocomplete";
import CloseIcon from "@material-ui/icons/Close";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import CancelPresentationIcon from "@mui/icons-material/CancelPresentation";
import Box from "@mui/material/Box";

//import { Calendar, momentLocalizer, Views } from "react-big-calendar";
//import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
//import "react-big-calendar/lib/addons/dragAndDrop/styles.css";

//import "react-big-calendar/lib/css/react-big-calendar.css";

import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
//import CardActions from '@mui/material/CardActions'
import CardContent from "@mui/material/CardContent";
import Checkbox from "@mui/material/Checkbox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

import SearchIcon from "@mui/icons-material/Search";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import SendIcon from "@mui/icons-material/Send";

import { useTranslate } from "../lib/translate/i18n/useTranslate";

import { getURLAPI } from "../utils/getURL";

import { default as Spinner } from "../components/Spinner";

//import { useHotkeys } from "react-hotkeys-hook";
//*********** */

//import { CssVarsProvider, extendTheme } from "@mui/joy/styles";
//import { RadioGroup as RadioGroup2 } from "@mui/joy";
//import { Radio as Radio2 } from "@mui/joy";

import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import FormLabel from "@mui/material/FormLabel";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import Switch from "@mui/material/Switch";
import FormGroup from "@mui/material/FormGroup";
import Collapse from "@mui/material/Collapse";
import ListItem from "@mui/material/ListItem";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import AccessAlarm from "@mui/icons-material/AccessAlarm";
import EnhancedEncryptionIcon from "@mui/icons-material/EnhancedEncryption";
import SaveIcon from "@mui/icons-material/Save";
import DeleteIcon from "@mui/icons-material/Delete";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

//import MuiPhoneNumber from "material-ui-phone-number";

//import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
//import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
//import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

//import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "dayjs/locale/ru";
import "dayjs/locale/uk";

import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

//import { useForm } from "../hooks/remote";

import moment from "moment";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import ReactPanZoom from "react-image-pan-zoom-rotate";

import Draggable from "react-draggable";
//import { values } from "lodash";
import {
  Polyline,
  MapContainer,
  TileLayer,
  useMap,
  Marker,
  Popup,
  Tooltip as TooltipMap,
} from "react-leaflet";

import L from "leaflet";
//import { isAsyncFunction } from 'util/types'
import "leaflet/dist/leaflet.css";

const Styles = styled.div`
  table {
    border-spacing: 0;
    border: 1px solid #c3c3c3;
    border-radius: 10px;
    border-collapse: initial;
    tr {
      border-radius: 0px;

      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th,
    td {
      margin: 0;
      padding: 0.5rem;
      border-bottom: 1px solid #c3c3c3;
      border-right: 1px solid #c3c3c3;

      :last-child {
        border-right: 0;
      }
    }
  }
`;

const defaultPropGetter = () => ({});

var columns = [];
var data = [];
let AllPosition = [];

var columns1 = [];
var data1 = [];

var columns2 = [];
var data2 = [];

var columns3 = [];
var data3 = [];

var columns4 = [];
var data4 = [];

var columns5 = [];
var data5 = [];

var data5a = [];
var data5b = [];

var columns6 = [];
var data6 = [];

var columns7 = [];
var data7 = [];

var columns8 = [];
var data8 = [];

var columns9 = [];
var data9 = [];

var columns10 = [];
var data10 = [];

let n = 0;
let x = 0;
let y = 0;

let startposition = []; //50.380600, 30.453207 50.425051, 30.3631675];

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
//const theme = extendTheme({});

const Home = () => {
  const doctors_item = useSelector((state) => state.app.body.doctors_item);
  const auth = useContext(AuthContext);
  const current = useSelector((state) => state.app.current);
  const app = useSelector((state) => state.app);
  const body = useSelector((state) => state.app.body);
  const access = useSelector((state) => state.app.access.access);
  const UserSupervisors = access && access.user && access.user.supervisors;
  const isadmin = (access && access.isadmin) || false;
  const allsupervisors = (access && access.allsupervisors) || false;
  const isXSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const [tab, setTab] = useState("1");
  const [tabR, setTabR] = useState("1");
  const [stopButton, setstopButton] = useState(false);

  const [SelectedDate, setSelectedDate] = useState(new Date()); //auth.Unitype || [])
  const [SelectedSupervisor, setSelectedSupervisor] = useState(); //auth.Unitype || [])
  const [SelectedManager, setSelectedManager] = useState(null); //auth.Unitype || [])
  const [SelectedStore, setSelectedStore] = useState(); //auth.Unitype || [])
  const [SelectedOrder, setSelectedOrder] = useState(); //auth.Unitype || [])

  const [SelectedDelivery, setSelectedDelivery] = useState(); //auth.Unitype || [])
  const [SelectedInvoice, setSelectedInvoice] = useState(); //auth.Unitype || [])

  // console.log("Refresh HOME!!!");
  const onGetLogTorg = (param, _Date) => {
    //if (!SelectedManager) return;
    let date1a = moment(_Date, "YYYY.MM.DD").format("YYYY.MM.DD") + " 06:00";
    let date2a = moment(_Date, "YYYY.MM.DD").format("YYYY.MM.DD") + " 20:00";
    let date1 = moment(date1a, "YYYY.MM.DD HH:mm").unix();
    let date2 = moment(date2a, "YYYY.MM.DD HH:mm").unix();
    if (param)
      dispatch({
        type: "FETCH_GETLOG",
        action: {
          ta_guid: param.id,
          date1: date1, //"1710412402",
          date2: date2, //"1710440402",
        },
      });
  };

  const clearAll = () => {
    setSelectedManager(null);
    setSelectedStore(null);

    //очистить поездку
    dispatch({
      type: "fetch/get_add", //'fetch/get_add',
      payload: null,
      ob: "routesheets",
    });

    // очистить трек
    dispatch({
      type: "fetch/get_add", //'fetch/get_add',
      payload: null,
      ob: "getlog",
    });

    dispatch({
      type: "fetch/get_add", //'fetch/get_add',
      payload: [],
      ob: "filefotos",
    });

    dispatch({
      type: "fetch/get_add", //'fetch/get_add',
      payload: [],
      ob: "orders",
    });

    dispatch({
      type: "fetch/get_add", //'fetch/get_add',
      payload: [],
      ob: "returns",
    });

    dispatch({
      type: "fetch/get_add", //'fetch/get_add',
      payload: [],
      ob: "payments",
    });

    dispatch({
      type: "fetch/get_add", //'fetch/get_add',
      payload: [],
      ob: "debts",
    });
  };
  const onChangeDate = (value) => {
    let date = new Date(value);
    let dates = moment(date).format("YYYY-MM-DD");
    setSelectedDate(dates);
    setSelectedManager(SelectedManager);
    setStartRefreshRouteSheets(true);
    onGetLogTorg(SelectedManager, date);
    setStartRefreshManagers(true);
    setstartRefreshDeliverys(true);
    setstartRefreshInvoices(true);
    setstartRefreshManagerTasks(true);
    setstartRefreshReturns(true);
    setstartRefreshSalesplans(true);
  };

  const changeSupervisor = (event, value) => {
    clearAll();
    setSelectedSupervisor(value);
    setStartRefreshManagers(true);

    //    auth.setWorker(value)
  };

  const handleSelectManager = (param) => {
    setSelectedStore(null);
    setSelectedManager(param);
    onGetLogTorg(param, SelectedDate);
    setStartRefreshRouteSheets(true);
    //setstartRefreshReturns(true);
    setstartRefreshDeliverys(true);
    setstartRefreshManagerTasks(true);
    setstartRefreshSalesplans(true);
  };

  const handleSelectedStore = (param) => {
    setSelectedStore(param);
    setopenFoto("0");
    setstartRefreshFotos(true);
    setstartRefreshDebts(true);
    setstartRefreshPayments(true);
    setstartRefreshOrders(true);
    setstartRefreshReturns(true);
    setstartRefreshInvoices(true);
  };

  //  var map = L.map("map").setView([51.505, -0.09], 13);
  const OnChangeTab2 = async function (value) {
    if (value === "1") {
      //setstartRefreshMaps(true);
      //onGetLogTorg(SelectedManager);
    } else if (value === "2") {
      //onGetLogTorg(SelectedManager);
      //setstartRefreshFotos(true);
    }
  };

  const OnChangeTab1 = async function (value) {
    //console.log("OnChangeTab1 value", value);

    if (value === "1") {
      //setstartRefreshMaps(true);
      onGetLogTorg(SelectedManager, SelectedDate);
    } else if (value === "2") {
      setstartRefreshFotos(true);
    } else if (value === "3") {
      setstartRefreshOrders(true);
    } else if (value === "4") {
      setstartRefreshPayments(true);
    } else if (value === "5") {
      setstartRefreshDebts(true);
    } else if (value === "6") {
      setstartRefreshReturns(true);
    } else if (value === "7") {
      setstartRefreshDeliverys(true);
    } else if (value === "8") {
      setstartRefreshInvoices(true);
    }
  };

  const clickRef = useRef(null);
  const [swithOldTasks, setswithOldTasks] = React.useState(false);
  const [swithOldTermins, setswithOldTermins] = React.useState(true);

  const [swithSearch, setswithSearch] = React.useState(false);

  const [openSearch, setOpenSearch] = React.useState(false);

  //Alert Dargable Dialog
  const [maxWidth, setmaxWidth] =
    React.useState("xs"); /*  "xs"  "sm"  "md"  "lg"  "xl"  */
  const [TitleAlertDialog, setTitleAlertDialog] = React.useState("");
  const [TextAlertDialog, setTextAlertDialog] = React.useState("");
  const [alertMessages, setAlertMessages] = useState([]);
  const [alertMessagesBorder, setAlertMessagesBorder] = useState(2);
  const [openAlertDialog, setOpenAlertDialog] = React.useState(false);
  const [TypeAlertDialog, setTypeAlertDialog] =
    React.useState("ErrorDeleteTermin");

  //Alert Bottom Termin Info
  const [showAlert, setShowAlert] = React.useState(false);
  const [AlertMessage, setAlertMessage] = React.useState(null);
  const [AlertType, setAlertType] = React.useState("error");

  const nameTheme = access && access.user && access.user.theme;
  const translate = useTranslate();
  const dispatch = useDispatch();

  let maxwidth = "65em";

  const [openFotoDialog, setopenFotoDialog] = React.useState(false);
  const [openFoto, setopenFoto] = React.useState("0");

  const [openOrderDialog, setOpenOrderDialog] = React.useState(false);
  const [openDeliveryDialog, setOpenDeliveryDialog] = React.useState(false);
  const [openInvoiceDialog, setOpenInvoiceDialog] = React.useState(false);

  const handleAlertClose = () => {
    setTitleAlertDialog("");
    setTextAlertDialog("");
    setOpenAlertDialog(false);
  };

  const handleOrderClose = () => {
    setOpenOrderDialog(false);
  };

  const handleDeliveryClose = () => {
    setOpenDeliveryDialog(false);
  };

  const handleInvoiceClose = () => {
    setOpenInvoiceDialog(false);
  };

  //***************************************/

  let Managers = body.manager;
  let managers = [];
  if (SelectedSupervisor && Managers && Managers.length > 0) {
    managers = Managers.filter((item) => {
      //      console.log('item', item)
      //      console.log('item.name', item.name)
      return (
        item.itemsupervisors &&
        item.itemsupervisors.supervisorid === SelectedSupervisor.value
      );
    });
  }

  //START get all str from table Roles
  //console.log('Refresh!!! customers...')
  const [startRefreshFilter, setStartRefreshFilter] = useState(true);
  const [startRefreshManagers, setStartRefreshManagers] = useState(true);

  const [startRefreshMaps, setstartRefreshMaps] = useState(false);
  const [startRefreshFotos, setstartRefreshFotos] = useState(false);
  const [startRefreshOrders, setstartRefreshOrders] = useState(false);
  const [startRefreshReturns, setstartRefreshReturns] = useState(false);
  const [startRefreshDeliverys, setstartRefreshDeliverys] = useState(false);
  const [startRefreshInvoices, setstartRefreshInvoices] = useState(false);
  const [startRefreshSalesplans, setstartRefreshSalesplans] = useState(false);
  const [startRefreshManagerTasks, setstartRefreshManagerTasks] =
    useState(false);

  const [startRefreshPayments, setstartRefreshPayments] = useState(false);
  const [startRefreshDebts, setstartRefreshDebts] = useState(false);

  const [startRefreshRouteSheets, setStartRefreshRouteSheets] = useState(false);

  //замыкание
  const ws_client = auth.ws_client; //body.ws_client

  const item_id = null; // = worker2 && worker2.value; //body.item_id

  const readonly = body.readonly;

  const GetData_handle = function (_result) {
    const { tableName, result, readonly, action } = _result;
    //console.log('result handle1 ', result
    if (
      tableName === "Managers" &&
      action &&
      action.metod &&
      action.metod === "getInfo"
    ) {
      dispatch({
        type: "fetch/get_add", //'fetch/get_add',
        payload: result,
        ob: "manager",
      });
    }
    if (
      tableName === "Supervisors" &&
      action &&
      action.metod &&
      action.metod === "get"
    ) {
      dispatch({
        type: "fetch/get_add", //'fetch/get_add',
        payload: result,
        ob: "supervisors",
      });
    }
    if (
      tableName === "RouteSheets" &&
      action &&
      action.metod &&
      action.metod === "getForManager"
    ) {
      dispatch({
        type: "fetch/get_add", //'fetch/get_add',
        payload: result,
        ob: "routesheets",
      });
    }
    if (
      tableName === "FileFotos" &&
      action &&
      action.metod &&
      action.metod === "getbyFiler"
    ) {
      dispatch({
        type: "fetch/get_add", //'fetch/get_add',
        payload: result,
        ob: "filefotos",
      });
    }

    if (
      tableName === "Orders" &&
      action &&
      action.metod &&
      action.metod === "getbyFiler"
    ) {
      dispatch({
        type: "fetch/get_add", //'fetch/get_add',
        payload: result,
        ob: "orders",
      });
    }

    if (
      tableName === "Returns" &&
      action &&
      action.metod &&
      action.metod === "getbyFiler"
    ) {
      dispatch({
        type: "fetch/get_add", //'fetch/get_add',
        payload: result,
        ob: "returns",
      });
    }

    if (
      tableName === "Invoices" &&
      action &&
      action.metod &&
      action.metod === "getDelivery"
    ) {
      dispatch({
        type: "fetch/get_add", //'fetch/get_add',
        payload: result,
        ob: "delivery",
      });
    }

    if (
      tableName === "Invoices" &&
      action &&
      action.metod &&
      action.metod === "getInvoices"
    ) {
      dispatch({
        type: "fetch/get_add", //'fetch/get_add',
        payload: result,
        ob: "invoices",
      });
    }

    if (
      tableName === "Salesplans" &&
      action &&
      action.metod &&
      action.metod === "getbyFiler"
    ) {
      dispatch({
        type: "fetch/get_add", //'fetch/get_add',
        payload: result,
        ob: "salesplans",
      });
    }

    if (
      tableName === "ManagerTasks" &&
      action &&
      action.metod &&
      action.metod === "getbyFiler"
    ) {
      dispatch({
        type: "fetch/get_add", //'fetch/get_add',
        payload: result,
        ob: "managertasks",
      });
    }

    if (
      tableName === "Payments" &&
      action &&
      action.metod &&
      action.metod === "getbyFiler"
    ) {
      dispatch({
        type: "fetch/get_add", //'fetch/get_add',
        payload: result,
        ob: "payments",
      });
    }

    if (
      tableName === "Debts" &&
      action &&
      action.metod &&
      action.metod === "getbyFiler"
    ) {
      dispatch({
        type: "fetch/get_add", //'fetch/get_add',
        payload: result,
        ob: "debts",
      });
    }
  };

  const Refresh_handle = async function (result, event, parent) {
    const { tableName, action, metod } = result;
    /*console.log(
      'Refresh_handle ',
      event,
      new Date().toLocaleTimeString(),
      result
    )*/
    if (result && result.tableName) {
      let tableName = result.tableName;
      let metod = result.action && result.action.metod;
    }
  };

  const paramsManagers = {
    URL_WS: getURLAPI(auth.config, "URL_WS"), //get address WebSocket BK from config
    auth: { userId: auth.userId, tocken: auth.token },
    handleGetData: GetData_handle,
    handleChangeData: Refresh_handle /* () => {
      setStartRefreshManagers(true)
    },*/,
    //Refresh_handle,
    //handleSetRefresh: setStartRefresh,
    handleErrorRead: null,
    handleErrorChange: null,
    models: [
      {
        model_call_nm: "Model_read",
        tableName: "Managers",
        action: {
          metod: "getInfo",
          date: SelectedDate,
        },
      },
    ],
  };

  const paramsFilter = {
    URL_WS: getURLAPI(auth.config, "URL_WS"), //get address WebSocket BK from config
    auth: { userId: auth.userId, tocken: auth.token },
    handleGetData: GetData_handle,
    handleChangeData: Refresh_handle /* () => {
      setStartRefreshFilter(true)
    },*/,
    //Refresh_handle,
    //handleSetRefresh: setStartRefresh,
    handleErrorRead: null,
    handleErrorChange: null,
    models: [
      {
        model_call_nm: "Model_read",
        tableName: "Supervisors",
        action: {
          metod: "get",
        },
      },
    ],
  };

  const paramsMaps = {
    URL_WS: getURLAPI(auth.config, "URL_WS"), //get address WebSocket BK from config
    auth: { userId: auth.userId, tocken: auth.token },
    handleGetData: GetData_handle,
    handleChangeData: Refresh_handle,
    /*() => {
      setstartRefreshChats(true)
    },*/
    // Refresh_handle,
    //handleSetRefresh: setStartRefresh,
    handleErrorRead: null,
    handleErrorChange: null,
    models: [
      {
        model_call_nm: "Model_read",
        tableName: "Chats",
        action: {
          metod: "get",
          //customerid: customer2 && customer2.value, // formState.client.id,
          //userid: auth.userId,
        },
      },
    ],
  };

  const paramsFotos = {
    URL_WS: getURLAPI(auth.config, "URL_WS"), //get address WebSocket BK from config
    auth: { userId: auth.userId, tocken: auth.token },
    handleGetData: GetData_handle,
    handleChangeData: Refresh_handle,
    /*() => {
      setstartRefreshChats(true)
    },*/
    // Refresh_handle,
    //handleSetRefresh: setStartRefresh,
    handleErrorRead: null,
    handleErrorChange: null,
    models: [
      {
        model_call_nm: "Model_read",
        tableName: "FileFotos",
        action: {
          metod: "getbyFiler",
          date: SelectedDate,
          managerid: SelectedManager && SelectedManager.id,
          storeid: SelectedStore && SelectedStore.id,
        },
      },
    ],
  };

  const paramsOrders = {
    URL_WS: getURLAPI(auth.config, "URL_WS"), //get address WebSocket BK from config
    auth: { userId: auth.userId, tocken: auth.token },
    handleGetData: GetData_handle,
    handleChangeData: Refresh_handle,
    /* () => {
      setstartRefreshTermins(true)
    },*/
    //Refresh_handle,
    //handleSetRefresh: setStartRefresh,
    handleErrorRead: null,
    handleErrorChange: null,
    models: [
      {
        model_call_nm: "Model_read",
        tableName: "Orders",
        action: {
          metod: "getbyFiler",
          date: SelectedDate,
          managerid: SelectedManager && SelectedManager.id,
          storeid: SelectedStore && SelectedStore.id,
        },
      },
    ],
  };

  const paramsReturns = {
    URL_WS: getURLAPI(auth.config, "URL_WS"), //get address WebSocket BK from config
    auth: { userId: auth.userId, tocken: auth.token },
    handleGetData: GetData_handle,
    handleChangeData: Refresh_handle,
    /* () => {
      setstartRefreshTermins(true)
    },*/
    //Refresh_handle,
    //handleSetRefresh: setStartRefresh,
    handleErrorRead: null,
    handleErrorChange: null,
    models: [
      {
        model_call_nm: "Model_read",
        tableName: "Returns",
        action: {
          metod: "getbyFiler",
          date: SelectedDate,
          managerid: SelectedManager && SelectedManager.id,
          storeid: SelectedStore && SelectedStore.id,
        },
      },
    ],
  };

  const paramsDelivery = {
    URL_WS: getURLAPI(auth.config, "URL_WS"), //get address WebSocket BK from config
    auth: { userId: auth.userId, tocken: auth.token },
    handleGetData: GetData_handle,
    handleChangeData: Refresh_handle,
    /* () => {
      setstartRefreshTermins(true)
    },*/
    //Refresh_handle,
    //handleSetRefresh: setStartRefresh,
    handleErrorRead: null,
    handleErrorChange: null,
    models: [
      {
        model_call_nm: "Model_read",
        tableName: "Invoices",
        action: {
          metod: "getDelivery",
          date: SelectedDate,
          managerid: SelectedManager && SelectedManager.id,
        },
      },
    ],
  };

  const paramsInvoices = {
    URL_WS: getURLAPI(auth.config, "URL_WS"), //get address WebSocket BK from config
    auth: { userId: auth.userId, tocken: auth.token },
    handleGetData: GetData_handle,
    handleChangeData: Refresh_handle,
    /* () => {
      setstartRefreshTermins(true)
    },*/
    //Refresh_handle,
    //handleSetRefresh: setStartRefresh,
    handleErrorRead: null,
    handleErrorChange: null,
    models: [
      {
        model_call_nm: "Model_read",
        tableName: "Invoices",
        action: {
          metod: "getInvoices",
          date: SelectedDate,
          managerid: SelectedManager && SelectedManager.id,
          storeid: SelectedStore && SelectedStore.id,
        },
      },
    ],
  };

  const paramsSalesplans = {
    URL_WS: getURLAPI(auth.config, "URL_WS"), //get address WebSocket BK from config
    auth: { userId: auth.userId, tocken: auth.token },
    handleGetData: GetData_handle,
    handleChangeData: Refresh_handle,
    /* () => {
      setstartRefreshTermins(true)
    },*/
    //Refresh_handle,
    //handleSetRefresh: setStartRefresh,
    handleErrorRead: null,
    handleErrorChange: null,
    models: [
      {
        model_call_nm: "Model_read",
        tableName: "Salesplans",
        action: {
          metod: "getbyFiler",
          date: SelectedDate,
          managerid: SelectedManager && SelectedManager.id,
        },
      },
    ],
  };

  const paramsManagerTasks = {
    URL_WS: getURLAPI(auth.config, "URL_WS"), //get address WebSocket BK from config
    auth: { userId: auth.userId, tocken: auth.token },
    handleGetData: GetData_handle,
    handleChangeData: Refresh_handle,
    /* () => {
      setstartRefreshTermins(true)
    },*/
    //Refresh_handle,
    //handleSetRefresh: setStartRefresh,
    handleErrorRead: null,
    handleErrorChange: null,
    models: [
      {
        model_call_nm: "Model_read",
        tableName: "ManagerTasks",
        action: {
          metod: "getbyFiler",
          date: SelectedDate,
          managerid: SelectedManager && SelectedManager.id,
          storeid: SelectedStore && SelectedStore.id,
        },
      },
    ],
  };

  const paramsPayments = {
    URL_WS: getURLAPI(auth.config, "URL_WS"), //get address WebSocket BK from config
    auth: { userId: auth.userId, tocken: auth.token },
    handleGetData: GetData_handle,
    handleChangeData: Refresh_handle,
    //handleSetRefresh: setStartRefresh,
    handleErrorRead: null,
    handleErrorChange: null,
    models: [
      {
        model_call_nm: "Model_read",
        tableName: "Payments",
        where: {},
        action: {
          metod: "getbyFiler",
          date: SelectedDate,
          managerid: SelectedManager && SelectedManager.id,
          storeid: SelectedStore && SelectedStore.id,
        },
      },
    ],
  };

  const paramsDebts = {
    URL_WS: getURLAPI(auth.config, "URL_WS"), //get address WebSocket BK from config
    auth: { userId: auth.userId, tocken: auth.token },
    handleGetData: GetData_handle,
    handleChangeData: Refresh_handle,
    //handleSetRefresh: setStartRefresh,
    handleErrorRead: null,
    handleErrorChange: null,
    models: [
      {
        model_call_nm: "Model_read",
        tableName: "Debts",
        where: {},
        action: {
          metod: "getbyFiler",
          date: SelectedDate,
          managerid: SelectedManager && SelectedManager.id,
          storeid: SelectedStore && SelectedStore.id,
        },
      },
    ],
  };

  //let getdate = null; //formState.tab === "2" ? weekDate : dayDate;
  //let getdate1 = moment(getdate).add(-6, "days");
  //let getdate2 = moment(getdate).add(6, "days");

  const paramsRouteSheets = {
    URL_WS: getURLAPI(auth.config, "URL_WS"), //get address WebSocket BK from config
    auth: { userId: auth.userId, tocken: auth.token },
    handleGetData: GetData_handle,
    handleChangeData: Refresh_handle,
    //handleSetRefresh: setStartRefresh,
    handleErrorRead: null,
    handleErrorChange: null,
    models: [
      {
        model_call_nm: "Model_read",
        tableName: "RouteSheets",
        where: {},
        action: {
          metod: "getForManager",
          date: SelectedDate,
          managerid: SelectedManager && SelectedManager.id,
        },
      },
    ],
  };

  //deblock item whet close page
  useEffect(
    () => () => {
      try {
        if (!readonly)
          ws_client &&
            ws_client.deblock({ tableName: "Customers", id: item_id });
      } catch (err) {
        console.log("deblock error ", err);
      }
    },
    []
  );

  if (ws_client && ws_client.ws_login && startRefreshManagers) {
    setStartRefreshManagers(false);
    ws_client.open_process(paramsManagers);
  }

  if (ws_client && ws_client.ws_login && startRefreshFilter) {
    setStartRefreshFilter(false);
    ws_client.open_process(paramsFilter);
  }

  if (ws_client && ws_client.ws_login && startRefreshMaps) {
    setstartRefreshMaps(false);
    ws_client.open_process(paramsMaps);
  }

  if (
    SelectedManager &&
    SelectedManager.id &&
    SelectedStore &&
    SelectedStore.id &&
    ws_client &&
    ws_client.ws_login &&
    startRefreshFotos
  ) {
    setstartRefreshFotos(false);
    ws_client.open_process(paramsFotos);
  }

  if (
    SelectedManager &&
    SelectedManager.id &&
    SelectedStore &&
    SelectedStore.id &&
    ws_client &&
    ws_client.ws_login &&
    startRefreshOrders
  ) {
    setstartRefreshOrders(false);
    ws_client.open_process(paramsOrders);
  }

  if (
    SelectedManager &&
    SelectedManager.id &&
    //SelectedStore &&
    //SelectedStore.id &&
    ws_client &&
    ws_client.ws_login &&
    startRefreshReturns
  ) {
    setstartRefreshReturns(false);
    ws_client.open_process(paramsReturns);
  }

  if (
    SelectedManager &&
    SelectedManager.id &&
    ws_client &&
    ws_client.ws_login &&
    startRefreshDeliverys
  ) {
    setstartRefreshDeliverys(false);
    ws_client.open_process(paramsDelivery);
  }

  if (
    SelectedManager &&
    SelectedManager.id &&
    SelectedStore &&
    SelectedStore.id &&
    ws_client &&
    ws_client.ws_login &&
    startRefreshInvoices
  ) {
    setstartRefreshInvoices(false);
    ws_client.open_process(paramsInvoices);
  }

  if (
    SelectedManager &&
    SelectedManager.id &&
    ws_client &&
    ws_client.ws_login &&
    startRefreshSalesplans
  ) {
    setstartRefreshSalesplans(false);
    ws_client.open_process(paramsSalesplans);
  }

  if (
    SelectedManager &&
    SelectedManager.id &&
    ws_client &&
    ws_client.ws_login &&
    startRefreshManagerTasks
  ) {
    setstartRefreshManagerTasks(false);
    ws_client.open_process(paramsManagerTasks);
  }

  if (
    SelectedManager &&
    SelectedManager.id &&
    SelectedStore &&
    SelectedStore.id &&
    ws_client &&
    ws_client.ws_login &&
    startRefreshPayments
  ) {
    setstartRefreshPayments(false);
    ws_client.open_process(paramsPayments);
  }

  if (
    SelectedManager &&
    SelectedManager.id &&
    SelectedStore &&
    SelectedStore.id &&
    ws_client &&
    ws_client.ws_login &&
    startRefreshDebts
  ) {
    setstartRefreshDebts(false);
    ws_client.open_process(paramsDebts);
  }

  if (
    SelectedManager &&
    SelectedManager.id &&
    ws_client &&
    ws_client.ws_login &&
    startRefreshRouteSheets
  ) {
    setStartRefreshRouteSheets(false);
    ws_client.open_process(paramsRouteSheets);
  }

  //END get all str from table Roles

  //***************************************/

  columns = [
    /*    {
      Header: "№",
      accessor: "num",
    },
    */
    {
      Header: translate("resources.manager.fields.name"), //'Тип работ',
      accessor: "name",
    },
    {
      Header: translate("resources.manager.fields.summorder_order"), //"Суммы: Заказов/ Платежей", //translate("resources.manager.fields.ordersumma"), //'Тип работ',
      accessor: "summa",
    },
  ];

  data = [];

  columns1 = [
    {
      Header: "№",
      accessor: "num",
    },
    {
      Header: translate("resources.store.fields.name"), //'Тип работ',
      accessor: "name",
    },
    {
      Header: translate("resources.store.fields.info"), //'Тип работ',
      accessor: "info",
    },
  ];

  columns2 = [
    {
      Header: "№",
      accessor: "num",
    },

    {
      Header: translate("resources.debts.fields.numnakl"), //'Тип работ',
      accessor: "numnakl",
    },
    {
      Header: translate("resources.debts.fields.datenakl"), //'Тип работ',
      accessor: "datenakl",
    },
    {
      Header: translate("resources.order.fields.formpay"), //'Тип работ',
      accessor: "formpay",
    },
    {
      Header: translate("resources.order.fields.vidpay"), //'Тип работ',
      accessor: "vidpay",
    },
    /*    {
      Header: translate("resources.debts.fields.storename"), //'Тип работ',
      accessor: "storename",
    },*/
    {
      Header: translate("resources.debts.fields.dolgsumma"), //'Тип работ',
      accessor: "dolgsumma",
    },
    {
      Header: translate("resources.debts.fields.delaydolgsumma"), //'Тип работ',
      accessor: "delaydolgsumma",
    },
    {
      Header: translate("resources.debts.fields.delaydays"), //'Тип работ',
      accessor: "delaydays",
    },
  ];

  data2 = [];

  columns3 = [
    {
      Header: "№",
      accessor: "num",
    },

    {
      Header: translate("resources.payments.fields.numnakl"), //'Тип работ',
      accessor: "numnakl",
    },
    {
      Header: translate("resources.payments.fields.datenakl"), //'Тип работ',
      accessor: "datenakl",
    },
    {
      Header: translate("resources.payments.fields.datepay"), //'Тип работ',
      accessor: "datepay",
    },
    {
      Header: translate("resources.payments.fields.paysumma"), //'Тип работ',
      accessor: "paysumma",
    },
  ];

  data3 = [];

  columns4 = [
    {
      Header: "№",
      accessor: "num",
    },
    {
      Header: translate("resources.order.fields.date"), //'Тип работ',
      accessor: "date",
    },
    {
      Header: translate("resources.order.fields.manager"), //'Тип работ',
      accessor: "manager",
    },
    /*    {
      Header: translate("resources.order.fields.store"), //'Тип работ',
      accessor: "store",
    },*/
    {
      Header: translate("resources.order.fields.summa"), //'Тип работ',
      accessor: "summa",
    },
    {
      Header: translate("resources.order.fields.formpay"), //'Тип работ',
      accessor: "formpay",
    },
    {
      Header: translate("resources.order.fields.vidpay"), //'Тип работ',
      accessor: "vidpay",
    },
    {
      Header: translate("resources.order.fields.typeprice"), //'Тип работ',
      accessor: "typeprice",
    },
  ];

  data4 = [];

  columns5 = [
    {
      Header: "№",
      accessor: "num",
    },
    {
      Header: translate("resources.order.fields.tov"), //'Тип работ',
      accessor: "tov",
    },
    {
      Header: translate("resources.order.fields.tovunit"), //'Тип работ',
      accessor: "tovunit",
    },
    {
      Header: translate("resources.order.fields.price"), //'Тип работ',
      accessor: "price",
    },
    {
      Header: translate("resources.order.fields.qty"), //'Тип работ',
      accessor: "qty",
    },
    {
      Header: translate("resources.order.fields.summa"), //'Тип работ',
      accessor: "summa",
    },
  ];

  columns6 = [
    {
      Header: "№",
      accessor: "num",
    },
    {
      Header: translate("resources.returns.fields.store"), //'Тип работ',
      accessor: "storenm",
    },
    {
      Header: translate("resources.returns.fields.tov"), //'Тип работ',
      accessor: "tov",
    },
    {
      Header: translate("resources.returns.fields.tovunit"), //'Тип работ',
      accessor: "tovunit",
    },
    {
      Header: translate("resources.returns.fields.qty"), //'Тип работ',
      accessor: "qty",
    },
    {
      Header: translate("resources.returns.fields.sellqty"), //'Тип работ',
      accessor: "sellqty",
    },
  ];

  data6 = [];

  columns7 = [
    {
      Header: "№",
      accessor: "num",
    },
    {
      Header: translate("resources.order.fields.date"), //'Тип работ',
      accessor: "date",
    },
    {
      Header: translate("resources.order.fields.store"), //'Тип работ',
      accessor: "storenm",
    },
    {
      Header: translate("resources.order.fields.driver"), //'Тип работ',
      accessor: "driver",
    },
    /*    {
      Header: translate("resources.order.fields.store"), //'Тип работ',
      accessor: "store",
    },*/
    {
      Header: translate("resources.order.fields.summa"), //'Тип работ',
      accessor: "summa",
    },
    {
      Header: translate("resources.order.fields.formpay"), //'Тип работ',
      accessor: "formpay",
    },
    {
      Header: translate("resources.order.fields.vidpay"), //'Тип работ',
      accessor: "vidpay",
    },
    {
      Header: translate("resources.order.fields.typeprice"), //'Тип работ',
      accessor: "typeprice",
    },
  ];

  data7 = [];

  columns8 = [
    {
      Header: "№",
      accessor: "num",
    },
    {
      Header: translate("resources.order.fields.doctype"), //'Тип работ',
      accessor: "doctype",
    },

    {
      Header: translate("resources.order.fields.date"), //'Тип работ',
      accessor: "date",
    },
    {
      Header: translate("resources.order.fields.manager"), //'Тип работ',
      accessor: "manager",
    },
    /*    {
      Header: translate("resources.order.fields.store"), //'Тип работ',
      accessor: "store",
    },*/
    {
      Header: translate("resources.order.fields.summa"), //'Тип работ',
      accessor: "summa",
    },
    {
      Header: translate("resources.order.fields.formpay"), //'Тип работ',
      accessor: "formpay",
    },
    {
      Header: translate("resources.order.fields.vidpay"), //'Тип работ',
      accessor: "vidpay",
    },
    {
      Header: translate("resources.order.fields.typeprice"), //'Тип работ',
      accessor: "typeprice",
    },
  ];

  data8 = [];

  columns9 = [
    {
      Header: "№",
      accessor: "num",
    },
    {
      Header: translate("resources.salesplan.fields.category"), //'Тип работ',
      accessor: "category",
    },
    {
      Header: translate("resources.salesplan.fields.plan"), //'Тип работ',
      accessor: "plan",
    },
    {
      Header: translate("resources.salesplan.fields.sale"), //'Тип работ',
      accessor: "sale",
    },
    {
      Header: translate("resources.salesplan.fields.procent"), //'Тип работ',
      accessor: "procent",
    },
  ];

  columns10 = [
    {
      Header: "№",
      accessor: "num",
    },
    {
      Header: translate("resources.managertask.fields.date"), //'Тип работ',
      accessor: "date",
    },
    {
      Header: translate("resources.managertask.fields.store"), //'Тип работ',
      accessor: "store",
    },
    {
      Header: translate("resources.managertask.fields.name"), //'Тип работ',
      accessor: "name",
    },
    {
      Header: translate("resources.managertask.fields.description"), //'Тип работ',
      accessor: "description",
    },
    {
      Header: translate("resources.managertask.fields.finished"), //'Тип работ',
      accessor: "isfinished",
    },
  ];

  data10 = [];

  let getCustomerName = (Item) => {
    let customername =
      (Item &&
        Item.itemcustomers &&
        Item.itemcustomers.items &&
        Item.itemcustomers.items.name) ||
      "???";
    return "  (" + customername + ")";
  };

  let num4 = 1;
  let orders = body.orders;

  const searchmanager = (id) => {
    if (SelectedManager.id === id) {
      return true;
    }
    return false;
  };

  /*
  if (SelectedManager && orders && orders.length > 0) {
    orders = orders.filter((item) => {
      //      console.log('item', item)
      //      console.log('item.name', item.name)
      return searchmanager(item.managerid);
    });
  }
*/
  let SommaOpAll = 0;
  let summ;
  for (var iw in orders) {
    summ = orders[iw].summa.replaceAll(" ", "");
    summ = summ.replaceAll(",", ".");
    SommaOpAll = SommaOpAll + Number(summ);
    data4.push({
      num: num4++,
      date: moment(orders[iw].date).format("DD.MM.YYYY"),
      summa: orders[iw].summa,
      formpay: orders[iw].formpays && orders[iw].formpays.name,
      vidpay: orders[iw].vidpays && orders[iw].vidpays.name,
      typeprice: orders[iw].typeprices && orders[iw].typeprices.name,
      store: orders[iw].stores && orders[iw].stores.name,
      manager: orders[iw].managers && orders[iw].managers.name,
      order: orders[iw],
    });
  }
  if (data4.length > 0)
    data4.push({
      summa: SommaOpAll.toFixed(2),
      manager: "Итого: ",
    });

  let num5 = 1;
  let items = SelectedOrder && SelectedOrder.items;
  data5 = [];
  for (var iw5 in items) {
    data5.push({
      num: num5++,
      tov: items[iw5].tovs && items[iw5].tovs.name,
      tovunit: items[iw5].tovunits && items[iw5].tovunits.name,
      qty: items[iw5].qty,
      price: items[iw5].price,
      summa: items[iw5].summa,
      items: items[iw5],
    });
  }
  //

  let num5a = 1;
  let itemsa = SelectedDelivery && SelectedDelivery.items;
  data5a = [];
  for (var iw5a in itemsa) {
    data5a.push({
      num: num5a++,
      tov: itemsa[iw5a].tovs && itemsa[iw5a].tovs.name,
      tovunit: itemsa[iw5a].tovunits && itemsa[iw5a].tovunits.name,
      qty: itemsa[iw5a].qty,
      price: itemsa[iw5a].price,
      summa: itemsa[iw5a].summa,
      items: itemsa[iw5a],
    });
  }

  let num5b = 1;
  let itemsb = SelectedInvoice && SelectedInvoice.items;
  data5b = [];
  for (var iw5b in itemsb) {
    data5b.push({
      num: num5b++,
      tov: itemsb[iw5b].tovs && itemsb[iw5b].tovs.name,
      tovunit: itemsb[iw5b].tovunits && itemsb[iw5b].tovunits.name,
      qty: itemsb[iw5b].qty,
      price: itemsb[iw5b].price,
      summa: itemsb[iw5b].summa,
      items: itemsb[iw5b],
    });
  }

  let num6 = 1;
  let returns = body.returns;

  data6 = [];
  for (var iw6 in returns) {
    data6.push({
      num: num6++,
      storenm:
        returns[iw6].stores &&
        returns[iw6].stores.name + getCustomerName(returns[iw6].stores),
      tov: returns[iw6].tovs && returns[iw6].tovs.name,
      tovunit: returns[iw6].tovunits && returns[iw6].tovunits.name,
      qty: returns[iw6].qty,
      sellqty: returns[iw6].sellqty,
      items: returns[iw6],
    });
  }

  let num7 = 1;
  let delivery = body.delivery;

  data7 = [];
  for (var iw7 in delivery) {
    data7.push({
      num: num7++,
      date: moment(delivery[iw7].date).format("DD.MM.YYYY"),
      storenm:
        delivery[iw7].stores &&
        delivery[iw7].stores.name + getCustomerName(delivery[iw7].stores),
      driver: delivery[iw7].driver,
      summa: delivery[iw7].summa,
      formpay: delivery[iw7].formpays && delivery[iw7].formpays.name,
      vidpay: delivery[iw7].vidpays && delivery[iw7].vidpays.name,
      typeprice: delivery[iw7].typeprices && delivery[iw7].typeprices.name,
      store: delivery[iw7].stores && delivery[iw7].stores.name,
      manager: delivery[iw7].managers && delivery[iw7].managers.name,
      order: delivery[iw7],
    });
  }

  let num8 = 1;
  let invoices = body.invoices;

  data8 = [];
  for (var iw8 in invoices) {
    if (!invoices[iw8].doctypeid) invoices[iw8].doctypeid = "1";
    data8.push({
      num: num8++,
      doctype:
        invoices[iw8].doctypeid === "1"
          ? translate("resources.home.invoice")
          : translate("resources.home.return"),
      date: moment(invoices[iw8].date).format("DD.MM.YYYY"),
      storenm:
        invoices[iw8].stores &&
        invoices[iw8].stores.name +
          getCustomerName(invoices[iw8].stores.stores),
      driver: invoices[iw8].driver,
      summa:
        invoices[iw8].doctypeid === "1"
          ? invoices[iw8].summa
          : "-" + invoices[iw8].summa,
      formpay: invoices[iw8].formpays && invoices[iw8].formpays.name,
      vidpay: invoices[iw8].vidpays && invoices[iw8].vidpays.name,
      typeprice: invoices[iw8].typeprices && invoices[iw8].typeprices.name,
      store: invoices[iw8].stores && invoices[iw8].stores.name,
      manager: invoices[iw8].managers && invoices[iw8].managers.name,
      item: invoices[iw8],
    });
  }

  let num9 = 1;
  let salesplans = body.salesplans;

  data9 = [];
  if (SelectedManager && SelectedManager.id)
    for (var iw9 in salesplans) {
      data9.push({
        num: num9++,
        category: salesplans[iw9].categories?.name,
        plan: salesplans[iw9].saleweight,
        sale: Number.parseFloat(salesplans[iw9].sales).toFixed(2), //salesplans[iw9].sale,
        procent:
          Number.parseFloat(
            100 * (salesplans[iw9].sales / salesplans[iw9].saleweight)
          ).toFixed(2) + " %", //salesplans[iw9].procent,
        item: salesplans[iw9],
      });
    }

  let num10 = 1;
  let managertasks = body.managertasks;

  data10 = [];
  for (var iw10 in managertasks) {
    data10.push({
      num: num10++,
      date: moment(managertasks[iw10].date).format("DD.MM.YYYY"),
      store:
        managertasks[iw10].stores &&
        managertasks[iw10].stores.name +
          getCustomerName(managertasks[iw10].stores),
      manager: managertasks[iw10].managers && managertasks[iw10].managers.name,
      name: managertasks[iw10].name,
      description: managertasks[iw10].description,
      isfinished: managertasks[iw10].isfinished,
      item: managertasks[iw10],
    });
  }

  let Getlog = body.getlog;
  AllPosition = useMemo(() => {
    let _AllPosition = [];
    x = 0;
    y = 0;
    n = 0;
    for (let g in Getlog) {
      n++;
      x = x + Getlog[g].x;
      y = y + Getlog[g].y;
      _AllPosition.push([
        Getlog[g].x, //50.557668,
        Getlog[g].y, //30.255422
      ]);
    }
    if (n > 0) {
      x = x / n;
      y = y / n;
      startposition = [x, y];
    }
    return _AllPosition;
  }, [Getlog]);

  function chunkArray(array, chunk) {
    const newArray = [];
    for (let i = 0; i < array.length; i += chunk) {
      newArray.push(array.slice(i, i + chunk));
    }
    return newArray;
  }

  let FileFotos = body.filefotos;
  let allfilefotos = [];
  let filefotos = [];
  if (FileFotos) {
    for (let f in FileFotos) {
      let items = FileFotos[f];
      allfilefotos.push({
        id: items.id,
        times: moment(items.datetimes, "YYYY-MM-DD HH:mm").format("HH:mm"),
        comments: items.comments,
        longitude: items.longitude,
        latitude: items.latitude,
      });
    }
    filefotos = chunkArray(allfilefotos, 2);
  }

  function getNumFoto(str) {
    let i = "0";
    for (let ii in allfilefotos) {
      if (allfilefotos[ii].id === str.id) i = ii;
    }
    return i;
  }

  const arrayfindid = (array, value) => {
    const found = array.find((element) => element.id === value);
    return found;
  };

  let inforoutesheets = body.routesheets;
  let Routesheets = inforoutesheets && inforoutesheets.routesheets; //body.routesheets;
  let AddStores = inforoutesheets && inforoutesheets.addstores; //body.routesheets;
  let Infostores = inforoutesheets && inforoutesheets.infostores; //body.routesheets;

  let routesheets = useMemo(() => {
    let _routesheets = [];
    if (Routesheets && Routesheets[0] && Routesheets[0].items) {
      let items = Routesheets[0].items;
      for (let r in items) {
        _routesheets.push({
          id: items[r].storeid,
          num: items[r].num,
          name: items[r].stores.name,
          longitude: items[r].stores.longitude,
          latitude: items[r].stores.latitude,
          addstore: false,
          sumpays: 0,
          sumorders: 0,
          dolgsumma: 0,
          delaydolgsumma: 0,
          customer:
            items[r].stores.itemcustomers &&
            items[r].stores.itemcustomers.items,
        });
      }
    }
    if (AddStores && AddStores.length > 0) {
      for (let a in AddStores) {
        let isExists = _routesheets.find((item) => {
          return item.id === AddStores[a].id;
        });
        if (!isExists)
          _routesheets.push({
            id: AddStores[a].id,
            num: "",
            name: AddStores[a].name,
            longitude: AddStores[a].longitude,
            latitude: AddStores[a].latitude,
            addstore: true,
            sumpays: 0,
            sumorders: 0,
            dolgsumma: 0,
            delaydolgsumma: 0,
            customer:
              AddStores[a].itemcustomers && AddStores[a].itemcustomers.items,
          });
      }
    }

    let row;

    for (let r2 in _routesheets) {
      row = arrayfindid(Infostores, _routesheets[r2].id);
      if (row) {
        _routesheets[r2].sumpays = row.sumpays.toFixed(2);
        _routesheets[r2].sumorders = row.sumorders.toFixed(2);
        _routesheets[r2].dolgsumma = row.dolgsumma.toFixed(2);
        _routesheets[r2].delaydolgsumma = row.delaydolgsumma.toFixed(2);
      }
    }

    return _routesheets;
  }, [Routesheets, AddStores]);

  data1 = useMemo(() => {
    let num1 = 1;
    let _data1 = [];
    for (var iw1 in routesheets) {
      _data1.push({
        num: routesheets[iw1].num, //num1++,
        name:
          routesheets[iw1].name +
          (routesheets[iw1].customer &&
            "  (" + routesheets[iw1].customer.name + ")"),
        info:
          routesheets[iw1].sumorders +
          " / " +
          routesheets[iw1].sumpays +
          " / " +
          routesheets[iw1].dolgsumma +
          " / " +
          routesheets[iw1].delaydolgsumma,
        id: routesheets[iw1].id,

        store: routesheets[iw1],
      });
    }

    return _data1;
  }, [routesheets]);

  let num = 1;

  for (var iw in managers) {
    data.push({
      num: num++,
      name: managers[iw].name,
      summa:
        ((managers[iw].OrderSumma && managers[iw].OrderSumma.toFixed(2)) || 0) +
        "/ " +
        //        String(managers[iw].Prosrochka || 0) +
        //        "/ " +
        String(
          (managers[iw].Payments && managers[iw].Payments.toFixed(2)) || 0
        ),
      supervisor:
        managers[iw].itemsupervisors &&
        managers[iw].itemsupervisors.items &&
        managers[iw].itemsupervisors.items.name,
      manager: managers[iw],
    });
  }
  const AllSupervisors = body.supervisors;

  let supervisors = useMemo(() => {
    let _supervisors = [];
    let Supervisors = [];
    let SupervisorsID = [];
    if (isadmin || allsupervisors) {
      Supervisors = AllSupervisors;
    } else {
      if (UserSupervisors) {
        Supervisors = [];
        SupervisorsID = [];
        for (let ii in UserSupervisors) {
          Supervisors.push(UserSupervisors[ii].supervisor);
          SupervisorsID.push(UserSupervisors[ii].supervisor.id);
        }
      }

      //    usersupervisors && usersupervisors.length;
    }

    for (var iw2 in Supervisors) {
      _supervisors.push({
        label: Supervisors[iw2].name,
        value: Supervisors[iw2].id,
      });
    }
    return _supervisors;
  }, [AllSupervisors]);

  let summaDemtsAllDolg = 0;
  let summaDemtsAllDolgdelay = 0;

  let num2 = 1;
  let Debts = body.debts || null;
  if (Debts && Debts.length > 0) {
    for (let d in Debts) {
      summaDemtsAllDolg = summaDemtsAllDolg + Number(Debts[d].dolgsumma);
      summaDemtsAllDolgdelay =
        summaDemtsAllDolgdelay + Number(Debts[d].delaydolgsumma);
      data2.push({
        num: num2++,
        numnakl: Debts[d].numnakl,
        datenakl: moment(Debts[d].datenakl, "YYYY-MM-DD").format("DD.MM.YYYY"),
        storename: Debts[d].stores && Debts[d].stores.name,
        dolgsumma: Debts[d].dolgsumma,
        delaydays: Debts[d].delaydays,
        delaydolgsumma: Debts[d].delaydolgsumma,
        formpay: Debts[d].formpays && Debts[d].formpays.name,
        vidpay: Debts[d].vidpays && Debts[d].vidpays.name,
      });
    }
    data2.push({
      datenakl: "Итого:",
      dolgsumma: summaDemtsAllDolg.toFixed(2),
      delaydolgsumma: summaDemtsAllDolgdelay.toFixed(2),
    });
  }

  let Payments = body.payments || null;

  let SummaAll = 0;
  let num3 = 0;
  if (Payments && Payments.length > 0) {
    for (let p in Payments) {
      let items = Payments[p];
      num3++;
      SummaAll = SummaAll + Number(items.paysumma);
      data3.push({
        num: num3,
        numnakl: items.numnakl,
        datenakl: moment(items.datenakl, "YYYY-MM-DD").format("DD.MM.YYYY"),
        datepay: moment(items.datepay, "YYYY-MM-DD").format("DD.MM.YYYY"),
        storename: items.stores && items.stores.name,
        paysumma: items.paysumma,
      });
    }
    data3.push({
      numnakl: "",
      datenakl: "Итого: ",
      storename: " ",
      paysumma: SummaAll.toFixed(2),
    });
  }

  //          body.managers.map((Item) => <ItemList Item={Item} />)}
  function Table({
    columns,
    data,
    getHeaderProps = defaultPropGetter,
    getColumnProps = defaultPropGetter,
    getRowProps = defaultPropGetter,
    getCellProps = defaultPropGetter,
  }) {
    // Use the state and functions returned from useTable to build your UI
    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
      useTable({
        columns,
        data,
      });

    return (
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  // Return an array of prop objects and react-table will merge them appropriately
                  {...column.getHeaderProps([
                    {
                      className: column.className,
                      style: column.style,
                    },
                    getColumnProps(column),

                    getHeaderProps(column),
                  ])}
                >
                  {column.render("Header")}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              // Merge user row props in
              <tr {...row.getRowProps(getRowProps(row))}>
                {row.cells.map((cell) => {
                  return (
                    <td
                      // Return an array of prop objects and react-table will merge them appropriately
                      {...cell.getCellProps([
                        {
                          className: cell.column.className,
                          style: cell.column.style,
                        },
                        getColumnProps(cell.column),
                        //  getCellProps(cell),
                      ])}
                      align="center"
                      //                    width="140px"
                    >
                      {(cell.column.id !== "timeline" && (
                        <Button
                          //as={Link}
                          variant="outline-primary"
                          style={{
                            backgroundColor:
                              cell.row.original.manager.id ===
                              (SelectedManager && SelectedManager.id)
                                ? "#00ff2369"
                                : "",
                          }}
                          //style={{ marginBottom: 5 }}

                          //to={{
                          //  pathname: "/pages/manager/item",
                          //}}
                          //state={{
                          //  id: cell.row.original.manager.id, //cell.row.original['worktime_id'],
                          //}}
                          onClick={() => {
                            handleSelectManager({
                              id: cell.row.original.manager.id,
                              name: cell.row.original.manager.name,
                            });
                            /*
                            dispatch({
                              type: "fetch/get", //_add',
                              payload: cell.row.original.manager.id,
                              ob: "item_id",
                            });
                            */
                          }}
                        >
                          {cell.render("Cell")}
                        </Button>
                      )) ||
                        cell.render("Cell")}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  }

  function Table1({
    columns,
    data,
    getHeaderProps = defaultPropGetter,
    getColumnProps = defaultPropGetter,
    getRowProps = defaultPropGetter,
    getCellProps = defaultPropGetter,
  }) {
    // Use the state and functions returned from useTable to build your UI
    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
      useTable({
        columns,
        data,
      });

    return (
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  // Return an array of prop objects and react-table will merge them appropriately
                  {...column.getHeaderProps([
                    {
                      className: column.className,
                      style: column.style,
                    },
                    getColumnProps(column),

                    getHeaderProps(column),
                  ])}
                >
                  {column.render("Header")}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              // Merge user row props in
              <tr {...row.getRowProps(getRowProps(row))}>
                {row.cells.map((cell) => {
                  return (
                    <td
                      // Return an array of prop objects and react-table will merge them appropriately
                      {...cell.getCellProps([
                        {
                          className: cell.column.className,
                          style: cell.column.style,
                        },
                        getColumnProps(cell.column),
                        //  getCellProps(cell),
                      ])}
                      align="center"
                      //                    width="140px"
                    >
                      {(cell.column.id !== "num" && (
                        <Button
                          //as={Link}
                          variant="outline-primary"
                          style={{
                            backgroundColor:
                              cell.row.original.store.id ===
                              (SelectedStore && SelectedStore.id)
                                ? "#00ff2369"
                                : "",
                          }}
                          //to={{
                          //  pathname: "/pages/manager/item",
                          //}}
                          //state={{
                          //  id: cell.row.original.manager.id, //cell.row.original['worktime_id'],
                          //}}
                          onClick={() => {
                            handleSelectedStore({
                              id: cell.row.original.store.id,
                              name: cell.row.original.store.name,
                              longitude: cell.row.original.store.longitude,
                              latitude: cell.row.original.store.latitude,
                              customer: cell.row.original.store.customer,
                            });
                            /*
                            dispatch({
                              type: "fetch/get", //_add',
                              payload: cell.row.original.manager.id,
                              ob: "item_id",
                            });
                            */
                          }}
                        >
                          {cell.render("Cell")}
                        </Button>
                      )) ||
                        cell.render("Cell")}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  }

  //Заказы
  function Table_O({
    columns,
    data,
    getHeaderProps = defaultPropGetter,
    getColumnProps = defaultPropGetter,
    getRowProps = defaultPropGetter,
    getCellProps = defaultPropGetter,
  }) {
    // Use the state and functions returned from useTable to build your UI
    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
      useTable({
        columns,
        data,
      });

    return (
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  // Return an array of prop objects and react-table will merge them appropriately
                  {...column.getHeaderProps([
                    {
                      className: column.className,
                      style: column.style,
                    },
                    getColumnProps(column),

                    getHeaderProps(column),
                  ])}
                >
                  {column.render("Header")}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              // Merge user row props in
              <tr {...row.getRowProps(getRowProps(row))}>
                {row.cells.map((cell) => {
                  return (
                    <td
                      // Return an array of prop objects and react-table will merge them appropriately
                      {...cell.getCellProps([
                        {
                          className: cell.column.className,
                          style: cell.column.style,
                        },
                        getColumnProps(cell.column),
                        //  getCellProps(cell),
                      ])}
                      align="center"
                      //                    width="140px"
                    >
                      {(cell.column.id !== "num" && (
                        <Button
                          //as={Link}
                          variant="outline-primary"
                          //to={{
                          //  pathname: "/pages/manager/item",
                          //}}
                          //state={{
                          //  id: cell.row.original.manager.id, //cell.row.original['worktime_id'],
                          //}}
                          onClick={() => {
                            setSelectedOrder(cell.row.original.order);
                            setOpenOrderDialog(true);
                            //handleSelectedStore({
                            //  id: cell.row.original.store.id,
                            //  name: cell.row.original.store.name,
                            //});
                            /*
                            dispatch({
                              type: "fetch/get", //_add',
                              payload: cell.row.original.manager.id,
                              ob: "item_id",
                            });
                            */
                          }}
                        >
                          {cell.render("Cell")}
                        </Button>
                      )) ||
                        cell.render("Cell")}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  }

  //Возврат
  function Table_R({
    columns,
    data,
    getHeaderProps = defaultPropGetter,
    getColumnProps = defaultPropGetter,
    getRowProps = defaultPropGetter,
    getCellProps = defaultPropGetter,
  }) {
    // Use the state and functions returned from useTable to build your UI
    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
      useTable({
        columns,
        data,
      });

    return (
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  // Return an array of prop objects and react-table will merge them appropriately
                  {...column.getHeaderProps([
                    {
                      className: column.className,
                      style: column.style,
                    },
                    getColumnProps(column),

                    getHeaderProps(column),
                  ])}
                >
                  {column.render("Header")}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              // Merge user row props in
              <tr {...row.getRowProps(getRowProps(row))}>
                {row.cells.map((cell) => {
                  return (
                    <td
                      // Return an array of prop objects and react-table will merge them appropriately
                      {...cell.getCellProps([
                        {
                          className: cell.column.className,
                          style: cell.column.style,
                        },
                        getColumnProps(cell.column),
                        //  getCellProps(cell),
                      ])}
                      align="center"
                      //                    width="140px"
                    >
                      {(cell.column.id !== "num" && (
                        <Button
                          //as={Link}
                          variant="outline-primary"
                          //to={{
                          //  pathname: "/pages/manager/item",
                          //}}
                          //state={{
                          //  id: cell.row.original.manager.id, //cell.row.original['worktime_id'],
                          //}}
                          onClick={() => {
                            setSelectedOrder(cell.row.original.order);
                            setOpenOrderDialog(true);
                            //handleSelectedStore({
                            //  id: cell.row.original.store.id,
                            //  name: cell.row.original.store.name,
                            //});
                            /*
                            dispatch({
                              type: "fetch/get", //_add',
                              payload: cell.row.original.manager.id,
                              ob: "item_id",
                            });
                            */
                          }}
                        >
                          {cell.render("Cell")}
                        </Button>
                      )) ||
                        cell.render("Cell")}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  }

  //Доставка
  function Table_D({
    columns,
    data,
    getHeaderProps = defaultPropGetter,
    getColumnProps = defaultPropGetter,
    getRowProps = defaultPropGetter,
    getCellProps = defaultPropGetter,
  }) {
    // Use the state and functions returned from useTable to build your UI
    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
      useTable({
        columns,
        data,
      });

    return (
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  // Return an array of prop objects and react-table will merge them appropriately
                  {...column.getHeaderProps([
                    {
                      className: column.className,
                      style: column.style,
                    },
                    getColumnProps(column),

                    getHeaderProps(column),
                  ])}
                >
                  {column.render("Header")}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              // Merge user row props in
              <tr {...row.getRowProps(getRowProps(row))}>
                {row.cells.map((cell) => {
                  return (
                    <td
                      // Return an array of prop objects and react-table will merge them appropriately
                      {...cell.getCellProps([
                        {
                          className: cell.column.className,
                          style: cell.column.style,
                        },
                        getColumnProps(cell.column),
                        //  getCellProps(cell),
                      ])}
                      align="center"
                      //                    width="140px"
                    >
                      {(cell.column.id !== "num" && (
                        <Button
                          //as={Link}
                          variant="outline-primary"
                          //to={{
                          //  pathname: "/pages/manager/item",
                          //}}
                          //state={{
                          //  id: cell.row.original.manager.id, //cell.row.original['worktime_id'],
                          //}}
                          onClick={() => {
                            setSelectedDelivery(cell.row.original.order);
                            setOpenDeliveryDialog(true);
                            //handleSelectedStore({
                            //  id: cell.row.original.store.id,
                            //  name: cell.row.original.store.name,
                            //});
                            /*
                            dispatch({
                              type: "fetch/get", //_add',
                              payload: cell.row.original.manager.id,
                              ob: "item_id",
                            });
                            */
                          }}
                        >
                          {cell.render("Cell")}
                        </Button>
                      )) ||
                        cell.render("Cell")}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  }

  //Отгрузки
  function Table_I({
    columns,
    data,
    getHeaderProps = defaultPropGetter,
    getColumnProps = defaultPropGetter,
    getRowProps = defaultPropGetter,
    getCellProps = defaultPropGetter,
  }) {
    // Use the state and functions returned from useTable to build your UI
    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
      useTable({
        columns,
        data,
      });

    return (
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  // Return an array of prop objects and react-table will merge them appropriately
                  {...column.getHeaderProps([
                    {
                      className: column.className,
                      style: column.style,
                    },
                    getColumnProps(column),

                    getHeaderProps(column),
                  ])}
                >
                  {column.render("Header")}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              // Merge user row props in
              <tr {...row.getRowProps(getRowProps(row))}>
                {row.cells.map((cell) => {
                  return (
                    <td
                      // Return an array of prop objects and react-table will merge them appropriately
                      {...cell.getCellProps([
                        {
                          className: cell.column.className,
                          style: cell.column.style,
                        },
                        getColumnProps(cell.column),
                        //  getCellProps(cell),
                      ])}
                      align="center"
                      //                    width="140px"
                    >
                      {(cell.column.id !== "num" && (
                        <Button
                          //as={Link}
                          variant="outline-primary"
                          //to={{
                          //  pathname: "/pages/manager/item",
                          //}}
                          //state={{
                          //  id: cell.row.original.manager.id, //cell.row.original['worktime_id'],
                          //}}
                          onClick={() => {
                            setSelectedInvoice(cell.row.original.item);
                            setOpenInvoiceDialog(true);
                            //handleSelectedStore({
                            //  id: cell.row.original.store.id,
                            //  name: cell.row.original.store.name,
                            //});
                            /*
                            dispatch({
                              type: "fetch/get", //_add',
                              payload: cell.row.original.manager.id,
                              ob: "item_id",
                            });
                            */
                          }}
                        >
                          {cell.render("Cell")}
                        </Button>
                      )) ||
                        cell.render("Cell")}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  }

  //Выполнение Планов на менеджеров
  function Table_P({
    columns,
    data,
    getHeaderProps = defaultPropGetter,
    getColumnProps = defaultPropGetter,
    getRowProps = defaultPropGetter,
    getCellProps = defaultPropGetter,
  }) {
    // Use the state and functions returned from useTable to build your UI
    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
      useTable({
        columns,
        data,
      });

    return (
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  // Return an array of prop objects and react-table will merge them appropriately
                  {...column.getHeaderProps([
                    {
                      className: column.className,
                      style: column.style,
                    },
                    getColumnProps(column),

                    getHeaderProps(column),
                  ])}
                >
                  {column.render("Header")}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              // Merge user row props in
              <tr {...row.getRowProps(getRowProps(row))}>
                {row.cells.map((cell) => {
                  let v = cell.row.original;
                  let Checked = false;
                  if (v && v.isfinished) {
                    Checked = true;
                  }
                  return (
                    <td
                      // Return an array of prop objects and react-table will merge them appropriately
                      {...cell.getCellProps([
                        {
                          className: cell.column.className,
                          style: cell.column.style,
                        },
                        getColumnProps(cell.column),
                        //  getCellProps(cell),
                      ])}
                      align="center"
                      //                    width="140px"
                    >
                      {cell.column.id === "isfinished" && (
                        <>
                          <FormControlLabel
                            style={{
                              marginRight: 15,
                              marginLeft: 10,
                            }}
                            control={
                              <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={Checked}
                                disabled={readonly}
                                /*                    <Switch
                                id={1}
                                name={'DopInfo'}*/
                                color="primary"
                                //                              onChange={handleChangeSwitch}
                                onChange={async (item) => {
                                  if (!readonly) {
                                    try {
                                      /*
                                      await ws_client.wss_setItem({
                                        tableName: "Roles",
                                        item: role_item,
                                        call_nm: "Model_update",
                                        where: {
                                          checked: Checked,
                                          unit_id: v.unit_id,
                                          //roleunit_id: v.roleunit_id,
                                        },
                                        action: {
                                          metod: "setroleunits",
                                        },
                                        callback: setRoles,
                                      });
                                      */
                                    } catch (e) {
                                      console.log("errr e", e);
                                    }
                                  }
                                }}
                              />
                            }
                            label={""}
                          />
                        </>
                      )}

                      {cell.column.id !== "isfinished" && cell.render("Cell")}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  }

  //Задачи менеджера
  function Table_T({
    columns,
    data,
    getHeaderProps = defaultPropGetter,
    getColumnProps = defaultPropGetter,
    getRowProps = defaultPropGetter,
    getCellProps = defaultPropGetter,
  }) {
    // Use the state and functions returned from useTable to build your UI
    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
      useTable({
        columns,
        data,
      });

    return (
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  // Return an array of prop objects and react-table will merge them appropriately
                  {...column.getHeaderProps([
                    {
                      className: column.className,
                      style: column.style,
                    },
                    getColumnProps(column),

                    getHeaderProps(column),
                  ])}
                >
                  {column.render("Header")}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              // Merge user row props in
              <tr {...row.getRowProps(getRowProps(row))}>
                {row.cells.map((cell) => {
                  let v = cell.row.original;
                  let Checked = false;
                  if (v && v.isfinished) {
                    Checked = true;
                  }
                  return (
                    <td
                      // Return an array of prop objects and react-table will merge them appropriately
                      {...cell.getCellProps([
                        {
                          className: cell.column.className,
                          style: cell.column.style,
                        },
                        getColumnProps(cell.column),
                        //  getCellProps(cell),
                      ])}
                      align="center"
                      //                    width="140px"
                    >
                      {cell.column.id === "isfinished" && (
                        <>
                          <FormControlLabel
                            style={{
                              marginRight: 15,
                              marginLeft: 10,
                            }}
                            control={
                              <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={Checked}
                                disabled={readonly}
                                /*                    <Switch
                                id={1}
                                name={'DopInfo'}*/
                                color="primary"
                                //                              onChange={handleChangeSwitch}
                                onChange={async (item) => {
                                  if (!readonly) {
                                    try {
                                      /*
                                      await ws_client.wss_setItem({
                                        tableName: "Roles",
                                        item: role_item,
                                        call_nm: "Model_update",
                                        where: {
                                          checked: Checked,
                                          unit_id: v.unit_id,
                                          //roleunit_id: v.roleunit_id,
                                        },
                                        action: {
                                          metod: "setroleunits",
                                        },
                                        callback: setRoles,
                                      });
                                      */
                                    } catch (e) {
                                      console.log("errr e", e);
                                    }
                                  }
                                }}
                              />
                            }
                            label={""}
                          />
                        </>
                      )}

                      {cell.column.id !== "isfinished" && cell.render("Cell")}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  }

  function Table2({
    columns,
    data,
    getHeaderProps = defaultPropGetter,
    getColumnProps = defaultPropGetter,
    getRowProps = defaultPropGetter,
    getCellProps = defaultPropGetter,
  }) {
    // Use the state and functions returned from useTable to build your UI
    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
      useTable({
        columns,
        data,
      });

    return (
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  // Return an array of prop objects and react-table will merge them appropriately
                  {...column.getHeaderProps([
                    {
                      className: column.className,
                      style: column.style,
                    },
                    getColumnProps(column),

                    getHeaderProps(column),
                  ])}
                >
                  {column.render("Header")}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              // Merge user row props in
              <tr {...row.getRowProps(getRowProps(row))}>
                {row.cells.map((cell) => {
                  return (
                    <td
                      // Return an array of prop objects and react-table will merge them appropriately
                      {...cell.getCellProps([
                        {
                          className: cell.column.className,
                          style: cell.column.style,
                        },
                        getColumnProps(cell.column),
                        //  getCellProps(cell),
                      ])}
                      align="center"
                      //                    width="140px"
                    >
                      {(cell.column.id !== "num" && (
                        <Button
                          //as={Link}
                          variant="outline-primary"
                          //to={{
                          //  pathname: "/pages/manager/item",
                          //}}
                          //state={{
                          //  id: cell.row.original.manager.id, //cell.row.original['worktime_id'],
                          //}}
                          onClick={() => {
                            //handleSelectedStore({
                            //  id: cell.row.original.store.id,
                            //  name: cell.row.original.store.name,
                            //});
                            /*
                            dispatch({
                              type: "fetch/get", //_add',
                              payload: cell.row.original.manager.id,
                              ob: "item_id",
                            });
                            */
                          }}
                        >
                          {cell.render("Cell")}
                        </Button>
                      )) ||
                        cell.render("Cell")}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  }
  let language = "ru";
  if (access && access.user && access.user.language) {
    if (access.user.language === "ru") language = "ru";
    if (access.user.language === "ua") language = "uk";
    if (access.user.language === "en") language = "en";
    if (access.user.language === "de") language = "de";
  }

  moment.locale(language); //'ua')
  //const localizer = momentLocalizer(moment);

  const ref = React.useRef(null);

  //  useHotkeys("f5", () => ref.current && ref.current.focus());

  const ready = () => {
    if (doctors_item /* && calendars*/ || true)
      // || !(worker2 && worker2.value))
      return true;
    else return false;
  };

  function DContent(props) {
    const {
      bgColor,
      color,
      labelIcon: LabelIcon,
      labelInfo,
      labelText,
      Id,
      parentId,
      level,
      ...other
    } = props;
    return (
      <>
        {TypeAlertDialog === "ErroDeleteTermin" && (
          <RadioGroup
            row
            //{...register("termin.alertdelete", { type: "radio" })}
            style={{
              marginLeft: 50,
            }}
            //onChange={RhandleChangelanguage}
          >
            <FormControlLabel
              value="DELETE"
              control={<Radio />}
              label={translate("resources.home.alertdelete")}
            />
            <FormControlLabel
              value="CREATE"
              control={<Radio />}
              label={translate("resources.home.alertcreate")}
            />
            <FormControlLabel
              value="NOT"
              control={<Radio />}
              label={translate("resources.home.alertnotdelete")}
            />
          </RadioGroup>
        )}
        {TypeAlertDialog === "MoveTermin" && <> </>}
      </>
    );
  }

  function DContext(props) {
    const {
      bgColor,
      color,
      labelIcon: LabelIcon,
      labelInfo,
      labelText,
      Id,
      parentId,
      level,
      ...other
    } = props;
    return (
      <>
        {TypeAlertDialog === "ErroDeleteTermin" && TextAlertDialog}
        {(TypeAlertDialog === "MoveTermin" ||
          TypeAlertDialog === "DelTermin") && (
          <>
            <FormControl fullWidth sx={{ m: 1 }}>
              <TableContainer component={Paper}>
                <Table aria-label="simple table">
                  <TableBody
                    sx={{
                      border: alertMessagesBorder ? alertMessagesBorder : 2,
                    }}
                  >
                    {alertMessages.map((row) => (
                      <TableRow
                        key={row.num}
                        sx={{
                          border: row.border ? row.border : 0,
                          "&:last-child td, &:last-child th": {
                            border: 0,
                          },
                        }}
                      >
                        {/*                        <TableCell align="right" sx={{ width: 30 }}>
                          {row.num}
                        </TableCell>
                      */}
                        <TableCell
                          component="th"
                          scope="row"
                          style={{
                            fontSize: "1.60rem",
                            color: row.color ? row.color : "",
                            backgroundColor: row.bkcolor ? row.bkcolor : "",
                          }}
                        >
                          {row.msg}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </FormControl>
          </>
        )}
      </>
    );
  }

  function PaperComponent(props) {
    return (
      <Draggable
        handle="#draggable-dialog-title"
        cancel={'[class*="MuiDialogContent-root"]'}
      >
        <Paper {...props} />
      </Draggable>
    );
  }

  function AlertDialog(props) {
    return (
      <>
        {/* Alert Dialog */}
        <Dialog
          open={openAlertDialog}
          maxWidth={maxWidth}
          PaperComponent={PaperComponent}
          aria-labelledby="draggable-dialog-title"
          onClose={handleAlertClose}
          PaperProps={{
            component: "form",
            onSubmit: async (event) => {
              event.preventDefault();
              //const formData = new FormData(event.currentTarget);
              //const formJson = Object.fromEntries(formData.entries());

              if (TypeAlertDialog === "ErroDeleteTermin") {
                //WhenErrorDeleteTermin();
              }
              handleAlertClose();
            },
          }}
        >
          <DialogTitle
            style={{
              fontSize: "1.60rem",
            }}
          >
            {TitleAlertDialog}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              <DContext />
            </DialogContentText>
            <DContent />
          </DialogContent>
          <DialogActions>
            <Button
              sx={{
                ml: 1,
                alignItems: "center",
                "&:hover": {
                  backgroundColor: "primary.light",
                  //backgroundColor: 'primary.dark',
                  opacity: [0.9, 0.8, 0.7],
                },
              }}
              variant="contained"
              onClick={handleAlertClose}
            >
              Cancel
            </Button>
            <Button
              sx={{
                ml: 1,
                alignItems: "center",
                "&:hover": {
                  backgroundColor: "primary.light",
                  //backgroundColor: 'primary.dark',
                  opacity: [0.9, 0.8, 0.7],
                },
              }}
              variant="contained"
              type="submit"
            >
              Ok
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }

  function OrderDialog(props) {
    return (
      <>
        {/* Order Dialog */}
        <Dialog
          open={openOrderDialog}
          maxWidth={"xl"}
          PaperComponent={isXSmall ? null : PaperComponent}
          aria-labelledby="draggable-dialog-title"
          onClose={handleOrderClose}
          PaperProps={{
            component: "form",
            /*       onSubmit: async (event) => {
              event.preventDefault();
              //const formData = new FormData(event.currentTarget);
              //const formJson = Object.fromEntries(formData.entries());
            },*/
          }}
        >
          <DialogTitle
            style={{
              fontSize: "1.10rem",
            }}
          >
            <div
              style={{
                //            marginTop: -20,
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              {"Заказ"}
              <div>
                {(SelectedStore && SelectedStore.name) +
                  "  ( " +
                  (SelectedStore &&
                    SelectedStore.customer &&
                    SelectedStore.customer.name) +
                  " )"}
              </div>

              <Button
                sx={{
                  ml: 1,
                  alignItems: "center",
                  "&:hover": {
                    backgroundColor: "primary.light",
                    //backgroundColor: 'primary.dark',
                    opacity: [0.9, 0.8, 0.7],
                  },
                }}
                variant="contained"
                onClick={handleOrderClose}
              >
                {"X"}
              </Button>
            </div>
            <div
              style={{
                //            marginTop: -20,
                width: "80%",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div>{SelectedOrder && "Сумма:   " + SelectedOrder.summa}</div>
              <div>
                {SelectedOrder &&
                  "Форма оплаты:   " + SelectedOrder.formpays.name}
              </div>
              <div>
                {SelectedOrder && "Вид оплаты:   " + SelectedOrder.vidpays.name}
              </div>
              <div>
                {SelectedOrder && "Тип цін:   " + SelectedOrder.typeprices.name}
              </div>
            </div>
          </DialogTitle>
          <DialogContent>
            {/*            <DialogContentText></DialogContentText> */}
            <Styles>
              <Table2
                columns={columns5}
                data={data5}
                getHeaderProps={
                  (nameTheme &&
                    nameTheme !== "dark" &&
                    ((row) => {
                      return {
                        style: {
                          //                   background:
                          //                   row.index % 2 === 0 ? 'rgba(0,0,0,.1)' : 'white',
                        },
                      };
                    })) ||
                  ((row) => {
                    return {
                      style: {
                        color: "#ffffffb3",
                        //                    background:
                        //                    row.index % 2 === 0 ? 'rgba(0,0,0,.1)' : '#ffffffb3', //'#616161e6', //'#ffffffb3',
                      },
                    };
                  })
                }
                /*          
          getHeaderProps={(column) => ({
            onClick: () => alert('Header!'),
          })}
          getColumnProps={(column) => ({
            onClick: () => alert('Column!'),
          })}
          */
                getRowProps={
                  (nameTheme &&
                    nameTheme !== "dark" &&
                    ((row) => {
                      return {
                        style: {
                          background:
                            row.index % 2 === 0 ? "rgba(0,0,0,.1)" : "white",
                        },
                      };
                    })) ||
                  ((row) => {
                    return {
                      style: {
                        background:
                          row.index % 2 === 0 ? "rgba(0,0,0,.1)" : "#616161e6", //'#ffffffb3',
                      },
                    };
                  })
                }
                getCellProps={(cellInfo) => ({
                  //            onClick: (req, res) => onClickCell(cellInfo, req, res),
                  //            style: {
                  //              backgroundColor: `hsl(${
                  //                120 * ((120 - cellInfo.value) / 120) * -1 + 120
                  //              }, 100%, 67%)`,
                  //            },
                })}
              />
            </Styles>
          </DialogContent>
          {/*    <DialogActions></DialogActions>  */}
        </Dialog>
      </>
    );
  }

  function DeliveryDialog(props) {
    return (
      <>
        {/* Delivery Dialog */}
        <Dialog
          open={openDeliveryDialog}
          maxWidth={"xl"}
          PaperComponent={isXSmall ? null : PaperComponent}
          aria-labelledby="draggable-dialog-title"
          onClose={handleDeliveryClose}
          PaperProps={{
            component: "form",
            /*       onSubmit: async (event) => {
              event.preventDefault();
              //const formData = new FormData(event.currentTarget);
              //const formJson = Object.fromEntries(formData.entries());
            },*/
          }}
        >
          <DialogTitle
            style={{
              fontSize: "1.10rem",
            }}
          >
            <div
              style={{
                //            marginTop: -20,
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              {"Доставка"}
              <div>
                {(SelectedStore && SelectedStore.name) +
                  "  ( " +
                  (SelectedStore &&
                    SelectedStore.customer &&
                    SelectedStore.customer.name) +
                  " )"}
              </div>

              <Button
                sx={{
                  ml: 1,
                  alignItems: "center",
                  "&:hover": {
                    backgroundColor: "primary.light",
                    //backgroundColor: 'primary.dark',
                    opacity: [0.9, 0.8, 0.7],
                  },
                }}
                variant="contained"
                onClick={handleDeliveryClose}
              >
                {"X"}
              </Button>
            </div>
            <div
              style={{
                //            marginTop: -20,
                width: "80%",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div>
                {SelectedDelivery && "Сумма:   " + SelectedDelivery.summa}
              </div>
              <div>
                {SelectedDelivery &&
                  "Форма оплаты:   " + SelectedDelivery.formpays.name}
              </div>
              <div>
                {SelectedDelivery &&
                  "Вид оплаты:   " + SelectedDelivery.vidpays.name}
              </div>
              <div>
                {SelectedDelivery &&
                  "Тип цін:   " + SelectedDelivery.typeprices.name}
              </div>
            </div>
          </DialogTitle>
          <DialogContent>
            {/*            <DialogContentText></DialogContentText> */}
            <Styles>
              <Table2
                columns={columns5}
                data={data5a}
                getHeaderProps={
                  (nameTheme &&
                    nameTheme !== "dark" &&
                    ((row) => {
                      return {
                        style: {
                          //                   background:
                          //                   row.index % 2 === 0 ? 'rgba(0,0,0,.1)' : 'white',
                        },
                      };
                    })) ||
                  ((row) => {
                    return {
                      style: {
                        color: "#ffffffb3",
                        //                    background:
                        //                    row.index % 2 === 0 ? 'rgba(0,0,0,.1)' : '#ffffffb3', //'#616161e6', //'#ffffffb3',
                      },
                    };
                  })
                }
                /*          
          getHeaderProps={(column) => ({
            onClick: () => alert('Header!'),
          })}
          getColumnProps={(column) => ({
            onClick: () => alert('Column!'),
          })}
          */
                getRowProps={
                  (nameTheme &&
                    nameTheme !== "dark" &&
                    ((row) => {
                      return {
                        style: {
                          background:
                            row.index % 2 === 0 ? "rgba(0,0,0,.1)" : "white",
                        },
                      };
                    })) ||
                  ((row) => {
                    return {
                      style: {
                        background:
                          row.index % 2 === 0 ? "rgba(0,0,0,.1)" : "#616161e6", //'#ffffffb3',
                      },
                    };
                  })
                }
                getCellProps={(cellInfo) => ({
                  //            onClick: (req, res) => onClickCell(cellInfo, req, res),
                  //            style: {
                  //              backgroundColor: `hsl(${
                  //                120 * ((120 - cellInfo.value) / 120) * -1 + 120
                  //              }, 100%, 67%)`,
                  //            },
                })}
              />
            </Styles>
          </DialogContent>
          {/*    <DialogActions></DialogActions>  */}
        </Dialog>
      </>
    );
  }

  function InvoiceDialog(props) {
    return (
      <>
        {/* Invoice Dialog */}
        <Dialog
          open={openInvoiceDialog}
          maxWidth={"xl"}
          PaperComponent={isXSmall ? null : PaperComponent}
          aria-labelledby="draggable-dialog-title"
          onClose={handleInvoiceClose}
          PaperProps={{
            component: "form",
            /*       onSubmit: async (event) => {
              event.preventDefault();
              //const formData = new FormData(event.currentTarget);
              //const formJson = Object.fromEntries(formData.entries());
            },*/
          }}
        >
          <DialogTitle
            style={{
              fontSize: "1.10rem",
            }}
          >
            <div
              style={{
                //            marginTop: -20,
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              {SelectedInvoice && SelectedInvoice.doctypeid === "1"
                ? translate("resources.home.invoice")
                : translate("resources.home.return")}
              <div>
                {(SelectedStore && SelectedStore.name) +
                  "  ( " +
                  (SelectedStore &&
                    SelectedStore.customer &&
                    SelectedStore.customer.name) +
                  " )"}
              </div>

              <Button
                sx={{
                  ml: 1,
                  alignItems: "center",
                  "&:hover": {
                    backgroundColor: "primary.light",
                    //backgroundColor: 'primary.dark',
                    opacity: [0.9, 0.8, 0.7],
                  },
                }}
                variant="contained"
                onClick={handleInvoiceClose}
              >
                {"X"}
              </Button>
            </div>
            <div
              style={{
                //            marginTop: -20,
                width: "80%",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div>
                {SelectedInvoice && "Сумма:   " + SelectedInvoice.summa}
              </div>
              <div>
                {SelectedInvoice &&
                  "Форма оплаты:   " + SelectedInvoice.formpays.name}
              </div>
              <div>
                {SelectedInvoice &&
                  "Вид оплаты:   " + SelectedInvoice.vidpays.name}
              </div>
              <div>
                {SelectedInvoice &&
                  "Тип цін:   " + SelectedInvoice.typeprices.name}
              </div>
            </div>
          </DialogTitle>
          <DialogContent>
            {/*            <DialogContentText></DialogContentText> */}
            <Styles>
              <Table2
                columns={columns5}
                data={data5b}
                getHeaderProps={
                  (nameTheme &&
                    nameTheme !== "dark" &&
                    ((row) => {
                      return {
                        style: {
                          //                   background:
                          //                   row.index % 2 === 0 ? 'rgba(0,0,0,.1)' : 'white',
                        },
                      };
                    })) ||
                  ((row) => {
                    return {
                      style: {
                        color: "#ffffffb3",
                        //                    background:
                        //                    row.index % 2 === 0 ? 'rgba(0,0,0,.1)' : '#ffffffb3', //'#616161e6', //'#ffffffb3',
                      },
                    };
                  })
                }
                /*          
          getHeaderProps={(column) => ({
            onClick: () => alert('Header!'),
          })}
          getColumnProps={(column) => ({
            onClick: () => alert('Column!'),
          })}
          */
                getRowProps={
                  (nameTheme &&
                    nameTheme !== "dark" &&
                    ((row) => {
                      return {
                        style: {
                          background:
                            row.index % 2 === 0 ? "rgba(0,0,0,.1)" : "white",
                        },
                      };
                    })) ||
                  ((row) => {
                    return {
                      style: {
                        background:
                          row.index % 2 === 0 ? "rgba(0,0,0,.1)" : "#616161e6", //'#ffffffb3',
                      },
                    };
                  })
                }
                getCellProps={(cellInfo) => ({
                  //            onClick: (req, res) => onClickCell(cellInfo, req, res),
                  //            style: {
                  //              backgroundColor: `hsl(${
                  //                120 * ((120 - cellInfo.value) / 120) * -1 + 120
                  //              }, 100%, 67%)`,
                  //            },
                })}
              />
            </Styles>
          </DialogContent>
          {/*    <DialogActions></DialogActions>  */}
        </Dialog>
      </>
    );
  }

  //для антидреьезга...???
  const setPause = (num, times) => {
    setstopButton(true);

    setTimeout(function () {
      console.log("pause ", times);
      setstopButton(false);
    }, times);
  };

  const getPosition = () => {
    let pos = [50.380456, 30.452588]; //50.425051, 30.3631675];

    if (AllPosition[0]) pos = AllPosition[0];
    if (startposition[0]) pos = startposition;
    if (SelectedStore && SelectedStore.longitude && SelectedStore.latitude) {
      pos = [SelectedStore.longitude, SelectedStore.latitude];
    }

    return pos;
  };

  function FlyMapTo() {
    const map = useMap();

    useEffect(() => {
      map.flyTo(getPosition());
    }, [AllPosition, SelectedStore]);

    return null;
  }

  const ChangeView = ({ center, zoom }) => {
    const map = useMap();
    map.setView(center, zoom);
    return null;
  };

  const myIconGreen = L.icon({
    iconUrl: require("../assets/marker3.png"),
    iconSize: [32, 56],
    iconAnchor: [12, 64],
    //popupAnchor: null,
    shadowUrl: null,
    //shadowSize: null,
    //shadowAnchor: null,
  });

  const myIconRed = L.icon({
    iconUrl: require("../assets/marker4.png"),
    iconSize: [32, 56],
    iconAnchor: [12, 64],
    //popupAnchor: null,
    shadowUrl: null,
    //shadowSize: null,
    //shadowAnchor: null,
  });

  const myIconBlue = L.icon({
    iconUrl: require("../assets/marker6.png"),
    iconSize: [32, 56],
    iconAnchor: [12, 64],
    //popupAnchor: null,
    shadowUrl: null,
    //shadowSize: null,
    //shadowAnchor: null,
  });

  const myIconYellow = L.icon({
    iconUrl: require("../assets/marker5.png"),
    iconSize: [32, 56],
    iconAnchor: [12, 64],
    //popupAnchor: null,
    shadowUrl: null,
    //shadowSize: null,
    //shadowAnchor: null,
  });

  const CustomReactPopup = ({ row }) => {
    //        <p> {row.name + "  ( " + (row.customer && row.customer.name) + " )"}</p>

    return (
      <div style={{ fontSize: "14px" }}>
        <Button
          id="popup-button-id"
          onClick={() => {
            //console.log("click row", row);

            handleSelectedStore({
              id: row.id,
              name: row.name,
              longitude: row.longitude,
              latitude: row.latitude,
              customer: row.customer,
            });
          }}
        >
          {row.num +
            (row.num !== "" ? ")   " : "") +
            row.name +
            "  ( " +
            (row.customer && row.customer.name) +
            " )"}
        </Button>
      </div>
    );
  };

  return (
    <>
      {ready() && (
        <>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <Box
              sx={{
                width: "100%",
                height: "100%",
                display: "flex",
                flexDirection: !isXSmall ? "row" : "column",
                flexWrap: !isXSmall ? "wrap" : "",
                marginTop: -3,
                justifyContent: !isXSmall ? "space-between" : "",
              }}
            >
              <Box
                style={{
                  width: !isXSmall ? "60%" : "100%",
                  height: !isXSmall ? "100%" : "44%",
                  //marginBottom: 60,
                }}
              >
                <TabContext value={tab} sx={{ p: 0 }}>
                  <Box sx={{ borderBottom: 0, borderColor: "divider", p: 0 }}>
                    <TabList
                      onChange={(_, value) => {
                        setSelectedOrder(null);
                        OnChangeTab1(value);
                        setTab(value);
                      }}
                      variant="scrollable"
                      aria-label="lab API tabs example"
                    >
                      <Tab label={translate("resources.home.maps")} value="1" />
                      <Tab
                        label={translate("resources.home.foto")}
                        style={
                          (filefotos && filefotos.length) > 0
                            ? { color: "green", fontWeight: "bold" }
                            : {}
                        }
                        value="2"
                      />
                      <Tab
                        label={translate("resources.home.orders")}
                        style={
                          (data4 && data4.length) > 0
                            ? { color: "green", fontWeight: "bold" }
                            : {}
                        }
                        value="3"
                      />
                      <Tab
                        label={translate("resources.home.payments")}
                        style={
                          (data3 && data3.length) > 0
                            ? { color: "green", fontWeight: "bold" }
                            : {}
                        }
                        value="4"
                      />
                      <Tab
                        label={translate("resources.home.debts")}
                        style={
                          (data2 && data2.length) > 0
                            ? { color: "green", fontWeight: "bold" }
                            : {}
                        }
                        value="5"
                      />
                      <Tab
                        label={translate("resources.home.returns")}
                        style={
                          (data6 && data6.length) > 0
                            ? { color: "green", fontWeight: "bold" }
                            : {}
                        }
                        value="6"
                      />
                      <Tab
                        label={translate("resources.home.invoices")}
                        style={
                          (data7 && data7.length) > 0
                            ? { color: "green", fontWeight: "bold" }
                            : {}
                        }
                        value="7"
                      />
                      <Tab
                        label={translate("resources.home.lastinvoices")}
                        style={
                          (data8 && data8.length) > 0
                            ? { color: "green", fontWeight: "bold" }
                            : {}
                        }
                        value="8"
                      />
                      <Tab
                        label={translate("resources.home.salesplans")}
                        style={
                          (data10 && data10.length) > 0
                            ? { color: "green", fontWeight: "bold" }
                            : {}
                        }
                        value="9"
                      />
                      <Tab
                        label={translate("resources.home.managertasks")}
                        style={
                          (data10 && data10.length) > 0
                            ? { color: "green", fontWeight: "bold" }
                            : {}
                        }
                        value="10"
                      />
                    </TabList>
                  </Box>
                  <TabPanel value="1" sx={{ p: 0 }}>
                    <div
                      style={{ marginTop: 5, height: !isXSmall ? 600 : 400 }}
                    >
                      <Box>
                        <Card
                          sx={{ minWidth: 275, height: !isXSmall ? 680 : 400 }}
                        >
                          <CardContent>
                            <MapContainer
                              center={getPosition()}
                              zoom={13}
                              scrollWheelZoom={true}
                              //zoomControl={true}>
                              style={{ height: "80vh", width: "*80wh" }}
                            >
                              <TileLayer
                                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                              />
                              <Polyline positions={AllPosition} color="red" />
                              {false &&
                                SelectedStore &&
                                SelectedStore.longitude &&
                                SelectedStore.latitude && (
                                  <>
                                    <Marker
                                      position={[
                                        SelectedStore.longitude,
                                        SelectedStore.latitude,
                                      ]}
                                      icon={myIconRed}
                                      eventHandlers={{
                                        click: () => {
                                          //setSelectedMarker(point.point.name);
                                          handleSelectedStore({
                                            id: SelectedStore.id,
                                            name: SelectedStore.name,
                                            longitude: SelectedStore.longitude,
                                            latitude: SelectedStore.latitude,
                                            customer: SelectedStore.customer,
                                          });
                                        },
                                      }}
                                    >
                                      <Popup>
                                        <div>{SelectedStore.name}</div>
                                        <Button>Click</Button>
                                      </Popup>
                                    </Marker>
                                  </>
                                )}
                              {routesheets &&
                                routesheets.map(
                                  (row) =>
                                    row.longitude &&
                                    row.latitude && (
                                      <>
                                        <Marker
                                          position={[
                                            row.longitude,
                                            row.latitude,
                                          ]}
                                          icon={
                                            (SelectedStore &&
                                              SelectedStore.id) === row.id
                                              ? myIconGreen
                                              : !row.addstore
                                              ? myIconYellow
                                              : myIconBlue
                                          }
                                        >
                                          <Popup>
                                            <CustomReactPopup row={row} />
                                          </Popup>
                                        </Marker>
                                      </>
                                    )
                                )}

                              <FlyMapTo />
                            </MapContainer>
                          </CardContent>
                        </Card>
                      </Box>
                    </div>
                  </TabPanel>
                  <TabPanel value="2" sx={{ p: 0 }}>
                    <div
                      style={{ marginTop: 0, height: !isXSmall ? 700 : 400 }}
                    >
                      {
                        <Box>
                          <Card
                            sx={{
                              minWidth: 275,
                              height: !isXSmall ? 760 : 400,
                            }}
                          >
                            <CardContent>
                              <div
                                //            className="hstack gap-2"
                                style={{
                                  marginTop: 0,
                                  display: "flex",
                                  height: "100%",

                                  //justifyContent: "space-between",
                                }}
                              >
                                <Typography
                                  id="outlined-basic"
                                  label="Клиент"
                                  //variant="standard"
                                  className="title h6"
                                  style={{
                                    marginTop: 0,
                                    marginBottom: 0,
                                    marginRight: 40,
                                    //display: 'flex',
                                    //justifyContent: 'space-between',
                                  }}
                                >
                                  {translate("resources.home.store") + " :"}
                                </Typography>
                                <div>
                                  {((SelectedStore && SelectedStore.name) ||
                                    translate("resources.home.notstore")) +
                                    ((SelectedStore &&
                                      SelectedStore.customer &&
                                      "  ( " +
                                        SelectedStore.customer.name +
                                        " )") ||
                                      " - (" +
                                        translate(
                                          "resources.home.notcustomer"
                                        ) +
                                        ")")}
                                </div>
                              </div>
                              <div
                                //            className="hstack gap-2"
                                style={{
                                  marginTop: 0,
                                  //display: "flex",
                                  //justifyContent: "space-between",
                                }}
                              >
                                <List
                                  style={{
                                    maxHeight: !isXSmall ? "760px" : "400px",
                                    overflow: "auto",
                                    height: "100%", //380,
                                  }}
                                >
                                  {!openFotoDialog &&
                                    filefotos &&
                                    filefotos.map((row) => {
                                      let ls = row.map((str) => {
                                        return (
                                          <>
                                            <div
                                              style={{
                                                marginLeft: 10,
                                                marginRight: 10,
                                              }}
                                              onClick={() => {
                                                let i = getNumFoto(str);
                                                setopenFoto(i);
                                                setopenFotoDialog(true);
                                              }}
                                            >
                                              <img
                                                src={
                                                  "https://torg.rud.com.ua/file/" +
                                                  str.id +
                                                  ".jpeg"
                                                }
                                                width="250"
                                                height="250"
                                              />
                                              <Typography
                                                id="outlined-basic"
                                                label="Клиент"
                                                //variant="standard"
                                                className="title h6"
                                                style={{
                                                  marginTop: 0,
                                                  marginBottom: 15,
                                                  marginRight: 150,
                                                  //display: 'flex',
                                                  //justifyContent: 'space-between',
                                                }}
                                              >
                                                {str.comments +
                                                  " (" +
                                                  str.times +
                                                  ")"}
                                              </Typography>
                                            </div>
                                          </>
                                        );
                                      });
                                      return (
                                        <>
                                          <div
                                            //            className="hstack gap-2"
                                            style={{
                                              marginTop: 0,
                                              display: "flex",
                                              //justifyContent: "space-between",
                                            }}
                                          >
                                            {ls}
                                          </div>
                                        </>
                                      );
                                    })}
                                  {openFotoDialog &&
                                    allfilefotos &&
                                    allfilefotos.length > 0 && (
                                      <>
                                        <div
                                          style={{
                                            fontSize: "1.20rem",
                                            display: "flex",
                                            justifyContent: "space-between",
                                          }}
                                        >
                                          {allfilefotos[openFoto] &&
                                            allfilefotos[openFoto].comments +
                                              " (" +
                                              allfilefotos[openFoto].times +
                                              ")"}
                                          <Button
                                            sx={{
                                              //ml: 1,
                                              alignItems: "center",
                                              "&:hover": {
                                                backgroundColor:
                                                  "primary.light",
                                                //backgroundColor: 'primary.dark',
                                                opacity: [0.9, 0.8, 0.7],
                                              },
                                            }}
                                            variant="contained"
                                            //type="submit"
                                            onClick={() => {
                                              setopenFotoDialog(false);
                                            }}
                                          >
                                            X
                                          </Button>
                                        </div>

                                        <div
                                          //            className="hstack gap-2"
                                          style={{
                                            //marginTop: 0,
                                            width: "100%",
                                            display: "flex",
                                            justifyContent: "space-between",
                                          }}
                                        >
                                          <Button
                                            sx={{
                                              //ml: 1,
                                              height: 50,
                                              marginTop: isXSmall ? 15 : 40,
                                              alignItems: "center",
                                              "&:hover": {
                                                backgroundColor:
                                                  "primary.light",
                                                //backgroundColor: 'primary.dark',
                                                opacity: [0.9, 0.8, 0.7],
                                              },
                                            }}
                                            variant="contained"
                                            //type="submit"
                                            onClick={() => {
                                              let o = parseInt(openFoto) - 1;
                                              if (o < 0) {
                                                o = allfilefotos.length - 1;
                                              }

                                              setopenFoto(o.toString());
                                            }}
                                          >
                                            {"<"}
                                          </Button>
                                          <div
                                            style={{
                                              width: "83%",
                                              height: "100%",
                                              position: "relative",
                                              overflow: "hidden",
                                            }}
                                          >
                                            <ReactPanZoom
                                              //image="https://images.unsplash.com/photo-1551091708-fda32ed3178c"
                                              image={
                                                "https://torg.rud.com.ua/file/" +
                                                (allfilefotos[openFoto] &&
                                                  allfilefotos[openFoto].id) +
                                                ".jpeg"
                                              }
                                              //alt="Image alt text"
                                              alt={
                                                allfilefotos[openFoto] &&
                                                allfilefotos[openFoto]
                                                  .comments +
                                                  " (" +
                                                  allfilefotos[openFoto].times +
                                                  ")"
                                              }
                                            />
                                          </div>
                                          {/*
                                          <img
                                            src={
                                              "https://torg.rud.com.ua/file/" +
                                              (allfilefotos[openFoto] &&
                                                allfilefotos[openFoto].id) +
                                              ".jpeg"
                                            }
                                            width="760"
                                            height="100%"
                                          />
                                          */}
                                          <Button
                                            sx={{
                                              //ml: 1,
                                              height: 50,
                                              marginTop: isXSmall ? 15 : 40,
                                              //alignItems: "center",
                                              "&:hover": {
                                                backgroundColor:
                                                  "primary.light",
                                                //backgroundColor: 'primary.dark',
                                                opacity: [0.9, 0.8, 0.7],
                                              },
                                            }}
                                            variant="contained"
                                            //type="submit"
                                            onClick={() => {
                                              let o = parseInt(openFoto) + 1;
                                              if (o > allfilefotos.length - 1) {
                                                o = 0;
                                              }

                                              setopenFoto(o.toString());
                                            }}
                                          >
                                            {">"}
                                          </Button>
                                        </div>
                                      </>
                                    )}
                                </List>
                              </div>
                            </CardContent>
                          </Card>
                        </Box>
                      }
                    </div>
                  </TabPanel>

                  <TabPanel value="3" sx={{ p: 0 }}>
                    <div
                      style={{ marginTop: 0, height: !isXSmall ? 600 : 400 }}
                    >
                      {
                        <Box>
                          <Card
                            sx={{
                              minWidth: 275,
                              height: !isXSmall ? 680 : 400,
                            }}
                          >
                            <CardContent>
                              <div
                                //            className="hstack gap-2"
                                style={{
                                  marginTop: 0,
                                  display: "flex",
                                  height: "100%",

                                  //justifyContent: "space-between",
                                }}
                              >
                                <Typography
                                  id="outlined-basic"
                                  label="Клиент"
                                  //variant="standard"
                                  className="title h6"
                                  style={{
                                    marginTop: 0,

                                    marginBottom: 15,
                                    marginRight: 40,
                                    //display: 'flex',
                                    //justifyContent: 'space-between',
                                  }}
                                >
                                  {translate("resources.home.store") + " :"}
                                </Typography>
                                <div>
                                  {((SelectedStore && SelectedStore.name) ||
                                    translate("resources.home.notstore")) +
                                    ((SelectedStore &&
                                      SelectedStore.customer &&
                                      "  ( " +
                                        SelectedStore.customer.name +
                                        " )") ||
                                      " - (" +
                                        translate(
                                          "resources.home.notcustomer"
                                        ) +
                                        ")")}
                                </div>
                              </div>
                              <List
                                style={{
                                  maxHeight: "600px",
                                  overflow: "auto",
                                  height: "100%", //380,
                                }}
                              >
                                <Styles>
                                  <Table_O
                                    columns={columns4}
                                    data={data4}
                                    getHeaderProps={
                                      (nameTheme &&
                                        nameTheme !== "dark" &&
                                        ((row) => {
                                          return {
                                            style: {
                                              //                   background:
                                              //                   row.index % 2 === 0 ? 'rgba(0,0,0,.1)' : 'white',
                                            },
                                          };
                                        })) ||
                                      ((row) => {
                                        return {
                                          style: {
                                            color: "#ffffffb3",
                                            //                    background:
                                            //                    row.index % 2 === 0 ? 'rgba(0,0,0,.1)' : '#ffffffb3', //'#616161e6', //'#ffffffb3',
                                          },
                                        };
                                      })
                                    }
                                    /*          
          getHeaderProps={(column) => ({
            onClick: () => alert('Header!'),
          })}
          getColumnProps={(column) => ({
            onClick: () => alert('Column!'),
          })}
          */
                                    getRowProps={
                                      (nameTheme &&
                                        nameTheme !== "dark" &&
                                        ((row) => {
                                          return {
                                            style: {
                                              background:
                                                row.index % 2 === 0
                                                  ? "rgba(0,0,0,.1)"
                                                  : "white",
                                            },
                                          };
                                        })) ||
                                      ((row) => {
                                        return {
                                          style: {
                                            background:
                                              row.index % 2 === 0
                                                ? "rgba(0,0,0,.1)"
                                                : "#616161e6", //'#ffffffb3',
                                          },
                                        };
                                      })
                                    }
                                    getCellProps={(cellInfo) => ({
                                      //            onClick: (req, res) => onClickCell(cellInfo, req, res),
                                      //            style: {
                                      //              backgroundColor: `hsl(${
                                      //                120 * ((120 - cellInfo.value) / 120) * -1 + 120
                                      //              }, 100%, 67%)`,
                                      //            },
                                    })}
                                  />
                                </Styles>
                              </List>
                            </CardContent>
                          </Card>
                        </Box>
                      }
                    </div>
                  </TabPanel>
                  <TabPanel value="4" sx={{ p: 0 }}>
                    <div
                      style={{ marginTop: 0, height: !isXSmall ? 600 : 400 }}
                    >
                      {
                        <Box>
                          <Card
                            sx={{
                              minWidth: 275,
                              height: !isXSmall ? 680 : 400,
                            }}
                          >
                            <CardContent>
                              <div
                                //            className="hstack gap-2"
                                style={{
                                  marginTop: 0,
                                  display: "flex",
                                  height: "100%",

                                  //justifyContent: "space-between",
                                }}
                              >
                                <Typography
                                  id="outlined-basic"
                                  label="Клиент"
                                  //variant="standard"
                                  className="title h6"
                                  style={{
                                    marginTop: 0,
                                    marginBottom: 15,
                                    marginRight: 40,
                                    //display: 'flex',
                                    //justifyContent: 'space-between',
                                  }}
                                >
                                  {translate("resources.home.store") + " :"}
                                </Typography>
                                <div>
                                  {((SelectedStore && SelectedStore.name) ||
                                    translate("resources.home.notstore")) +
                                    ((SelectedStore &&
                                      SelectedStore.customer &&
                                      "  ( " +
                                        SelectedStore.customer.name +
                                        " )") ||
                                      " - (" +
                                        translate(
                                          "resources.home.notcustomer"
                                        ) +
                                        ")")}
                                </div>
                              </div>
                              <List
                                style={{
                                  maxHeight: "600px",
                                  overflow: "auto",
                                  height: "100%", //380,
                                }}
                              >
                                <Styles>
                                  <Table2
                                    columns={columns3}
                                    data={data3}
                                    getHeaderProps={
                                      (nameTheme &&
                                        nameTheme !== "dark" &&
                                        ((row) => {
                                          return {
                                            style: {
                                              //                   background:
                                              //                   row.index % 2 === 0 ? 'rgba(0,0,0,.1)' : 'white',
                                            },
                                          };
                                        })) ||
                                      ((row) => {
                                        return {
                                          style: {
                                            color: "#ffffffb3",
                                            //                    background:
                                            //                    row.index % 2 === 0 ? 'rgba(0,0,0,.1)' : '#ffffffb3', //'#616161e6', //'#ffffffb3',
                                          },
                                        };
                                      })
                                    }
                                    /*          
          getHeaderProps={(column) => ({
            onClick: () => alert('Header!'),
          })}
          getColumnProps={(column) => ({
            onClick: () => alert('Column!'),
          })}
          */
                                    getRowProps={
                                      (nameTheme &&
                                        nameTheme !== "dark" &&
                                        ((row) => {
                                          return {
                                            style: {
                                              background:
                                                row.index % 2 === 0
                                                  ? "rgba(0,0,0,.1)"
                                                  : "white",
                                            },
                                          };
                                        })) ||
                                      ((row) => {
                                        return {
                                          style: {
                                            background:
                                              row.index % 2 === 0
                                                ? "rgba(0,0,0,.1)"
                                                : "#616161e6", //'#ffffffb3',
                                          },
                                        };
                                      })
                                    }
                                    getCellProps={(cellInfo) => ({
                                      //            onClick: (req, res) => onClickCell(cellInfo, req, res),
                                      //            style: {
                                      //              backgroundColor: `hsl(${
                                      //                120 * ((120 - cellInfo.value) / 120) * -1 + 120
                                      //              }, 100%, 67%)`,
                                      //            },
                                    })}
                                  />
                                </Styles>
                              </List>
                            </CardContent>
                          </Card>
                        </Box>
                      }
                    </div>
                  </TabPanel>
                  <TabPanel value="5" sx={{ p: 0 }}>
                    <div
                      style={{ marginTop: 0, height: !isXSmall ? 600 : 400 }}
                    >
                      {
                        <Box>
                          <Card
                            sx={{
                              minWidth: 275,
                              height: !isXSmall ? 680 : 400,
                            }}
                          >
                            <CardContent>
                              <div
                                //            className="hstack gap-2"
                                style={{
                                  marginTop: 0,
                                  display: "flex",
                                  height: "100%",

                                  //justifyContent: "space-between",
                                }}
                              >
                                <Typography
                                  id="outlined-basic"
                                  label="Клиент"
                                  //variant="standard"
                                  className="title h6"
                                  style={{
                                    marginTop: 0,
                                    marginBottom: 15,
                                    marginRight: 40,
                                    //display: 'flex',
                                    //justifyContent: 'space-between',
                                  }}
                                >
                                  {translate("resources.home.store") + " :"}
                                </Typography>
                                <div>
                                  {((SelectedStore && SelectedStore.name) ||
                                    translate("resources.home.notstore")) +
                                    ((SelectedStore &&
                                      SelectedStore.customer &&
                                      "  ( " +
                                        SelectedStore.customer.name +
                                        " )") ||
                                      " - (" +
                                        translate(
                                          "resources.home.notcustomer"
                                        ) +
                                        ")")}
                                </div>
                              </div>
                              <List
                                style={{
                                  maxHeight: "600px",
                                  overflow: "auto",
                                  height: "100%", //380,
                                }}
                              >
                                <Styles>
                                  <Table2
                                    columns={columns2}
                                    data={data2}
                                    getHeaderProps={
                                      (nameTheme &&
                                        nameTheme !== "dark" &&
                                        ((row) => {
                                          return {
                                            style: {
                                              //                   background:
                                              //                   row.index % 2 === 0 ? 'rgba(0,0,0,.1)' : 'white',
                                            },
                                          };
                                        })) ||
                                      ((row) => {
                                        return {
                                          style: {
                                            color: "#ffffffb3",
                                            //                    background:
                                            //                    row.index % 2 === 0 ? 'rgba(0,0,0,.1)' : '#ffffffb3', //'#616161e6', //'#ffffffb3',
                                          },
                                        };
                                      })
                                    }
                                    /*          
          getHeaderProps={(column) => ({
            onClick: () => alert('Header!'),
          })}
          getColumnProps={(column) => ({
            onClick: () => alert('Column!'),
          })}
          */
                                    getRowProps={
                                      (nameTheme &&
                                        nameTheme !== "dark" &&
                                        ((row) => {
                                          return {
                                            style: {
                                              background:
                                                row.index % 2 === 0
                                                  ? "rgba(0,0,0,.1)"
                                                  : "white",
                                            },
                                          };
                                        })) ||
                                      ((row) => {
                                        return {
                                          style: {
                                            background:
                                              row.index % 2 === 0
                                                ? "rgba(0,0,0,.1)"
                                                : "#616161e6", //'#ffffffb3',
                                          },
                                        };
                                      })
                                    }
                                    getCellProps={(cellInfo) => ({
                                      //            onClick: (req, res) => onClickCell(cellInfo, req, res),
                                      //            style: {
                                      //              backgroundColor: `hsl(${
                                      //                120 * ((120 - cellInfo.value) / 120) * -1 + 120
                                      //              }, 100%, 67%)`,
                                      //            },
                                    })}
                                  />
                                </Styles>
                              </List>
                            </CardContent>
                          </Card>
                        </Box>
                      }
                    </div>
                  </TabPanel>
                  <TabPanel value="6" sx={{ p: 0 }}>
                    <div
                      style={{ marginTop: 0, height: !isXSmall ? 600 : 400 }}
                    >
                      {
                        <Box>
                          <Card
                            sx={{
                              minWidth: 275,
                              height: !isXSmall ? 680 : 400,
                            }}
                          >
                            <CardContent>
                              <div
                                //            className="hstack gap-2"
                                style={{
                                  marginTop: 0,
                                  display: "flex",
                                  height: "100%",

                                  //justifyContent: "space-between",
                                }}
                              >
                                <Typography
                                  id="outlined-basic"
                                  label="Клиент"
                                  //variant="standard"
                                  className="title h6"
                                  style={{
                                    marginTop: 0,

                                    marginBottom: 15,
                                    marginRight: 40,
                                    //display: 'flex',
                                    //justifyContent: 'space-between',
                                  }}
                                >
                                  {translate("resources.manager.name") + " :"}
                                </Typography>
                                <div>
                                  {(SelectedManager && SelectedManager.name) ||
                                    " - (" +
                                      translate("resources.manager.noname") +
                                      ")"}
                                </div>
                              </div>
                              <List
                                style={{
                                  maxHeight: "600px",
                                  overflow: "auto",
                                  height: "100%", //380,
                                }}
                              >
                                <Styles>
                                  <Table_R
                                    columns={columns6}
                                    data={data6}
                                    getHeaderProps={
                                      (nameTheme &&
                                        nameTheme !== "dark" &&
                                        ((row) => {
                                          return {
                                            style: {
                                              //                   background:
                                              //                   row.index % 2 === 0 ? 'rgba(0,0,0,.1)' : 'white',
                                            },
                                          };
                                        })) ||
                                      ((row) => {
                                        return {
                                          style: {
                                            color: "#ffffffb3",
                                            //                    background:
                                            //                    row.index % 2 === 0 ? 'rgba(0,0,0,.1)' : '#ffffffb3', //'#616161e6', //'#ffffffb3',
                                          },
                                        };
                                      })
                                    }
                                    /*          
          getHeaderProps={(column) => ({
            onClick: () => alert('Header!'),
          })}
          getColumnProps={(column) => ({
            onClick: () => alert('Column!'),
          })}
          */
                                    getRowProps={
                                      (nameTheme &&
                                        nameTheme !== "dark" &&
                                        ((row) => {
                                          return {
                                            style: {
                                              background:
                                                row.index % 2 === 0
                                                  ? "rgba(0,0,0,.1)"
                                                  : "white",
                                            },
                                          };
                                        })) ||
                                      ((row) => {
                                        return {
                                          style: {
                                            background:
                                              row.index % 2 === 0
                                                ? "rgba(0,0,0,.1)"
                                                : "#616161e6", //'#ffffffb3',
                                          },
                                        };
                                      })
                                    }
                                    getCellProps={(cellInfo) => ({
                                      //            onClick: (req, res) => onClickCell(cellInfo, req, res),
                                      //            style: {
                                      //              backgroundColor: `hsl(${
                                      //                120 * ((120 - cellInfo.value) / 120) * -1 + 120
                                      //              }, 100%, 67%)`,
                                      //            },
                                    })}
                                  />
                                </Styles>
                              </List>
                            </CardContent>
                          </Card>
                        </Box>
                      }
                    </div>
                  </TabPanel>

                  <TabPanel value="7" sx={{ p: 0 }}>
                    <div
                      style={{ marginTop: 0, height: !isXSmall ? 600 : 400 }}
                    >
                      {
                        <Box>
                          <Card
                            sx={{
                              minWidth: 275,
                              height: !isXSmall ? 680 : 400,
                            }}
                          >
                            <CardContent>
                              <div
                                //            className="hstack gap-2"
                                style={{
                                  marginTop: 0,
                                  display: "flex",
                                  height: "100%",

                                  //justifyContent: "space-between",
                                }}
                              >
                                <Typography
                                  id="outlined-basic"
                                  label="Клиент"
                                  //variant="standard"
                                  className="title h6"
                                  style={{
                                    marginTop: 0,

                                    marginBottom: 15,
                                    marginRight: 40,
                                    //display: 'flex',
                                    //justifyContent: 'space-between',
                                  }}
                                >
                                  {translate("resources.manager.name") + " :"}
                                </Typography>
                                <div>
                                  {(SelectedManager && SelectedManager.name) ||
                                    translate("resources.home.notmanager")}
                                </div>
                              </div>
                              <List
                                style={{
                                  maxHeight: "600px",
                                  overflow: "auto",
                                  height: "100%", //380,
                                }}
                              >
                                <Styles>
                                  <Table_D
                                    columns={columns7}
                                    data={data7}
                                    getHeaderProps={
                                      (nameTheme &&
                                        nameTheme !== "dark" &&
                                        ((row) => {
                                          return {
                                            style: {
                                              //                   background:
                                              //                   row.index % 2 === 0 ? 'rgba(0,0,0,.1)' : 'white',
                                            },
                                          };
                                        })) ||
                                      ((row) => {
                                        return {
                                          style: {
                                            color: "#ffffffb3",
                                            //                    background:
                                            //                    row.index % 2 === 0 ? 'rgba(0,0,0,.1)' : '#ffffffb3', //'#616161e6', //'#ffffffb3',
                                          },
                                        };
                                      })
                                    }
                                    /*          
          getHeaderProps={(column) => ({
            onClick: () => alert('Header!'),
          })}
          getColumnProps={(column) => ({
            onClick: () => alert('Column!'),
          })}
          */
                                    getRowProps={
                                      (nameTheme &&
                                        nameTheme !== "dark" &&
                                        ((row) => {
                                          return {
                                            style: {
                                              background:
                                                row.index % 2 === 0
                                                  ? "rgba(0,0,0,.1)"
                                                  : "white",
                                            },
                                          };
                                        })) ||
                                      ((row) => {
                                        return {
                                          style: {
                                            background:
                                              row.index % 2 === 0
                                                ? "rgba(0,0,0,.1)"
                                                : "#616161e6", //'#ffffffb3',
                                          },
                                        };
                                      })
                                    }
                                    getCellProps={(cellInfo) => ({
                                      //            onClick: (req, res) => onClickCell(cellInfo, req, res),
                                      //            style: {
                                      //              backgroundColor: `hsl(${
                                      //                120 * ((120 - cellInfo.value) / 120) * -1 + 120
                                      //              }, 100%, 67%)`,
                                      //            },
                                    })}
                                  />
                                </Styles>
                              </List>
                            </CardContent>
                          </Card>
                        </Box>
                      }
                    </div>
                  </TabPanel>
                  <TabPanel value="8" sx={{ p: 0 }}>
                    <div
                      style={{ marginTop: 0, height: !isXSmall ? 600 : 400 }}
                    >
                      {
                        <Box>
                          <Card
                            sx={{
                              minWidth: 275,
                              height: !isXSmall ? 680 : 400,
                            }}
                          >
                            <CardContent>
                              <div
                                //            className="hstack gap-2"
                                style={{
                                  marginTop: 0,
                                  display: "flex",
                                  height: "100%",

                                  //justifyContent: "space-between",
                                }}
                              >
                                <Typography
                                  id="outlined-basic"
                                  label="Клиент"
                                  //variant="standard"
                                  className="title h6"
                                  style={{
                                    marginTop: 0,

                                    marginBottom: 15,
                                    marginRight: 40,
                                    //display: 'flex',
                                    //justifyContent: 'space-between',
                                  }}
                                >
                                  {translate("resources.home.store") + " :"}
                                </Typography>
                                <div>
                                  {((SelectedStore && SelectedStore.name) ||
                                    translate("resources.home.notstore")) +
                                    ((SelectedStore &&
                                      SelectedStore.customer &&
                                      "  ( " +
                                        SelectedStore.customer.name +
                                        " )") ||
                                      " - (" +
                                        translate(
                                          "resources.home.notcustomer"
                                        ) +
                                        ")")}
                                </div>
                              </div>
                              <List
                                style={{
                                  maxHeight: "600px",
                                  overflow: "auto",
                                  height: "100%", //380,
                                }}
                              >
                                <Styles>
                                  <Table_I
                                    columns={columns8}
                                    data={data8}
                                    getHeaderProps={
                                      (nameTheme &&
                                        nameTheme !== "dark" &&
                                        ((row) => {
                                          return {
                                            style: {
                                              //                   background:
                                              //                   row.index % 2 === 0 ? 'rgba(0,0,0,.1)' : 'white',
                                            },
                                          };
                                        })) ||
                                      ((row) => {
                                        return {
                                          style: {
                                            color: "#ffffffb3",
                                            //                    background:
                                            //                    row.index % 2 === 0 ? 'rgba(0,0,0,.1)' : '#ffffffb3', //'#616161e6', //'#ffffffb3',
                                          },
                                        };
                                      })
                                    }
                                    /*          
          getHeaderProps={(column) => ({
            onClick: () => alert('Header!'),
          })}
          getColumnProps={(column) => ({
            onClick: () => alert('Column!'),
          })}
          */
                                    getRowProps={
                                      (nameTheme &&
                                        nameTheme !== "dark" &&
                                        ((row) => {
                                          return {
                                            style: {
                                              background:
                                                row.index % 2 === 0
                                                  ? "rgba(0,0,0,.1)"
                                                  : "white",
                                            },
                                          };
                                        })) ||
                                      ((row) => {
                                        return {
                                          style: {
                                            background:
                                              row.index % 2 === 0
                                                ? "rgba(0,0,0,.1)"
                                                : "#616161e6", //'#ffffffb3',
                                          },
                                        };
                                      })
                                    }
                                    getCellProps={(cellInfo) => ({
                                      //            onClick: (req, res) => onClickCell(cellInfo, req, res),
                                      //            style: {
                                      //              backgroundColor: `hsl(${
                                      //                120 * ((120 - cellInfo.value) / 120) * -1 + 120
                                      //              }, 100%, 67%)`,
                                      //            },
                                    })}
                                  />
                                </Styles>
                              </List>
                            </CardContent>
                          </Card>
                        </Box>
                      }
                    </div>
                  </TabPanel>
                  <TabPanel value="9" sx={{ p: 0 }}>
                    <div
                      style={{ marginTop: 0, height: !isXSmall ? 600 : 400 }}
                    >
                      {
                        <Box>
                          <Card
                            sx={{
                              minWidth: 275,
                              height: !isXSmall ? 680 : 400,
                            }}
                          >
                            <CardContent>
                              <div
                                //            className="hstack gap-2"
                                style={{
                                  marginTop: 0,
                                  display: "flex",
                                  height: "100%",

                                  //justifyContent: "space-between",
                                }}
                              >
                                <Typography
                                  id="outlined-basic"
                                  label="Клиент"
                                  //variant="standard"
                                  className="title h6"
                                  style={{
                                    marginTop: 0,

                                    marginBottom: 15,
                                    marginRight: 40,
                                    //display: 'flex',
                                    //justifyContent: 'space-between',
                                  }}
                                >
                                  {translate("resources.manager.name") + " :"}
                                </Typography>
                                <div>
                                  {(SelectedManager && SelectedManager.name) ||
                                    translate("resources.manager.noname")}
                                </div>
                              </div>
                              <List
                                style={{
                                  maxHeight: "600px",
                                  overflow: "auto",
                                  height: "100%", //380,
                                }}
                              >
                                <Styles>
                                  <Table_P
                                    columns={columns9}
                                    data={data9}
                                    getHeaderProps={
                                      (nameTheme &&
                                        nameTheme !== "dark" &&
                                        ((row) => {
                                          return {
                                            style: {
                                              //                   background:
                                              //                   row.index % 2 === 0 ? 'rgba(0,0,0,.1)' : 'white',
                                            },
                                          };
                                        })) ||
                                      ((row) => {
                                        return {
                                          style: {
                                            color: "#ffffffb3",
                                            //                    background:
                                            //                    row.index % 2 === 0 ? 'rgba(0,0,0,.1)' : '#ffffffb3', //'#616161e6', //'#ffffffb3',
                                          },
                                        };
                                      })
                                    }
                                    /*          
          getHeaderProps={(column) => ({
            onClick: () => alert('Header!'),
          })}
          getColumnProps={(column) => ({
            onClick: () => alert('Column!'),
          })}
          */
                                    getRowProps={
                                      (nameTheme &&
                                        nameTheme !== "dark" &&
                                        ((row) => {
                                          return {
                                            style: {
                                              background:
                                                row.index % 2 === 0
                                                  ? "rgba(0,0,0,.1)"
                                                  : "white",
                                            },
                                          };
                                        })) ||
                                      ((row) => {
                                        return {
                                          style: {
                                            background:
                                              row.index % 2 === 0
                                                ? "rgba(0,0,0,.1)"
                                                : "#616161e6", //'#ffffffb3',
                                          },
                                        };
                                      })
                                    }
                                    getCellProps={(cellInfo) => ({
                                      //            onClick: (req, res) => onClickCell(cellInfo, req, res),
                                      //            style: {
                                      //              backgroundColor: `hsl(${
                                      //                120 * ((120 - cellInfo.value) / 120) * -1 + 120
                                      //              }, 100%, 67%)`,
                                      //            },
                                    })}
                                  />
                                </Styles>
                              </List>
                            </CardContent>
                          </Card>
                        </Box>
                      }
                    </div>
                  </TabPanel>
                  <TabPanel value="10" sx={{ p: 0 }}>
                    <div
                      style={{ marginTop: 0, height: !isXSmall ? 600 : 400 }}
                    >
                      {
                        <Box>
                          <Card
                            sx={{
                              minWidth: 275,
                              height: !isXSmall ? 680 : 400,
                            }}
                          >
                            <CardContent>
                              <div
                                //            className="hstack gap-2"
                                style={{
                                  marginTop: 0,
                                  display: "flex",
                                  height: "100%",

                                  //justifyContent: "space-between",
                                }}
                              >
                                <Typography
                                  id="outlined-basic"
                                  label="Клиент"
                                  //variant="standard"
                                  className="title h6"
                                  style={{
                                    marginTop: 0,

                                    marginBottom: 15,
                                    marginRight: 40,
                                    //display: 'flex',
                                    //justifyContent: 'space-between',
                                  }}
                                >
                                  {translate("resources.manager.name") + " :"}
                                </Typography>
                                <div>
                                  {(SelectedManager && SelectedManager.name) ||
                                    translate("resources.home.notmanager")}
                                </div>
                              </div>
                              <List
                                style={{
                                  maxHeight: "600px",
                                  overflow: "auto",
                                  height: "100%", //380,
                                }}
                              >
                                <Styles>
                                  <Table_T
                                    columns={columns10}
                                    data={data10}
                                    getHeaderProps={
                                      (nameTheme &&
                                        nameTheme !== "dark" &&
                                        ((row) => {
                                          return {
                                            style: {
                                              //                   background:
                                              //                   row.index % 2 === 0 ? 'rgba(0,0,0,.1)' : 'white',
                                            },
                                          };
                                        })) ||
                                      ((row) => {
                                        return {
                                          style: {
                                            color: "#ffffffb3",
                                            //                    background:
                                            //                    row.index % 2 === 0 ? 'rgba(0,0,0,.1)' : '#ffffffb3', //'#616161e6', //'#ffffffb3',
                                          },
                                        };
                                      })
                                    }
                                    /*          
          getHeaderProps={(column) => ({
            onClick: () => alert('Header!'),
          })}
          getColumnProps={(column) => ({
            onClick: () => alert('Column!'),
          })}
          */
                                    getRowProps={
                                      (nameTheme &&
                                        nameTheme !== "dark" &&
                                        ((row) => {
                                          return {
                                            style: {
                                              background:
                                                row.index % 2 === 0
                                                  ? "rgba(0,0,0,.1)"
                                                  : "white",
                                            },
                                          };
                                        })) ||
                                      ((row) => {
                                        return {
                                          style: {
                                            background:
                                              row.index % 2 === 0
                                                ? "rgba(0,0,0,.1)"
                                                : "#616161e6", //'#ffffffb3',
                                          },
                                        };
                                      })
                                    }
                                    getCellProps={(cellInfo) => ({
                                      //            onClick: (req, res) => onClickCell(cellInfo, req, res),
                                      //            style: {
                                      //              backgroundColor: `hsl(${
                                      //                120 * ((120 - cellInfo.value) / 120) * -1 + 120
                                      //              }, 100%, 67%)`,
                                      //            },
                                    })}
                                  />
                                </Styles>
                              </List>
                            </CardContent>
                          </Card>
                        </Box>
                      }
                    </div>
                  </TabPanel>
                </TabContext>
              </Box>
              <Box
                style={{
                  width: !isXSmall ? "40%" : "100%",
                  height: !isXSmall ? "100%" : "60%",
                  //marginTop: 50,

                  // justifyContent: "space-between"
                }}
              >
                <Card
                  style={{
                    marginTop: !isXSmall ? 50 : 0,
                    height: "91%",
                  }}
                >
                  {/* style={{ width: "25%", height: "100%", marginTop: 50 }}> */}
                  <CardContent>
                    {/*                      <RightPanel /> */}
                    <>
                      <TextField
                        id="outlined-basic"
                        label={translate("resources.route.fields.manager")} //"Тип работ"
                        //variant="standard"
                        variant="outlined"
                        style={{
                          //marginBottom: 15,
                          //marginLeft: 20,
                          width: "100%",
                          //display: 'flex',
                          //justifyContent: 'space-between',
                        }}
                        value={(SelectedManager && SelectedManager.name) || ""}
                        /*                          onChange={(item) => {
                            if (!getAccessToChange()) {
                              route_item.change = true
                              dispatch({
                                type: 'app/set',
                                payload: {
                                  id: route_item,
                                  field: 'name',
                                  value: item.target.value,
                                },
                                ob: 'route_item',
                              })
                            }
                          }}
*/
                      ></TextField>
                      <LocalizationProvider
                        dateAdapter={AdapterDayjs}
                        adapterLocale={language}
                      >
                        <DesktopDatePicker
                          label={translate("resources.home.date")} //"Дата с:"
                          onFocus={(e) => (e.target.readOnly = true)} //выключение клавиатуры при выборе значения
                          //                defaultValue={startDate}
                          value={SelectedDate} //startDate}
                          //inputFormat="dd.MM.yyyy"
                          onChange={onChangeDate}
                          renderInput={(params) => (
                            <TextField
                              sx={{ width: "100%" }}
                              variant="outlined"
                              {...params}
                            />
                          )}
                        />
                      </LocalizationProvider>
                      <Autocomplete
                        //multiple
                        id="size-small-outlined" //      id="checkboxes-tags"
                        options={supervisors}
                        onChange={changeSupervisor}
                        size="small"
                        value={SelectedSupervisor}
                        //                defaultValue={(worker2 && worker2[0]) || []}
                        limitTags={4}
                        //              filterSelectedOptions
                        isOptionEqualToValue={(props, option) => {
                          let rez = false;
                          if (props.value === option.value) rez = true;
                          return rez;
                        }}
                        selectOnFocus
                        clearOnBlur
                        onFocus={(e) => {
                          //            console.log(e.target.readOnly)
                          return (e.target.readOnly = true);
                        }} //выключение клавиатуры при выборе      handleHomeEndKeys
                        //      disableCloseOnSelect
                        getOptionLabel={(option) => option.label}
                        renderOption={(props, option, { selected }) => {
                          return (
                            <li {...props}>
                              <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={selected}
                              />
                              {option.label}
                            </li>
                          );
                        }}
                        style={{ width: "100%" }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={translate(
                              "resources.home.selectacsupervisor"
                            )} //""Выберите супервайзера" //Выберите Тип доступа"
                            variant="outlined"
                            //          variant="standard"
                            //          placeholder="Выберите Мастера"
                          />
                        )}
                      />

                      <TabContext value={tabR} sx={{ p: 0 }}>
                        <Box
                          sx={{
                            borderBottom: 0,
                            borderColor: "divider",
                            p: 0,
                          }}
                        >
                          <TabList
                            onChange={(_, value) => {
                              OnChangeTab2(value);
                              setTabR(value);
                            }}
                            aria-label="lab API tabs example"
                          >
                            <Tab
                              label={translate("resources.home.managers")}
                              value="1"
                            />
                            <Tab
                              label={translate("resources.home.routes")}
                              value="2"
                            />
                          </TabList>
                        </Box>
                        <TabPanel value="1" sx={{ p: 0 }}>
                          <>
                            <List
                              style={{
                                maxHeight: "450px",
                                overflow: "auto",
                                height: "100%",
                              }}
                            >
                              <Styles>
                                <Table
                                  columns={columns}
                                  data={data}
                                  getHeaderProps={
                                    (nameTheme &&
                                      nameTheme !== "dark" &&
                                      ((row) => {
                                        return {
                                          style: {
                                            //                   background:
                                            //                   row.index % 2 === 0 ? 'rgba(0,0,0,.1)' : 'white',
                                          },
                                        };
                                      })) ||
                                    ((row) => {
                                      return {
                                        style: {
                                          color: "#ffffffb3",
                                          //                    background:
                                          //                    row.index % 2 === 0 ? 'rgba(0,0,0,.1)' : '#ffffffb3', //'#616161e6', //'#ffffffb3',
                                        },
                                      };
                                    })
                                  }
                                  /*          
          getHeaderProps={(column) => ({
            onClick: () => alert('Header!'),
          })}
          getColumnProps={(column) => ({
            onClick: () => alert('Column!'),
          })}
          */
                                  getRowProps={
                                    (nameTheme &&
                                      nameTheme !== "dark" &&
                                      ((row) => {
                                        return {
                                          style: {
                                            background:
                                              row.index % 2 === 0
                                                ? "rgba(0,0,0,.1)"
                                                : "white",
                                          },
                                        };
                                      })) ||
                                    ((row) => {
                                      return {
                                        style: {
                                          background:
                                            row.index % 2 === 0
                                              ? "rgba(0,0,0,.1)"
                                              : "#616161e6", //'#ffffffb3',
                                        },
                                      };
                                    })
                                  }
                                  getCellProps={(cellInfo) => ({
                                    //            onClick: (req, res) => onClickCell(cellInfo, req, res),
                                    //            style: {
                                    //              backgroundColor: `hsl(${
                                    //                120 * ((120 - cellInfo.value) / 120) * -1 + 120
                                    //              }, 100%, 67%)`,
                                    //            },
                                  })}
                                />
                              </Styles>
                            </List>
                          </>
                        </TabPanel>
                        <TabPanel value="2" sx={{ p: 0 }}>
                          <>
                            <List
                              style={{
                                maxHeight: "450px",
                                overflow: "auto",
                                height: "100%",
                              }}
                            >
                              <Styles>
                                <Table1
                                  columns={columns1}
                                  data={data1}
                                  getHeaderProps={
                                    (nameTheme &&
                                      nameTheme !== "dark" &&
                                      ((row) => {
                                        return {
                                          style: {
                                            //                   background:
                                            //                   row.index % 2 === 0 ? 'rgba(0,0,0,.1)' : 'white',
                                          },
                                        };
                                      })) ||
                                    ((row) => {
                                      return {
                                        style: {
                                          color: "#ffffffb3",
                                          //                    background:
                                          //                    row.index % 2 === 0 ? 'rgba(0,0,0,.1)' : '#ffffffb3', //'#616161e6', //'#ffffffb3',
                                        },
                                      };
                                    })
                                  }
                                  /*          
          getHeaderProps={(column) => ({
            onClick: () => alert('Header!'),
          })}
          getColumnProps={(column) => ({
            onClick: () => alert('Column!'),
          })}
          */
                                  getRowProps={
                                    (nameTheme &&
                                      nameTheme !== "dark" &&
                                      ((row) => {
                                        return {
                                          style: {
                                            background:
                                              row.index % 2 === 0
                                                ? "rgba(0,0,0,.1)"
                                                : "white",
                                          },
                                        };
                                      })) ||
                                    ((row) => {
                                      return {
                                        style: {
                                          background:
                                            row.index % 2 === 0
                                              ? "rgba(0,0,0,.1)"
                                              : "#616161e6", //'#ffffffb3',
                                        },
                                      };
                                    })
                                  }
                                  getCellProps={(cellInfo) => ({
                                    //            onClick: (req, res) => onClickCell(cellInfo, req, res),
                                    //            style: {
                                    //              backgroundColor: `hsl(${
                                    //                120 * ((120 - cellInfo.value) / 120) * -1 + 120
                                    //              }, 100%, 67%)`,
                                    //            },
                                  })}
                                />
                              </Styles>
                            </List>
                          </>
                        </TabPanel>
                      </TabContext>
                    </>
                  </CardContent>
                </Card>
              </Box>
            </Box>
          </LocalizationProvider>
          {/* Alert Dialog */}
          <AlertDialog />
          <OrderDialog />
          <DeliveryDialog />
          <InvoiceDialog />
        </>
      )}
      {!ready() && (
        <>
          <Box maxWidth={maxwidth}>
            <Card>
              <CardContent>
                <Spinner />
              </CardContent>
            </Card>
          </Box>
        </>
      )}
    </>
  );
};

export default Home;
