import React, { useContext, useState, useEffect } from 'react'
//import { Form, ListGroup, Row, Col } from 'react-bootstrap'
import Button from '@mui/material/Button'
import { Link } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { useMediaQuery } from '@mui/material'
import { AuthContext } from '../../../context/AuthContext'

import moment from 'moment'
import { useTable } from 'react-table'
import styled from 'styled-components'
import { getAccessCode } from '../../../access'
import { useCallback } from 'react'
import SelectViberUser from '../../../components/SelectViberUser'

import FormControlLabel from '@mui/material/FormControlLabel'
import Switch, { SwitchProps } from '@mui/material/Switch'

import Checkbox from '@mui/material/Checkbox'
import Typography from '@material-ui/core/Typography'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBoxIcon from '@mui/icons-material/CheckBox'

import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
//import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import 'dayjs/locale/ru'
import 'dayjs/locale/uk'

import { Card, CardContent, Box } from '@mui/material'
import { useTranslate } from '../../../lib/translate/i18n/useTranslate'

import { getURLAPI } from '../../../utils/getURL'

import { default as Spinner } from '../../../components/Spinner'

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />
const checkedIcon = <CheckBoxIcon fontSize="small" />

const Styles = styled.div`
  table {
    border-spacing: 0;
    border: 1px solid #c3c3c3;
    border-radius: 10px;
    border-collapse: initial;
    tr {
      border-radius: 0px;

      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th,
    td {
      margin: 0;
      padding: 0.5rem;
      border-bottom: 1px solid #c3c3c3;
      border-right: 1px solid #c3c3c3;

      :last-child {
        border-right: 0;
      }
    }
  }
`

const defaultPropGetter = () => ({})

function Table({
  columns,
  data,
  getHeaderProps = defaultPropGetter,
  getColumnProps = defaultPropGetter,
  getRowProps = defaultPropGetter,
  getCellProps = defaultPropGetter,
}) {
  // Use the state and functions returned from useTable to build your UI
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns,
      data,
    })

  return (
    <table {...getTableProps()}>
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <th
                // Return an array of prop objects and react-table will merge them appropriately
                {...column.getHeaderProps([
                  {
                    className: column.className,
                    style: column.style,
                  },
                  getColumnProps(column),

                  getHeaderProps(column),
                ])}
              >
                {column.render('Header')}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row, i) => {
          prepareRow(row)
          return (
            // Merge user row props in
            <tr {...row.getRowProps(getRowProps(row))}>
              {row.cells.map((cell) => {
                //                console.log(cell)
                return (
                  <td
                    // Return an array of prop objects and react-table will merge them appropriately
                    {...cell.getCellProps([
                      {
                        className: cell.column.className,
                        style: cell.column.style,
                      },
                      getColumnProps(cell.column),
                      //  getCellProps(cell),
                    ])}
                    align="center"
                    //                    width="140px"
                  >
                    {(cell.column.id !== 'timeline' && (
                      <Link
                        as={Link}
                        variant="outline-primary"
                        //style={{ marginBottom: 5 }}

                        to={{
                          pathname: '/pages/worktime/item',
                        }}
                        state={{
                          id: cell.row.original.worktime.id, //cell.row.original['worktime_id'],
                          arg: {
                            parenthtml: '/pages/customers/item',
                            parentid: cell.row.original.id,
                          },
                        }}
                      >
                        {cell.render('Cell')}
                      </Link>
                    )) ||
                      cell.render('Cell')}
                  </td>
                )
              })}
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}

var columns = []
var data = []

//History
function Table2({
  columns,
  data,
  getHeaderProps = defaultPropGetter,
  getColumnProps = defaultPropGetter,
  getRowProps = defaultPropGetter,
  getCellProps = defaultPropGetter,
}) {
  // Use the state and functions returned from useTable to build your UI
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns,
      data,
    })

  return (
    <table {...getTableProps()}>
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <th
                // Return an array of prop objects and react-table will merge them appropriately
                {...column.getHeaderProps([
                  {
                    className: column.className,
                    style: column.style,
                  },
                  getColumnProps(column),

                  getHeaderProps(column),
                ])}
              >
                {column.render('Header')}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row, i) => {
          prepareRow(row)
          return (
            // Merge user row props in
            <tr {...row.getRowProps(getRowProps(row))}>
              {row.cells.map((cell) => {
                let colorbutton = 'primary'
                let variant = 'outlined'
                if (cell.row.original.color2 === 'green') {
                  colorbutton = 'success'
                  variant = 'contained'
                }
                return (
                  <td
                    // Return an array of prop objects and react-table will merge them appropriately
                    {...cell.getCellProps([
                      {
                        className: cell.column.className,
                        style: cell.column.style,
                      },
                      getColumnProps(cell.column),
                      //  getCellProps(cell),
                    ])}
                    align="center"
                    //                    width="140px"
                  >
                    {(cell.column.id === 'times' &&
                      !cell.row.original.worktime && (
                        <Button
                          size="small"
                          //component={Link}
                          color={colorbutton} //"primary"
                          variant={variant} //"outlined"
                          sx={{ display: 'inline-flex', alignItems: 'center' }}
                          //                        as={Link}
                          //                          variant="outline-primary"
                          style={{ marginBottom: 5 }}
                          onClick={() => {
                            //                        console.log('CLICK!!!', cell.row.original)
                            cell.row.original.fn(
                              cell.row.original.time1,
                              cell.row.original.time2
                            )
                          }}
                          /*
                          onClick={(item) => {
                            const change = true
                            const dispatch = useDispatch()

                            dispatch({
                              type: 'app/set',
                              payload: {
                                id: cell.row.original.worktime_item,
                                field: 'time1',
                                value: cell.row.original.time1,
                              },
                              ob: 'worktime_item',
                            })
                            dispatch({
                              type: 'app/set',
                              payload: {
                                id: cell.row.original.worktime_item,
                                field: 'time2',
                                value: cell.row.original.time2,
                              },
                              ob: 'worktime_item',
                            })
                          }}

                          /*                          to={{
                            pathname: '/pages/worktime/item',
                          }}
                          /*                        state={{
                          id: cell.row.original.worktime.id, //cell.row.original['worktime_id'],
                          arg: { parenthtml: '/pages/worktime/list' },
                        }}
  */
                        >
                          {cell.render('Cell')}
                        </Button>
                      )) ||
                      cell.render('Cell')}
                  </td>
                )
              })}
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}

let columns2 = []
let data2 = []

//Messages
function Table3({
  columns,
  data,
  getHeaderProps = defaultPropGetter,
  getColumnProps = defaultPropGetter,
  getRowProps = defaultPropGetter,
  getCellProps = defaultPropGetter,
}) {
  // Use the state and functions returned from useTable to build your UI
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns,
      data,
    })

  return (
    <table {...getTableProps()}>
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <th
                // Return an array of prop objects and react-table will merge them appropriately
                {...column.getHeaderProps([
                  {
                    className: column.className,
                    style: column.style,
                  },
                  getColumnProps(column),

                  getHeaderProps(column),
                ])}
              >
                {column.render('Header')}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row, i) => {
          prepareRow(row)
          return (
            // Merge user row props in
            <tr {...row.getRowProps(getRowProps(row))}>
              {row.cells.map((cell) => {
                let colorbutton = 'primary'
                let variant = 'outlined'
                if (cell.row.original.color2 === 'green') {
                  colorbutton = 'success'
                  variant = 'contained'
                }
                return (
                  <td
                    // Return an array of prop objects and react-table will merge them appropriately
                    {...cell.getCellProps([
                      {
                        className: cell.column.className,
                        style: cell.column.style,
                      },
                      getColumnProps(cell.column),
                      //  getCellProps(cell),
                    ])}
                    align="center"
                    //                    width="140px"
                  >
                    {(cell.column.id === 'times' &&
                      !cell.row.original.worktime && (
                        <Button
                          size="small"
                          //component={Link}
                          color={colorbutton} //"primary"
                          variant={variant} //"outlined"
                          sx={{ display: 'inline-flex', alignItems: 'center' }}
                          //                        as={Link}
                          //                          variant="outline-primary"
                          style={{ marginBottom: 5 }}
                          onClick={() => {
                            //                        console.log('CLICK!!!', cell.row.original)
                            cell.row.original.fn(
                              cell.row.original.time1,
                              cell.row.original.time2
                            )
                          }}
                          /*
                          onClick={(item) => {
                            const change = true
                            const dispatch = useDispatch()

                            dispatch({
                              type: 'app/set',
                              payload: {
                                id: cell.row.original.worktime_item,
                                field: 'time1',
                                value: cell.row.original.time1,
                              },
                              ob: 'worktime_item',
                            })
                            dispatch({
                              type: 'app/set',
                              payload: {
                                id: cell.row.original.worktime_item,
                                field: 'time2',
                                value: cell.row.original.time2,
                              },
                              ob: 'worktime_item',
                            })
                          }}

                          /*                          to={{
                            pathname: '/pages/worktime/item',
                          }}
                          /*                        state={{
                          id: cell.row.original.worktime.id, //cell.row.original['worktime_id'],
                          arg: { parenthtml: '/pages/worktime/list' },
                        }}
  */
                        >
                          {cell.render('Cell')}
                        </Button>
                      )) ||
                      cell.render('Cell')}
                  </td>
                )
              })}
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}

let columns3 = []
let data3 = []

let columns4 = []
let data4 = []

const Customers = () => {
  const customers_item = useSelector((state) => state.app.body.customers_item)
  //  const auth = useContext(AuthContext)
  const auth = useContext(AuthContext)
  const current = useSelector((state) => state.app.current)
  const app = useSelector((state) => state.app)
  const body = useSelector((state) => state.app.body)
  const access = useSelector((state) => state.app.access.access)
  const isadmin = (access && access.isadmin) || false
  const isXSmall = useMediaQuery((theme) => theme.breakpoints.down('sm'))

  const [DopInfoOn, setDopInfoOn] = useState(false)
  const [HistoryOn, setHistoryOn] = useState(false)
  const [WorkTimeOn, setWorkTimeOn] = useState(false)
  const [MessagesOn, setMessagesOn] = useState(false)

  let maxwidth = '30em'

  if (HistoryOn) maxwidth = '80em'
  if (MessagesOn) maxwidth = '50em'
  if (WorkTimeOn) maxwidth = '40em'

  const nameTheme = access && access.user && access.user.theme
  const translate = useTranslate()
  const dispatch = useDispatch()

  const [subPages, setSubPages] = useState({ page: 'main' })

  //START get all str from table Roles
  //console.log('Refresh!!! customers...')
  const [startRefreshFilter, setStartRefreshFilter] = useState(true)
  const [startRefresh, setStartRefresh] = useState(true)

  //замыкание
  const ws_client = auth.ws_client //body.ws_client

  const item_id = body.item_id

  const readonly = body.readonly

  const getCustomerItem = function (_result, _parent) {
    //console.log(_result)
    if (_result && _result.result && _result.result.status === 'err') {
      console.log('Err', _result.result.message)
      return
    }
    let { parent, item, getid = null } = _result && _result.result
    dispatch({
      type: 'fetch/item_add',
      payload: { item, getid }, //parent,
      ob: 'store',
    })
    setSubPages({ page: 'store' })
  }

  const setCustomerItem = function (_result, _parent) {
    //console.log(_result)
    if (_result && _result.result && _result.result.status === 'err') {
      console.log('Err', _result.result.message)
      setSubPages({ page: 'main' })
      return
    }

    if (_result) _result.result.change = true
    dispatch({
      type: 'fetch/get_add',
      payload: _result && _result.result,
      ob: 'customers_item',
    })
    setSubPages({ page: 'main' })
  }

  const GetData_handle = function (_result) {
    const { tableName, result, readonly, action } = _result
    //console.log('result handle1 ', result

    if (tableName === 'Segments') {
      dispatch({
        type: 'fetch/filter', //'fetch/get_add',
        payload: result,
        ob: 'segment',
      })
    }

    if (tableName === 'Users' && action.metod === 'getUserName') {
      dispatch({
        type: 'fetch/filter', //'fetch/get_add',
        payload: result,
        ob: 'worker',
      })
    }

    if (tableName === 'Users' && action.metod === 'getUserSegments') {
      dispatch({
        type: 'fetch/get_add',
        payload: result,
        ob: 'usersegments',
      })
    }

    if (tableName === 'Messages') {
      dispatch({
        type: 'fetch/get_add',
        payload: result,
        ob: 'customermessages',
      })
    }

    if (tableName === 'WorkTimes') {
      dispatch({
        type: 'fetch_current/get',
        payload: result,
        ob: 'worktime',
      })
    }

    if (tableName === 'Customers' && action.metod && action.metod === 'get') {
      dispatch({
        type: 'fetch/filter', //'fetch/get_add',
        payload: result,
        ob: 'customers',
      })
    }
    if (
      tableName === 'Customers' &&
      action.metod &&
      action.metod === 'getOne'
    ) {
      dispatch({
        type: 'fetch/get_add', //'fetch/get_add',
        payload: result,
        ob: 'customers_item',
      })
      dispatch({
        type: 'fetch/get_add',
        payload: readonly,
        ob: 'readonly',
      })
    }
  }

  const Refresh_handle = async function (result, event, parent) {
    const { tableName, action, metod } = result
    /*console.log(
      'Refresh_handle ',
      event,
      new Date().toLocaleTimeString(),
      result
    )*/
    setStartRefresh(true)
  }

  const paramsFilter = {
    URL_WS: getURLAPI(auth.config, 'URL_WS'), //get address WebSocket BK from config
    auth: { userId: auth.userId, tocken: auth.token },
    handleGetData: GetData_handle,
    //handleChangeData: Refresh_handle,
    handleSetRefresh: setStartRefresh,
    handleErrorRead: null,
    handleErrorChange: null,
    models: [
      {
        model_call_nm: 'Model_read',
        tableName: 'Segments',
        action: {
          //          metod: 'get',
        },
      },
      {
        model_call_nm: 'Model_read',
        tableName: 'Users',
        action: {
          metod: 'getUserName',
        },
      },
      {
        model_call_nm: 'Model_read',
        tableName: 'Customers',
        action: {
          metod: 'get',
        },
      },
      {
        model_call_nm: 'Model_read',
        tableName: 'Users',
        action: {
          metod: 'getUserSegments',
          id: auth.userId,
        },
      },
      {
        model_call_nm: 'Model_read',
        tableName: 'Messages',
        where: { id: item_id },
        action: {
          metod: 'getCustomerMessages',
        },
      },
      {
        model_call_nm: 'Model_read',
        tableName: 'WorkTimes',
        //where: { id: item_id },
        action: {
          metod: 'get',
          // metod: 'getCustomerMessages',
        },
      },
    ],
  }

  const params = {
    URL_WS: getURLAPI(auth.config, 'URL_WS'), //get address WebSocket BK from config
    auth: { userId: auth.userId, tocken: auth.token },
    handleGetData: GetData_handle,
    //handleChangeData: Refresh_handle,
    handleSetRefresh: setStartRefresh,
    handleErrorRead: null,
    handleErrorChange: null,
    models: [
      {
        model_call_nm: 'Model_read',
        tableName: 'Customers',
        where: { id: item_id },
        action: {
          metod: 'getOne',
          isBlocked: true,
        },
      },
    ],
  }

  //deblock item whet close page
  useEffect(
    () => () => {
      try {
        if (!readonly)
          ws_client &&
            ws_client.deblock({ tableName: 'Customers', id: item_id })
      } catch (err) {
        console.log('deblock error ', err)
      }
    },
    []
  )

  if (ws_client && ws_client.ws_login && startRefreshFilter) {
    setStartRefreshFilter(false)
    ws_client.open_process(paramsFilter)
  }

  if (item_id && ws_client && ws_client.ws_login && startRefresh) {
    setStartRefresh(false)
    ws_client.open_process(params)
  }

  //END get all str from table Roles

  const config = auth.config
  let URLAPI = getURLAPI(config)

  let language = 'ru'
  if (access && access.user && access.user.language) {
    if (access.user.language === 'ru') language = 'ru'
    if (access.user.language === 'ua') language = 'uk'
    if (access.user.language === 'en') language = 'en'
  }

  let greybkcolor = 'rgba(255,255,255,.3)'

  if (nameTheme) {
    greybkcolor =
      nameTheme !== 'dark' ? 'rgba(255,255,255,1.0)' : 'rgba(255,255,255,.3)'
  }

  const handleChangeDopInfo = useCallback((event) => {
    setDopInfoOn(!DopInfoOn)
  })

  const handleChangeHistory = useCallback((event) => {
    setHistoryOn(!HistoryOn)
    setMessagesOn(false)
    setWorkTimeOn(false)
  })

  const handleChangeWorkTime = useCallback((event) => {
    setWorkTimeOn(!WorkTimeOn)
    setMessagesOn(false)
    setHistoryOn(false)
  })

  const handleChangeMessages = useCallback((event) => {
    setMessagesOn(!MessagesOn)
    setHistoryOn(false)
    setWorkTimeOn(false)
  })

  let date1
  let date2
  let typeworkid
  let workid

  let userworkers =
    access && access.user && access.user.workers && access.user.workers
  //console.log('userworkers', userworkers)
  let userworkerid = []
  for (let u in userworkers) {
    userworkerid.push(userworkers[u].workerid)
  }

  const AllWorkers = useSelector((state) => state.app.filter.worker_filter)
  const UserWorkers = access && access.user && access.user.workers
  let Workers
  if (isadmin) {
    Workers = AllWorkers
  } else {
    if (UserWorkers) {
      Workers = []
      for (let ii in UserWorkers) {
        Workers.push(UserWorkers[ii].user)
      }
    }
  }

  //  const [customersegments, setcustomersegments] = useState([])

  const changeSegment = (event, value) => {
    //    setcustomersegments(value)
    if (!getAccessToChange())
      dispatch({
        type: 'app/set',
        payload: {
          id: customers_item,
          field: 'csegments',
          value: value,
        },
        ob: 'customers_item',
      })
    //    auth.setWorker(value)
  }

  let usersegments = []
  if (body && body.usersegments) {
    for (let u in body.usersegments.segments)
      usersegments.push(body.usersegments.segments[u].segment.id)
  }

  let index
  let segments = []
  let Segments = app.filter.segment_filter
  for (var s in Segments) {
    index = usersegments.findIndex((el) => el == Segments[s].id)
    if (index !== -1 || isadmin)
      segments.push({
        label: Segments[s].name,
        value: Segments[s].id,
      })
  }
  let customer_segments = []
  let cs = customers_item && customers_item.segments
  if (cs) {
    for (let c in cs) {
      if (cs[c].segment)
        customer_segments.push({
          label: cs[c].segment.name,
          value: cs[c].segment.id,
        })
    }
    //    if (customersegments !== rez) setcustomersegments(rez)
  }

  if (
    customers_item &&
    customer_segments.length > 0 &&
    !customers_item.item &&
    !customers_item.csegments
  ) {
    //циклится при возврате при сохранении работы - т.к. при возвратесохраняло в item
    dispatch({
      type: 'app/set',
      payload: {
        id: customers_item,
        field: 'csegments',
        value: customer_segments,
      },
      ob: 'customers_item',
    })
  }

  var workers = []
  for (var i in Workers) {
    if (Workers[i].isworker)
      workers.push({
        label: Workers[i].fullName,
        value: Workers[i].id,
      })
  }

  const [worker2, setWorker2] = useState(auth.Worker || [])

  const changeWorker = (event, value) => {
    setWorker2(value)
    auth.setWorker(value)
  }

  const findWorks = (item, worksid) => {
    let rez = false
    for (var i = 0; i < item.items.length; i++) {
      if (item.items[i].works && item.items[i].worksid === worksid) rez = true
    }
    return rez
  }
  const findTypeWorks = (item, typeworksid) => {
    let rez = false
    for (var i = 0; i < item.items.length; i++) {
      if (
        item.items[i].works &&
        item.items[i].works.typeworksid === typeworksid
      )
        rez = true
    }
    return rez
  }
  function accessworker(workerid) {
    let rez = false
    for (let i in Workers) {
      if (Workers[i].id === workerid) rez = true
    }
    return rez
  }

  let worktimes = current.worktime
  let customerid = null

  if (customers_item && customers_item.id) customerid = customers_item.id

  const findWorkers = (item, workersid) => {
    let rez = false
    for (let t in workersid) if (item.userid === workersid[t].id) rez = true

    return rez
  }

  let workersid = []
  //if (worker2['0']) workerid = worker2['0'].value || ' '
  for (let t in worker2) workersid.push({ id: worker2[t].value })

  if (worktimes) {
    worktimes = worktimes.filter((item) => {
      //        console.log(item)
      return (
        ((accessworker(item.workerid) || isadmin) &&
          //            (item.date >= date1 || date1 == null) &&
          //            (item.date <= date2 || date2 == null) &&
          //            (findTypeWorks(item, typeworkid) || typeworkid === null) &&
          //            (findWorks(item, workid) || workid === null) &&
          (findWorkers(item, workersid) || workersid.length === 0) &&
          //(item.workerid === workerid || workerid === null) &&
          item.customersid === customerid) ||
        false
      )
    })
  }

  columns = [
    {
      Header: '№',
      accessor: 'num',
    },
    {
      Header: translate('resources.customers.fields.date'), //'Дата',
      accessor: 'date',
    },
    {
      Header: translate('resources.customers.fields.worker'), //'Мастер',
      accessor: 'worker',
    },
    {
      Header: translate('resources.customers.fields.client'), //'Клиент',
      accessor: 'customer',
    },
    {
      Header: translate('resources.customers.fields.comments'), //'Коментарии',
      accessor: 'comments',
    },
  ]
  if (!isXSmall) {
    columns.push({
      Header: translate('resources.customers.fields.status'), //'Статус',
      accessor: 'status',
    })
  }
  data = []

  //берем данные по закаам на выбранную датуы
  //      const worktimes = body.worktime.filter((item) => item.date.includes(date))
  //{worktimes && worktimes.map((Item) => <ItemList Item={Item} />)}
  let workername = ''
  let customername = ''
  let statusname = ''
  let comments = ''
  let num = 1
  for (var iw in worktimes) {
    workername = null
    customername = null
    statusname = null
    comments = null
    if (worktimes[iw].user) {
      workername = worktimes[iw].user.fullName
    }
    if (worktimes[iw].customers) {
      customername =
        worktimes[iw].customers.name +
        ' ' +
        (worktimes[iw].customers.lastName
          ? worktimes[iw].customers.lastName
          : '') +
        '/ ' +
        (worktimes[iw].customers.tel1 ? worktimes[iw].customers.tel1 : '')
    }
    if (worktimes[iw].worktimestatus) {
      statusname = worktimes[iw].worktimestatus.name
    }
    if (worktimes[iw].comments) {
      comments = worktimes[iw].comments
    }

    data.push({
      date: moment(worktimes[iw].date).format('DD.MM'),
      num: num++,
      worker: workername,
      customer: customername,
      comments: comments,
      status: statusname,
      worktime: worktimes[iw],
      id: customers_item && customers_item.id,
    })
  }

  //Table2
  columns2 = [
    {
      Header: '№',
      accessor: 'num',
    },
    {
      Header: translate('resources.customers.fields.name'), //'Имя',
      accessor: 'name',
    },
    {
      Header: translate('resources.customers.fields.lastname'), //'Фамилия',
      accessor: 'lastName',
    },
    {
      Header: translate('resources.customers.fields.tel1'), //'Телефон ',
      accessor: 'tel1',
    },
    {
      Header: translate('resources.customers.fields.changed'), //'Изменено ',
      accessor: 'datechange',
    },
    {
      Header: translate('resources.customers.fields.user'), //'User ',
      accessor: 'userinfo',
    },
  ]

  if (!isXSmall) {
    /*    columns2.push({
      Header: translate('resources.customers.fields.tel2'), //'Телефон 2',
      accessor: 'tel2',
    })*/

    columns2.push({
      Header: translate('resources.customers.fields.comments'), //'Коментарий',
      accessor: 'comments',
    })

    columns2.push({
      Header: translate('resources.customers.fields.birthday'), //'birthday',
      accessor: 'birthday',
    })
    columns2.push({
      Header: translate('resources.customers.fields.birthdaymessage'), //'birthdaymessage',
      accessor: 'birthdaymessage',
    })
    columns2.push({
      Header: translate('resources.customers.fields.viberOn'), //'viberOn',
      accessor: 'viberOn',
    })
    columns2.push({
      Header: translate('resources.customers.fields.viberid'), //'viberid',
      accessor: 'viberid',
    })
  }

  data2 = []

  let num2 = 1
  if (customers_item && customers_item.historis) {
    const customers2 = customers_item.historis

    for (var iw2 in customers2) {
      data2.push({
        num: num2++,
        name: customers2[iw2].name,
        lastName: customers2[iw2].lastName,
        tel1: customers2[iw2].tel1,
        tel2: customers2[iw2].tel2,
        comments: customers2[iw2].comments,
        customer: customers2[iw2],
        datechange: moment(customers2[iw2].datechange).format(
          'DD-MM-YY HH:mm:ss'
        ),

        userinfo:
          customers2[iw2].user &&
          customers2[iw2].user.login + '/' + customers2[iw2].user.firstName,

        birthday: customers2[iw2].birthday,
        birthdaymessage: customers2[iw2].birthdaymessage ? 'Да' : 'Нет',
        viberOn: customers2[iw2].viberOn ? 'Да' : 'Нет',
        viberid:
          customers2[iw2].viber &&
          customers2[iw2].viber.name +
            (customers2[iw2].viber.phone
              ? '/' + customers2[iw2].viber.phone
              : ''),
      })
    }
  }

  //Table3   Messages
  columns3 = [
    {
      Header: '№',
      accessor: 'num',
    },
    {
      Header: translate('resources.customers.fields.message'), //'Сообщение',
      accessor: 'message',
    },
    {
      Header: translate('resources.customers.fields.date'), //'Дата',
      accessor: 'date',
    },
    {
      Header: translate('resources.customers.fields.status'), //'Статус ',
      accessor: 'status',
    },
  ]

  if (!isXSmall) {
    columns3.push({
      Header: translate('resources.customers.fields.record'), //'Запись',
      accessor: 'worktimeinfo',
    })

    if (isadmin) {
      columns3.push({
        Header: translate('resources.customers.fields.user'), //'User',
        accessor: 'user_id',
      })
    }
  }

  data3 = []

  function getnamestatus(status) {
    let result = translate('resources.customers.message.send') //'Отправлен'
    switch (status) {
      case 'new':
        result = translate('resources.customers.message.new') //'Новое (не отправлено)'
        break
      case 'send':
        result = translate('resources.customers.message.send') //'Отправлено'
        break
      case 'delivered':
        result = translate('resources.customers.message.delivered') //'Доставлено'
        break
      case 'seen':
        result = translate('resources.customers.message.seen') //'Просмотрено'
        break
        defaelt: result = translate('resources.customers.message.send') //'Отправлен'
    }

    return result
  }

  let num3 = 1
  if (app.body.customermessages) {
    const customermessages = app.body.customermessages

    let date
    let worker
    let time1
    let time2
    let worktimeinfo
    for (var iw3 in customermessages) {
      worktimeinfo = ''
      if (customermessages[iw3].worktime) {
        date = moment(customermessages[iw3].worktime.date).format('DD.MM')
        worker = customermessages[iw3].worktime.worker.name
        time1 = customermessages[iw3].worktime.time1
        time2 = customermessages[iw3].worktime.time2
        worktimeinfo =
          'Запись от ' + date + ' ' + worker + ' ' + time1 + '/' + time2
      }

      data3.push({
        num: num3++,
        message: customermessages[iw3].message,
        date: moment(new Date(customermessages[iw3].date)).format(
          'DD-MM-YY HH:mm:ss'
        ),
        status: getnamestatus(customermessages[iw3].status),
        user_id: customermessages[iw3].user_id,
        worktimeinfo: worktimeinfo,
        //        customermessages: customermessages[iw3],
      })
    }
  }

  columns4 = [
    {
      Header: '№',
      accessor: 'num',
    },
    {
      Header: translate('resources.store.fields.name'), //'Сообщение',
      accessor: 'name',
    },
  ]

  data4 = []

  const items = customers_item && customers_item.items && customers_item.items

  let num4 = 1
  for (var iw4 in items) {
    data4.push({
      num: num4++,
      name: items[iw4].stores && items[iw4].stores.name,
      items: items[iw4],
    })
  }

  function Table4({
    columns,
    data,
    getHeaderProps = defaultPropGetter,
    getColumnProps = defaultPropGetter,
    getRowProps = defaultPropGetter,
    getCellProps = defaultPropGetter,
  }) {
    // Use the state and functions returned from useTable to build your UI
    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
      useTable({
        columns,
        data,
      })

    return (
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  // Return an array of prop objects and react-table will merge them appropriately
                  {...column.getHeaderProps([
                    {
                      className: column.className,
                      style: column.style,
                    },
                    getColumnProps(column),

                    getHeaderProps(column),
                  ])}
                >
                  {column.render('Header')}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row)
            return (
              // Merge user row props in
              <tr {...row.getRowProps(getRowProps(row))}>
                {row.cells.map((cell) => {
                  const vv = cell
                  let parent = Object.assign(
                    {},
                    cell.row.original.customers_item
                  )
                  function clearptw(parent) {
                    if (parent && parent.getwt) {
                      parent.getwt = false //обнуляем getwt для загрузки WT при возврате назад
                    }
                    return parent
                  }
                  let storeid = cell.row.original.items.storeid
                  return (
                    <td
                      // Return an array of prop objects and react-table will merge them appropriately
                      {...cell.getCellProps([
                        {
                          className: cell.column.className,
                          style: storeid
                            ? cell.column.style
                            : { color: 'blue', backgroundColor: 'yellow' },
                        },
                        getColumnProps(cell.column),
                        //  getCellProps(cell),
                      ])}
                      align="center"
                      //                    width="140px"
                    >
                      {(cell.column.id !== 'timeline' && (
                        <Button
                          //as={Link}
                          variant="outline-primary"
                          //style={{ marginBottom: 5 }}
                          style={{
                            pointerEvents: readonly ? 'none' : '',
                          }}
                          onClick={async () => {
                            try {
                              await ws_client.wss_getItem({
                                tableName: 'Heads',
                                //item: cell.row.original.customers_item,
                                where: cell.row.original.customers_item.id,
                                action: {
                                  metod: 'getSupervisor',
                                  mode: 'get',
                                  parent: clearptw(parent), //cell.row.original.customers_item,
                                  id: cell.row.original.items.id, //role_id,
                                },
                                //callback: getWork,
                              })
                            } catch (e) {
                              console.log('errr e', e)
                            }
                            //setSubPages({ page: 'role' })
                          }}
                        >
                          {cell.render('Cell')}
                        </Button>
                      )) ||
                        cell.render('Cell')}
                    </td>
                  )
                })}
              </tr>
            )
          })}
        </tbody>
      </table>
    )
  }

  const getChange = () => {
    if (customers_item && customers_item.change === true) return 'contained'
    else return 'outlined'
  }

  const getAccessToChange = () => {
    return readonly || false
  }

  const ready = () => {
    if (customers_item) return true
    else return false
  }

  return (
    <>
      {ready() && (
        <>
          <Box maxWidth={maxwidth}>
            <Card>
              <CardContent>
                <>
                  <div
                    //            className="hstack gap-2"
                    style={{
                      marginTop: -20,
                      display: 'flex',
                      justifyContent: 'space-between',
                    }}
                  >
                    <Typography
                      id="outlined-basic"
                      label="Клиент"
                      //variant="standard"
                      className="title h6"
                      style={{
                        marginTop: 15,
                        marginBottom: 15,
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                    >
                      {translate('resources.customers.title')}
                    </Typography>
                    {false &&
                      (isadmin ||
                        getAccessCode(
                          'SHOW_DOPINFO_CUSTOMER',
                          access && access.access
                        )) && (
                        <FormControlLabel
                          style={{
                            marginRight: isXSmall ? 10 : 10,
                            marginLeft: isXSmall ? 160 : 160,
                          }}
                          control={
                            <Switch
                              id={customers_item && customers_item.id}
                              name={'DopInfo'}
                              color="primary"
                              onChange={handleChangeDopInfo}
                              //                          onFocus={onFocus}
                              checked={DopInfoOn}
                              //                      {...sanitizeInputRestProps(rest)}
                              //                      {...options}
                              //                      disabled={disabled}
                            />
                          }
                          label={translate('resources.switch.dop')}
                        />
                      )}

                    {false &&
                      (isadmin ||
                        getAccessCode(
                          'SHOW_LOGS',
                          access && access.access
                        )) && (
                        <FormControlLabel
                          style={{
                            //              marginRight: 15,
                            marginLeft: isXSmall ? 0 : 5,
                          }}
                          control={
                            <Switch
                              id={customers_item && customers_item.id}
                              name={'History'}
                              color="primary"
                              onChange={handleChangeHistory}
                              //                          onFocus={onFocus}
                              checked={HistoryOn}
                              //                      {...sanitizeInputRestProps(rest)}
                              //                      {...options}
                              //                      disabled={disabled}
                            />
                          }
                          label={translate('resources.switch.changed')}
                        />
                      )}
                    {readonly && (
                      <Typography
                        id="outlined-basic"
                        label="Title"
                        //variant="standard"
                        className="title h6"
                        style={{
                          marginTop: 15,
                          marginBottom: 15,
                          //marginRight: 275,
                          color: 'red',
                        }}
                        //                      {translate('resources.workers.readonly')}
                      >
                        Readonly
                      </Typography>
                    )}
                  </div>
                  {customers_item && (
                    <>
                      <div
                        //            className="hstack gap-2"
                        style={{
                          //            marginTop: -20,
                          display: 'flex',
                          //                justifyContent: 'space-between',
                        }}
                      >
                        <TextField
                          id="outlined-basic"
                          label={translate('resources.customers.fields.name')} //"Имя"
                          //variant="standard"
                          variant="outlined"
                          style={{
                            //                  width: isXSmall ? 170 : 200,
                            marginRight: isXSmall ? 15 : 15,
                            marginBottom: isXSmall ? 10 : 10,
                            //                  display: 'flex',
                            justifyContent: 'space-between',
                          }}
                          value={(customers_item && customers_item.name) || ''}
                          onChange={(item) => {
                            if (!getAccessToChange()) {
                              customers_item.change = true
                              dispatch({
                                type: 'app/set',
                                payload: {
                                  id: customers_item,
                                  field: 'name',
                                  value: item.target.value,
                                },
                                ob: 'customers_item',
                              })
                            }
                          }}
                        ></TextField>
                        <TextField
                          id="outlined-basic"
                          label={translate('resources.customers.fields.tel1')} //"Телефон 1" //"Ведите номер телефона"
                          variant="outlined"
                          style={{
                            //                  width: isXSmall ? 170 : 200,
                            marginRight: isXSmall ? 5 : 15,
                            marginBottom: isXSmall ? 10 : 15,
                            //                  display: 'flex',
                            justifyContent: 'space-between',
                          }}
                          value={(customers_item && customers_item.tel1) || ''}
                          onChange={(item) => {
                            if (!getAccessToChange()) {
                              customers_item.change = true
                              dispatch({
                                type: 'app/set',
                                payload: {
                                  id: customers_item,
                                  field: 'tel1',
                                  value: item.target.value,
                                },
                                ob: 'customers_item',
                              })
                            }
                          }}
                        />
                      </div>
                      <TextField
                        id="outlined-basic"
                        label={translate('resources.customers.fields.comments')} //"Коментарии" //"Ведите коментарии"
                        variant="outlined"
                        style={{
                          marginBottom: 15,
                          width: isXSmall ? 350 : 440,
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}
                        value={
                          (customers_item && customers_item.comments) || ''
                        }
                        onChange={(item) => {
                          if (!getAccessToChange()) {
                            customers_item.change = true
                            dispatch({
                              type: 'app/set',
                              payload: {
                                id: customers_item,
                                field: 'comments',
                                value: item.target.value,
                              },
                              ob: 'customers_item',
                            })
                          }
                        }}
                      />
                      {false &&
                        (isadmin ||
                          getAccessCode(
                            'SHOW_CUSTOMER_SEGMENTS',
                            access && access.access
                          )) && (
                          <Autocomplete
                            multiple
                            id="size-small-outlined" //      id="checkboxes-tags"
                            options={segments}
                            onChange={changeSegment}
                            size="small"
                            disableClearable={true}
                            value={
                              (customers_item.csegments && [
                                ...customers_item.csegments,
                              ]) ||
                              []
                            } //customersegments]}
                            limitTags={4}
                            //              filterSelectedOptions
                            isOptionEqualToValue={(props, option) => {
                              let rez = false
                              if (props.value === option.value) rez = true
                              return rez
                            }}
                            selectOnFocus
                            clearOnBlur
                            onFocus={(e) => {
                              //            console.log(e.target.readOnly)
                              return (e.target.readOnly = true)
                            }} //выключение клавиатуры при выборе      handleHomeEndKeys
                            //      disableCloseOnSelect
                            getOptionLabel={(option) => option.label}
                            renderOption={(props, option, { selected }) => {
                              return (
                                <li {...props}>
                                  <Checkbox
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    style={{ marginRight: 8 }}
                                    checked={selected}
                                  />
                                  {option.label}
                                </li>
                              )
                            }}
                            style={{ width: isXSmall ? 350 : 470 }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label={translate(
                                  'resources.customers.segments'
                                )} //"Группы клиентов"
                                variant="outlined"
                                //          variant="standard"
                                //          placeholder="Выберите Мастера"
                              />
                            )}
                          />
                        )}
                    </>
                  )}
                  {false &&
                    (isadmin ||
                      getAccessCode(
                        'SHOW_DOPINFO_CUSTOMER',
                        access && access.access
                      )) &&
                    DopInfoOn && (
                      <>
                        <div
                          //            className="hstack gap-2"
                          style={{
                            //            marginTop: -20,
                            display: 'flex',
                            //                  justifyContent: 'space-between',
                          }}
                        >
                          <FormControlLabel
                            style={{
                              width: isXSmall ? 190 : 260,
                              marginRight: 5,
                              //                    marginBottom: 15,
                            }}
                            control={
                              <Switch
                                id={'birthdaymessage'}
                                name={'birthdaymessage'}
                                color="primary"
                                onChange={(item, value) => {
                                  if (!getAccessToChange()) {
                                    customers_item.change = true
                                    dispatch({
                                      type: 'app/set',
                                      payload: {
                                        id: customers_item,
                                        field: 'birthdaymessage',
                                        value: !customers_item.birthdaymessage,
                                      },
                                      ob: 'customers_item',
                                    })
                                  }
                                }}
                                //                          onFocus={onFocus}
                                checked={
                                  customers_item &&
                                  customers_item.birthdaymessage
                                }
                                //                      {...sanitizeInputRestProps(rest)}
                                //                      {...options}
                                //                      disabled={disabled}
                              />
                            }
                            label={
                              isXSmall
                                ? translate('resources.customers.congratulate')
                                : translate('resources.customers.congratulate2')
                            }
                          />

                          <LocalizationProvider
                            dateAdapter={AdapterDayjs}
                            adapterLocale={language}
                          >
                            <DesktopDatePicker
                              /*
              <TextField
                id="outlined-basic"
                label="Доп.Инфо"
                variant="standard"
                style={{
                  marginTop: -25,
                  marginBottom: 15,
                  width: 240,
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              />
*/
                              label={translate(
                                'resources.customers.datebirthday'
                              )} //"День рождения"
                              onFocus={(e) => (e.target.readOnly = true)} //выключение клавиатуры при выборе значения
                              value={
                                (customers_item && customers_item.birthday) ||
                                ''
                              }
                              //                    inputFormat="dd.MM.yyyy"
                              onChange={(item) => {
                                if (!getAccessToChange()) {
                                  customers_item.change = true
                                  dispatch({
                                    type: 'app/set',
                                    payload: {
                                      id: customers_item,
                                      field: 'birthday',
                                      value: new Date(item),
                                    },
                                    ob: 'customers_item',
                                  })
                                }
                              }}
                              renderInput={(params) => (
                                <TextField
                                  sx={{ width: isXSmall ? 170 : 218 }}
                                  variant="outlined"
                                  {...params}
                                />
                              )}
                            />
                          </LocalizationProvider>
                        </div>
                        <div
                          //            className="hstack gap-2"
                          style={{
                            //            marginTop: -20,
                            display: 'flex',
                            //                justifyContent: 'space-between',
                          }}
                        >
                          <FormControlLabel
                            style={{
                              width: isXSmall ? 180 : 250,
                            }}
                            control={
                              <Switch
                                id={'viberOn'}
                                name={'viberOn'}
                                color="primary"
                                onChange={(item, value) => {
                                  if (!getAccessToChange()) {
                                    customers_item.change = true
                                    dispatch({
                                      type: 'app/set',
                                      payload: {
                                        id: customers_item,
                                        field: 'viberOn',
                                        value: !customers_item.viberOn,
                                      },
                                      ob: 'customers_item',
                                    })
                                  }
                                }}
                                //                          onFocus={onFocus}
                                checked={
                                  customers_item && customers_item.viberOn
                                }
                                //                      {...sanitizeInputRestProps(rest)}
                                //                      {...options}
                                //                      disabled={disabled}
                              />
                            }
                            label={
                              isXSmall
                                ? translate('resources.customers.viber')
                                : translate('resources.customers.vibermessage')
                            } //'Viber' : 'Сообщения на Viber'}
                          />

                          <SelectViberUser
                            name="viber"
                            field={customers_item}
                            customers={app.filter.customers_filter}
                            title={translate(
                              'resources.customers.viberprofile'
                            )} //"Viber Profile"
                            placeholder={translate(
                              'resources.customers.viberprofile'
                            )} //"Выберите Viber Profile"
                            value="customers_item"
                            disabled={
                              customers_item &&
                              !customers_item.viberOn &&
                              getAccessToChange()
                            }
                            onChanged={() => {
                              customers_item.change = true
                              dispatch({
                                type: 'app/set',
                                payload: {
                                  id: customers_item,
                                  field: 'change',
                                  value: true,
                                },
                                ob: 'customers_item',
                              })
                            }}
                          />
                        </div>

                        <div
                          //            className="hstack gap-2"
                          style={{
                            //            marginTop: -20,
                            display: 'flex',
                            //                  justifyContent: 'space-between',
                          }}
                        >
                          <FormControlLabel
                            style={{
                              width: isXSmall ? 180 : 250,
                            }}
                            control={
                              <Switch
                                id={'telegramOn'}
                                name={'telegramOn'}
                                color="primary"
                                onChange={(item, value) => {
                                  if (!getAccessToChange()) {
                                    customers_item.change = true
                                    dispatch({
                                      type: 'app/set',
                                      payload: {
                                        id: customers_item,
                                        field: 'telegramOn',
                                        value: !customers_item.telegramOn,
                                      },
                                      ob: 'customers_item',
                                    })
                                  }
                                }}
                                //                          onFocus={onFocus}
                                checked={
                                  customers_item && customers_item.telegramOn
                                }
                                //                      {...sanitizeInputRestProps(rest)}
                                //                      {...options}
                                //                      disabled={disabled}
                              />
                            }
                            label={
                              isXSmall
                                ? translate('resources.customers.telegram')
                                : translate(
                                    'resources.customers.telegrammessage'
                                  )
                            } //'Telegram' : 'Сообщения на Telegram'}
                          />

                          <TextField
                            id="outlined-basic"
                            label="Telegram phone"
                            variant="outlined"
                            tepe="datetime"
                            style={{
                              width: isXSmall ? 170 : 218,
                              marginBottom: 15,
                              display: 'flex',
                              justifyContent: 'space-between',
                            }}
                            value={
                              (customers_item &&
                                customers_item.telegramPhone) ||
                              ''
                            }
                            onChange={(item) => {
                              if (!getAccessToChange()) {
                                customers_item.change = true
                                dispatch({
                                  type: 'app/set',
                                  payload: {
                                    id: customers_item,
                                    field: 'telegramPhone',
                                    value: item.target.value,
                                  },
                                  ob: 'customers_item',
                                })
                              }
                            }}
                            disabled={
                              customers_item && !customers_item.telegramOn
                            }
                          />
                        </div>

                        <div
                          //            className="hstack gap-2"
                          style={{
                            //            marginTop: -20,
                            display: 'flex',
                            //                  justifyContent: 'space-between',
                          }}
                        >
                          <FormControlLabel
                            style={{
                              width: isXSmall ? 180 : 250,
                              marginlEFT: isXSmall ? 10 : 15,
                              marginBottom: 15,
                            }}
                            control={
                              <Switch
                                id={'firstmessage'}
                                name={'firstmessage'}
                                color="primary"
                                onChange={(item, value) => {
                                  if (!getAccessToChange()) {
                                    customers_item.change = true
                                    dispatch({
                                      type: 'app/set',
                                      payload: {
                                        id: customers_item,
                                        field: 'firstmessage',
                                        value: !customers_item.firstmessage,
                                      },
                                      ob: 'customers_item',
                                    })
                                  }
                                }}
                                //                          onFocus={onFocus}
                                checked={
                                  customers_item && customers_item.firstmessage
                                }
                                //                      {...sanitizeInputRestProps(rest)}
                                //                      {...options}
                                //                      disabled={disabled}
                              />
                            }
                            label={
                              isXSmall
                                ? translate('resources.customers.firstmessage')
                                : translate('resources.customers.firstmessage2')
                            } //'Сообщение-1' : 'Первое Сообщение'}
                          />
                          <FormControlLabel
                            style={{
                              width: isXSmall ? 150 : 250,
                              marginBottom: 15,
                            }}
                            control={
                              <Switch
                                id={'secondmessage'}
                                name={'secondmessage'}
                                color="primary"
                                onChange={(item, value) => {
                                  if (!getAccessToChange()) {
                                    customers_item.change = true
                                    dispatch({
                                      type: 'app/set',
                                      payload: {
                                        id: customers_item,
                                        field: 'secondmessage',
                                        value: !customers_item.secondmessage,
                                      },
                                      ob: 'customers_item',
                                    })
                                  }
                                }}
                                //                          onFocus={onFocus}
                                checked={
                                  customers_item && customers_item.secondmessage
                                }
                                //                      {...sanitizeInputRestProps(rest)}
                                //                      {...options}
                                //                      disabled={disabled}
                              />
                            }
                            label={
                              isXSmall
                                ? translate('resources.customers.secondmessage')
                                : translate(
                                    'resources.customers.secondmessage2'
                                  )
                            } //'Сообщение-2' : 'Второе Сообщения'}
                          />
                        </div>
                      </>
                    )}
                  <Button
                    size="small"
                    component={Link}
                    color="primary"
                    //variant="outlined"
                    sx={{ display: 'inline-flex', alignItems: 'center' }}
                    variant={getChange()} //"outline-success"
                    to={{
                      pathname: '/pages/customers/list',
                    }}
                    //state={customers_item}
                    disabled={getAccessToChange()}
                    onClick={async () => {
                      try {
                        if (!getAccessToChange())
                          await ws_client.wss_setItem({
                            tableName: 'Customers',
                            item: customers_item,
                            call_nm: 'Model_update',
                            where: { id: customers_item.id },
                            action: { metod: 'set' },
                          })
                      } catch (e) {
                        console.log('errr e', e)
                      }
                    }}
                  >
                    {translate('resources.button.save')}
                  </Button>
                  <Button
                    size="small"
                    component={Link}
                    color="primary"
                    variant="outlined"
                    sx={{
                      marginLeft: 2,
                      display: 'inline-flex',
                      alignItems: 'center',
                    }}
                    to="/pages/customers/list"
                  >
                    {translate('resources.button.cancel')}
                  </Button>
                  {false &&
                    (isadmin ||
                      getAccessCode(
                        'SHOW_CUSTOMER_WORKTIME',
                        access && access.access
                      )) && (
                      <FormControlLabel
                        style={{
                          marginLeft: isXSmall ? 1 : 1,
                        }}
                        control={
                          <Switch
                            id={customers_item && customers_item.id}
                            name={'WorkTime'}
                            color="primary"
                            onChange={handleChangeWorkTime}
                            //                          onFocus={onFocus}
                            checked={WorkTimeOn}
                            //                      {...sanitizeInputRestProps(rest)}
                            //                      {...options}
                            //                      disabled={disabled}
                          />
                        }
                        label={translate('resources.customers.records')}
                      />
                    )}
                  {false &&
                    (isadmin ||
                      getAccessCode(
                        'SHOW_CUSTOMER_MESSAGES',
                        access && access.access
                      )) && (
                      <FormControlLabel
                        style={{
                          marginLeft: isXSmall ? 1 : 1,
                        }}
                        control={
                          <Switch
                            id={customers_item && customers_item.id}
                            name={'Messages'}
                            color="primary"
                            onChange={handleChangeMessages}
                            //                          onFocus={onFocus}
                            checked={MessagesOn}
                            //                      {...sanitizeInputRestProps(rest)}
                            //                      {...options}
                            //                      disabled={disabled}
                          />
                        }
                        label={translate('resources.customers.messages')}
                      />
                    )}
                  {false &&
                    (isadmin ||
                      getAccessCode('SHOW_LOGS', access && access.access)) &&
                    HistoryOn && (
                      <>
                        {translate('resources.switch.changed')}
                        <Styles>
                          <Table2
                            columns={columns2}
                            data={data2}
                            /*          
          getHeaderProps={(column) => ({
            onClick: () => alert('Header!'),
          })}
          getColumnProps={(column) => ({
            onClick: () => alert('Column!'),
          })}
          */
                            getRowProps={(row) => ({
                              style: {
                                background: row.original.color, //index % 2 === 0 ? 'rgba(0,0,0,.1)' : 'white',
                              },
                            })}
                            getCellProps={(cellInfo) => {
                              console.log('sssss')

                              return {
                                //            onClick: (req, res) => onClickCell(cellInfo, req, res),

                                style: {
                                  backgroundColor: `hsl(${
                                    120 * ((120 - cellInfo.value) / 120) * -1 +
                                    120
                                  }, 100%, 67%)`,
                                },
                              }
                            }}
                          />
                        </Styles>
                      </>
                    )}
                  {false &&
                    (isadmin ||
                      getAccessCode(
                        'SHOW_CUSTOMER_MESSAGES',
                        access && access.access
                      )) &&
                    MessagesOn && (
                      <>
                        {translate('resources.worktime.messages')}
                        <Styles>
                          <Table3
                            columns={columns3}
                            data={data3}
                            /*          
          getHeaderProps={(column) => ({
            onClick: () => alert('Header!'),
          })}
          getColumnProps={(column) => ({
            onClick: () => alert('Column!'),
          })}
          */
                            getRowProps={(row) => ({
                              style: {
                                background: row.original.color, //index % 2 === 0 ? 'rgba(0,0,0,.1)' : 'white',
                              },
                            })}
                            getCellProps={(cellInfo) => {
                              console.log('sssss')

                              return {
                                //            onClick: (req, res) => onClickCell(cellInfo, req, res),

                                style: {
                                  backgroundColor: `hsl(${
                                    120 * ((120 - cellInfo.value) / 120) * -1 +
                                    120
                                  }, 100%, 67%)`,
                                },
                              }
                            }}
                          />
                        </Styles>
                      </>
                    )}
                  {!HistoryOn && WorkTimeOn && (
                    <>
                      <Autocomplete
                        multiple
                        id="size-small-outlined" //      id="checkboxes-tags"
                        options={workers}
                        onChange={changeWorker}
                        size="small"
                        value={[...worker2]}
                        limitTags={4}
                        //              filterSelectedOptions
                        isOptionEqualToValue={(props, option) => {
                          let rez = false
                          if (props.value === option.value) rez = true
                          return rez
                        }}
                        selectOnFocus
                        clearOnBlur
                        onFocus={(e) => {
                          //            console.log(e.target.readOnly)
                          return (e.target.readOnly = true)
                        }} //выключение клавиатуры при выборе      handleHomeEndKeys
                        //      disableCloseOnSelect
                        getOptionLabel={(option) => option.label}
                        renderOption={(props, option, { selected }) => {
                          return (
                            <li {...props}>
                              <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={selected}
                              />
                              {option.label}
                            </li>
                          )
                        }}
                        style={{ width: isXSmall ? 350 : 500 }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={translate('resources.worktime.changeworker')} //"Выбери мастера"
                            variant="outlined"
                            //          variant="standard"
                            //          placeholder="Выберите Мастера"
                          />
                        )}
                      />

                      <br />
                      <Styles>
                        <Table
                          columns={columns}
                          data={data}
                          getHeaderProps={
                            (nameTheme &&
                              nameTheme !== 'dark' &&
                              ((row) => {
                                return {
                                  style: {
                                    //                   background:
                                    //                   row.index % 2 === 0 ? 'rgba(0,0,0,.1)' : 'white',
                                  },
                                }
                              })) ||
                            ((row) => {
                              return {
                                style: {
                                  color: '#ffffffb3',
                                  //                    background:
                                  //                    row.index % 2 === 0 ? 'rgba(0,0,0,.1)' : '#ffffffb3', //'#616161e6', //'#ffffffb3',
                                },
                              }
                            })
                          }
                          /*          
          getHeaderProps={(column) => ({
            onClick: () => alert('Header!'),
          })}
          getColumnProps={(column) => ({
            onClick: () => alert('Column!'),
          })}
          */
                          getRowProps={
                            (nameTheme &&
                              nameTheme !== 'dark' &&
                              ((row) => {
                                return {
                                  style: {
                                    background:
                                      row.index % 2 === 0
                                        ? 'rgba(0,0,0,.1)'
                                        : 'white',
                                  },
                                }
                              })) ||
                            ((row) => {
                              return {
                                style: {
                                  background:
                                    row.index % 2 === 0
                                      ? 'rgba(0,0,0,.1)'
                                      : '#616161e6', //'#ffffffb3',
                                },
                              }
                            })
                          }
                          getCellProps={(cellInfo) => ({
                            //            onClick: (req, res) => onClickCell(cellInfo, req, res),
                            //                style: {
                            //                 backgroundColor: `hsl(${
                            //                   120 * ((120 - cellInfo.value) / 120) * -1 + 120
                            //                 }, 100%, 67%)`,
                            //                },
                          })}
                        />
                      </Styles>
                    </>
                  )}
                  <br />
                  {(isadmin ||
                    getAccessCode(
                      'CUSTOMERSTORE_VIEW',
                      access && access.access
                    )) && (
                    <Styles>
                      <br />
                      <Table4
                        columns={columns4}
                        data={data4}
                        getHeaderProps={
                          (nameTheme &&
                            nameTheme !== 'dark' &&
                            ((row) => {
                              return {
                                style: {
                                  //                   background:
                                  //                   row.index % 2 === 0 ? 'rgba(0,0,0,.1)' : 'white',
                                },
                              }
                            })) ||
                          ((row) => {
                            return {
                              style: {
                                color: '#ffffffb3',
                                //                    background:
                                //                    row.index % 2 === 0 ? 'rgba(0,0,0,.1)' : '#ffffffb3', //'#616161e6', //'#ffffffb3',
                              },
                            }
                          })
                        }
                        /*          
          getHeaderProps={(column) => ({
            onClick: () => alert('Header!'),
          })}
          getColumnProps={(column) => ({
            onClick: () => alert('Column!'),
          })}
          */
                        getRowProps={
                          (nameTheme &&
                            nameTheme !== 'dark' &&
                            ((row) => {
                              return {
                                style: {
                                  background:
                                    row.index % 2 === 0
                                      ? 'rgba(0,0,0,.1)'
                                      : 'white',
                                },
                              }
                            })) ||
                          ((row) => {
                            return {
                              style: {
                                background:
                                  row.index % 2 === 0
                                    ? 'rgba(0,0,0,.1)'
                                    : '#616161e6', //'#ffffffb3',
                              },
                            }
                          })
                        }
                        getCellProps={(cellInfo) => ({
                          //            onClick: (req, res) => onClickCell(cellInfo, req, res),
                          //                style: {
                          //                  backgroundColor: `hsl(${
                          //                    120 * ((120 - cellInfo.value) / 120) * -1 + 120
                          //                  }, 100%, 67%)`,
                          //                },
                        })}
                      />
                    </Styles>
                  )}{' '}
                </>
              </CardContent>
            </Card>
          </Box>
        </>
      )}
      {!ready() && (
        <>
          <Box maxWidth={maxwidth}>
            <Card>
              <CardContent>
                <Spinner />
              </CardContent>
            </Card>
          </Box>
        </>
      )}
    </>
  )
}
export default Customers
