import React, { useCallback, useContext } from 'react'
import { Nav, Navbar, NavDropdown } from 'react-bootstrap'
import { NavLink } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { AuthContext } from '../context/AuthContext'
import { MenuItemLinkProps } from './MenuItemLink'

import {
  Box,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Typography,
  useMediaQuery,
  Theme,
} from '@mui/material'
import SettingsIcon from '@mui/icons-material/Settings'
import ExitIcon from '@mui/icons-material/PowerSettingsNew'
import {
  Link,
  //,
  //  useLocation,
  //  Navigate,
  //  Outlet,
  //  useNavigate,
  //  unstable_HistoryRouter as HistoryRouter,
} from 'react-router-dom'

import { useTranslate } from '../lib/translate/i18n/useTranslate'

const ProfileMenu = React.forwardRef((props: MenuItemLinkProps, ref) => {
  const { onClick, ...rest } = props
  const auth = useContext(AuthContext)
  const dispatch = useDispatch()

  const handleMenuTap = useCallback(
    (e: any) => {
      onClick && onClick(e)
    },
    [onClick]
  )
  const translate = useTranslate()
  return (
    <MenuItem
      component={Link}
      // @ts-ignore
      ref={ref}
      {...props}
      to="/auth/auth/profile"
      state={{ id: auth.userId }}
      //onClick={handleMenuTap}
      onClick={() => {
        dispatch({
          type: 'fetch/get_add',
          payload: auth.userId,
          ob: 'item_id',
        })
      }}
    >
      <ListItemIcon>
        <SettingsIcon />
      </ListItemIcon>
      <ListItemText>{translate('pos.menu.profile')}</ListItemText>
    </MenuItem>
  )
})

export default ProfileMenu
