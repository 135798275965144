import React, { useContext, useState, useEffect } from 'react'
import { Form } from 'react-bootstrap'
import Button from '@mui/material/Button'
import { Link } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import Typography from '@material-ui/core/Typography'
import TextField from '@mui/material/TextField'
import { useMediaQuery } from '@mui/material'

import { Card, CardContent, Box } from '@mui/material'
import { useTranslate } from '../../../lib/translate/i18n/useTranslate'
import { AuthContext } from '../../../context/AuthContext'
import { getURLAPI } from '../../../utils/getURL'

import { default as Spinner } from '../../../components/Spinner'

const UnitTypeItem = () => {
  const unittype_item = useSelector((state) => state.app.body.unittype_item)
  const auth = useContext(AuthContext)
  const body = useSelector((state) => state.app.body)
  const access = useSelector((state) => state.app.access.access)
  const isadmin = (access && access.isadmin) || false
  const isXSmall = useMediaQuery((theme) => theme.breakpoints.down('sm'))

  const nameTheme = access && access.user && access.user.theme
  const translate = useTranslate()

  const config = auth && auth.config
  let URLAPI = getURLAPI(config)

  //START get all str from table Roles
  const [startRefresh, setStartRefresh] = useState(true)

  //замыкание
  const ws_client = auth.ws_client //body.ws_client

  const item_id = body.item_id

  const readonly = body.readonly || false

  //  const [startWss, setstartWss] = useState(true)

  const GetData_handle = function (_result) {
    const { tableName, result, readonly, action } = _result
    //console.log('result handle1 ', result)
    dispatch({
      type: 'fetch/get_add',
      payload: result,
      ob: 'unittype_item',
    })
    dispatch({
      type: 'fetch/get_add',
      payload: readonly,
      ob: 'readonly',
    })
  }

  const Refresh_handle = async function (result, event, parent) {
    const { tableName, action, metod } = result
    /*console.log(
      'Refresh_handle ',
      event,
      new Date().toLocaleTimeString(),
      result
    )*/
    setStartRefresh(true)
  }

  const params = {
    URL_WS: getURLAPI(auth.config, 'URL_WS'), //get address WebSocket BK from config
    auth: { userId: auth.userId, tocken: auth.token },
    handleGetData: GetData_handle,
    //handleChangeData: Refresh_handle,
    handleSetRefresh: Refresh_handle, //setStartRefresh,
    handleErrorRead: null,
    handleErrorChange: null,
    models: [
      {
        model_call_nm: 'Model_read',
        tableName: 'UnitTypes',
        where: { id: item_id },
        action: {
          metod: 'getOne',
          isBlocked: true,
        },
      },
    ],
  }

  //deblock item whet close page
  useEffect(
    () => () => {
      try {
        if (!readonly)
          ws_client &&
            ws_client.deblock({ tableName: 'UnitTypes', id: item_id })
      } catch (err) {
        console.log('deblock error ', err)
      }
    },
    []
  )

  if (item_id && ws_client && ws_client.ws_login && startRefresh) {
    ws_client.open_process(params)
    setStartRefresh(false)
  }

  //END get all str from table Roles

  let greybkcolor = 'rgba(255,255,255,.3)'

  if (nameTheme) {
    greybkcolor =
      nameTheme !== 'dark' ? 'rgba(255,255,255,1.0)' : 'rgba(255,255,255,.3)'
  }
  const dispatch = useDispatch()

  const getChange = () => {
    if (unittype_item && unittype_item.change === true) return 'contained'
    else return 'outlined'
  }
  const getAccessToChange = () => {
    return readonly || false
  }

  const ready = () => {
    if (unittype_item) return true
    else return false
  }

  return (
    <>
      {ready() && (
        <>
          <Box maxWidth="30em">
            <Card>
              <CardContent>
                <>
                  <div
                    //            className="hstack gap-2"
                    style={{
                      marginTop: -20,
                      display: 'flex',
                      justifyContent: 'space-between',
                    }}
                  >
                    <Typography
                      id="outlined-basic"
                      label="Клиент"
                      //variant="standard"
                      className="title h6"
                      style={{
                        marginTop: 15,
                        marginBottom: 15,
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                    >
                      {translate('resources.unittype.title')}
                    </Typography>
                    {readonly && (
                      <Typography
                        id="outlined-basic"
                        label="Title"
                        //variant="standard"
                        //className="title h6"
                        style={{
                          marginTop: 15,
                          marginBottom: 15,
                          //  marginRight: 275,
                          color: 'red',
                        }}
                        //                      {translate('resources.workers.readonly')}
                      >
                        Readonly
                      </Typography>
                    )}
                  </div>

                  {unittype_item && (
                    <>
                      <TextField
                        id="outlined-basic"
                        label={translate('resources.unittype.fields.name')} //"Название Типа Доступа"
                        //variant="standard"
                        variant="outlined"
                        style={{
                          marginBottom: 15,
                          marginTop: 15,
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}
                        value={(unittype_item && unittype_item.name) || ''}
                        onChange={(item) => {
                          if (!getAccessToChange()) {
                            unittype_item.change = true
                            dispatch({
                              type: 'app/set',
                              payload: {
                                id: unittype_item,
                                field: 'name',
                                value: item.target.value,
                              },
                              ob: 'unittype_item',
                            })
                          }
                        }}
                      />

                      <TextField
                        id="outlined-basic"
                        label={translate('resources.unittype.fields.comments')} //"Коментарии"
                        //variant="standard"
                        variant="outlined"
                        style={{
                          marginBottom: 15,
                          marginTop: 15,
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}
                        value={(unittype_item && unittype_item.comments) || ''}
                        onChange={(item) => {
                          if (!getAccessToChange()) {
                            unittype_item.change = true
                            dispatch({
                              type: 'app/set',
                              payload: {
                                id: unittype_item,
                                field: 'comments',
                                value: item.target.value,
                              },
                              ob: 'unittype_item',
                            })
                          }
                        }}
                      />
                    </>
                  )}

                  <>
                    <Button
                      size="small"
                      component={Link}
                      color="primary"
                      //variant="outlined"
                      sx={{ display: 'inline-flex', alignItems: 'center' }}
                      variant={getChange()} //"outline-success"
                      to={{
                        pathname: '/auth/unittype/list',
                      }}
                      //state={unittype_item}
                      disabled={getAccessToChange()}
                      onClick={async () => {
                        try {
                          if (!getAccessToChange())
                            await ws_client.wss_setItem({
                              tableName: 'UnitTypes',
                              item: unittype_item,
                              call_nm: 'Model_update',
                              where: { id: unittype_item.id },
                              //action: { metod: 'set' },
                            })
                        } catch (e) {
                          console.log('errr e', e)
                        }
                      }}
                    >
                      {translate('resources.button.save')}
                    </Button>{' '}
                    <Button
                      size="small"
                      component={Link}
                      color="primary"
                      variant="outlined"
                      sx={{ display: 'inline-flex', alignItems: 'center' }}
                      //variant={getChange()} //"outline-success"
                      to={{
                        pathname: '/auth/unittype/list',
                      }}
                    >
                      {translate('resources.button.cancel')}
                    </Button>{' '}
                    <br />
                  </>
                </>
              </CardContent>
            </Card>
          </Box>
        </>
      )}
      {!ready() && (
        <>
          <Box maxWidth="30em">
            <Card>
              <CardContent>
                <Spinner />
              </CardContent>
            </Card>
          </Box>
        </>
      )}
    </>
  )
}

export default UnitTypeItem
