import { useMemo } from 'react'
import { useI18nProvider } from './useI18nProvider'
import { useStore } from '../../../store/useStore'
import { useSelector } from 'react-redux'

/**
 * Get the current locale and the ability to change it
 *
 * @example
 *
 * import { useLocaleState } from 'react-admin';
 *
 * const availableLanguages = {
 *     en: 'English',
 *     fr: 'Français',
 * }
 * const LocaleSwitcher = () => {
 *     const [locale, setLocale] = useLocaleState();
 *     return (
 *         <div>
 *             <div>Language</div>
 *             <Button disabled={locale === 'fr'} onClick={() => setLocale('fr')}>
 *                 English
 *             </Button>
 *             <Button disabled={locale === 'en'} onClick={() => setLocale('en')}>
 *                 French
 *             </Button>
 *         </div>
 *     );
 * };
 */
export const useLocaleState = () => {
  const i18nProvider = useI18nProvider()
  const access = useSelector((state: any) => state.app.access.access)

  let defaultLocale = useMemo(() => i18nProvider.getLocale(), [i18nProvider])

  if (access && access.user && access.user.language)
    defaultLocale = access && access.user && access.user.language

  //return ['en'] //
  return useStore<string>('locale', defaultLocale)
}
