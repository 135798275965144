import * as React from 'react'
import { useCallback, ReactElement } from 'react'
import PropTypes from 'prop-types'
import Tooltip from '@mui/material/Tooltip'
import IconButton, { IconButtonProps } from '@mui/material/IconButton'
import NavigationRefresh from '@mui/icons-material/Refresh'

import { useContext } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { AuthContext } from '../context/AuthContext'
import { SocketManager } from '../ext/SocketManager'

import { getURLAPI } from '../utils/getURL'

export const RefreshIconButton = (props: RefreshIconButtonProps) => {
  const {
    label = 'refresh',
    icon = defaultIcon,
    onClick,
    className,
    ...rest
  } = props
  const auth = useContext(AuthContext)

  const config = auth.config
  let URLAPI = getURLAPI(config)
  let URL_WS = getURLAPI(config, 'URL_WS')
  const dispatch = useDispatch()

  const refresh = null // useRefresh()
  //  const translate = useTranslate()
  const handleClick = useCallback(
    (event: any) => {
      event.preventDefault()
      //запустить обновление

      //      dispatch({ type: 'FETCH_ACCESS', urlapi: URLAPI, id: auth.userId })
      const setaccess = (access: any) => {
        dispatch({
          type: 'fetch/access',
          payload: access,
          ob: 'access',
        })
      }

      let wss: SocketManager

      if (auth.ws_client) {
        //    URLAPI = getURLAPI(config)
        //    URL_WS = getURLAPI(config, 'URL_WS') //get address WebSocket BK from config
        wss = auth.ws_client
        // console.log('test wss.ready!!! ', wss.wss.ready)
        if (wss.wss.ready) {
          try {
            wss.wss_getaccess(auth.userId, setaccess)

            /*      try {
  wss_get_access({ id: auth.userId, token: auth.token, URL_WS }).then(
    (res: any) => {
      //console.log('res', res)
      //let rez
      dispatch({
        type: 'fetch/access',
        payload: res,
        ob: 'access',
      })
    }
  ) // Gets the response
  */
          } catch (err) {
            console.log(err)
          }
        }
      }

      //      refresh()
      if (typeof onClick === 'function') {
        onClick(event)
      }
    },
    [refresh, onClick]
  )

  return (
    <Tooltip title={'Обновить'}>
      <IconButton
        aria-label={'Обновить'}
        //        className={'className'}
        color="inherit"
        onClick={handleClick}
        {...rest}
        size="large"
      >
        {icon}
      </IconButton>
    </Tooltip>
  )
}

const defaultIcon = <NavigationRefresh />

interface Props {
  className?: string
  icon?: ReactElement
  label?: string
  onClick?: (e: MouseEvent) => void
}

export type RefreshIconButtonProps = Props & IconButtonProps

RefreshIconButton.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  icon: PropTypes.element,
}
