//import { push } from 'connected-react-router'
import { put } from "redux-saga/effects";

import { URL_API } from "../../globals";
import { Storage } from "megajs";
//import { Buffer } from 'buffer'
//import { validateSchema } from 'graphql'
import Crypto from "../../utils/crypto";

function* getImg(urlapi, action) {
  //  function* getItem_item(name, mode, params) {
  const name = "worktime";
  const { type, data } = action;
  const params = data;
  try {
    let URLAPI = urlapi || URL_API;
    const request = yield fetch(`${URLAPI}/api/worktime/work-image`, {
      method: "POST",
      mode: "cors",
      credentials: "include", //this is what I need to tell the browser to include cookies
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ data: params }),
    });
    const rdata = yield request.json();
    yield put({ type: "fetch/item", payload: rdata.data, ob: name });
  } catch {}
}

function* getMegaStorage(action) {
  //  const { getMega } = useHttp()
  try {
    const { email, password } = action.Mega; //'f5611003-759b-426d-a249-60f0bf1d5a43'
    let megastorage = null;
    const storage = new Storage(
      {
        email: email,
        password: password,
        userAgent: "Firefox", //ExampleClient/1.0',
        forceHttps: false,
        keepalive: true, //Keep connection open to receive server-to-client requests that will be mapped to events. Defaults to true.
        //      autologin: false, //Logins to MEGA. Defaults to true. Set to false if you want to change request options,
        //autoload: false, //Load in file structure. Defaults to true.
      },
      (error) => {
        if (error) {
          console.log(error);
          // Some error happened
        } else {
          // User is now logged in
          //          console.log('getMega storage!!!!!!!!!!!!!!!!!!!!!!!!!!!!!', storage)
          //          storage.login()
          megastorage = storage;
        }
      }
    );

    //    const data1 = await getMega()
    //    console.log(megastorage)
    yield storage.ready;
    /*
    storage.upload({
      uploadCiphertext: true,
      // This key will be used to encrypt the attributes
      // Avoid reusing keys (like using `Buffer.alloc(32)`)
      // as seems MEGA does some kind of caching on those
      key: Buffer.alloc(32), //crypto.randomBytes(32)
    })
*/
    megastorage = storage;
    //    console.log('megastorage!!!', megastorage)
    //yield getMega()
    yield put({ type: "fetch/mega", payload: megastorage, ob: "mega" });
  } catch {}
}

/*
function* getLogin1(login, password) {
  try {
    console.log(login, password)
    const request = yield fetch(`${URL_API}/api/auth/login`, {
      method: 'POST',
      mode: 'cors',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ login, password }),
    })

    const data = yield request.json()
    yield put({ type: 'fetch/item', payload: data })
  } catch (error) {
    console.error(error)
  }
}
*/
function* getLogin(urlapi, name, { login = "", password = "" }) {
  try {
    //    console.log(action)
    let URLAPI = urlapi || URL_API;
    const request = yield fetch(`${URLAPI}/api/auth/login`, {
      method: "POST",
      mode: "cors",
      credentials: "include", //this is what I need to tell the browser to include cookies
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ login, password }),
    });
    const data = yield request.json();
    data.data = []; // Array()
    yield put({ type: "fetch/item", payload: data.data, ob: name });
  } catch {}
}

function* setLogin(urlapi, name, params) {
  try {
    console.log(params);
    const login = params.login;
    const password = params.password;
    //    var tt = JSON.stringify({ login, password })
    //    var t = JSON.stringify(params)
    let URLAPI = urlapi || URL_API;
    const request = yield fetch(`${URLAPI}/api/auth/login`, {
      method: "POST",
      mode: "cors",
      credentials: "include", //this is what I need to tell the browser to include cookies
      //withcredentials: true,
      headers: {
        "Content-Type": "application/json",
        //        'Access-Control-Allow-Origin': '*',
        //        'Access-Control-Allow-Methods': 'POST,GET',
      },
      body: JSON.stringify({ login, password }),
    });
    const data = yield request.json();
    data.status = request.status;
    data.field = "password";
    data.ob = "auth_item";

    if (data.userId) {
    }

    yield put({ type: `${name}/set_item`, payload: data });
  } catch (error) {
    console.error(error);
  }
}

function* changePsw(urlapi, name, params) {
  try {
    console.log(params);
    let URLAPI = urlapi || URL_API;
    const request = yield fetch(`${URLAPI}/api/auth/changepsw`, {
      method: "POST",
      mode: "cors",
      credentials: "include", //this is what I need to tell the browser to include cookies
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(params),
    });
    const data = yield request.json();
    if (data.status === "ok") {
      //      yield delay(1)
      //      yield put(push('/auth/auth/logout'))
    } else {
      data.field = "error";
      yield put({ type: `${name}/change_psw`, payload: data });
    }
  } catch {}
}

function* getList(urlapi, name, mode) {
  const userId = JSON.parse(localStorage.userData).userId; //localStorage.userData
  const token =
    "?token=" + JSON.parse(localStorage.userData).token + "&userId=" + userId; //localStorage.
  //  console.log('token', token)
  try {
    let URLAPI = urlapi || URL_API;
    const request = yield fetch(`${URLAPI}/api/${name}/get`, {
      credentials: "include", //this is what I need to tell the browser to include cookies
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const data = yield request.json();

    if (mode === 1) {
      yield put({ type: "fetch/get", payload: data.data, ob: name });
    } else {
      if (mode === 2)
        yield put({ type: "fetch_current/get", payload: data.data, ob: name });
      else yield put({ type: "fetch/get_add", payload: data.data, ob: name });
    }
  } catch (error) {
    console.error(error);
  }
}

function* getListUser(urlapi, name, { id = "", action, arg }) {
  try {
    //    console.log(action)
    let URLAPI = urlapi || URL_API;
    const request = yield fetch(`${URLAPI}/api/${name}/getuser`, {
      method: "POST",
      mode: "cors",
      credentials: "include", //this is what I need to tell the browser to include cookies
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ id, action }),
    });

    const data = yield request.json();
    const mode = 2;
    if (mode === 1) {
      yield put({ type: "fetch/get", payload: data.data, ob: name });
    } else {
      if (mode === 2)
        yield put({ type: "fetch_current/get", payload: data.data, ob: name });
      else yield put({ type: "fetch/get_add", payload: data.data, ob: name });
    }
  } catch (error) {
    console.error(error);
  }
}

function* getFilter(urlapi, name) {
  try {
    let URLAPI = urlapi || URL_API;
    const request = yield fetch(`${URLAPI}/api/${name}/get`, {
      credentials: "include", //this is what I need to tell the browser to include cookies
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const data = yield request.json();
    yield put({ type: "fetch/filter", payload: data.data, ob: name });
  } catch (error) {
    console.error(error);
  }
}

function* getItem(urlapi, name, { id = "", action, arg }) {
  try {
    //    console.log(action)
    let URLAPI = urlapi || URL_API;
    const request = yield fetch(`${URLAPI}/api/${name}/getone`, {
      method: "POST",
      mode: "cors",
      credentials: "include", //this is what I need to tell the browser to include cookies
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ id, action }),
    });

    const data = yield request.json();
    if (name === "auth" || name === "config") {
      if (typeof data.data !== "object")
        data.data = Crypto.DecryptObject(data.data);
    }
    if (name === "worktime" && data.data) {
      if (id === null && arg) {
        data.data.date = arg["date"];
        data.data.workerid = arg["workerid"];
        data.data.worker = arg["worker"];
        data.data.workplaceid = arg["workplaceid"];
        data.data.workplace = arg["workplaces"];
        data.data.worktimestatusid = arg["worktimestatusid"];
        data.data.worktimestatus = arg["worktimestatus"];
        data.data.time1 = arg["time1"];
        data.data.time2 = arg["time2"];
        data.data.parent = arg["parent"];
        data.data.parenthtml = arg["parenthtml"];
        data.data.parentid = arg["parentid"];
      }
    }
    yield put({ type: "fetch/item", payload: data.data, ob: name });
  } catch {}
}

function* getItem_item(urlapi, name, mode, params) {
  try {
    let URLAPI = urlapi || URL_API;
    const request = yield fetch(`${URLAPI}/api/${name}/${mode}`, {
      method: "POST",
      mode: "cors",
      credentials: "include", //this is what I need to tell the browser to include cookies
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ data: params }),
    });
    const data = yield request.json();
    if (
      typeof data.data !== "object" &&
      (name === "auth" || name === "config")
    ) {
      data.data = Crypto.DecryptObject(data.data);
    }
    yield put({ type: "fetch/item", payload: data.data, ob: name });
  } catch {}
}
/*
function* getItem_image(urlapi,name, mode, params) {
  try {
    let URLAPI = urlapi || URL_API
    const request = yield fetch(`${URLAPI}/api/${name}/${mode}`, {
      method: 'POST',
      mode: 'cors',
      credentials: 'include', //this is what I need to tell the browser to include cookies
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ data: params }),
    })
    const data = yield request.json()
    yield put({ type: 'fetch/item', payload: data.data, ob: name })
  } catch {}
}
*/
function* getItem_item2(urlapi, name, mode, { id = "", action }) {
  //(name, mode, params) {
  try {
    let URLAPI = urlapi || URL_API;
    const request = yield fetch(`${URLAPI}/api/${name}/${mode}`, {
      method: "POST",
      mode: "cors",
      credentials: "include", //this is what I need to tell the browser to include cookies
      headers: {
        "Content-Type": "application/json",
      },
      //      body: JSON.stringify({ data: params }),
      body: JSON.stringify({ id, action }),
    });
    let data = yield request.json();
    data.data.parent = action.parent;
    yield put({ type: "fetch/item", payload: data.data, ob: name });
  } catch {}
}

function* setItem(urlapi, name, params) {
  try {
    //    console.log(params)
    let URLAPI = urlapi || URL_API;
    const request = yield fetch(`${URLAPI}/api/${name}/set`, {
      method: "POST",
      mode: "cors",
      credentials: "include", //this is what I need to tell the browser to include cookies
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "http://localhost:3015/",
      },
      body: JSON.stringify(params),
    });
    const data = yield request.json();
    //    console.log('set data', data)
    yield put({ type: `${name}/set_item`, payload: data.data });
  } catch {}
}
function* delItem(urlapi, name, params) {
  try {
    console.log("del", params);
    let URLAPI = urlapi || URL_API;
    const request = yield fetch(`${URLAPI}/api/${name}/del`, {
      method: "POST",
      mode: "cors",
      credentials: "include", //this is what I need to tell the browser to include cookies
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "http://localhost:3015/",
      },
      body: JSON.stringify(params),
    });
    const data = yield request.json();
    yield put({ type: `${name}/del_item`, payload: data.data });
  } catch {}
}

function ParseAccess(data) {
  let isadmin = (data && data.isadmin) || false;
  let access = [];
  let user;
  let UserName = null;

  let userfolder;
  let role;
  let unit;
  let type_access;
  let code;

  //data.active - !!!

  let cloudid =
    data && data.config && data.config.cloudid && data.config.cloudid;

  try {
    for (let c in data.clouds) {
      if (data.clouds[c].cloud && data.clouds[c].cloud.id === cloudid) {
        userfolder = data.clouds[c].userfolder;
      }
    }
    if (data.login)
      user = {
        id: data.id,
        login: data.login,
        firstName: data.firstName,
        lastName: data.lastName,
        avatar: data.avatar,
        worker: data.worker,
        ShowTime: data.config && data.config.ShowTime,
        halfanHour: data.config && data.config.halfanHour,
        cloudOn: data.config && data.config.cloudOn,
        language: data.config && data.config.language,
        theme: (data.config && data.config.theme) || "default",
        clouds: data.clouds && data.clouds,
        workers: data.workers && data.workers,
        typeworks: data.typeworks && data.typeworks,
        workplaces: data.workplaces && data.workplaces,
        cloud: {
          email: data.config && data.config.cloud && data.config.cloud.email,
          password:
            data.config && data.config.cloud && data.config.cloud.password,
          cloudtype:
            data.config &&
            data.config.cloud &&
            data.config.cloud.cloudtype.code,
          rootfolder:
            data.config &&
            data.config.cloud &&
            data.config.cloud.rootfolder &&
            data.config.cloud.rootfolder,
          userfolder: userfolder,
          cloudid: cloudid,
        },
      };

    if (data) {
      for (let j in data.roles) {
        type_access = null;
        code = null;
        role = data.roles[j].role;
        for (let k in role.units) {
          type_access = role.units[k].type_access;
          code = role.units[k].unit.code;
          access.push({ code, type_access });
        }
      }
      type_access = null;
      code = null;

      for (let i in data.units) {
        unit = data.units[i];
        type_access = unit.type_access;
        code = unit.unit.code;
        access.push({ code, type_access });
      }
    }

    if (user) {
      if (user.firstName) UserName = user.firstName;
      if (user.lastName) UserName = UserName + " " + user.lastName;
      if (UserName === null) UserName = user.login;
    }
  } catch (error) {
    console.log(error);
  }
  return { isadmin, access, user, UserName };
}

function* getAccessUser(urlapi, action) {
  //  const { getMega } = useHttp()

  try {
    //    const megastorage = '' //yield getMega()
    //    yield put({ type: 'fetch/item', payload: megastorage, ob: 'mega' })
    //    console.log('getAccessUser action', action)
    const userId = action.id; //'f5611003-759b-426d-a249-60f0bf1d5a43'
    //    console.log('getAccessUser userId', userId)
    const params = { id: userId };
    let URLAPI = urlapi || URL_API;
    const request = yield fetch(`${URLAPI}/api/auth/getone`, {
      method: "POST",
      mode: "cors",
      credentials: "include", //this is what I need to tell the browser to include cookies
      headers: {
        "Content-Type": "application/json",
        //'Access-Control-Allow-Origin': '*', // 'http://185.30.200.25:3013', //http://srv.home2020.keenetic.pro',
        "Access-Control-Allow-Credentials": "true",
      },
      body: JSON.stringify(params),
    });
    const data = yield request.json();
    //console.log('getAccessUser data', data.data)
    if (request.status === 401 && data.message === "Unauthorized") {
      console.log("Error 401. Unauthorized user.");
      localStorage.removeItem("userData");
    }
    let access;
    if (data.status !== "error") {
      if (typeof data.data !== "object")
        data.data = Crypto.DecryptObject(data.data);
      access = ParseAccess(data.data);
      if (access && !access.user) {
        access.access.push({ logout: true });
      }
    }
    yield put({ type: "fetch/access", payload: access, ob: "access" });
  } catch (error) {
    console.error(error);
  }
}

function* clearAccessUser() {
  //  const { getMega } = useHttp()

  try {
    let access = { access: [] };
    yield put({ type: "fetch/access", payload: access, ob: "access" });
  } catch (error) {
    console.error(error);
  }
}

function* getAvatarUser(urlapi, action) {
  //  const { getMega } = useHttp()

  try {
    //    const megastorage = '' //yield getMega()
    //    yield put({ type: 'fetch/item', payload: megastorage, ob: 'mega' })
    //    console.log('getAccessUser action', action)
    const userId = action.id; //'f5611003-759b-426d-a249-60f0bf1d5a43'
    //    console.log('getAccessUser userId', userId)
    const params = { id: userId };
    let URLAPI = urlapi || URL_API;
    const request = yield fetch(`${URLAPI}/api/auth/getoneavatar`, {
      method: "POST",
      mode: "cors",
      credentials: "include", //this is what I need to tell the browser to include cookies
      headers: {
        "Content-Type": "application/json",
        //        'Access-Control-Allow-Origin': 'http://localhost:3015/',
      },
      body: JSON.stringify(params),
    });
    const data = yield request.json();
    //console.log('getAccessUser data', data.data)
    yield put({ type: "fetch/access_add", payload: data.data, ob: "avatar" });
  } catch (error) {
    console.error(error);
  }
}

function* getWorktime(urlapi, action) {
  try {
    //    const params = action.arg
    let URLAPI = urlapi || URL_API;
    const request = yield fetch(`${URLAPI}/api/worktime/getwt`, {
      credentials: "include", //this is what I need to tell the browser to include cookies
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(action.arg),
    });
    const data = yield request.json();

    yield put({ type: "fetch/get_add", payload: data.data, ob: action.ob });
  } catch (error) {
    console.error(error);
  }
}

function* getDeletedItem(urlapi, name, { id = "", action, arg }) {
  try {
    //    console.log(action)
    let URLAPI = urlapi || URL_API;
    const request = yield fetch(`${URLAPI}/api/${name}/getdeleted`, {
      method: "POST",
      mode: "cors",
      credentials: "include", //this is what I need to tell the browser to include cookies
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ id, action }),
    });

    const data = yield request.json();
    if (name === "auth" || name === "config") {
      if (typeof data.data !== "object")
        data.data = Crypto.DecryptObject(data.data);
    }
    yield put({ type: "fetch/item", payload: data.data, ob: name });
  } catch {}
}

function* setDeleted(urlapi, name, params) {
  try {
    const { arg = "", parent } = params;
    //    console.log(action)
    let URLAPI = urlapi || URL_API;
    const request = yield fetch(`${URLAPI}/api/${name}/setdeleted`, {
      method: "POST",
      mode: "cors",
      credentials: "include", //this is what I need to tell the browser to include cookies
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ item: parent, arg }),
    });

    const data = yield request.json();
    if (name === "auth" || name === "config") {
      if (typeof data.data !== "object")
        data.data = Crypto.DecryptObject(data.data);
    }
    yield put({ type: "fetch/item", payload: data.data, ob: name });
  } catch {}
}

function* getAvatars(urlapi) {
  try {
    let URLAPI = urlapi || URL_API;
    const request = yield fetch(`${URLAPI}/api/auth/getavatar`, {
      credentials: "include", //this is what I need to tell the browser to include cookies
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const data = yield request.json();

    yield put({ type: "fetch/get_add", payload: data.data, ob: "avatar" });
  } catch (error) {
    console.error(error);
  }
}

function* getUserSegments(urlapi, params) {
  let { id = "", action, arg } = params;
  try {
    let URLAPI = urlapi || URL_API;
    const request = yield fetch(`${URLAPI}/api/auth/getusersegments`, {
      credentials: "include", //this is what I need to tell the browser to include cookies
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ id, action }),
    });
    const data = yield request.json();

    yield put({
      type: "fetch/get_add",
      payload: data.data,
      ob: "usersegments",
    });
  } catch (error) {
    console.error(error);
  }
}

function* createMessage(urlapi, params) {
  let { id = "", firstmessage } = params;
  try {
    let URLAPI = urlapi || URL_API;
    const request = yield fetch(`${URLAPI}/api/message/sendmessage`, {
      credentials: "include", //this is what I need to tell the browser to include cookies
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ id, firstmessage }),
    });
    const data = yield request.json();

    yield put({ type: "fetch/get_add", payload: data.data, ob: "sendmessage" });
  } catch (error) {
    console.error(error);
  }
}

function* getCustomerMessages(urlapi, params) {
  let { id = "", firstmessage, act } = params;
  try {
    let URLAPI = urlapi || URL_API;
    const request = yield fetch(`${URLAPI}/api/message/getcustomermessages`, {
      credentials: "include", //this is what I need to tell the browser to include cookies
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ id, firstmessage, act }),
    });
    const data = yield request.json();

    yield put({
      type: "fetch/get_add",
      payload: data.data,
      ob: "customermessages",
    });
  } catch (error) {
    console.error(error);
  }
}

function* getWorktimeMessages(urlapi, { id = "", firstmessage, act }) {
  try {
    let URLAPI = urlapi || URL_API;
    const request = yield fetch(`${URLAPI}/api/message/getworktimemessages`, {
      credentials: "include", //this is what I need to tell the browser to include cookies
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ id, firstmessage, act }),
    });
    const data = yield request.json();

    yield put({
      type: "fetch/get_add",
      payload: data.data,
      ob: "worktimemessages",
    });
  } catch (error) {
    console.error(error);
  }
}

function* setMessage(urlapi, params) {
  try {
    const name = "message";
    let URLAPI = urlapi || URL_API;
    const request = yield fetch(`${URLAPI}/api/message/set`, {
      credentials: "include", //this is what I need to tell the browser to include cookies
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(params),
    });
    const data = yield request.json();
    yield put({ type: `${name}/set_item`, payload: data.data });
  } catch (error) {
    console.error(error);
  }
}

function* setRoleUnits(urlapi, params) {
  const { id, arg } = params;
  try {
    let URLAPI = urlapi || URL_API;
    const request = yield fetch(`${URLAPI}/api/role/setroleunits`, {
      credentials: "include", //this is what I need to tell the browser to include cookies
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(params), //{ id, arg }),
    });
    const data = yield request.json();
    /*
    yield put({
      type: 'fetch/get_add',
      payload: data.data,
      ob: 'constructorone',
    })
*/

    yield put({ type: "fetch/item", payload: data.data, ob: "role" });
  } catch (error) {
    console.error(error);
  }
}

function* setUserUnits(urlapi, params) {
  const { id, arg } = params;
  try {
    let URLAPI = urlapi || URL_API;
    const request = yield fetch(`${URLAPI}/api/auth/setuserunits`, {
      credentials: "include", //this is what I need to tell the browser to include cookies
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(params), //{ id, arg }),
    });
    const data = yield request.json();
    /*
    yield put({
      type: 'fetch/get_add',
      payload: data.data,
      ob: 'constructorone',
    })
*/

    yield put({ type: "fetch/item", payload: data.data, ob: "auth" });
  } catch (error) {
    console.error(error);
  }
}

function* getlog(param) {
  let { ta_guid, date1, date2 } = param.action;

  try {
    //    console.log(action)
    let URLAPI = "http://185.59.100.225:3024";

    const request = yield fetch(`https://torg.rud.com.ua/getlogtorg`, {
      method: "POST",
      //mode: "cors",
      //credentials: "include", //this is what I need to tell the browser to include cookies
      headers: {
        "Content-Type": "application/json",
        Authorization: "pTcDWuNe1ONWCWrj",
      },
      //body: JSON.stringify(action),
      body: JSON.stringify({ ta_guid, date1, date2 }),
      /*      body: JSON.stringify({
        ta_guid: "84d2fa03-20fc-11e7-ad6e-5cf3fc31fcf7",
        date1: "1710412402",
        date2: "1710430402",
      }),
      */
    });

    const data = yield request.json();
    yield put({ type: "fetch/get_add", payload: data.date, ob: "getlog" });
  } catch (err) {
    console.log(err);
  }
}

export {
  getList,
  getItem,
  setItem,
  delItem,
  getItem_item,
  //  getItem_image,
  getItem_item2,
  getLogin,
  setLogin,
  changePsw,
  getFilter,
  getAccessUser,
  clearAccessUser,
  getAvatarUser,
  getMegaStorage,
  getImg,
  getListUser,
  getWorktime,
  getDeletedItem,
  setDeleted,
  getAvatars,
  getUserSegments,
  createMessage,
  getCustomerMessages,
  getWorktimeMessages,
  setMessage,
  setRoleUnits,
  setUserUnits,
  getlog,
};
