import React from 'react'
import Access from './access'

export default Access

export function getAccessCode(code: any, access: any) {
  let rez = false
  for (let i in access) {
    if (access[i].code === code) rez = true
  }
  return rez
}
