import { combineReducers } from 'redux'
import { createBrowserHistory } from 'history'
//import { connectRouter } from 'connected-react-router'
import { createRouterReducer } from '../../redux-react-router' //'@lagunovsky/redux-react-router'
//import { createRouterReducer } from '@lagunovsky/redux-react-router'

import appReducer from './app'

const history = createBrowserHistory()

const reducer = combineReducers({
  app: appReducer,
  router: createRouterReducer(history), // connectRouter(history),
})

export { reducer, history }
