import React, { useContext, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Button from "@mui/material/Button";

import { useTable } from "react-table";
import styled from "styled-components";
import { useMediaQuery } from "@mui/material";

import moment from "moment";

import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

import { Card, CardContent, Box } from "@mui/material";
import { useTranslate } from "../../../lib/translate/i18n/useTranslate";
import { AuthContext } from "../../../context/AuthContext";
import { getURLAPI } from "../../../utils/getURL";

import { default as Spinner } from "../../../components/Spinner";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const Styles = styled.div`
  table {
    border-spacing: 0;
    border: 1px solid #c3c3c3;
    border-radius: 10px;
    border-collapse: initial;
    tr {
      border-radius: 0px;

      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th,
    td {
      margin: 0;
      padding: 0.5rem;
      border-bottom: 1px solid #c3c3c3;
      border-right: 1px solid #c3c3c3;

      :last-child {
        border-right: 0;
      }
    }
  }
`;

const defaultPropGetter = () => ({});

var columns = [];
var data = [];

const OrderList = () => {
  const body = useSelector((state) => state.app.body);
  const access = useSelector((state) => state.app.access.access);
  const isadmin = (access && access.isadmin) || false;
  const isXSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const nameTheme = access && access.user && access.user.theme;
  const translate = useTranslate();
  const auth = useContext(AuthContext);
  const dispatch = useDispatch();
  const config = auth.config;
  let URLAPI = getURLAPI(config);

  const [supervisor2, setSupervisor2] = useState([]); //auth.Unitype || [])

  const changeSupervisor = (event, value) => {
    setSupervisor2(value);
    //    auth.setWorker(value)
  };

  const [managers2, setManagers2] = useState([]); //auth.Unitype || [])

  const changeManagers = (event, value) => {
    setManagers2(value);
    //    auth.setWorker(value)
  };

  //START get all str from table Roles
  //console.log('Refresh!!! workplace...')
  const [startRefresh, setStartRefresh] = useState(true);

  //замыкание
  const ws_client = auth.ws_client; //body.ws_client

  //  const item_id = body.item_id

  //  const readonly = body.readonly

  const GetData_handle = function (_result) {
    const { tableName, result, readonly, action } = _result;
    //console.log('result handle1 ', result

    if (tableName === "Orders") {
      dispatch({
        type: "fetch/get_add",
        payload: result,
        ob: "order",
      });
    }

    if (tableName === "Supervisors" && action.metod === "get") {
      dispatch({
        type: "fetch/filter", //'fetch/get_add',
        payload: result,
        ob: "supervisors",
      });
    }
    if (tableName === "Managers" && action.metod === "getall") {
      dispatch({
        type: "fetch/filter", //'fetch/get_add',
        payload: result,
        ob: "managers",
      });
    }
  };

  const Refresh_handle = async function (result, event, parent) {
    const { tableName, action, metod } = result;
    /*console.log(
      'Refresh_handle ',
      event,
      new Date().toLocaleTimeString(),
      result
    )*/
    setStartRefresh(true);
  };

  const params = {
    URL_WS: getURLAPI(auth.config, "URL_WS"), //get address WebSocket BK from config
    auth: { userId: auth.userId, tocken: auth.token },
    handleGetData: GetData_handle,
    //handleChangeData: Refresh_handle,
    handleSetRefresh: setStartRefresh,
    handleErrorRead: null,
    handleErrorChange: null,
    models: [
      {
        model_call_nm: "Model_read",
        tableName: "Orders",
        action: {
          metod: "get",
        },
      },
      {
        model_call_nm: "Model_read",
        tableName: "Supervisors",
        action: {
          metod: "get",
        },
      },
      {
        model_call_nm: "Model_read",
        tableName: "Managers",
        action: {
          metod: "getall",
        },
      },
    ],
  };

  // close page
  useEffect(
    () => () => {
      try {
        dispatch({
          type: "fetch/get",
          payload: true,
          ob: "close",
        });
      } catch (err) {
        console.log("deblock error ", err);
      }
    },
    []
  );

  if (ws_client && ws_client.ws_login && startRefresh) {
    setStartRefresh(false);
    ws_client.open_process(params);
  }

  //END get all str from table Roles

  let greybkcolor = "rgba(255,255,255,.3)";

  if (nameTheme) {
    greybkcolor =
      nameTheme !== "dark" ? "rgba(255,255,255,1.0)" : "rgba(255,255,255,.3)";
  }

  columns = [
    {
      Header: "№",
      accessor: "num",
    },
    {
      Header: translate("resources.order.fields.date"), //'Тип работ',
      accessor: "date",
    },
    {
      Header: translate("resources.order.fields.manager"), //'Тип работ',
      accessor: "manager",
    },
    {
      Header: translate("resources.order.fields.store"), //'Тип работ',
      accessor: "store",
    },
    {
      Header: translate("resources.order.fields.summa"), //'Тип работ',
      accessor: "summa",
    },
    {
      Header: translate("resources.order.fields.formpay"), //'Тип работ',
      accessor: "formpay",
    },
    {
      Header: translate("resources.order.fields.vidpay"), //'Тип работ',
      accessor: "vidpay",
    },
    {
      Header: translate("resources.order.fields.typeprice"), //'Тип работ',
      accessor: "typeprice",
    },
  ];

  data = [];

  let num = 1;
  let orders = body.order;

  const searchmanager = (id) => {
    for (let i in managers2) {
      if (managers2[i].value === id) {
        return true;
      }
    }
    return false;
  };

  if (managers2 && managers2.length > 0 && orders) {
    orders = orders.filter((item) => {
      //      console.log('item', item)
      //      console.log('item.name', item.name)
      return searchmanager(item.managerid);
    });
  }
  for (var iw in orders) {
    data.push({
      num: num++,
      date: moment(orders[iw].date).format("DD.MM.YYYY"),
      summa: orders[iw].summa,
      formpay: orders[iw].formpays && orders[iw].formpays.name,
      vidpay: orders[iw].vidpays && orders[iw].vidpays.name,
      typeprice: orders[iw].typeprices && orders[iw].typeprices.name,
      store: orders[iw].stores && orders[iw].stores.name,
      manager: orders[iw].managers && orders[iw].managers.name,
      order: orders[iw],
    });
  }

  let Supervisors = useSelector((state) => state.app.filter.supervisors_filter);

  let supervisors = [];
  for (var ii in Supervisors) {
    supervisors.push({
      label: Supervisors[ii].name,
      value: Supervisors[ii].id,
    });
  }

  let Managers = useSelector((state) => state.app.filter.managers_filter);

  let managers = [];
  for (var jj in Managers) {
    managers.push({
      label: Managers[jj].name,
      value: Managers[jj].id,
    });
  }

  //          body.orders.map((Item) => <ItemList Item={Item} />)}
  //          body.orders.map((Item) => <ItemList Item={Item} />)}
  function Table({
    columns,
    data,
    getHeaderProps = defaultPropGetter,
    getColumnProps = defaultPropGetter,
    getRowProps = defaultPropGetter,
    getCellProps = defaultPropGetter,
  }) {
    // Use the state and functions returned from useTable to build your UI
    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
      useTable({
        columns,
        data,
      });

    return (
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  // Return an array of prop objects and react-table will merge them appropriately
                  {...column.getHeaderProps([
                    {
                      className: column.className,
                      style: column.style,
                    },
                    getColumnProps(column),

                    getHeaderProps(column),
                  ])}
                >
                  {column.render("Header")}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              // Merge user row props in
              <tr {...row.getRowProps(getRowProps(row))}>
                {row.cells.map((cell) => {
                  return (
                    <td
                      // Return an array of prop objects and react-table will merge them appropriately
                      {...cell.getCellProps([
                        {
                          className: cell.column.className,
                          style: cell.column.style,
                        },
                        getColumnProps(cell.column),
                        //  getCellProps(cell),
                      ])}
                      align="center"
                      //                    width="140px"
                    >
                      {(cell.column.id !== "timeline" && (
                        <Link
                          as={Link}
                          variant="outline-primary"
                          //style={{ marginBottom: 5 }}

                          to={{
                            pathname: "/pages/order/item",
                          }}
                          state={{
                            id: cell.row.original.order.id, //cell.row.original['worktime_id'],
                          }}
                          onClick={() => {
                            dispatch({
                              type: "fetch/get", //_add',
                              payload: cell.row.original.order.id,
                              ob: "item_id",
                            });
                          }}
                        >
                          {cell.render("Cell")}
                        </Link>
                      )) ||
                        cell.render("Cell")}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  }

  const ready = () => {
    if (body && body.order) return true;
    else return false;
  };

  return (
    <>
      {ready() && (
        <>
          <Box maxWidth="80em">
            <Card>
              <CardContent>
                <>
                  {/*                  <Button
                    size="small"
                    component={Link}
                    color="primary"
                    variant="outlined"
                    //style={{ marginBottom: 10 }}
                    sx={{
                      display: "inline-flex",
                      alignItems: "center",
                      "&:hover": {
                        backgroundColor: "primary.light",
                        //backgroundColor: 'primary.dark',
                        opacity: [0.9, 0.8, 0.7],
                      },
                    }}
                    //        as={Link}        variant="outline-primary"        style={{ marginBottom: 5 }}
                    to="/pages/order/item"
                  >
                    {translate("resources.button.add")}
                  </Button>
                  */}
                  <Button
                    size="small"
                    component={Link}
                    color="primary"
                    variant="outlined"
                    //style={{ marginBottom: 10 }}
                    sx={{
                      display: "inline-flex",
                      alignItems: "center",
                      "&:hover": {
                        backgroundColor: "primary.light",
                        //backgroundColor: 'primary.dark',
                        opacity: [0.9, 0.8, 0.7],
                      },
                    }}
                    //        as={Link}        variant="outline-primary"        style={{ marginBottom: 5 }}
                    to="/"
                  >
                    {translate("resources.button.return")}
                  </Button>

                  <br />
                  <div
                    style={{
                      //            marginTop: -20,
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Autocomplete
                      multiple
                      id="size-small-outlined" //      id="checkboxes-tags"
                      options={supervisors}
                      onChange={changeSupervisor}
                      size="small"
                      value={[...supervisor2]}
                      //                defaultValue={(worker2 && worker2[0]) || []}
                      limitTags={4}
                      //              filterSelectedOptions
                      isOptionEqualToValue={(props, option) => {
                        let rez = false;
                        if (props.value === option.value) rez = true;
                        return rez;
                      }}
                      selectOnFocus
                      clearOnBlur
                      onFocus={(e) => {
                        //            console.log(e.target.readOnly)
                        return (e.target.readOnly = true);
                      }} //выключение клавиатуры при выборе      handleHomeEndKeys
                      //      disableCloseOnSelect
                      getOptionLabel={(option) => option.label}
                      renderOption={(props, option, { selected }) => {
                        return (
                          <li {...props}>
                            <Checkbox
                              icon={icon}
                              checkedIcon={checkedIcon}
                              style={{ marginRight: 8 }}
                              checked={selected}
                            />
                            {option.label}
                          </li>
                        );
                      }}
                      style={{ width: isXSmall ? 210 : 400 }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Выберите супервайзера" //{translate('resources.unit.selectaccesstype')} //"Выберите Тип доступа"
                          variant="outlined"
                          //          variant="standard"
                          //          placeholder="Выберите Мастера"
                        />
                      )}
                    />
                    <Autocomplete
                      multiple
                      id="size-small-outlined" //      id="checkboxes-tags"
                      options={managers}
                      onChange={changeManagers}
                      size="small"
                      value={[...managers2]}
                      //                defaultValue={(worker2 && worker2[0]) || []}
                      limitTags={4}
                      //              filterSelectedOptions
                      isOptionEqualToValue={(props, option) => {
                        let rez = false;
                        if (props.value === option.value) rez = true;
                        return rez;
                      }}
                      selectOnFocus
                      clearOnBlur
                      onFocus={(e) => {
                        //            console.log(e.target.readOnly)
                        return (e.target.readOnly = true);
                      }} //выключение клавиатуры при выборе      handleHomeEndKeys
                      //      disableCloseOnSelect
                      getOptionLabel={(option) => option.label}
                      renderOption={(props, option, { selected }) => {
                        return (
                          <li {...props}>
                            <Checkbox
                              icon={icon}
                              checkedIcon={checkedIcon}
                              style={{ marginRight: 8 }}
                              checked={selected}
                            />
                            {option.label}
                          </li>
                        );
                      }}
                      style={{ width: isXSmall ? 210 : 400 }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Выберите менеджера" //{translate('resources.unit.selectaccesstype')} //"Выберите Тип доступа"
                          variant="outlined"
                          //          variant="standard"
                          //          placeholder="Выберите Мастера"
                        />
                      )}
                    />
                  </div>
                  <Styles>
                    <Table
                      columns={columns}
                      data={data}
                      getHeaderProps={
                        (nameTheme &&
                          nameTheme !== "dark" &&
                          ((row) => {
                            return {
                              style: {
                                //                   background:
                                //                   row.index % 2 === 0 ? 'rgba(0,0,0,.1)' : 'white',
                              },
                            };
                          })) ||
                        ((row) => {
                          return {
                            style: {
                              color: "#ffffffb3",
                              //                    background:
                              //                    row.index % 2 === 0 ? 'rgba(0,0,0,.1)' : '#ffffffb3', //'#616161e6', //'#ffffffb3',
                            },
                          };
                        })
                      }
                      /*          
          getHeaderProps={(column) => ({
            onClick: () => alert('Header!'),
          })}
          getColumnProps={(column) => ({
            onClick: () => alert('Column!'),
          })}
          */
                      getRowProps={
                        (nameTheme &&
                          nameTheme !== "dark" &&
                          ((row) => {
                            return {
                              style: {
                                background:
                                  row.index % 2 === 0
                                    ? "rgba(0,0,0,.1)"
                                    : "white",
                              },
                            };
                          })) ||
                        ((row) => {
                          return {
                            style: {
                              background:
                                row.index % 2 === 0
                                  ? "rgba(0,0,0,.1)"
                                  : "#616161e6", //'#ffffffb3',
                            },
                          };
                        })
                      }
                      getCellProps={(cellInfo) => ({
                        //            onClick: (req, res) => onClickCell(cellInfo, req, res),
                        //            style: {
                        //              backgroundColor: `hsl(${
                        //                120 * ((120 - cellInfo.value) / 120) * -1 + 120
                        //              }, 100%, 67%)`,
                        //            },
                      })}
                    />
                  </Styles>
                </>
              </CardContent>
            </Card>
          </Box>
        </>
      )}
      {!ready() && (
        <>
          <Box maxWidth="30em">
            <Card>
              <CardContent>
                <Spinner />
              </CardContent>
            </Card>
          </Box>
        </>
      )}
    </>
  );
};

export default OrderList;
