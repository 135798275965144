import React, { useContext } from 'react'
import { AuthContext } from '../../context/AuthContext'
import { Navigate } from 'react-router-dom'

export const LogoutPage = () => {
  const auth = useContext(AuthContext)
  auth.logout()
  return (
    <>
      <Navigate to="/" replace={true} />
    </>
  )
}
