import React, { useContext, useState, useEffect } from "react";
import { Form, Modal, ListGroup, Row, Col } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { useTable } from "react-table";
import styled from "styled-components";
import { useMediaQuery } from "@mui/material";
import { Link } from "react-router-dom";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { useTranslate } from "../lib/translate/i18n/useTranslate";
import { AuthContext } from "../context/AuthContext";
import { getURLAPI } from "../utils/getURL";

const Styles = styled.div`
  table {
    border-spacing: 0;
    border: 1px solid black;

    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th,
    td {
      margin: 0;
      padding: 0.5rem;
      border-bottom: 1px solid black;
      border-right: 1px solid black;

      :last-child {
        border-right: 0;
      }
    }
  }
`;

const defaultPropGetter = () => ({});

var columns = [];
var data = [];

const selectItem = (select, Item) => {
  select(Item);
};

const SelectUsers = ({
  field,
  title,
  placeholder,
  value,
  onChanged,
  disabled,
}) => {
  const name = "user";

  const access = useSelector((state) => state.app.access.access);
  const isadmin = (access && access.isadmin) || false;
  const isXSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const nameTheme = access && access.user && access.user.theme;
  const translate = useTranslate();
  const auth = useContext(AuthContext);

  const dispatch = useDispatch();
  const config = auth.config;
  let URLAPI = getURLAPI(config);

  let greybkcolor = "rgba(255,255,255,.3)";

  if (nameTheme) {
    greybkcolor =
      nameTheme !== "dark" ? "rgba(255,255,255,1.0)" : "rgba(255,255,255,.3)";
  }

  const [modalShow, setModalShow] = React.useState(false);
  const [filter, setFilter] = useState("");

  let viewtValue = field && field[name] && field[name].fullName;

  const ws_client = auth.ws_client;

  //  const item_id = body.item_id

  //  const readonly = body.readonly

  const GetData_handle = function (_result) {
    const { tableName, result, readonly, action } = _result;
    //console.log('result handle1 ', result)
    dispatch({
      type: "fetch_current/get",
      payload: result,
      ob: name, //'phone',
    });
  };

  const params = {
    URL_WS: getURLAPI(auth.config, "URL_WS"), //get address WebSocket BK from config
    auth: { userId: auth.userId, tocken: auth.token },
    handleGetData: GetData_handle,
    //handleChangeData: Refresh_handle,
    //handleSetRefresh: setStartRefresh,
    models: [
      {
        model_call_nm: "Model_read",
        tableName: "Users",
        action: {
          metod: "getAvatar", // 'get',
        },
      },
    ],
  };

  //*********** */

  useEffect(() => {
    if (modalShow) {
      ws_client.open_process(params);
    }

    //    modalShow && dispatch({ type: 'FETCH_LIST', urlapi: URLAPI, ob: name })
  }, [modalShow]);

  /*
  const select = (Item) => {
    dispatch({
      type: 'app/set',
      payload: {
        id: field,
        field: `${name}id`,
        value: Item,
        ob: name,
      },
      ob: value,
    })

    setModalShow(false)
  }
*/

  const list = useSelector((state) => state.app.current[name]) || [];

  let users = list; //access && access.user && access.user.users && access.user.users

  let userworkers = access && access.user && access.user.workers;
  //console.log('userworkers', userworkers)
  let userworkerid = [];
  for (let u in userworkers) {
    userworkerid.push(userworkers[u].user && userworkers[u].user.id);
  }
  //console.log('userworkerid', userworkerid)
  //console.log('users', users)

  if (!isadmin)
    users = users.filter((item) => {
      let rez = false;
      for (let ii in userworkerid)
        if (item.id === userworkerid[ii]) {
          rez = true;
        }
      //    console.log('userworkerid.find(item.id)', userworkerid, item.id)
      return rez; //userworkerid.find(item.id)
      //[item.name].contains('filter')
    });

  //  console.log('users', users)

  if (filter)
    users = users.filter((item) => {
      //      console.log('item', item)
      //      console.log('item.name', item.name)
      return (
        (item.name && item.name.toLowerCase().includes(filter.toLowerCase())) ||
        (item.lastName &&
          item.lastName.toLowerCase().includes(filter.toLowerCase())) ||
        (item.tel1 && item.tel1.toLowerCase().includes(filter.toLowerCase())) ||
        (item.tel2 && item.tel2.toLowerCase().includes(filter.toLowerCase())) ||
        //        (item.cloudtype.name && item.cloudtype.name.includes(filter)) ||
        false
      );
      //[item.name].contains('filter')
    });

  columns = [
    {
      Header: translate("resources.select.fields.name"), //'Имя',
      accessor: "fullName",
    },
  ];

  /*
    {
      Header: 'Должность',
      accessor: 'jobTitle',
    },

*/

  if (!isXSmall) {
    columns.push({
      Header: translate("resources.select.fields.tel"), //'Телефоны',
      accessor: "phone",
    });
  }
  data = [];

  //  const users = body.user
  let num = 1;
  let user;
  let tel2;
  for (var iw in users) {
    tel2 = "";
    if (users[iw].tel2) tel2 = " / " + users[iw].tel2;
    data.push({
      num: num++,
      name: users[iw].name,
      fullName: users[iw].fullName,
      jobTitle: users[iw].jobTitle,
      phone: users[iw].phone,
      user: users[iw],
    });
  }

  //************************* */
  function Table({
    columns,
    data,
    getHeaderProps = defaultPropGetter,
    getColumnProps = defaultPropGetter,
    getRowProps = defaultPropGetter,
    getCellProps = defaultPropGetter,
  }) {
    // Use the state and functions returned from useTable to build your UI
    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
      useTable({
        columns,
        data,
      });

    return (
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  // Return an array of prop objects and react-table will merge them appropriately
                  {...column.getHeaderProps([
                    {
                      className: column.className,
                      style: column.style,
                    },
                    getColumnProps(column),

                    getHeaderProps(column),
                  ])}
                >
                  {column.render("Header")}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              // Merge user row props in
              <tr {...row.getRowProps(getRowProps(row))}>
                {row.cells.map((cell) => {
                  return (
                    <td
                      // Return an array of prop objects and react-table will merge them appropriately
                      {...cell.getCellProps([
                        {
                          className: cell.column.className,
                          style: cell.column.style,
                        },
                        getColumnProps(cell.column),
                        //  getCellProps(cell),
                      ])}
                      align="center"
                      //                    width="140px"
                    >
                      {(cell.column.id === "fullName" && (
                        <Button
                          size="small"
                          color="primary"
                          variant="outlined"
                          sx={{ display: "inline-flex", alignItems: "center" }}
                          onClick={(req, res) => {
                            const user = cell.row.original.user;
                            //                            console.log(cloud.id)
                            //cell.row.original['worktime_id'],

                            dispatch({
                              type: "app/set",
                              payload: {
                                id: field,
                                field: `${name}id`,
                                value: user,
                                ob: name,
                              },
                              ob: value,
                            });

                            onChanged && onChanged(user);
                            setModalShow(false);
                          }} //onClickCell(cellInfo, req, res),
                          //                        onClick
                        >
                          {cell.render("Cell")}
                        </Button>
                      )) ||
                        cell.render("Cell")}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  }

  return (
    <>
      <Modal
        show={modalShow && !disabled}
        onHide={() => setModalShow(false)}
        size="lg"
        aria-labelledby={`contained-modal-${name}-vcenter`}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id={`contained-modal-${name}-vcenter`}>
            {title}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <TextField
            id="outlined-basic"
            label={translate("resources.allselect.search")} //"Введите поисковый запрос"
            //variant="standard"
            variant="outlined"
            style={{
              marginBottom: 15,
              background: nameTheme !== "dark" ? "rgba(0,0,0,.1)" : "#616161e6", //'#ffffffb3',

              display: "flex",
              justifyContent: "space-between",
            }}
            defaultValue={filter}
            onChange={(item) => setFilter(item.target.value)}
          />
          <Styles>
            <Table
              columns={columns}
              data={data}
              getHeaderProps={
                (nameTheme &&
                  nameTheme !== "dark" &&
                  ((row) => {
                    return {
                      style: {
                        //                   background:
                        //                   row.index % 2 === 0 ? 'rgba(0,0,0,.1)' : 'white',
                      },
                    };
                  })) ||
                ((row) => {
                  return {
                    style: {
                      color: "#ffffffb3",
                      background:
                        nameTheme !== "dark" ? "rgba(0,0,0,.1)" : "#616161e6", //'#ffffffb3',
                      //                    background:
                      //                    row.index % 2 === 0 ? 'rgba(0,0,0,.1)' : '#ffffffb3', //'#616161e6', //'#ffffffb3',
                    },
                  };
                })
              }
              /*          
          getHeaderProps={(column) => ({
            onClick: () => alert('Header!'),
          })}
          getColumnProps={(column) => ({
            onClick: () => alert('Column!'),
          })}
          */
              getRowProps={
                (nameTheme &&
                  nameTheme !== "dark" &&
                  ((row) => {
                    return {
                      style: {
                        background:
                          row.index % 2 === 0 ? "rgba(0,0,0,.1)" : "white",
                      },
                    };
                  })) ||
                ((row) => {
                  return {
                    style: {
                      background:
                        row.index % 2 === 0 ? "rgba(0,0,0,.1)" : "#616161e6", //'#ffffffb3',
                    },
                  };
                })
              }
              getCellProps={(cellInfo) => ({
                //                onClick: (req, res) => {
                //                  console.log(cellInfo)
                //                }, //onClickCell(cellInfo, req, res),
                /*                style: {
                  backgroundColor: `hsl(${
                    120 * ((120 - cellInfo.value) / 120) * -1 + 120
                  }, 100%, 67%)`,
                },
              */
              })}
            />
          </Styles>
        </Modal.Body>
      </Modal>

      <TextField
        readOnly
        id="outlined-basic"
        label={placeholder}
        //variant="standard"
        variant="outlined"
        style={{
          marginBottom: 15,
          display: "flex",
          justifyContent: "space-between",
          backgroundColor: viewtValue || !field ? greybkcolor : greybkcolor, // "yellow",
        }}
        //defaultValue={viewtValue || ''} //field && field[name] && field[name].name}
        disabled={disabled || false}
        value={viewtValue || ""} //field && field[name] && field[name].name}
        onClick={() => setModalShow(true)}
        onFocus={(e) => (e.target.readOnly = true)} //выключение клавиатуры при выборе значения
      />
    </>
  );
};

export default SelectUsers;
