import CryptoJS from 'crypto-js'

const secretkey = 'la bonne biere'
// EncryptObject

const EncryptObject = (data: any) => {
  var ciphertext = CryptoJS.AES.encrypt(
    JSON.stringify(data),
    secretkey
  ).toString()
  return ciphertext
}
// DecryptObject
const DecryptObject = (data: any) => {
  var bytes = CryptoJS.AES.decrypt(data, secretkey)
  var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8))
  return decryptedData
}

export default { EncryptObject, DecryptObject }
