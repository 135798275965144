import React, { useContext, useState, useEffect } from 'react'
import { Form, Modal, ListGroup, Row, Col } from 'react-bootstrap'
import { useSelector, useDispatch } from 'react-redux'
import { useTable } from 'react-table'
import styled from 'styled-components'
import { useMediaQuery } from '@mui/material'
import { Link } from 'react-router-dom'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import { useCallback } from 'react'
import FormControlLabel from '@mui/material/FormControlLabel'
import Switch, { SwitchProps } from '@mui/material/Switch'
import { useTranslate } from '../lib/translate/i18n/useTranslate'
import { AuthContext } from '../context/AuthContext'
import { getURLAPI } from '../utils/getURL'

const Styles = styled.div`
  table {
    border-spacing: 0;
    border: 1px solid black;

    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th,
    td {
      margin: 0;
      padding: 0.5rem;
      border-bottom: 1px solid black;
      border-right: 1px solid black;

      :last-child {
        border-right: 0;
      }
    }
  }
`

const defaultPropGetter = () => ({})

var columns = []
var data = []

const selectItem = (select, Item) => {
  select(Item)
}

const SelectViberUser = ({
  field,
  customers,
  title,
  placeholder,
  value,
  onChanged,
  disabled,
}) => {
  const name = 'viber'

  const access = useSelector((state) => state.app.access.access)
  const isadmin = (access && access.isadmin) || false
  const isXSmall = useMediaQuery((theme) => theme.breakpoints.down('sm'))

  const nameTheme = access && access.user && access.user.theme
  const translate = useTranslate()
  const auth = useContext(AuthContext)
  const config = auth.config
  let URLAPI = getURLAPI(config)

  const [modalShow, setModalShow] = React.useState(false)
  const [filter, setFilter] = useState('')

  const [findsubscribed, setfindsubscribed] = useState(false)

  const handleChangefindsubscribed = useCallback((event) => {
    setfindsubscribed(!findsubscribed)
  })

  const [findused, setfindused] = useState(false)

  const handleChangefindused = useCallback((event) => {
    setfindused(!findused)
  })

  let viewtValue = field && field[name] && field[name].name
  if (field && field[name] && field[name].phone)
    viewtValue = viewtValue + '/' + field[name].phone
  if (field && field[name])
    viewtValue = viewtValue + '/' + (field[name].subscribed ? '+' : '-')

  const ws_client = auth.ws_client

  //  const item_id = body.item_id

  //  const readonly = body.readonly

  const GetData_handle = function (_result) {
    const { tableName, result, readonly, action } = _result
    //console.log('result handle1 ', result)
    dispatch({
      type: 'fetch_current/get',
      payload: result,
      ob: name, //'phone',
    })
  }

  const params = {
    URL_WS: getURLAPI(auth.config, 'URL_WS'), //get address WebSocket BK from config
    auth: { userId: auth.userId, tocken: auth.token },
    handleGetData: GetData_handle,
    //handleChangeData: Refresh_handle,
    //handleSetRefresh: setStartRefresh,
    models: [
      {
        model_call_nm: 'Model_read',
        tableName: 'ViberUserProfiles',
        action: {
          //          metod: 'getRoles',
        },
      },
    ],
  }

  //*********** */
  const dispatch = useDispatch()
  useEffect(() => {
    if (modalShow) {
      ws_client.open_process(params)
    }

    //    modalShow && dispatch({ type: 'FETCH_LIST', urlapi: URLAPI, ob: name })
  }, [modalShow])

  /*
  const select = (Item) => {
    dispatch({
      type: 'app/set',
      payload: {
        id: field,
        field: `${name}id`,
        value: Item,
        ob: name,
      },
      ob: value,
    })

    setModalShow(false)
  }
*/

  //  const customers = field && field.customers

  const list = useSelector((state) => state.app.current[name]) || []

  let viberUsers = list //access && access.user && access.user.viberUsers && access.user.viberUsers

  if (!findsubscribed)
    viberUsers = viberUsers.filter((item) => {
      //      console.log('item', item)
      //      console.log('item.name', item.name)
      return item.subscribed || false
      //[item.name].contains('filter')
    })
  if (!findused)
    viberUsers = viberUsers.filter((item) => {
      //      console.log('item', item)
      //      console.log('item.name', item.name)
      return (
        customers.find((customer) => {
          return customer.viberid === item.id
        }) === undefined || false
      )
      //[item.name].contains('filter')
    })

  if (filter)
    viberUsers = viberUsers.filter((item) => {
      //      console.log('item', item)
      //      console.log('item.name', item.name)
      return (
        (item.name && item.name.toLowerCase().includes(filter.toLowerCase())) ||
        (item.phone &&
          item.phone.toLowerCase().includes(filter.toLowerCase())) ||
        false
      )
      //[item.name].contains('filter')
    })

  columns = [
    {
      Header: translate('resources.select.fields.avatar'), //'avatar',
      accessor: 'avatar',
    },
    {
      Header: translate('resources.select.fields.name'), //'Имя',
      accessor: 'name',
    },
    {
      Header: translate('resources.select.fields.tel'), //'phone',
      accessor: 'phone',
    },
  ]

  /*
    {
      Header: 'Должность',
      accessor: 'jobTitle',
    },

*/

  if (!isXSmall) {
    columns.push({
      Header: translate('resources.select.fields.subscribed'), //'Подписан',
      accessor: 'subscribed',
    })
    columns.push({
      Header: translate('resources.select.fields.client'), //'Клиент',
      accessor: 'customer',
    })
  }

  data = []

  //  const viberUsers = body.worker
  let num = 1
  let customer
  for (var iw in viberUsers) {
    customer = null
    customer = customers.find(
      (customer) => customer.viberid === viberUsers[iw].id
    )
    data.push({
      num: num++,
      avatar: viberUsers[iw].avatar,
      name: viberUsers[iw].name,
      phone: viberUsers[iw].phone,
      subscribed: viberUsers[iw].subscribed ? '+' : '-',
      viberUsers: viberUsers[iw],
      customer:
        customer && customer.name + (customer.tel1 ? '/' + customer.tel1 : ''),
    })
  }

  //************************* */
  function Table({
    columns,
    data,
    getHeaderProps = defaultPropGetter,
    getColumnProps = defaultPropGetter,
    getRowProps = defaultPropGetter,
    getCellProps = defaultPropGetter,
  }) {
    // Use the state and functions returned from useTable to build your UI
    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
      useTable({
        columns,
        data,
      })

    return (
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  // Return an array of prop objects and react-table will merge them appropriately
                  {...column.getHeaderProps([
                    {
                      className: column.className,
                      style: column.style,
                    },
                    getColumnProps(column),

                    getHeaderProps(column),
                  ])}
                >
                  {column.render('Header')}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row)
            return (
              // Merge user row props in
              <tr {...row.getRowProps(getRowProps(row))}>
                {row.cells.map((cell) => {
                  const UserAvatar = cell.row.original.avatar
                  return (
                    <td
                      // Return an array of prop objects and react-table will merge them appropriately
                      {...cell.getCellProps([
                        {
                          className: cell.column.className,
                          style: cell.column.style,
                        },
                        getColumnProps(cell.column),
                        //  getCellProps(cell),
                      ])}
                      align="center"
                      //                    width="140px"
                    >
                      {cell.column.id === 'avatar' && UserAvatar !== '' && (
                        <div>
                          <img
                            className="image-gallery-thumbnail-image"
                            src={UserAvatar}
                            height={'50px'}
                            width={'50px'} //                          style={(width = 100)}
                          ></img>
                        </div>
                      )}

                      {cell.column.id !== 'avatar' && (
                        //                      {(cell.column.id === 'name' && (
                        <Button
                          size="small"
                          color="primary"
                          variant="outlined"
                          sx={{ display: 'inline-flex', alignItems: 'center' }}
                          onClick={(req, res) => {
                            const viberUsers = cell.row.original.viberUsers
                            //                            console.log(cloud.id)
                            //cell.row.original['worktime_id'],

                            dispatch({
                              type: 'app/set',
                              payload: {
                                id: field,
                                field: `${name}id`,
                                value: viberUsers,
                                ob: name,
                              },
                              ob: value,
                            })
                            onChanged && onChanged()

                            setModalShow(false)
                          }} //onClickCell(cellInfo, req, res),
                          //                        onClick
                        >
                          {cell.render('Cell')}
                        </Button>
                      )}
                    </td>
                  )
                })}
              </tr>
            )
          })}
        </tbody>
      </table>
    )
  }

  return (
    <>
      <Modal
        show={modalShow && !disabled}
        onHide={() => setModalShow(false)}
        size="lg"
        aria-labelledby={`contained-modal-${name}-vcenter`}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id={`contained-modal-${name}-vcenter`}>
            {title}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <TextField
            id="outlined-basic"
            label={translate('resources.select.search')} //"Введите поисковый запрос"
            //variant="standard"
            variant="outlined"
            style={{
              marginBottom: 15,
              background: nameTheme !== 'dark' ? 'rgba(0,0,0,.1)' : '#616161e6', //'#ffffffb3',

              display: 'flex',
              justifyContent: 'space-between',
            }}
            defaultValue={filter}
            onChange={(item) => setFilter(item.target.value)}
          />
          <div
            //            className="hstack gap-2"
            style={{
              //            marginTop: -20,
              display: 'flex',
              //                  justifyContent: 'space-between',
            }}
          >
            <FormControlLabel
              style={{
                marginRight: isXSmall ? 0 : 15,
                marginLeft: isXSmall ? 0 : 10,
              }}
              control={
                <Switch
                  id={field && field.id}
                  name={'findused'}
                  color="primary"
                  onChange={handleChangefindused}
                  //                          onFocus={onFocus}
                  checked={findused}
                  //                      {...sanitizeInputRestProps(rest)}
                  //                      {...options}
                  //                      disabled={disabled}
                />
              }
              label={translate('resources.select.fields.linked')}
            />
            <FormControlLabel
              style={{
                marginRight: isXSmall ? 0 : 15,
                marginLeft: isXSmall ? 0 : 10,
              }}
              control={
                <Switch
                  id={field && field.id}
                  name={'findsubscribed'}
                  color="primary"
                  onChange={handleChangefindsubscribed}
                  //                          onFocus={onFocus}
                  checked={findsubscribed}
                  //                      {...sanitizeInputRestProps(rest)}
                  //                      {...options}
                  //                      disabled={disabled}
                />
              }
              label={translate('resources.select.fields.notsubscribe')}
            />
          </div>
          <Styles>
            <Table
              columns={columns}
              data={data}
              getHeaderProps={
                (nameTheme &&
                  nameTheme !== 'dark' &&
                  ((row) => {
                    return {
                      style: {
                        //                   background:
                        //                   row.index % 2 === 0 ? 'rgba(0,0,0,.1)' : 'white',
                      },
                    }
                  })) ||
                ((row) => {
                  return {
                    style: {
                      color: '#ffffffb3',
                      background:
                        nameTheme !== 'dark' ? 'rgba(0,0,0,.1)' : '#616161e6', //'#ffffffb3',
                      //                    background:
                      //                    row.index % 2 === 0 ? 'rgba(0,0,0,.1)' : '#ffffffb3', //'#616161e6', //'#ffffffb3',
                    },
                  }
                })
              }
              /*          
          getHeaderProps={(column) => ({
            onClick: () => alert('Header!'),
          })}
          getColumnProps={(column) => ({
            onClick: () => alert('Column!'),
          })}
          */
              getRowProps={
                (nameTheme &&
                  nameTheme !== 'dark' &&
                  ((row) => {
                    return {
                      style: {
                        background:
                          row.index % 2 === 0 ? 'rgba(0,0,0,.1)' : 'white',
                      },
                    }
                  })) ||
                ((row) => {
                  return {
                    style: {
                      background:
                        row.index % 2 === 0 ? 'rgba(0,0,0,.1)' : '#616161e6', //'#ffffffb3',
                    },
                  }
                })
              }
              getCellProps={(cellInfo) => ({
                //                onClick: (req, res) => {
                //                  console.log(cellInfo)
                //                }, //onClickCell(cellInfo, req, res),
                /*                style: {
                  backgroundColor: `hsl(${
                    120 * ((120 - cellInfo.value) / 120) * -1 + 120
                  }, 100%, 67%)`,
                },
              */
              })}
            />
          </Styles>
        </Modal.Body>
      </Modal>

      <TextField
        readOnly
        id="outlined-basic"
        label={placeholder}
        //variant="standard"
        variant="outlined"
        style={{
          marginBottom: 15,
          display: 'flex',
          justifyContent: 'space-between',
        }}
        disabled={disabled || false}
        //defaultValue={viewtValue || ''} //field && field[name] && field[name].name}
        value={viewtValue || ''} //field && field[name] && field[name].name}
        onClick={() => setModalShow(true)}
        onFocus={(e) => (e.target.readOnly = true)} //выключение клавиатуры при выборе значения
      />
    </>
  )
}

export default SelectViberUser
