import { createContext } from 'react'

export const UserMenuContext = createContext({
  onClose: () => '',
}) //undefined)

export type UserMenuContextValue = {
  /**
   * Closes the user menu
   * @see UserMenu
   */
  onClose: () => void
}
