import React, { useContext, useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Typography from "@material-ui/core/Typography";
import TextField from "@mui/material/TextField";
import { Divider, useMediaQuery } from "@mui/material";

import {
  Card,
  CardContent,
  Box,
  InputAdornment,
  IconButton,
} from "@mui/material";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { useTranslate } from "../../../lib/translate/i18n/useTranslate";
import { AuthContext } from "../../../context/AuthContext";
import { getURLAPI } from "../../../utils/getURL";

import Brightness1Icon from "@mui/icons-material/Brightness1";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch, { SwitchProps } from "@mui/material/Switch";

import Tooltip from "@mui/material/Tooltip";

import { default as Spinner } from "../../../components/Spinner";

const RabbitMQItem = () => {
  const amqp_item = useSelector((state) => state.app.body.amqp_item);
  const auth = useContext(AuthContext);
  const body = useSelector((state) => state.app.body);
  const access = useSelector((state) => state.app.access.access);
  const isadmin = (access && access.isadmin) || false;
  const isXSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const nameTheme = access && access.user && access.user.theme;
  const translate = useTranslate();

  const config = auth && auth.config;
  let URLAPI = getURLAPI(config);

  //START get all str from table Roles
  const [startRefresh, setStartRefresh] = useState(true);

  //замыкание
  const ws_client = auth.ws_client; //body.ws_client

  const item_id = body.item_id;

  const readonly = body.readonly || false;

  //  const [startWss, setstartWss] = useState(true)

  const GetData_handle = function (_result) {
    const { tableName, result, readonly, action } = _result;
    //console.log('result handle1 ', result)
    dispatch({
      type: "fetch/get_add",
      payload: result,
      ob: "amqp_item",
    });
    dispatch({
      type: "fetch/get_add",
      payload: readonly,
      ob: "readonly",
    });
  };

  const Refresh_handle = async function (result, event, parent) {
    const { tableName, action, metod } = result;
    /*console.log(
      'Refresh_handle ',
      event,
      new Date().toLocaleTimeString(),
      result
    )*/
    setStartRefresh(true);
  };

  const params = {
    URL_WS: getURLAPI(auth.config, "URL_WS"), //get address WebSocket BK from config
    auth: { userId: auth.userId, tocken: auth.token },
    handleGetData: GetData_handle,
    handleChangeData: Refresh_handle,
    //handleSetRefresh: Refresh_handle, //setStartRefresh,
    handleErrorRead: null,
    handleErrorChange: null,
    models: [
      {
        model_call_nm: "Model_read",
        tableName: "RabbitMQ",
        where: { id: item_id },
        action: {
          metod: "getOne",
          isBlocked: true,
          GetStateServer: true,
        },
      },
    ],
  };

  //deblock item whet close page
  useEffect(
    () => () => {
      try {
        if (!readonly)
          ws_client &&
            ws_client.deblock({ tableName: "RabbitMQ", id: item_id });
      } catch (err) {
        console.log("deblock error ", err);
      }
    },
    []
  );

  if (item_id && ws_client && ws_client.ws_login && startRefresh) {
    ws_client.open_process(params);
    setStartRefresh(false);
  }

  //END get all str from table Roles

  let greybkcolor = "rgba(255,255,255,.3)";

  if (nameTheme) {
    greybkcolor =
      nameTheme !== "dark" ? "rgba(255,255,255,1.0)" : "rgba(255,255,255,.3)";
  }
  const dispatch = useDispatch();

  const getChange = () => {
    if (amqp_item && amqp_item.change === true) return "contained";
    else return "outlined";
  };
  const getAccessToChange = () => {
    return readonly || false;
  };

  const getColorStatus = () => {
    let colorstatus = "#cccccc"; //'red'
    if (amqp_item.connect_ok) colorstatus = "lime";
    else colorstatus = "red";
    return colorstatus;
  };

  const ready = () => {
    if (amqp_item) return true;
    else return false;
  };

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  return (
    <>
      {ready() && (
        <>
          <Box maxWidth="30em">
            <Card>
              <CardContent>
                <>
                  <div
                    //            className="hstack gap-2"
                    style={{
                      marginTop: -20,
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography
                      id="outlined-basic"
                      label="Клиент"
                      //variant="standard"
                      className="title h6"
                      style={{
                        marginTop: 15,
                        marginBottom: 15,
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      {translate("resources.amqp.title")}
                    </Typography>
                    {readonly && (
                      <Typography
                        id="outlined-basic"
                        label="Title"
                        //variant="standard"
                        //className="title h6"
                        style={{
                          marginTop: 15,
                          marginBottom: 15,
                          //  marginRight: 275,
                          color: "red",
                        }}
                        //                      {translate('resources.workers.readonly')}
                      >
                        Readonly
                      </Typography>
                    )}

                    <Tooltip
                      title={translate("resources.tooltip.serveraccess")} //"Доступ к серверу"
                      placement="bottom" //"left-end" //top-start"
                    >
                      <Brightness1Icon
                        fontSize="small"
                        style={{
                          marginRight: -10,
                          marginTop: 15,
                          color: getColorStatus(),
                        }}
                      />
                    </Tooltip>
                  </div>

                  {amqp_item && (
                    <>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <TextField
                          id="outlined-basic"
                          label={translate("resources.amqp.fields.name")} //"Название Типа Доступа"
                          //variant="standard"
                          variant="outlined"
                          style={{
                            marginBottom: 15,
                            marginTop: 15,
                          }}
                          value={(amqp_item && amqp_item.name) || ""}
                          onChange={(item) => {
                            if (!getAccessToChange()) {
                              amqp_item.change = true;
                              dispatch({
                                type: "app/set",
                                payload: {
                                  id: amqp_item,
                                  field: "name",
                                  value: item.target.value,
                                },
                                ob: "amqp_item",
                              });
                            }
                          }}
                        />
                        {/*
                        <TextField
                          id="outlined-basic"
                          label={translate("resources.amqp.fields.code")} //"Название Типа Доступа"
                          //variant="standard"
                          variant="outlined"
                          style={{
                            marginBottom: 15,
                            marginTop: 15,
                            marginLeft: 5,
                          }}
                          value={(amqp_item && amqp_item.code) || ""}
                          onChange={(item) => {
                            if (!getAccessToChange()) {
                              amqp_item.change = true;
                              dispatch({
                                type: "app/set",
                                payload: {
                                  id: amqp_item,
                                  field: "code",
                                  value: item.target.value,
                                },
                                ob: "amqp_item",
                              });
                            }
                          }}
                        />
                        */}
                        <FormControlLabel
                          style={{
                            //marginRight: isXSmall ? 10 : 10,
                            marginLeft: 10,
                          }}
                          control={
                            <Switch
                              id={"ismain"}
                              name={"ismain"}
                              color="primary"
                              //                          onFocus={onFocus}
                              checked={
                                (amqp_item && amqp_item.mainserver) || false
                              }
                              onChange={(item, value) => {
                                //if (!getAccessToChange()) {
                                amqp_item.change = true;
                                dispatch({
                                  type: "app/set",
                                  payload: {
                                    id: amqp_item,
                                    field: "mainserver",
                                    value: value,
                                  },
                                  ob: "amqp_item",
                                });
                                //}
                              }}
                              //disabled={getAccessToChange()}
                            />
                          }
                          label={translate("resources.switch.mainserver")}
                        />
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <TextField
                          id="outlined-basic"
                          label={"URL"} //"Название Типа Доступа"
                          //variant="standard"
                          variant="outlined"
                          style={{
                            marginBottom: 15,
                            marginTop: 15,
                          }}
                          value={(amqp_item && amqp_item.url) || ""}
                          onChange={(item) => {
                            if (!getAccessToChange()) {
                              amqp_item.change = true;
                              dispatch({
                                type: "app/set",
                                payload: {
                                  id: amqp_item,
                                  field: "url",
                                  value: item.target.value,
                                },
                                ob: "amqp_item",
                              });
                            }
                          }}
                        />

                        <TextField
                          id="outlined-basic"
                          label={"Port"} //"Название Типа Доступа"
                          //variant="standard"
                          variant="outlined"
                          style={{
                            marginBottom: 15,
                            marginTop: 15,
                            marginLeft: 5,
                          }}
                          value={(amqp_item && amqp_item.port) || ""}
                          onChange={(item) => {
                            if (!getAccessToChange()) {
                              amqp_item.change = true;
                              dispatch({
                                type: "app/set",
                                payload: {
                                  id: amqp_item,
                                  field: "port",
                                  value: item.target.value,
                                },
                                ob: "amqp_item",
                              });
                            }
                          }}
                        />
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <TextField
                          id="outlined-basic"
                          label={"Login"} //"Название Типа Доступа"
                          //variant="standard"
                          variant="outlined"
                          style={{
                            marginBottom: 15,
                            marginTop: 15,
                          }}
                          value={(amqp_item && amqp_item.login) || ""}
                          onChange={(item) => {
                            if (!getAccessToChange()) {
                              amqp_item.change = true;
                              dispatch({
                                type: "app/set",
                                payload: {
                                  id: amqp_item,
                                  field: "login",
                                  value: item.target.value,
                                },
                                ob: "amqp_item",
                              });
                            }
                          }}
                        />

                        <TextField
                          id="outlined-basic25"
                          label="Password"
                          type={showPassword ? "text" : "password"}
                          //variant="standard"
                          variant="outlined"
                          style={{
                            marginBottom: 15,
                            marginTop: 15,
                            marginLeft: 5,
                            width: "50%",
                          }}
                          //defaultValue={(pbx_item && pbx_item.MYSQLPASS) || ''}
                          value={(amqp_item && amqp_item.psw) || ""}
                          onChange={(item) => {
                            //            pbx_item.change = true
                            //if (!getAccessToChange())
                            dispatch({
                              type: "app/set",
                              payload: {
                                id: amqp_item,
                                field: "psw",
                                value: item.target.value,
                              },
                              ob: "amqp_item",
                            });
                          }}
                          InputProps={{
                            // <-- This is where the toggle button is added.
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={handleClickShowPassword}
                                  onMouseDown={handleMouseDownPassword}
                                >
                                  {showPassword ? (
                                    <Visibility />
                                  ) : (
                                    <VisibilityOff />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </div>
                      <TextField
                        id="outlined-basic"
                        label={translate("resources.amqp.fields.comments")} //"Коментарии"
                        //variant="standard"
                        variant="outlined"
                        style={{
                          marginBottom: 25,
                          marginTop: 15,
                          display: "flex",
                          //justifyContent: "space-between",
                        }}
                        value={(amqp_item && amqp_item.comments) || ""}
                        onChange={(item) => {
                          if (!getAccessToChange()) {
                            amqp_item.change = true;
                            dispatch({
                              type: "app/set",
                              payload: {
                                id: amqp_item,
                                field: "comments",
                                value: item.target.value,
                              },
                              ob: "amqp_item",
                            });
                          }
                        }}
                      />
                      <Divider
                        style={{
                          marginTop: 15,
                          //marginBottom: 15,
                          //  marginRight: 275,
                        }}
                      />
                      <Typography
                        id="outlined-basic"
                        label="Title"
                        //variant="standard"
                        //className="title h6"
                        style={{
                          marginTop: 15,
                          //marginBottom: 15,
                          //  marginRight: 275,
                          //color: "red",
                        }}
                        //                      {translate('resources.workers.readonly')}
                      >
                        Queues
                      </Typography>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <TextField
                          id="outlined-basic"
                          label={translate(
                            "resources.amqp.fields.sendsmsqueue"
                          )} //"Название Типа Доступа"
                          //variant="standard"
                          variant="outlined"
                          style={{
                            marginBottom: 15,
                            marginTop: 15,
                          }}
                          value={(amqp_item && amqp_item.sendsmsqueue) || ""}
                          onChange={(item) => {
                            if (!getAccessToChange()) {
                              amqp_item.change = true;
                              dispatch({
                                type: "app/set",
                                payload: {
                                  id: amqp_item,
                                  field: "sendsmsqueue",
                                  value: item.target.value,
                                },
                                ob: "amqp_item",
                              });
                            }
                          }}
                        />

                        <TextField
                          id="outlined-basic"
                          label={translate(
                            "resources.amqp.fields.receivesmsqueue"
                          )} //"Название Типа Доступа"
                          //variant="standard"
                          variant="outlined"
                          style={{
                            marginBottom: 15,
                            marginTop: 15,
                            marginLeft: 5,
                          }}
                          value={(amqp_item && amqp_item.receivesmsqueue) || ""}
                          onChange={(item) => {
                            if (!getAccessToChange()) {
                              amqp_item.change = true;
                              dispatch({
                                type: "app/set",
                                payload: {
                                  id: amqp_item,
                                  field: "receivesmsqueue",
                                  value: item.target.value,
                                },
                                ob: "amqp_item",
                              });
                            }
                          }}
                        />
                      </div>

                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <TextField
                          id="outlined-basic"
                          label={translate("resources.amqp.fields.rpc_send")} //"Название Типа Доступа"
                          //variant="standard"
                          variant="outlined"
                          style={{
                            marginBottom: 15,
                            marginTop: 15,
                          }}
                          value={(amqp_item && amqp_item.rpc_send) || ""}
                          onChange={(item) => {
                            if (!getAccessToChange()) {
                              amqp_item.change = true;
                              dispatch({
                                type: "app/set",
                                payload: {
                                  id: amqp_item,
                                  field: "rpc_send",
                                  value: item.target.value,
                                },
                                ob: "amqp_item",
                              });
                            }
                          }}
                        />

                        <TextField
                          id="outlined-basic"
                          label={translate("resources.amqp.fields.rpc_respond")} //"Название Типа Доступа"
                          //variant="standard"
                          variant="outlined"
                          style={{
                            marginBottom: 15,
                            marginTop: 15,
                            marginLeft: 5,
                          }}
                          value={(amqp_item && amqp_item.rpc_response) || ""}
                          onChange={(item) => {
                            if (!getAccessToChange()) {
                              amqp_item.change = true;
                              dispatch({
                                type: "app/set",
                                payload: {
                                  id: amqp_item,
                                  field: "rpc_response",
                                  value: item.target.value,
                                },
                                ob: "amqp_item",
                              });
                            }
                          }}
                        />
                      </div>

                      {amqp_item && amqp_item.connect_ok && (
                        <>
                          <Divider
                            style={{
                              marginTop: 15,
                              //marginBottom: 15,
                              //  marginRight: 275,
                              //color: "red",
                            }}
                          />
                          <Typography
                            id="outlined-basic"
                            label="Title"
                            //variant="standard"
                            //className="title h6"
                            style={{
                              marginTop: 15,
                              //marginBottom: 15,
                              //  marginRight: 275,
                              //color: "red",
                            }}
                            //                      {translate('resources.workers.readonly')}
                          >
                            Info about server RabbitMQ
                          </Typography>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <TextField
                              id="outlined-basic"
                              label={"cluster_name"}
                              variant="outlined"
                              style={{
                                marginBottom: 15,
                                marginTop: 15,
                              }}
                              value={
                                (amqp_item && amqp_item.cluster_name) || ""
                              }
                            />

                            <TextField
                              id="outlined-basic"
                              label={"Product - Version"}
                              variant="outlined"
                              style={{
                                marginBottom: 15,
                                marginTop: 15,
                                marginLeft: 5,
                              }}
                              value={
                                (amqp_item &&
                                  amqp_item.product +
                                    " - " +
                                    amqp_item.version) ||
                                ""
                              }
                            />
                          </div>
                        </>
                      )}
                      {amqp_item &&
                        !amqp_item.connect_ok &&
                        amqp_item.connect_error && (
                          <TextField
                            id="outlined-basic"
                            label={"Connection ERROR"}
                            variant="outlined"
                            style={{
                              marginBottom: 15,
                              marginTop: 15,
                              display: "flex",
                              justifyContent: "space-between",
                              backgroundColor: "#ff00001f",
                            }}
                            value={(amqp_item && amqp_item.connect_error) || ""}
                          />
                        )}
                    </>
                  )}

                  <>
                    <Divider
                      style={{
                        marginTop: 15,
                        marginBottom: 15,
                        //  marginRight: 275,
                      }}
                    />
                    <Button
                      size="small"
                      component={Link}
                      color="primary"
                      //variant="outlined"
                      sx={{ display: "inline-flex", alignItems: "center" }}
                      variant={getChange()} //"outline-success"
                      to={{
                        pathname: "/auth/amqp/list",
                      }}
                      //state={amqp_item}
                      disabled={getAccessToChange()}
                      onClick={async () => {
                        try {
                          if (!getAccessToChange())
                            await ws_client.wss_setItem({
                              tableName: "RabbitMQ",
                              item: amqp_item,
                              call_nm: "Model_update",
                              where: { id: amqp_item.id },
                              action: { metod: "set" },
                            });
                        } catch (e) {
                          console.log("errr e", e);
                        }
                      }}
                    >
                      {translate("resources.button.save")}
                    </Button>{" "}
                    <Button
                      size="small"
                      component={Link}
                      color="primary"
                      variant="outlined"
                      sx={{ display: "inline-flex", alignItems: "center" }}
                      //variant={getChange()} //"outline-success"
                      to={{
                        pathname: "/auth/amqp/list",
                      }}
                    >
                      {translate("resources.button.cancel")}
                    </Button>{" "}
                    <br />
                  </>
                </>
              </CardContent>
            </Card>
          </Box>
        </>
      )}
      {!ready() && (
        <>
          <Box maxWidth="30em">
            <Card>
              <CardContent>
                <Spinner />
              </CardContent>
            </Card>
          </Box>
        </>
      )}
    </>
  );
};

export default RabbitMQItem;
