import React, { useContext } from 'react'
import { Nav, Navbar, NavDropdown } from 'react-bootstrap'
import { NavLink } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { AuthContext } from '../context/AuthContext'

import {
  Box,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Typography,
  useMediaQuery,
  Theme,
} from '@mui/material'
import SettingsIcon from '@mui/icons-material/Settings'
import ExitIcon from '@mui/icons-material/PowerSettingsNew'
import {
  Link,
  //,
  //  useLocation,
  Navigate,
  //  Outlet,
  //  useNavigate,
  //  unstable_HistoryRouter as HistoryRouter,
} from 'react-router-dom'

import { useTranslate } from '../lib/translate/i18n/useTranslate'

const LogoutMenu = React.forwardRef((props, ref) => {
  const translate = useTranslate()
  return (
    <MenuItem
      component={Link}
      // @ts-ignore
      ref={ref}
      {...props}
      to="/auth/auth/logout"
    >
      <ListItemIcon>
        <ExitIcon />
      </ListItemIcon>
      <ListItemText>{translate('pos.menu.logout')}</ListItemText>
    </MenuItem>
  )
})

export default LogoutMenu
