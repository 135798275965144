import React from 'react'
import { URL_API, FILE_API, URL_WS } from '../globals'

export const getBASEURL = () => {
  let urlbase = new URL(document.baseURI)
  let baseurl = urlbase.protocol + '//' + urlbase.host //+ '/'
  return baseurl
}

export const getURLAPI = (config, typeurl) => {
  let rez = URL_API
  if (typeurl === 'FILE_API') rez = FILE_API
  if (typeurl === 'URL_WS') rez = URL_WS

  if (config) {
    let FILE_API1
    let URL_API1
    let URL_WS1
    let conf = config['config']
    for (let c in conf) {
      if (conf[c]['type'] === 'URL_API') {
        URL_API1 = conf[c]['url']
      }
      if (conf[c]['type'] === 'FILE_API') {
        FILE_API1 = conf[c]['url']
      }
      if (conf[c]['type'] === 'URL_WS') {
        URL_WS1 = conf[c]['url']
      }
    }

    if (typeurl) {
      if (typeurl === 'URL_API') {
        return URL_API1
      }
      if (typeurl === 'FILE_API') {
        return FILE_API1
      }
      if (typeurl === 'URL_WS') {
        return URL_WS1
      }
    } else {
      return URL_API1
    }
  }

  return rez
}

const GetURL = (url, config, typeurl) => {
  let FILE_API
  let URL_API
  if (config) {
    let conf = config['config']
    for (let c in conf) {
      if (conf[c]['type'] === 'URL_API') {
        URL_API = conf[c]['url']
      }
      if (conf[c]['type'] === 'FILE_API') {
        FILE_API = conf[c]['url']
      }
    }
  }

  let rez = url

  try {
    if (typeurl) {
      if (typeurl === 'URL_API') {
        return URL_API
      }
      if (typeurl === 'FILE_API') {
        return FILE_API
      }
    } else {
      return URL_API
    }
    return rez
  } catch (error) {
    console.log(error)
    return rez
  }
}

/*
interface Data {
  name: string
  website: string
  email: string
  country: [{
    id: string
    name: string
  }]
}
*/
/*
export const UsersDetails = () =>{
const [data, setData] = useState<Data>()
const fetchJson = () => {
  fetch('./data.json')
  .then(response => {
    return response.json();
  }).then(data => {
    setData(data);
  }).catch((e: Error) => {
    console.log(e.message);
  });
}
useEffect(() => {
  fetchJson()
},[])
return(
  <>
  <div>
    <div>Name : {data?.name}</div>
    <div>Email : {data?.email}</div>
    <div>Website : {data?.website}</div>
    <div><label>Country :</label>
    <select>
      {data?.country?.map((country) => {
        return <option key={country.id} value={country.id}>{country.name}</option>
      })}
    </select>
    </div>
  </div>
  </>
)
}
*/
export default GetURL
