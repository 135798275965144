import { Action, History, Location } from 'history'
import React, { useContext, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Router } from 'react-router'
import { Middleware, Reducer } from 'redux'

import { URL_API } from '../globals'
import { AuthContext } from '../context/AuthContext'
import { getURLAPI } from '../utils/getURL'

// Actions

export type Methods = 'push' | 'replace' | 'go' | 'back' | 'forward'

/**
 * This action type will be dispatched when your history
 * receives a location change.
 */
export const ROUTER_ON_LOCATION_CHANGED = '@@router/ON_LOCATION_CHANGED'

export type LocationChangeAction = {
  type: typeof ROUTER_ON_LOCATION_CHANGED
  payload: {
    location: Location
    action: Action
  }
}

export const onLocationChanged = (
  location: Location,
  action: Action
): LocationChangeAction => ({
  type: ROUTER_ON_LOCATION_CHANGED,
  payload: { location, action },
})

/**
 * This action type will be dispatched by the history actions below.
 * If you're writing a middleware to watch for navigation events, be sure to
 * look for actions of this type.
 */
export const ROUTER_CALL_HISTORY_METHOD = '@@router/CALL_HISTORY_METHOD'

export type UpdateLocationAction<M extends Methods = Methods> = {
  type: typeof ROUTER_CALL_HISTORY_METHOD
  payload: {
    method: M
    args: Parameters<History[M]>
  }
}

function updateLocation<M extends Methods = Methods>(method: M) {
  return (...args: Parameters<History[M]>): UpdateLocationAction<M> => ({
    type: ROUTER_CALL_HISTORY_METHOD,
    payload: { method: method, args },
  })
}

/**
 * Pushes a new location onto the history stack, increasing its length by one.
 * If there were any entries in the stack after the current one, they are
 * lost.
 *
 * @param to - The new URL
 * @param state - Data to associate with the new location
 */
export const push = updateLocation('push')

/**
 * Replaces the current location in the history stack with a new one.  The
 * location that was replaced will no longer be available.
 *
 * @param to - The new URL
 * @param state - Data to associate with the new location
 */
export const replace = updateLocation('replace')

/**
 * Navigates to the next entry in the stack. Identical to go(1).
 */
export const go = updateLocation('go')

/**
 * Goes back one entry in the history stack. Identical to go(-1).
 */
export const back = updateLocation('back')

/**
 * Navigates to the next entry in the stack. Identical to go(1).
 */
export const forward = updateLocation('forward')

export const routerActions = {
  push,
  replace,
  go,
  back,
  forward,
}

export type RouterActions = LocationChangeAction | UpdateLocationAction

// Middleware

export function createRouterMiddleware(history: History): Middleware {
  return () =>
    (next) =>
    (
      action: ReturnType<
        typeof push & typeof replace & typeof go & typeof back & typeof forward
      >
    ) => {
      if (action.type !== ROUTER_CALL_HISTORY_METHOD) {
        return next(action)
      }
      history[action.payload.method](...action.payload.args)
    }
}

// Reducer

export type ReduxRouterState = {
  location: Location
  action: Action
}

export function createRouterReducer(
  history: History,
  url: string
): Reducer<ReduxRouterState, RouterActions> {
  const initialRouterState: ReduxRouterState = {
    location: history.location,
    action: history.action,
    //    URLAPI: url,
  }

  /*
   * This reducer will update the state with the most recent location history
   * has transitioned to.
   */
  return (state = initialRouterState, action: RouterActions) => {
    if (action.type === ROUTER_ON_LOCATION_CHANGED) {
      return action.payload
    }

    return state
  }
}

export type ReduxRouterSelector<T = any> = (state: T) => ReduxRouterState

export type ReduxRouterStoreState = { router: ReduxRouterState }

export function reduxRouterSelector<
  T extends ReduxRouterStoreState = ReduxRouterStoreState
>(state: T): ReduxRouterState {
  return state.router
}

// Component

export type ReduxRouterProps = {
  history: History
  basename?: string
  children: React.ReactNode
  store?: any
  routerSelector?: ReduxRouterSelector
}

export function ReduxRouter({
  routerSelector = reduxRouterSelector,
  ...props
}: ReduxRouterProps) {
  const dispatch = useDispatch()
  const skipHistoryChange = useRef<boolean>()
  const state = useSelector(routerSelector)
  let window = document.defaultView!
  let globalHistory = window.history
  const auth = useContext(AuthContext)

  useEffect(() => {
    const config = auth.config
    let URLAPI = getURLAPI(config)

    const listener = props.history.listen((nextState) => {
      if (skipHistoryChange.current === true) {
        skipHistoryChange.current = false
        return
      }
      let nextlocation = { ...nextState.location, urlapi: URLAPI }
      dispatch(onLocationChanged(nextlocation, nextState.action))
      //      dispatch(onLocationChanged(nextState.location, nextState.action))
    })
    if (props.history.location !== state.location || true) {
      let historylocation = { ...props.history.location, urlapi: URLAPI }
      dispatch(onLocationChanged(historylocation, props.history.action))

      //      dispatch(onLocationChanged(props.history.location, props.history.action))
    }
    return listener
  }, [props.history])

  useEffect(() => {
    if (skipHistoryChange.current === undefined) {
      skipHistoryChange.current = false
    } else if (props.history.location !== state.location) {
      skipHistoryChange.current = true
      props.history.replace(state.location)
    }
  }, [state])

  return (
    <Router
      navigationType={state.action}
      location={state.location}
      basename={props.basename}
      navigator={props.history}
      children={props.children}
    />
  )
}
