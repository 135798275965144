import React, { useContext, useState, useEffect } from 'react'
import { Form, Modal, ListGroup, Row, Col } from 'react-bootstrap'
import { useSelector, useDispatch } from 'react-redux'
import TextField from '@mui/material/TextField'
import { useTranslate } from '../lib/translate/i18n/useTranslate'
import { AuthContext } from '../context/AuthContext'
import { getURLAPI } from '../utils/getURL'

const selectItem = (select, Item) => {
  select(Item)
}

const ItemList = ({ Item, select }) => (
  <ListGroup.Item key={Item.id} onClick={() => selectItem(select, Item)}>
    <Row>
      <Col xs={12}>{Item.name}</Col>
    </Row>
  </ListGroup.Item>
)

const SelectControl = ({
  field,
  name,
  title,
  placeholder,
  value,
  saved,
  onChanged,
  disabled = false,
}) => {
  const [modalShow, setModalShow] = React.useState(false)
  const [filter, setFilter] = useState('')
  const dispatch = useDispatch()
  const translate = useTranslate()
  const auth = useContext(AuthContext)
  const config = auth.config
  let URLAPI = getURLAPI(config)

  const access = useSelector((state) => state.app.access.access)
  const nameTheme = access && access.user && access.user.theme
  let greybkcolor = 'rgba(255,255,255,.3)'

  if (nameTheme) {
    greybkcolor =
      nameTheme !== 'dark' ? 'rgba(255,255,255,1.0)' : 'rgba(255,255,255,.3)'
  }

  const ws_client = auth.ws_client

  //  const item_id = body.item_id

  //  const readonly = body.readonly

  const GetData_handle = function (_result) {
    const { tableName, result, readonly, action } = _result
    //console.log('result handle1 ', result)
    dispatch({
      type: 'fetch_current/get',
      payload: result,
      ob: name, //'phone',
    })
  }

  let tableName = 'TypeWorks'

  if (name === 'worker') tableName = 'Workers'
  if (name === 'typework') tableName = 'TypeWorks'
  if (name === 'workplace') tableName = 'WorkPlaces'
  if (name === 'cloudtype') tableName = 'CloudTypes'
  if (name === 'unittype') tableName = 'UnitTypes'

  const params = {
    URL_WS: getURLAPI(auth.config, 'URL_WS'), //get address WebSocket BK from config
    auth: { userId: auth.userId, tocken: auth.token },
    handleGetData: GetData_handle,
    //handleChangeData: Refresh_handle,
    //handleSetRefresh: setStartRefresh,
    models: [
      {
        model_call_nm: 'Model_read',
        tableName: tableName,
        action: {
          //          metod: 'getRoles',
        },
      },
    ],
  }

  //*********** */

  useEffect(() => {
    if (modalShow) {
      ws_client.open_process(params)
    }

    //    modalShow && dispatch({ type: 'FETCH_LIST', urlapi: URLAPI, ob: name })
  }, [modalShow])

  const select = (Item) => {
    onChanged && onChanged()
    setModalShow(false)
    dispatch({
      type: 'app/set',
      payload: {
        id: field,
        field: `${name}id`,
        value: Item,
        ob: name,
      },
      ob: value,
    })
    if (saved) saved(Item)
  }

  const list = useSelector((state) => state.app.current[name]) || []
  return (
    <>
      <Modal
        show={modalShow && !disabled}
        onHide={() => setModalShow(false)}
        size="lg"
        aria-labelledby={`contained-modal-${name}-vcenter`}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id={`contained-modal-${name}-vcenter`}>
            {title}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <TextField
            id="outlined-basic"
            label={translate('resources.select.setname')} //"Введите найменование"
            //variant="standard"
            variant="outlined"
            style={{
              marginBottom: 15,
              display: 'flex',
              justifyContent: 'space-between',
            }}
            defaultValue={filter}
            onChange={(item) => setFilter(item.target.value)}
          />
          <ListGroup style={{ minHeight: 500 }}>
            {list
              .filter(
                (item) =>
                  filter === '' ||
                  (item.name &&
                    item.name.toLowerCase().includes(filter.toLowerCase()))
              )
              .map((Item) => (
                <ItemList key={Item.id} Item={Item} select={select} />
              ))}
          </ListGroup>
        </Modal.Body>
      </Modal>

      <TextField
        readOnly
        id="outlined-basic"
        label={placeholder}
        //variant="standard"
        variant="outlined"
        style={{
          marginBottom: 15,
          display: 'flex',
          justifyContent: 'space-between',
          backgroundColor: greybkcolor,
        }}
        //defaultValue={(field && field[name] && field[name].name) || ''}
        disabled={disabled}
        value={(field && field[name] && field[name].name) || ''}
        onClick={() => setModalShow(true)}
        onFocus={(e) => (e.target.readOnly = true)} //выключение клавиатуры при выборе значения
      />
    </>
  )
}

export default SelectControl
