import inflection from 'inflection'

import { useResourceDefinitions } from './useResourceDefinitions'

export const useGetResourceLabel = (): GetResourceLabel => {
  //    const translate = useTranslate();
  const definitions = useResourceDefinitions()

  return (resource: string, count = 2): string => {
    const resourceDefinition = definitions[resource]

    const label =
      resourceDefinition &&
      resourceDefinition.options &&
      resourceDefinition.options.label
        ? resourceDefinition.options.label
        : inflection.humanize(
            count > 1
              ? inflection.pluralize(resource)
              : inflection.singularize(resource)
          )
    return label
  }
}

export type GetResourceLabel = (resource: string, count?: number) => string
